import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';

import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';

import db from '../../engine/db';

import PageHeader from '../../../../components/PageHeader';

const Container = styled.div`
  background: #f7f7f7;
  box-sizing: border-box;
`;

const ConfigRow = styled.div`
  padding: 12px;

  .btn {
    opacity: 0;
  }
  &:hover {
    background: #f7f7f7;
  }
  &:hover .btn {
    opacity: 1;
  }
`;

class EditCard extends React.Component {
  static defaultProps = {
    defaultKey: '',
    defaultValue: '',
  }

  save = () => {
    const key = this.parameterKey.value;
    const value = this.parameterValue.value;
    this.props.onSave(key, value);
  }

  render() {
    const { defaultKey, defaultValue, onCancel } = this.props; 
    return (
      <Card style={{ marginLeft: '-10%', width: '120%', position: 'relative', zIndex: 1 }}>
        <CardContent>
          <Grid container spacing={24}>
            <Grid item sm={4}>
              <TextField label="Parameter Key" inputRef={(node) => { this.parameterKey = node }} defaultValue={defaultKey} />
            </Grid>
            <Grid item sm={8}>
              <TextField fullWidth label="Parameter Value" inputRef={(node) => { this.parameterValue = node }} defaultValue={defaultValue} />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" color="default" onClick={onCancel}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={this.save}>Save</Button>
        </CardActions>
      </Card>
    );
  }
}

export default class extends React.Component {
  state = {
    showAddNew: false,
    focusedKey: '',
    configs: {},
  }

  componentDidMount() {
    const { buildingId } = this.props;
    const buildingRef = db.collection('meerkat_projects').doc(buildingId);
    buildingRef.onSnapshot(doc => {
      const data = doc.data();
      this.setState({
        configs: _.mapValues(_.get(data, 'app_config', {}), (value, key) => ({ value, key })),
      });
    });
  }

  save = (key, value) => {
    this.setState({
      focusedKey: '',
      showAddNew: false,
    });

    const { buildingId } = this.props;
    const buildingRef = db.collection('meerkat_projects').doc(buildingId);
    buildingRef.get()
      .then(doc => doc.data())
      .then(buildingData => {
        buildingRef.update({
          app_config: { ...buildingData.app_config, [key]: value },
        });
      });
  }

  cancelEdit = () => {
    this.setState({
      focusedKey: '',
      showAddNew: false,
    });
  }
  

  addItem = () => {
    this.setState({
      showAddNew: true,
    })
  }

  editItem = (keyToEdit) => {
    this.setState({
      focusedKey: keyToEdit,
      showAddNew: false,
    });
  }

  deleteItem = (keyToDelete) => {
    const { buildingId } = this.props;
    const buildingRef = db.collection('meerkat_projects').doc(buildingId);
    buildingRef.get()
      .then(doc => doc.data())
      .then(buildingData => {
        buildingRef.update({
          app_config: _.omit(buildingData.app_config, [keyToDelete]),
        });
      });
  }

  render() {
    const { showAddNew, configs, focusedKey } = this.state;
    return (
      <Container>
        <PageHeader
          title="App Config"
          renderFloatingActionButtons={() => {
            return !showAddNew && (
              <Button color="primary" variant="extendedFab" onClick={this.addItem}>Add Parameter</Button>
            ); 
          }}
        />
        <div style={{ maxWidth: 600, margin: '50px auto', padding: 12 }}>
          {showAddNew && (
            <EditCard onSave={this.save} onCancel={this.cancelEdit} />
          )}
          {_.map(configs, config =>
            config.key === focusedKey ? (
              <EditCard key={config.key} defaultKey={config.key} defaultValue={config.value} onSave={this.save} onCancel={this.cancelEdit} />
            ) : (
              <Card key={config.key}>
                <ConfigRow>
                  <Grid container alignItems="center">
                    <Grid item sm={5}>
                      {config.key}
                    </Grid>
                    <Grid item sm={4}>
                      {config.value} 
                    </Grid>
                    <Grid item sm={3} style={{ textAlign: 'right' }}>
                      <IconButton className="btn" onClick={() => this.editItem(config.key)}><IconEdit /></IconButton>
                      <IconButton className="btn" onClick={() => this.deleteItem(config.key)}><IconDelete /></IconButton>
                    </Grid>
                  </Grid>
                </ConfigRow>
              </Card>
            )
          )}
        </div>
      </Container>
    );
  }
}
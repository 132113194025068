import React from 'react';
import ResizeRect from './ResizeRect';
import map from 'lodash/map';
import uuidv1 from 'uuid/v1';

const defaultShelf = {
  id: uuidv1(),
  width: parseInt(200 , 10),
  height: parseInt(200 , 10),
  x: parseInt(0 , 10),
  y: parseInt(0 , 10)
};

const defaultNode = {
  id: uuidv1(),
  x: 0,
  y: 0,
  r: 2,
};

export default class extends React.Component {
  static defaultProps = {

  }

  state = {
    shelfs: [defaultShelf],
    nodes: [
      {
        id: 0,
        x: parseInt(0 , 10),
        y: parseInt(0 , 10)
      }
    ],
  }

  handleRectUpdate = shelfProp => {
    // console.log('blockProp', blockProp)
    // console.log('state', this.state.shelfs)
    this.setState({
      shelfs: this.state.shelfs.map(shelf => {
        if (shelf.id === shelfProp.id) return { ...shelf, ...shelfProp };
        return shelf;
      })
    });
  };

  handleMediaPopup = () => {

  }

  addShelf = () => {
    const { shelfs } = this.state;
    shelfs.push(defaultShelf);
    this.setState({ shelfs });
  }

  addNode = () => {
    const { nodes } = this.state;
    nodes.push(defaultNode);
    this.setState({ nodes });
  }

  render() {
    const { shelfs } = this.state;
    // console.log('shelfs', shelfs);
    return (
      <div>
        <div>
          <button onClick={() => this.addShelf()}>Add Shelf</button>
          <button onClick={() => this.addNode()}>Add Node</button>
        </div>
        <svg 
          viewBox={`0 0 ${1080} ${1920}`}
          width={400}
          height={711.11}
        >
          {map(shelfs, (rect, i) => (
            <ResizeRect
              key={i}
              {...rect}
              onUpdate={rect => this.handleRectUpdate(rect)}
              onOpenMediaPopup={o => this.handleMediaPopup(true, rect)}
            />
          ))}
        </svg>
      </div>
      
    );
  }
}
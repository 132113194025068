import { createMuiTheme } from '@material-ui/core/styles';

export const light = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#EB6323',
    },
    secondary: {
      main: '#ff3b3b',
    }
  },
  props: {
    MuiListItem: {
      activeClassName: 'active'
    }
  }
});

export const dark = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#EB6323',
    },
    secondary: {
      main: '#ff3b3b',
    }
  },
  props: {
    MuiListItem: {
      activeClassName: 'active'
    }
  }
})

export default {
  light,
  dark
}
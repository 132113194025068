import { createMuiTheme } from '@material-ui/core/styles';

export const primaryColor = '#1e88e5';

export default createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: '#333333',
    }
  },

  overrides: {
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: 20,
      }
    }
  }
});
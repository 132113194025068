import React from 'react';
import _ from 'lodash';
import { notify } from 'react-notify-toast';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddIcon from '@material-ui/icons/Add';

import PageHeader from '../../../../components/PageHeader';

const inviteUserToResource = (email, resourceName, role) => {
  return fetch(`${process.env.REACT_APP_CLOUDFUNCTIONURL}/iam/users/${email}/roles`, {
    method: 'POST',
    body: JSON.stringify({
      resourceName,
      role,
    })
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 'error') throw response;
      return response;
    })
}

export default class extends React.Component {
  static defaultProps = {
    resourceName: '',
  }
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: true,
      openInviteDialog: false,
    };

    this.inputEmail = React.createRef();
    this.inputRole = React.createRef();
  }

  fetchUsers = () => {
    const { resourceName } = this.props;
    fetch(`${process.env.REACT_APP_CLOUDFUNCTIONURL}/iam/resources/${resourceName}/users`)
    .then(response => response.json())
    .then(response => {
      this.setState({
        loading: false,
        users: response.data,
      });
    })
  }
  
  componentDidMount() {
    this.fetchUsers();    
  }

  handleSubmitInvite = () => {
    const { resourceName } = this.props;
    const email = this.inputEmail.current.value;
    const role = this.inputRole.current.value;
    this.closeDialog();
    inviteUserToResource(email, resourceName, role)
      .then(response => {
        notify.show(`Invited ${email} to resource as ${role}`, 'success', 4000);

        this.fetchUsers();
      })
      .catch(error => {
        notify.show(`Error ${error.message}`, 'error', 4000);
      });
  }

  openDialog = () => this.setState({ openInviteDialog: true })
  closeDialog = () => {
    this.setState({ openInviteDialog: false });
  }

  render() {
    const { users, loading } = this.state;
    return (
      <div>
        <PageHeader
          title="Members"
          renderFloatingActionButtons={() => (
            <Button variant="extendedFab" color="primary" onClick={this.openDialog}><AddIcon /> Invite Member</Button>
          )}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={80}></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && <TableRow><TableCell colSpan={3}><LinearProgress /></TableCell></TableRow>}
            {_.map(users, user => (
              <TableRow key={user.uid}>
                <TableCell>
                  {user.photoURL &&
                    <div style={{ padding: '10px 0' }}>
                      <Avatar src={user.photoURL} alt={user.displayName || user.email} style={{ width: 50, height: 50 }} />
                    </div>
                  }
                </TableCell>
                <TableCell>{user.displayName || user.email}</TableCell>
                <TableCell>{_.capitalize(user.role)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog
          open={this.state.openInviteDialog}
          onClose={this.closeDialog}
          maxWidth="sm"
          aria-labelledby="form-invite-member"
        >
          <DialogTitle id="form-invite-member">Invite a member</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item sm={8}>
                <TextField label="Email" inputRef={this.inputEmail} fullWidth autoFocus />
              </Grid>
              <Grid item sm={4}>
                <TextField label="Role" select SelectProps={{ native: true }} inputRef={this.inputRole} fullWidth>
                  <option value="admin">Admin</option>
                  <option value="editor">Editor</option>
                  <option value="viewer">Viewer</option>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeDialog}>Cancel</Button>
            <Button color="primary" onClick={this.handleSubmitInvite}>Invite</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
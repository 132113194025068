import React from "react";
import _ from "lodash";
import {ThemeProvider} from "styled-components";
import styled from "styled-components";
import SlickSlide from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import db from "../../engine/db";
import anime from "animejs";
import TrapezoidHeader from "./TrapezoidHeader";

const Container = styled.div`
  color: white;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.primary};
  padding: 0 15px;
  padding-bottom: 15px;
  h2 {
    color: ${props => props.theme.sub_title};
    font-size: 24px;
  }

  .slick-dots {
    bottom: -25px;
    li button:before {
      color: white;
    }
  }
`;

const ContainerSlider = styled.div`
  flex: 1;
  width: 100%;
  .slider-image {
    width: 100%;
  }
`;

const ContainnerTitle = styled.div`
  padding-bottom: 30px;
  align-items: center;
  display: flex;
  flex-direction: column;

  .title-id {
    color: ${props => props.theme.title};
    font-size: 24px;
  }
  .title-function {
    color: ${props => props.theme.sub_title};
    padding-top: 15px;
    font-size: 15px;
  }
  .title-size {
    padding-top: 15px;
    color: ${props => props.theme.body};
    font-size: 15px;
  }
`;

const ContainerSection = styled.div`
  width: 100%;
  flex: 1;
  font-size: 20px;
  img {
    width: 100%;
  }
  .floorPlan {
    overflow: hidden;
  }
  .floorPlanSubTitle {
    padding-top: 15px;
    color: ${props => props.theme.body};
    font-size: 15px;
  }
`;

export default class extends React.Component {
  state = {
    unit: null,
    unitType: null
  };
  async componentDidMount() {
    const { buildingId, unitId } = this.props.match.params;

    // Get Unit
    const unitRef = db
      .collection("cic_spaces")
      .doc(buildingId)
      .collection("entries")
      .doc(unitId);
    const unit = await unitRef.get().then(doc => doc.data());

    // Get Floor > units (for drawing)
    const floorPlateRef = db
      .collection("meerkat_projects")
      .doc(buildingId)
      .collection("floorplates");

      const getAllFloorRef = await floorPlateRef.get().then(querySnapshot =>
        _.reduce(querySnapshot.docs, (acc, doc) => {
            return ({
              ...acc,
              [doc.id]: doc.data()
            })
          }
      ));
    const getCodeFloor = _.find(getAllFloorRef, (eachFloor) => {
      if(_.includes(eachFloor.floors, _.get(unit, "fields.floor"))){
        return eachFloor
      }
    }).code
    
    const getFloorRef = floorPlateRef.doc(getCodeFloor);
    const floorplate = await getFloorRef.get().then(doc => doc.data());
    const floorPlateUnitsRef = getFloorRef.collection("units");
    const floorplateUnits = await floorPlateUnitsRef
      .get()
      .then(querySnapshot => {
        const data = _.map(querySnapshot.docs, doc => doc.data());
        return data;
      });

    // Get UnitType
    const unitTypeId = _.get(unit, "fields.room-type");
    const unitTypeRef = db
      .collection("meerkat_projects")
      .doc(buildingId)
      .collection("unitTypes")
      .doc(unitTypeId);
    const unitType = await unitTypeRef.get().then(doc => doc.data());

    // Get Media
    const mediasRef = db
      .collection("meerkat_projects")
      .doc(buildingId)
      .collection("medias");
    const medias = await mediasRef.get().then(querySnapshot =>
      _.reduce(querySnapshot.docs, (acc, doc) => ({
        ...acc,
        [doc.id]: doc.data()
      }))
    );

    // Get theme
    const theme = db.collection("meerkat_projects").doc(buildingId);
    const themeColor = await theme.get().then(doc => doc.data());

    // Set all data 
    this.setState({
      unit,
      unitFloorPlan: floorplateUnits,
      unitType: {
        ...unitType,
        gallery: _.map(unitType.gallery, mediaKey => _.get(medias, mediaKey))
      },
      floorplate: {
        ...floorplate,
        units: floorplateUnits
      },
      medias,
      themeColor: _.get(themeColor, "theme_setting.palette")
    });

    anime({
      targets: "#opacityFlash",
      direction: "alternate",
      opacity: 0.8,
      duration: 1000,
      easing: "linear",
      loop: true
    });
  }

  renderPolygon = (allUnits, hightlightedUnit) => {
    const createPolygon = _.map(allUnits, (unit, key) => {
      const opacity = 0.85;
        let fillColor =
        unit.id === _.get(hightlightedUnit, 'fields.floorplateUnitRef',"")
          ? "#30C3CA"
          : "transparent";
      switch (unit.type) {
        case "polygon":
          return (
            <polygon
              key={key}
              className="unit"
              id={unit.name}
              points={unit.points}
              fill={fillColor}
              opacity={opacity}
            />
          );
        case "path":
          return (
            <path
              key={key}
              className="unit"
              id={unit.name}
              d={unit.d}
              fill={fillColor}
              opacity={opacity}
            />
          );
        default:
          return (
            <rect
              key={key}
              className="unit"
              id={unit.name}
              x={unit.x}
              y={unit.y}
              width={unit.width}
              height={unit.height}
              fill={fillColor}
              opacity={opacity}
            />
          );
      }
    });
    return createPolygon;
  };

  getGalleryFromRoomType = keySearch => {
    let resultArray = [];
    const { unitType, medias } = this.state;
    _.map(medias, media => {
      if (_.includes(media.tags, _.get(unitType, keySearch))) {
        resultArray.push(media.url);
      }
    });
    return resultArray;
  };

  render() {
    const { themeColor, unit, unitType, unitFloorPlan, medias, floorplate} = this.state;
    if (_.isEmpty(unit)) return null;
    const allMoreType = `Size ${_.get(unit, "fields.area")} SQ.M.`;

    const textFunction = `${_.get(
      unitType,
      "functions.bath"
    )} Bathroom | ${_.get(unitType, "functions.bed")} Bedroom`;

    const floorplateImg = _.get(
      medias,
      `${floorplate.floorplan}.url`
    );
    const unitplanID = _.get(unitType, "unitplan");
    const unitplanImg = _.get(medias, unitplanID);
    const settingSlider = {
      dots: true,
      autoplaySpeed: 6000,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };


    return (
      <ThemeProvider theme={themeColor}>
        <TrapezoidHeader
          title={`${_.get(unit, "id")}`}
        >
          <Container>
            <ContainnerTitle>
              <div className="title-id">{`Type ${_.get(unit, "fields.room-type")}`}</div>
              <div className="title-function">{textFunction}</div>
              <div className="title-size">{allMoreType}</div>
            </ContainnerTitle>
            <ContainerSection>
              <h2>Unit Plan</h2>
              <div>
                <img src={unitplanImg.url} alt="" />
              </div>
            </ContainerSection>
            <ContainerSection buildingId={this.props.match.params.buildingId}>
              <h2>Floor Plan</h2>
              <div className="floorPlanSubTitle">
                Floor {unit.fields.floor}
              </div>
              <div className="floorPlan">
                <svg viewBox={`0 0 ${floorplate.svgWidth} ${floorplate.svgHeight}`} width="100%">
                  <image
                    style={{ opacity: 0.7 }}
                    xlinkHref={floorplateImg}
                    x={0}
                    y={0}
                    width="100%"
                    alt=""
                  />
                  <g id="opacityFlash" style={{ opacity: 0.3 }}>
                    {this.renderPolygon(unitFloorPlan, unit)}
                  </g>
                </svg>
              </div>
            </ContainerSection>
            {!_.isEmpty(this.getGalleryFromRoomType("category")) && (
              <ContainerSlider>
                <h2>Gallery</h2>
                <SlickSlide {...settingSlider}>
                  {_.map(this.getGalleryFromRoomType("category"), img => {
                    return (
                      <div>
                        <img className="slider-image" src={img} alt="" />
                      </div> 
                    );
                  })}
                </SlickSlide>
              </ContainerSlider>
            )}
          </Container>
        </TrapezoidHeader>
      </ThemeProvider>
    );
  }
}

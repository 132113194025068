import qs from 'query-string';
import _ from 'lodash';

const BASEURL = process.env.REACT_APP_BASEURL_CONTENT || 'http://localhost:5000/forviz-2017/us-central1/cic';
const BASEURL_MEDIA = process.env.REACT_APP_BASEURL_MEDIA;

const getAccessToken = user => user.stsTokenManager.accessToken;


/*
 * Get Spaces
 **/
export const fetchSpaces = user => (query) =>
  fetch(`${BASEURL}/spaces/?${qs.stringify(query)}`, {
    headers: { Authorization: `Bearer ${getAccessToken(user)}` }
  })
    .then(res => res.json())
    .then(res => {
      if (res.status !== 'success') throw new Error(res.message);
      return _.map(res.data, space => ({ ...space, id: space.id || _.get(space, 'sys.id') }));
    });

export const createSpace = user => (id, name, { projectId }) =>
  fetch(`${BASEURL}/spaces/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken(user)}`
    },
    body: JSON.stringify({
      name,
      projectId,
    }),
  })
    .then(res => res.json())
    .then(res => {
      if (res.status === 'error') throw new Error(res.message);
      return res.data;
    });

/*
 * Get Space
 **/
export const fetchSpace = user => (spaceId) =>
  fetch(`${BASEURL}/spaces/${spaceId}`, {
    headers: { Authorization: `Bearer ${getAccessToken(user)}` }
  })
    .then(res => res.json())
    .then(res => {
      if (res.status !== 'success') throw new Error(res.message);
      return res.data;
    });

export const fetchSpaceModels = user => (spaceId) =>
  fetch(`${BASEURL}/spaces/${spaceId}/models`, {
    headers: { Authorization: `Bearer ${getAccessToken(user)}` }
  })
    .then(res => res.json())
    .then(res => {
      if (res.status !== 'success') throw new Error(res.message);
      return res.data;
    });

export const fetchSpaceModel = user => (spaceId, modelId) =>
  fetch(`${BASEURL}/spaces/${spaceId}/models/${modelId}`, {
    headers: { Authorization: `Bearer ${getAccessToken(user)}` }
  })
    .then(res => res.json())
    .then(res => {
      if (res.status !== 'success') throw new Error(res.message);
      return res.data;
    });
      
export const updateSpaceModel = user => (spaceId, modelId, data) =>
  fetch(`${BASEURL}/spaces/${spaceId}/models/${modelId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken(user)}`
    },
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(res => {
      if (res.status !== 'success') throw new Error(res.message);
      return res.data;
    });

/*
 * Get Space Entries
 **/
export const fetchSpaceEntries = user => (spaceId, query) =>
  fetch(`${BASEURL}/spaces/${spaceId}/entries?${qs.stringify(query)}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken(user)}`
    },
  })
    .then(res => res.json())
    .then(res => res.data);

/*
 * Get Space Entry by Id
 **/
export const fetchSpaceEntry = user => (spaceId, entryId, query) =>
  fetch(`${BASEURL}/spaces/${spaceId}/entries/${entryId}?${qs.stringify(query)}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken(user)}`
    },
  })
    .then(res => res.json())
    .then(res => res.data);

/*
 * Create Space Entry
 **/
export const createSpaceEntry = user => (spaceId, model, data) =>
  fetch(`${BASEURL}/spaces/${spaceId}/entries`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken(user)}`
    },
    body: JSON.stringify({
      model,
      fields: data,
    }),
  })
    .then(res => res.json())
    .then(res => {
      if (res.status !== 'success') throw new Error(res.message);
      return res.data;
    });

/*
 * Update Space Entry
 **/
export const updateSpaceEntry = user => (spaceId, entryId, model, data) =>
  fetch(`${BASEURL}/spaces/${spaceId}/entries/${entryId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken(user)}`,
    },
    body: JSON.stringify({
      model,
      fields: data
    }),
  })
    .then(res => res.json())
    .then(res => {
      if (res.status !== 'success') throw new Error(res.message);
      return res.data;
    });

/*
 * Delete Space Entry
 **/
export const deleteSpaceEntry = user => (spaceId, entryId, query) =>
  fetch(`${BASEURL}/spaces/${spaceId}/entries/${entryId}?${qs.stringify(query)}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken(user)}`
    },
  })
    .then(res => res.json())
    .then(res => {
      if (res.status !== 'success') throw new Error(res.message);
      return res.data;
    });


/*
 * Get Space's Medias
 **/
export const fetchSpaceMedias = (spaceId) =>
  fetch(`${BASEURL_MEDIA}/?${qs.stringify({ prefix: `spaces/${spaceId}`})}`, {
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then(res => res.json())
    .then(res => res.data)
    .catch(error => console.error);

import React from 'react';
import _ from 'lodash';
import{ Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Navigation from '../../components/Navigation';

import * as Aspen from 'forviz-aspenjs';
import * as Media from 'forviz-mediajs';

// Actions
import { updateProject } from '../../actions';

// Screens
import ProjectDashboardScreen from '../ProjectDashboardScreen';

import MedicalCenterScreen from '../MedicalCenterScreen';
import DoctorScreen from '../DoctorScreen';
import PromotionScreen from '../PromotionScreen';
import ArticleScreen from '../ArticleScreen';

import FloorScreen from '../FloorScreen';
import FacilityScreen from '../FacilityScreen';

import AppConfigScreen from '../AppConfigScreen';
import KioskScreen from '../KioskScreen';
import MediaScreen from '../MediaScreen';

import UserManagement from '../../../iam/screens/UserManagementScreen';
import SettingScreen from '../SettingScreen';

const Container = styled.div`
  flex: 1;
  flex-direction: row;
  height: 100%;
`;

const Main = styled.div`
  flex: 1;
  margin-left: 280px;
  height: 100%;
`;

const createProjectMediaClient = (projectId) => {
  return Media.createClient({
    storagePath: `aspen/projects/${projectId}`,
    firestorePath: `aspen_projects/${projectId}/medias`
  });
}

const createProjectContentClient = (projectId) => {
  return Aspen.createClient({ projectId });
}

export default connect()(class extends React.Component {
  floorUnitsUnsubscribe = {};

  componentDidMount() {
    
    const { match, dispatch } = this.props;
    const projectId = _.get(match, 'params.projectId');
    const _self = this;
    // Get Project Medias
    const mediaClient = createProjectMediaClient(projectId);
    mediaClient.getMediasRef().onSnapshot(querySnapshot => {
      const medias = _.reduce(querySnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: { id: doc.id, ...doc.data() }}), {});
      dispatch(updateProject(projectId, 'medias', medias));
    });

    const dbClient = createProjectContentClient(projectId);

    
    // Get MedicalCenters
    dbClient.getMedicalCentersCollectionRef().onSnapshot(querySnapshot => {
      const shops = _.reduce(querySnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: doc.data()}), {});
      dispatch(updateProject(projectId, 'medicalCenters', shops));
    });

    dbClient.getProjectRef().collection('doctors').onSnapshot(querySnapshot => {
      const doctors = _.reduce(querySnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: doc.data()}), {});
      dispatch(updateProject(projectId, 'doctors', doctors));
    });

    // // Get Floors
    // dbClient.getFloorsCollectionRef().orderBy('order', 'desc').onSnapshot(querySnapshot => {
    //   const floors = _.reduce(querySnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: doc.data()}), {});
    //   dispatch(updateProject(projectId, 'floors', floors));

    //   querySnapshot.docChanges().forEach(function(change) {
    //     if (change.type === "added") {
    //       // console.log("New floor: ", change.doc.data());
    //       const floorId = change.doc.id;
    //       _self.floorUnitsUnsubscribe[floorId] = dbClient.getFloorDocRef(change.doc.id).collection('units').onSnapshot(unitSnapshot => {
    //         const units = _.reduce(unitSnapshot.docs, (acc, doc) => ({ ...acc, [`${floorId}!${doc.id}`]: { ...doc.data(), floorId } }), {});
    //         dispatch(updateUnits(projectId, units));
    //       });
    //     }
    //     if (change.type === "modified") {
    //       // console.log("Modified floor: ", change.doc.data());
    //     }
    //     if (change.type === "removed") {
    //       // console.log("Removed floor: ", change.doc.data());
    //       const floorId = change.doc.id;
    //       if (typeof _self.floorUnitsUnsubscribe[floorId] === 'function') _self.floorUnitsUnsubscribe[floorId]();
    //     }
    //   });
    // });

    // Get Nodes
    // dbClient.getNodesCollectionRef().onSnapshot(querySnapshot => {
    //   const nodes = _.reduce(querySnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: doc.data()}), {});
    //   dispatch(updateProject(projectId, 'nodes', nodes));
    // });

    // // Get Elements
    // dbClient.getCollectionRef('elements').onSnapshot(querySnapshot => {
    //   const elements = _.reduce(querySnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: { id: doc.id, ...doc.data() }}), {});
    //   dispatch(updateProject(projectId, 'elements', elements));
    // });

    // Get Zones

    
  }
  render() {
    const { match, accountId } = this.props;
    const projectId = _.get(match, 'params.projectId');

    const dbClient = createProjectContentClient(projectId);

    return (
      <Container>
        <Helmet>
          <title>{projectId} | Ombrés</title>
        </Helmet>
        <Navigation />
        <Main>
          <Switch>
            <Route exact path={`${match.url}`} render={routerProps => <ProjectDashboardScreen {...routerProps} projectId={projectId} />} />
            
            <Route path={`${match.url}/medical-centers`} render={routerProps => <MedicalCenterScreen {...routerProps} projectId={projectId} />} />
            <Route path={`${match.url}/doctors`} render={routerProps => <DoctorScreen {...routerProps} projectId={projectId} />} />
            <Route path={`${match.url}/promotions`} render={routerProps => <PromotionScreen {...routerProps} projectId={projectId} />}  />
            <Route path={`${match.url}/articles`} render={routerProps => <ArticleScreen {...routerProps} projectId={projectId} />}  />
            
            <Route path={`${match.url}/floors`} render={routerProps => <FloorScreen {...routerProps} projectId={projectId} />}  />
            <Route path={`${match.url}/facilities`} render={routerProps => <FacilityScreen {...routerProps} projectId={projectId} />}  />

            <Route path={`${match.url}/kiosks`} render={routerProps => <KioskScreen {...routerProps} projectId={projectId} />}  />
            <Route
              path={`${match.url}/config`}
              render={routerProps => (
                <AppConfigScreen
                  {...routerProps}
                  projectId={projectId}
                  client={createProjectContentClient(projectId)}
                />
              )}
            />

            <Route
              path={`${match.url}/medias`} render={routerProps =>
                <MediaScreen
                  {...routerProps}
                  projectId={projectId}
                  client={createProjectMediaClient(projectId)}
                />
              }
            />
            <Route
              path={`${match.url}/users`}
              exact
              render={routerProps => {
                return (
                   <UserManagement {...routerProps} resourceName={`forviz:${accountId}:aspen:${projectId}`} />
                );
              }}
            />
            <Route
              path={`${match.url}/settings`}
              exact
              render={routerProps => {
                return (
                   <SettingScreen {...routerProps} docRef={dbClient.getProjectRef()} projectId={projectId} />
                );
              }}
            />
          </Switch>
        </Main>
      </Container>
    );
  }
});

import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import Button from '@material-ui/core/Button';

import AddIcon from '@material-ui/icons/Add';

import {
  saveMediaToFirestore,
} from 'forviz-mediajs';

import db from '../../engine/db';

import Confirm from '../../components/Confirm';
import AddMediaDialog from '../../components/AddMediaDialog';
import CreateDocButton from '../../components/CreateDocButton';

import PageHeader from '../../../../components/PageHeader';

const HeaderTextWrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

const TextWrapper = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.9rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.00938em;
`;

const mapStateToProps = (state, ownProps) => {
  const buildingId = _.get(ownProps, 'match.params.buildingId');
  return {
    // medias: _.get(state, ['projects', buildingId, 'mediaLinks']),
    buildingId
  }
}

export default connect(
  mapStateToProps,
)(class extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showAddDialog: false,
      allItems: [],
      items: [],
      page: 0,
      rowsPerPage: 10,
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (param) => {
    const { buildingId } = this.props;
    let unitRefs = db.collection('meerkat_projects').doc(buildingId).collection('mediaLinks') ;
    
    unitRefs.get().then(querySnapshot => {
      if (_.get(querySnapshot, 'size') > 0) {
        const allItems = _.sortBy(_.map(querySnapshot.docs, doc => {
          return { id: doc.id, ...doc.data()};
        }), 'id');
        this.setState({
          allItems,
        }, () => {
          this.paginateData();
        })
      }
    });
  }

  handleChangePage = (event, page) => {
    this.setState({ page }, () => {
      this.paginateData();
    });
  }

  paginateData = () => {
    const { allItems, rowsPerPage } = this.state;

    if (_.get(allItems, 'length') > 0) {
      const currentPage = this.state.page;
      const tmpItems = [];
      const startIndex = (currentPage * rowsPerPage) < _.get(allItems, 'length') ? (currentPage * rowsPerPage) : _.get(allItems, 'length') - 1;
      const afterEndIndex = ((currentPage+1) * rowsPerPage) < _.get(allItems, 'length') ? ((currentPage+1) * rowsPerPage) : _.get(allItems, 'length');

      for (let index = startIndex; index < afterEndIndex; index++) {
        tmpItems.push(_.get(allItems, index));
      }
      this.setState({
        items: tmpItems,
      })
    }
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value }, () => {
      this.paginateData();
    });
  }
  
  render() {
    const { match, history } = this.props;
    const { rowsPerPage, page, items, allItems } = this.state;
    
    return (
      <div>
        <PageHeader
          title="Media Links"
          renderFloatingActionButtons={() => (
            <CreateDocButton label="Media Link" onCreate={id => {
              history.push(`${match.url}/${id}`);
            }} />
          )}
        />
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><HeaderTextWrapper>Title</HeaderTextWrapper></TableCell>
                <TableCell><HeaderTextWrapper>Link</HeaderTextWrapper></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(item => {
                return (
                  <TableRow key={item.id}>
                    <React.Fragment>
                      <TableCell style={{ fontSize: '0.7em', color: '#AAA' }}><TextWrapper>{_.get(item, 'title')}</TextWrapper></TableCell>
                      <TableCell style={{ fontSize: '0.7em', color: '#AAA' }}><TextWrapper>{_.get(item, 'link')}</TextWrapper></TableCell>
                    </React.Fragment>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={9}
                  count={_.get(allItems, 'length', 0)}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  rowsPerPageOptions={[10, 25, 50]}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
     
      </div>
    );
  }
});


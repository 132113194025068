import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { notify } from 'react-notify-toast';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddIcon from '@material-ui/icons/Add';

import PageHeader from '../../../../components/PageHeader';

const createApiKey = (resourceName, name) => {
  return fetch(`${process.env.REACT_APP_CLOUDFUNCTIONURL}/iam/apikeys`, {
    method: 'POST',
    body: JSON.stringify({
      name,
    })
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 'error') throw response;
      return response.data;
    })
}

export default class extends React.Component {
  static defaultProps = {
    resourceName: '',
  }
  constructor(props) {
    super(props);
    this.state = {
      apikeys: [],
      openInviteDialog: false,
    };

    this.inputName = React.createRef();
  }

  fetchApiKeys = () => {
    const { resourceName } = this.props;
    fetch(`${process.env.REACT_APP_CLOUDFUNCTIONURL}/iam/resources/${resourceName}/apikeys`)
      .then(response => response.json())
      .then(response => {
        console.log('apikeys', response.data);
        this.setState({
          apikeys: response.data,
        });
      })
  }
  
  componentDidMount() {
    this.fetchApiKeys();    
  }

  handleSubmitCreateKey = () => {
    const { resourceName } = this.props;
    const name = this.inputName.current.value;
    this.closeDialog();
    createApiKey(resourceName, name)
      .then(response => {
        console.log('success', response);
        notify.show(`API Key ${name} created`, 'success', 4000);

        this.fetchApiKeys();
      })
      .catch(error => {
        console.log('error', error);
        notify.show(`Error ${error.message}`, 'error', 4000);
      });
  }

  openDialog = () => this.setState({ openInviteDialog: true })
  closeDialog = () => {
    this.setState({ openInviteDialog: false });
  }

  render() {
    const { apikeys } = this.state;
    return (
      <div>
        <PageHeader
          title="API Keys"
          renderFloatingActionButtons={() => (
            <Button variant="extendedFab" color="primary" onClick={this.openDialog}><AddIcon /> Create new key</Button>
          )}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Creation</TableCell>
              <TableCell>Restriction</TableCell>
              <TableCell>Key</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(apikeys, apiKey => (
              <TableRow key={apiKey.id}>
                <TableCell>{apiKey.name}</TableCell>
                <TableCell>{moment(apiKey.createdAt._seconds, 'X').fromNow()}</TableCell>
                <TableCell>{apiKey.read && 'Read'}</TableCell>
                <TableCell>{apiKey.id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog
          open={this.state.openInviteDialog}
          onClose={this.closeDialog}
          maxWidth="sm"
          aria-labelledby="form-invite-member"
        >
          <DialogTitle id="form-invite-member">Create new api key</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField label="Name" inputRef={this.inputName} fullWidth autoFocus />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeDialog}>Cancel</Button>
            <Button color="primary" onClick={this.handleSubmitCreateKey}>Create</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
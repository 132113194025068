import React from 'react';
import _ from 'lodash';
import { notify } from 'react-notify-toast';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import LinearProgress from '@material-ui/core/LinearProgress';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddIcon from '@material-ui/icons/Add';

import PageHeader from '../../components/PageHeader';

import allApps from './constants';

const inviteUserToResource = (email, resourceName, role) => {
  return fetch(`${process.env.REACT_APP_CLOUDFUNCTIONURL}/iam/users/${email}/roles`, {
    method: 'POST',
    body: JSON.stringify({
      resourceName,
      role,
    })
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 'error') throw response;
      return response;
    })
}

export default class extends React.Component {
  static defaultProps = {
    resourceName: '',
  }
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: false,
      openInviteDialog: false,
    };

    this.inputService = React.createRef();
    this.inputEmail = React.createRef();
    this.inputRole = React.createRef();
  }
  
  componentDidMount() {
    const { resourceName } = this.props;
    this.setState({ loading: true })
    fetch(`${process.env.REACT_APP_CLOUDFUNCTIONURL}/iam/resources/${resourceName}/users`)
      .then(response => response.json())
      .then(response => {
        console.log('users', response.data);
        this.setState({
          users: response.data,
          loading: false,
        });
      })
  }

  handleSubmitInvite = () => {
    const { accountId } = this.props;
    const service = this.inputService.current.value;
    const email = this.inputEmail.current.value;
    const role = this.inputRole.current.value;
    this.closeDialog();
    inviteUserToResource(email, `ns:${accountId}:${service}:*`, role)
      .then(response => {
        console.log('success', response);
        notify.show(`Invited ${email} to resource as ${role}`, 'success', 4000);
      })
      .catch(error => {
        console.log('error', error);
        notify.show(`Error ${error.message}`, 'error', 4000);
      });
  }

  openDialog = () => this.setState({ openInviteDialog: true })
  closeDialog = () => {
    this.setState({ openInviteDialog: false });
  }

  render() {
    const { accountId } = this.props;
    const { users, loading } = this.state;

    // const accountApps = _.map(account.apps, (accountApp, accountAppId) => _.find(allApps, app => app.id === accountAppId));

    const groups = _.map(allApps, app => ({ service: app.id, name: app.name }));
    return (
      <div>
        <Grid container style={{ maxWidth: 980, margin: '0 auto', position: 'relative' }}>
          <Box style={{ position: 'absolute', right: 32, top: -24 }}>
            <Fab variant="extended" color="primary" onClick={this.openDialog}><AddIcon /> Invite Member</Fab>
          </Box>
          <Grid item sm={12}>
            <Card style={{ marginBottom: 32 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={80}></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Access</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && <TableRow><TableCell colSpan={3}><LinearProgress /></TableCell></TableRow>}
                  {_.map(users, user => {
                    // const resources = _.get(user, ['customClaims', '@', accountId, service]);
                    const resources = _.get(user, ['customClaims', '@', accountId]);
                    const resourcesAccess = _.map(resources, (resourceConfig, resource) => {
                      return _.map(resourceConfig, (role, project) => {
                        return (
                          <Chip
                            key={resource}
                            label={`${resource === '*' ? 'All services' : _.capitalize(resource)}  •  ${project === '*' ? 'All projects' : project}  •  ${role}`}
                          />
                        );
                      });
                    });
                    return (
                      <TableRow key={user.uid}>
                        <TableCell>
                          {user.photoURL &&
                            <div style={{ padding: '10px 0' }}>
                              <Avatar src={user.photoURL} alt={user.displayName} style={{ width: 50, height: 50 }} />
                            </div>
                          }
                        </TableCell>
                        <TableCell>{user.displayName || user.email}</TableCell>
                        <TableCell>{resourcesAccess}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Card>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.openInviteDialog}
          onClose={this.closeDialog}
          maxWidth="sm"
          aria-labelledby="form-invite-member"
        >
          <DialogTitle id="form-invite-member">Invite a member</DialogTitle>
          <DialogContent>
            <div style={{ padding: '8px 0'}}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <TextField label="Access" variant="outlined" inputRef={this.inputService} fullWidth select SelectProps={{ native: true }} defaultValue="*">
                    {_.map(groups, group => <option key={group.service} value={group.service}>{group.name}</option>)}
                  </TextField>
                </Grid>
                <Grid item sm={8}>
                  <TextField label="Email" inputRef={this.inputEmail} fullWidth autoFocus />
                </Grid>
                <Grid item sm={4}>
                  <TextField label="Role" select SelectProps={{ native: true }} inputRef={this.inputRole} fullWidth>
                    <option value="admin">Admin</option>
                    <option value="editor">Editor</option>
                    <option value="viewer">Viewer</option>
                  </TextField>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeDialog}>Cancel</Button>
            <Button color="primary" onClick={this.handleSubmitInvite}>Invite</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
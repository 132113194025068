import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import uuidv4 from 'uuid/v4';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';

import AddIcon from '@material-ui/icons/Add';

import CreateDocButton from '../../../../components/CreateDocButton';
import FirestoreCollectionViewer from '../../../../components/FirestoreCollectionViewer';
import PageHeader from '../../../../components/PageHeader';

import StatusTableCellValue from '../../components/StatusTableCellValue';

// import client from '../../engine';
import { createClient } from 'forviz-ombresjs';

import DetailScreen from './detail';

const COLLECTION_NAME = 'banners';

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;

  return {
    medias: _.get(state, ['projects', projectId, 'medias'])
  };
};

export default connect(mapStateToProps)(
  class extends React.Component {
    handleSelectRow = (record, selected, selectedRows, e) => {
      const { match, history } = this.props;
      history.push(`${match.url}/${record.id}`);
    };

    handleCreate = values => {
      const { projectId } = this.props;

      const { title } = values;
      const id = uuidv4();

      const client = createClient({ projectId });
      const docRef = client.getDocRef(COLLECTION_NAME, id);
      docRef
        .set({
          fields: {
            name: {
              en: title
            }
          }
        })
        .then(() => {
          const { match, history } = this.props;
          history.push(`${match.url}/${id}`);
        });
    };

    render() {
      const { match, history, projectId, medias } = this.props;
      const listScreenUrl = match.url;
      const client = createClient({ projectId });
      return (
        <div>
          <PageHeader
            title="Banner"
            renderFloatingActionButtons={() => (
              <CreateDocButton
                label="Banner"
                renderButton={() => (
                  <Button variant="extendedFab" color="primary">
                    <AddIcon /> Add Banner
                  </Button>
                )}
                onCreate={this.handleCreate}
              />
            )}
          />
          <FirestoreCollectionViewer
            columns={[
              { title: 'Name', dataIndex: 'fields.name.en' },
              {
                title: 'Media',
                dataIndex: 'fields.media',
                width: 150,
                render: mediaId => {
                  const media = _.get(medias, mediaId);
                  return <Avatar src={_.get(media, 'url')} />;
                }
              },
              { title: 'Type', dataIndex: 'fields.type' },
              { title: 'Duration', dataIndex: 'fields.duration' },
              { title: 'Start Date', dataIndex: 'fields.startDate' },
              { title: 'End Date', dataIndex: 'fields.endDate' },
              { title: 'Status', dataIndex: 'fields.status', render: status => <StatusTableCellValue status={status} /> }
            ]}
            collectionRef={client.getCollectionRef(COLLECTION_NAME)}
            onSelect={this.handleSelectRow}
          />
          <Route
            path={`${match.url}/:id`}
            children={({ match, ...rest }) => {
              const id = _.get(match, 'params.id');
              const docRef = !_.isEmpty(id) ? client.getDocRef(COLLECTION_NAME, id) : null;
              const docCustomFieldRef = client.getCustomFieldDocRef("banners");
              const open = docRef !== null;
              return (
                <Drawer anchor="right" open={open} onClose={() => history.goBack()}>
                  <DetailScreen
                    width="600px"
                    {...rest}
                    idEditable={false}
                    projectId={projectId}
                    docRef={docRef}
                    docCustomFieldRef={docCustomFieldRef}
                    onClose={() => {
                      history.push(listScreenUrl);
                    }}
                  />
                </Drawer>
              );
            }}
          />
        </div>
      );
    }
  }
);

import _ from 'lodash';

const initialState = {
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVED/ACCOUNTS':
      return _.mapValues(action.accounts, (account, accountId) => ({ ...state[accountId], ...account }));

    case 'ACCOUNT/UPDATE':
      return {
        ...state,
        [action.id]: { ...state[action.id], ...action.data }
      }

    default: return state;
  }
}
import React from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import logger from 'redux-logger';

import { utility } from '../../lib'

import { MuiThemeProvider } from '@material-ui/core/styles';

import UnAuthorization from '../../components/UnAuthorization'

// Screens
import AppRoot from './screens/AppRoot';
import ProjectRoot from './screens/ProjectRoot';
import theme from './theme';

import rootReducer from './reducers';

const { permission } = utility;
const {
  verifyUserHasAccessToApp,
} = permission;

const store = createStore(rootReducer, applyMiddleware(logger));

export default class extends React.Component {

  render() {
    const { match, project: account, user } = this.props;

    if (!verifyUserHasAccessToApp(user, account.id, 'ombres')) {
      return (
        <UnAuthorization
          message={`Sorry, you don't have permission to access Ombrés`}
        />
      );
    }

    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <title>Ombrés</title>
          </Helmet>
          <Route exact path={`${match.url}`} render={routerProps => <AppRoot {...routerProps} accountId={account.id} user={user} />} />
          <Route path={`${match.url}/:projectId`} render={routerProps => <ProjectRoot {...routerProps} accountId={account.id} user={user} />} />
        </MuiThemeProvider>
      </Provider>
    );
  }
}

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import FirestoreDocEditor from '../../../../components/FirestoreDocEditor';

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, 'projectId');
  const medicalCenters = _.get(state, ['projects', projectId, 'medicalCenters']);
  return {
    medias: _.get(state, ['projects', projectId, 'medias']),
    medicalCenterOptions: _.map(medicalCenters, medicalCenter => ({ value: medicalCenter.id, label: _.get(medicalCenter, 'fields.name.en') })),
  };
};

export default connect(mapStateToProps)((props) => {
  const { projectId, medicalCenterOptions, medias } = props;
  return (
    <FirestoreDocEditor
      {...props}
      mediaEngine="firebase"
      mediaEngineConfig={{
        storagePath: `aspen/projects/${projectId}`,
        firestorePath: `aspen_projects/${projectId}/medias`
      }}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => ({
        name: { label: 'Name', type: 'Object', layout: 'horizontal', value: _.get(data, 'fields.name', {}) },
        description: { label: 'Description', type: 'Object', layout: 'horizontal', value: _.get(data, 'fields.description', {}) },
        tags: { label: 'Keywords/Tags', value: _.join(_.get(data, 'fields.tags', [])) },
        medicalCenters: {
          label: 'Medical Centers',
          type: 'Dropdown',
          value: _.get(data, 'fields.medicalCenters', []),
          options: medicalCenterOptions,
        },
        status: {
          label: 'Status',
          type: 'Dropdown',
          value: _.get(data, 'fields.status', 'draft'),
          options: [
            { value: 'Draft', label: 'Draft' },
            { value: 'Published', label: 'Published' },
            { value: 'Archived', label: 'Archived' },
          ]
        },
        coverPhoto: {
          label: 'Logo',
          type: 'Image',
          value: _.get(data, 'fields.coverPhoto'),
          options: _.map(medias, (media, key) => ({ value: key, label: key, url: media.url }))
        },
        gallery: {
          label: 'Gallery',
          type: 'Gallery',
          value: _.get(data, 'fields.gallery'),
          options: _.map(medias, (media, key) => ({ value: key, label: key, url: media.url }))
        },
        order: { label: 'Order', value: _.get(data, 'fields.order', 0) },
        startDate: { label: 'Start Date', type: 'Date', value: _.get(data, 'fields.startDate') },
        endDate: { label: 'End Date', type: 'Date', value: _.get(data, 'fields.endDate') },
      })}
      mapFieldValuesToDocData={fieldValues => {
        // const fieldValues = _.mapValues(fields, field => field.value);
        return {
          fields: {
            name: fieldValues.name,
            description: fieldValues.description,
            coverPhoto: fieldValues.coverPhoto,
            gallery: fieldValues.gallery,
            startDate: fieldValues.startDate,
            endDate: fieldValues.endDate,
            shops: fieldValues.shops,
            tags: _.map(_.split(fieldValues.tags, ','), _.trim),
            status: fieldValues.status,
          }
        }
      }}
      sections={[
        {
          title: 'Promotion Info',
          fields: ['name', 'description'],
        },
        {
          title: 'Media',
          fields: ['coverPhoto', 'gallery'],
        },
        {
          title: 'Associated with',
          fields: ['medicalCenters']
        },
        {
          title: 'Taxonomy',
          fields: ['tags']
        },
        {
          title: 'Publish',
          fields: ['status'],
        }
      ]}
    />
  );
});
import React from 'react';
import _ from 'lodash';
import FirestoreDocEditor from '../../../../components/FirestoreDocEditor';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, 'projectId');
  return {
    medias: _.get(state, ['projects', projectId, 'medias']),
  }
};

export default connect(mapStateToProps)((props) => {
  const { projectId, medias } = props;
  const floorplanOptions = _
    .chain(medias)
    .map((media, key) => ({ value: key, label: key, url: media.url }))
    // .filter(media => _.includes(media.tags, 'floorplan'))
    .value();
  return (
    <FirestoreDocEditor
      {...props}
      mediaEngine="firebase"
      mediaEngineConfig={{
        storagePath: `aspen/projects/${projectId}`,
        firestorePath: `aspen_projects/${projectId}/medias`
      }}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => ({
        nameEn: { label: 'Name (English)', value: _.get(data, 'fields.name.en', id) },
        nameTh: { label: 'ชื่อ (ภาษาไทย)', value: _.get(data, 'fields.name.th', id) },
        nameZh: { label: 'ชื่อ (จีน)', value: _.get(data, 'fields.name.zh', id) },
        detailEn: { label: 'Detail (English)', value: _.get(data, 'fields.detail.en', '') },
        detailTh: { label: 'Detail (ภาษาไทย)', value: _.get(data, 'fields.detail.th', '') },
        detailZh: { label: 'Detail (จีน)', value: _.get(data, 'fields.detail.zh', '') },
        floorplan: {
          label: 'Floorplan',
          type: 'Image',
          value: _.get(data, 'fields.floorplan'),
          options: floorplanOptions,
        },
        order: { label: 'Order', value: _.get(data, 'order', 0) },
        svgWidth: { label: 'SVG Width', value: _.get(data, 'fields.svgWidth', 1920) },
        svgHeight: { label: 'SVG Height', value: _.get(data, 'fields.svgHeight', 1080) },
        status: {
          label: 'Status',
          type: 'Dropdown',
          value: _.get(data, 'fields.status', 'draft'),
          options: [
            { value: 'Draft', label: 'Draft' },
            { value: 'Published', label: 'Published' },
            { value: 'Archived', label: 'Archived' },
          ]
        },
      })}
      mapFieldValuesToDocData={fieldValues => {
        return {
          fields: {
            name: {
              en: fieldValues.nameEn,
              th: fieldValues.nameTh,
              zh: fieldValues.nameZh,
            },
            detail: {
              en: fieldValues.detailEn,
              th: fieldValues.detailTh,
              zh: fieldValues.detailZh,
            },
            floorplan: fieldValues.floorplan,
            svgWidth: _.toNumber(fieldValues.svgWidth),
            svgHeight: _.toNumber(fieldValues.svgHeight),
            status: fieldValues.status,
          },
          order: _.toNumber(fieldValues.order),
        }
      }}
      sections={[
        {
          title: 'Info',
          fields: ['nameEn', 'nameTh', 'nameZh']
        },
        {
          title: 'Detail',
          fields: ['detailEn', 'detailTh', 'detailZh']
        },
        {
          title: 'Order',
          fields: ['order'],
        },
        {
          title: 'Media',
          fields: ['floorplan', 'svgWidth', 'svgHeight'],
        },
        {
          title: 'Publish',
          fields: ['status'],
        },
      ]}
    />
  );
});
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { notify } from 'react-notify-toast';
import _ from 'lodash';
import SwipeableViews from 'react-swipeable-views';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';

// import MediaSelector from './MediaSelector';
import CreateDocButton from '../CreateDocButton';
import ConfirmDialog from '../ConfirmDialog';
import InputField from '../InputField';

import {
  createClient,
} from 'forviz-mediajs';

const cleanObject = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') cleanObject(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};

const DRAWER_WIDTH = 500;

const PaperContent = styled.div`
  padding: 40px;
  box-sizing: border-box;
`;

const Section = styled.section`
  margin: 25px 0;
`;

const ActionBar = styled.div`
  box-sizing: border-box;
  padding: 20px;
  position: fixed;
  right: 0;
  bottom: 0;
  width: ${DRAWER_WIDTH}px;
  background: #fafafa;
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: flex-end;
`;

class FirestoreDocEditor extends React.PureComponent {

  static propTypes = {
    mediaEngine: PropTypes.string,
    mediaEngineConfig: PropTypes.object,
    mapDocDataToFields: PropTypes.func,
    mapFieldsToDocData: PropTypes.func,
    sections: PropTypes.array,
    getSections: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    onUploadFile: PropTypes.func,
    onDeleteFile: PropTypes.func,
    allowEditId: PropTypes.bool,
    renderTitle: PropTypes.func,
    renderSectionAs: PropTypes.oneOf(['list', 'tab']),
    editableID: PropTypes.bool,
    canDelete: PropTypes.bool,
  }

  static defaultProps = {
    allowEditId: true,
    renderSectionAs: 'list',
    mediaEngine: 'firebase',
    mediaEngineConfig: {},
    mapDocDataToFields: (data) => {
      return _.mapValues(data, (value, key) => ({ label: _.toUpper(key), value }));
    },
    mapFieldValuesToDocData: fieldValues => {
      return _.mapValues(fieldValues, field => field.value);
    },
    sections: undefined,
    getSections: fields => {
      return [{ title: 'Fields', fields: _.keys(fields) }];
    },
    idEditable: true,
    canDelete: true
  }

  constructor(props) {
    super(props);
    // const { mapDocDataToFields } = props;
    this.state = {
      // fields: mapDocDataToFields(),
      docData: null,
      currentTab: 0,
      fieldValues: {},
    }

    this.show = notify.createShowQueue();
  }

  loadDoc = (docRef) => {
    if (docRef) {
      const { mapDocDataToFields } = this.props;
      this.unsubscribeDoc = docRef
        .onSnapshot(doc => {
          const fields = mapDocDataToFields(doc.data(), doc.id);
          this.setState({
            id: doc.id,
            docData: doc.data(),
            currentTab: 0,
            // fields,
            fieldValues: _.mapValues(fields, field => _.get(field, 'value')),
          });
        });
    } else {
      const { id, data, mapDocDataToFields } = this.props;
      const fields = mapDocDataToFields(data, id);
      // console.log('loadDoc:data', data);
      // console.log('loadDoc:fields', fields);
      this.setState({
        id,
        docData: data,
        currentTab: 0,
        fieldValues: _.mapValues(fields, field => _.get(field, 'value')),
      })
    }
  }

  componentDidMount() {
    this.loadDoc(this.props.docRef);
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.get(this.props, 'docRef.id') !== _.get(prevProps, 'docRef.id')) {
      this.loadDoc(this.props.docRef);
    }
  }

  componentWillUnmount() {
    if (typeof this.unsubscribeDoc === 'function') this.unsubscribeDoc();
  }

  save = async  (e) => {
    const { docRef, mapFieldValuesToDocData } = this.props;
    e.preventDefault();

    const id = docRef ? docRef.id : this.props.id;
    const data = mapFieldValuesToDocData(this.state.fieldValues);
    const cleanedData = cleanObject(data);
    console.log('save', cleanedData);

    if (docRef) {
      const doc = await docRef.get();
      if (doc.exists) {
        docRef.update(cleanedData);
      } else {
        docRef.set(cleanedData);
      }
    }
    this.show('Saved', 'success', 4000);
    if (typeof this.props.onSave === 'function') this.props.onSave(id, cleanedData, this.state.fieldValues);
    if (typeof this.props.onClose === 'function') this.props.onClose();
  }

  deleteDoc = async () => {
    // console.log('delete Doc');
    const { docRef, onClose, onDelete } = this.props;
    const id = docRef ? docRef.id : this.props.id;

    if (docRef) {
      await docRef.delete().then(() => {
        this.show('Deleted', 'success', 4000);
      });
    }

    if (typeof this.props.onDelete === 'function') onDelete(id);
    if (typeof this.props.onClose === 'function') onClose();

  }

  handleInputChange = (name, eventOrValue) => {
    const value = _.get(eventOrValue, 'target.value', eventOrValue);
    // console.log('inputChange', name, value);
    this.setState({
      fieldValues: {
        ...this.state.fieldValues,
        [name]: value,
      },
    })
  }

  checkFile = (file) => {
    // console.log('checkFile', files)
    const { mediaEngineConfig } = this.props
    const client = createClient(mediaEngineConfig);
    return client.getDownloadURL(file.name)
  }

  uploadFile = (media) => {
    switch (this.props.mediaEngine) {
      case 'custom': {
        const { onUploadFile } = this.props;
        onUploadFile(media);
        break;
      }

      case 'firebase':
      default: {
        const { mediaEngineConfig } = this.props;
        const client = createClient(mediaEngineConfig);
        const { title, tags, uploadStorageMethod } = media

        const mediaAttributes = {
          title,
          tags
        }
        // uploadStorageMethod // default, keepBoth => false, replace -> true
        const options = {
          replace: _.includes(['replace'], uploadStorageMethod),
          filename: undefined,
        }
        client.uploadFile(media.file, mediaAttributes, options).then(({ id, ref, url }) => {
          console.log()
          this.show(`Update Media ${id} complete`, 'success', 4000);
        })
        break;
      }
    }
  }

  deleteFile = (mediaRef) => {
    // console.log('deleteFile', mediaRef);
    switch (this.props.mediaEngine) {
      case 'custom': {
        const { onDeleteFile } = this.props;
        onDeleteFile(mediaRef);
        break;
      }

      case 'firebase':
      default: {
        const { mediaEngineConfig } = this.props;
        const client = createClient(mediaEngineConfig);
        client.getMediaFromRef(mediaRef)
          .then(media => {
            const storageRef = media.ref;
            return Promise.all([
              client.deleteMediaFromFirestore(mediaRef),
              client.deleteFromStorage(storageRef)
            ]);
          })
          .then(() => this.show(`Medias deleted`, 'success', 4000));

        break;
      }
    }
  }

  handleChangeID = async ({ newId }) => {
    const { docRef } = this.props;
    const data = await docRef.get().then(doc => {
      return doc.data();
    });

    docRef.parent.doc(newId).set(data)
      .then(() => {
        docRef.delete();
        this.show(`Successfully change Document ID ${docRef.id} to ${newId}`, 'success', 4000);

        // if (typeof this.props.onDelete === 'function') this.props.onDelete(docRef.id);
        if (typeof this.props.onClose === 'function') this.props.onClose();
      });
  }

  renderInputDefault = (inputProps) => <InputField {...inputProps} />

  renderInput = (inputProps) => {
    if (typeof this.props.renderInput === 'function') {
      return this.props.renderInput(inputProps, this.renderInputDefault);
    }
    return this.renderInputDefault(inputProps);
  }

  renderField = (field, fieldName) => {
    const value = this.state.fieldValues[fieldName];
    if (!field) return (<div />);

    const inputProps = {
      type: field.type || 'TextField',
      ...field,
      value,
      onChange: e => this.handleInputChange(fieldName, e),
      onUploadFile: this.uploadFile,
      onDeleteFile: this.deleteFile,
      onCheckFile: this.checkFile
    }

    return (
      <div key={`${fieldName}`} style={{ display: 'flex', padding: '6px 0', justifyContent: 'space-between', alignItems: 'center' }}>
        {this.renderInput(inputProps)}
      </div>
    );

  }

  renderSections = (sections, fields) => {
    switch (this.props.renderSectionAs) {
      case 'tab': {
        const { currentTab } = this.state;
        console.log('renderSections', this.state.currentTab);
        return (
          <React.Fragment>
              <Tabs value={currentTab} indicatorColor="primary" textColor="primary" onChange={(e, index) => this.setState({ currentTab: index })}>
                {_.map(sections, section => <Tab key={section.title} label={section.title} />)}
              </Tabs>
            <SwipeableViews
              index={this.state.currentTab}
              onChangeIndex={index => {
                console.log('onChangeIndex', index);
                this.setState({ currentTab: index })
              }}
            >
              {_.map(sections, section => (
                <Section key={section.title}>
                  <div>
                    {_.map(section.fields, fieldName => this.renderField(fields[fieldName], fieldName))}
                  </div>
                </Section>
              ))}
            </SwipeableViews>
          </React.Fragment>
        );
      }
      case 'list':
      default: {
        return _.map(sections, section => (
          <Section key={section.title}>
            <Typography variant="subtitle1" style={{ marginBottom: 20 }}>{section.title}</Typography>
            <div>
              {_.map(section.fields, fieldName => this.renderField(fields[fieldName], fieldName))}
            </div>
            <Divider />
          </Section>
        ));
      }
    }
  }

  render() {
    const { width, mapDocDataToFields, mapFieldValuesToDocData, docRef, sections, getSections, onClose, titleField = 'fields.name.en', renderTitle, allowEditId, idEditable, canDelete } = this.props;
    const { fieldValues } = this.state;
    const updatedData = mapFieldValuesToDocData(fieldValues);
    const fields = mapDocDataToFields(updatedData);
    const formSections = sections || getSections(fields);

    const id = docRef ? _.get(docRef, 'id', '') : this.props.id;
    const title = typeof renderTitle === 'function' ? renderTitle(updatedData) : _.get(updatedData, titleField, id);
    // console.log('fields', fields, titleField);
    return (
      <div style={{ height: '100vh', position: 'relative', overflow: 'scroll' }}>
        <Paper elevation={0} style={{ width, padding: '40px 0', boxSizing: 'border-box' }}>
          <PaperContent>
            <div>
              {allowEditId &&
                <Grid container alignItems="center">
                  <Grid item><Typography variant="caption" style={{ opacity: 0.5 }}>{id}</Typography></Grid>
                  <Grid item>
                    {
                      idEditable ?
                      (
                        <CreateDocButton
                          title="Change ID"
                          fields={[
                            { type: 'TextField', name: 'newId', label: 'New ID', required: true }
                          ]}
                          okText={`Change ID`}
                          renderButton={() => <IconButton><EditIcon fontSize="small" /></IconButton>}
                          onCreate={this.handleChangeID}
                        />
                      ) : null
                    }
                  </Grid>
                </Grid>
              }
              <Typography variant="h4" gutterBottom>{title}</Typography>
            </div>
            {this.renderSections(formSections, fields)}
          </PaperContent>
        </Paper>
        <ActionBar style={{ width, zIndex: 10 }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: canDelete ? 'space-between' : 'flex-end' }}>
            {
              canDelete && (
                <ConfirmDialog
                  title="Delete Document"
                  content="This will permanently delete this document, are you sure?"
                  onOk={this.deleteDoc}
                  render={() => <Button style={{ color: '#ff1744' }}>Delete</Button>}
                />
              )
            }
            <div style={{ display: 'flex' }}>
              <Button onClick={onClose}>Cancel</Button>
              <Button color="primary" onClick={this.save}>Save</Button>
            </div>
          </div>
        </ActionBar>
      </div>
    );
  }
}

export default FirestoreDocEditor
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// import styled, { css } from "styled-components";

import MediaLibrary from './MediaLibrary';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';

class MediaSelector extends Component {
  static defaultProps = {
    multiple: false,
    value: [],
  };

  static propTypes = {
    multiple: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.string),
  };

  state = {
    open: false,
    searchText: ''
  };

  handleClose = () => this.setState({ open: false });

  handleSearchInputChange = e => {
    this.setState({
      searchText: e.target.value
    });
  };

  handleSelect = (selectedValue, checked) => {
    const value = checked ? [...this.props.value, selectedValue] : _.filter(this.props.value, v => v !== selectedValue);
    this.props.onChange(value);

    if (!this.props.multiple) this.setState({ open: false });
  };

  handleUploadFile = file => {
    // console.log('handleUploadFile', file);
    this.props.onUploadFile(file);
    this.handleSelect(file.name);
  }

  openDialog = () => {
    this.setState({ open: true });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const fieldsToCheck = ['open', 'options', 'value', 'spaceId'];

    const propsIsEqual = _.isEqual(_.pick(nextProps, fieldsToCheck), _.pick(this.props, fieldsToCheck));
    const stateIsEqual = _.isEqual(nextState, this.state);
    if (!propsIsEqual || !stateIsEqual) {
      return true;
    }
    return false;
  }

  render() {
    const { multiple, options, onCheckFile } = this.props;
    const { open, searchText } = this.state;

    return (
      <React.Fragment>
        <Button style={{ width: '100%' }} onClick={this.openDialog}>
          {multiple ? `Select Media` : `Add Media`}
        </Button>
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
          <DialogTitle id="form-dialog-title">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5">Select Media</Typography>
              <div>
                <Input
                  onChange={this.handleSearchInputChange}
                  autoFocus
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <MediaLibrary
              onCheckFile={onCheckFile}
              onUploadFile={this.handleUploadFile}
              onSelect={this.handleSelect}
              multiple={multiple}
              value={this.props.value}
              options={options}
              searchText={searchText}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} variant="outlined" color="primary">
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default MediaSelector
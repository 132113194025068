import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// import styled, { css } from "styled-components";

import MediaLibrary from './MediaLibrary';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SearchIcon from '@material-ui/icons/Search';

export default class extends Component {
  static defaultProps = {
    multiple: false
  };

  static propTypes = {
    multiple: PropTypes.bool
  };

  state = {
    open: false,
    searchText: ''
  };

  handleClose = () => this.setState({ open: false });

  handleSearchInputChange = e => {
    this.setState({
      searchText: e.target.value
    });
  };

  handleSelect = selectedValue => {
    this.props.onSelect(selectedValue);
    this.setState({ open: false });
  };

  openDialog = () => {
    this.setState({ open: true });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const fieldsToCheck = ['options', 'value'];

    const propsIsEqual = _.isEqual(_.pick(nextProps, fieldsToCheck), _.pick(this.props, fieldsToCheck));
    const stateIsEqual = _.isEqual(nextState, this.state);
    if (!propsIsEqual || !stateIsEqual) {
      return true;
    }
    return false;
  }

  render() {
    const { spaceId, multiple } = this.props;
    const { open, searchText } = this.state;

    return (
      <React.Fragment>
        <Button style={{ width: '100%' }} onClick={this.openDialog}>
          Add Media
        </Button>
        <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
          <DialogTitle id="form-dialog-title">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5">Select Media</Typography>
              <div>
                <Input
                  onChange={this.handleSearchInputChange}
                  autoFocus
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <MediaLibrary spaceId={spaceId} onSelect={this.handleSelect} multiple={multiple} value={this.props.value} searchText={searchText} />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

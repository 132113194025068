import engine from '../engine';

export const receivedUser = (uid, user) => {
  return {
    type: 'USER/RECEIVED',
    uid,
    user,
  }
}

export const receivedUserAccounts = (uid, accounts) => {
  return {
    type: 'USER/RECEIVED/ACCOUNTS',
    uid,
    accounts,
  }
}

export const receivedAccount = (account) => {
  return {
    type: 'USER/RECEIVED/ACCOUNT',
    account,
  }
}

export const userLoggedOut = () => {
  return {
    type: 'USER/LOGGED_OUT',
  }
}


export const updateAccount = (accountId) => {
  return (dispatch) => {
    engine.db.collection('iam_projects').doc(accountId).get()
      .then(doc => {
        const account = { id: doc.id, ...doc.data() };
        dispatch(receivedAccount(account));
        return account;
      });
  }
}
import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { notify } from 'react-notify-toast';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import BackIcon from '@material-ui/icons/ArrowBack';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import AddIcon from '@material-ui/icons/Add';

import { db, storage } from '../../engine';

import Confirm from '../../components/Confirm';
import AddMediaDialog from '../../components/AddMediaDialog';

import PageHeader from '../../../../components/PageHeader';

const accept = 'image/*,video/*';

const Image = styled.div`
  position: relative;
  background: #f7f7f7;
  border: 1px solid #ececec;

  span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.6em 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    background: linear-gradient(to bottom, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.4) 100%);
    color: white;
    text-shadow: 0 1px rgba(0, 0, 0, 0.5);
  }
`;

const styles = {
  fullscreen: {
    position: 'fixed',
    zIndex: 1300,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'black',
  }
};

const DropzoneActive = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  border: 1px dashed #ececec;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  box-sizing: border-box;
  color: #666;
  transition: all 0.6s ease-out;

  ${props => props.visible && css`
    opacity: 1;
    z-index: 9999;
  `};
`;

const InfoPanel = styled.div`
  width: 300px;
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0;
  bottom: 0;
`;

export default class extends React.Component {
  static propTypes = {
    spaceId: PropTypes.string.isRequired,
  }

  static defaultProps = {
    spaceId: ''
  }
  
  constructor(props) {
    super(props);

    this.inputID = React.createRef();
    this.inputTitle = React.createRef();
    this.inputTags = React.createRef();

    this.state = {
      dropzoneActive: false,
      uploadedFiles: [],
      focusedImageId: null,
      showMediaDialog: false,
      currentTag: 'all',
      tags: ['pet', 'cat', 'dog']
    }
  }

  componentDidMount() {
    const { spaceId } = this.props;
    db.collection('cic_spaces').doc(spaceId).collection('medias').onSnapshot(querySnapshot => {
      this.setState({
        items: _.map(querySnapshot.docs, doc => {
          return { id: doc.id, ...doc.data() };
        }),
      })
    });
  }

  /* Dropzone */
  onDragEnter = () => {
    this.setState({
      dropzoneActive: true
    });
  }

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false
    });
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      uploadedFiles: acceptedFiles,
      dropzoneActive: false
    });

    const { spaceId } = this.props;
    const storageRef = storage.ref(`cic/spaces/${spaceId}`);

    Promise.all(
      _.map(acceptedFiles, uploadedFile => {
        const docId = uploadedFile.name;
        const fileRef = storageRef.child(docId);
        notify.show(`Uploading ${docId}`, 'success', 4000);

        return fileRef.put(uploadedFile)
          .then((snapshot) => {
            // console.log('Uploaded a blob or file!', snapshot.ref.toString());
            return Promise.all([
              Promise.resolve(uploadedFile),
              Promise.resolve(snapshot.ref),
              snapshot.ref.getDownloadURL(),
            ]);
          })
          .then(([ file, ref, url ]) => {
            // console.log('file, ref, url', file, ref, url);
            return { ...file, ref, url };
          });
      }),
    ).then(files => {
      // console.log('upload all complete', files);
      this.setState({ uploadedFiles: files, showMediaDialog: true });
    });

  }

  handleCloseDialog = () => this.setState({ showMediaDialog: false })

  applyMimeTypes = (event) => {
    this.setState({
      accept: event.target.value
    });
  }
  /* End of Dropzone */

  deleteMedia = (mediaId) => {
    const { spaceId } = this.props;
    const { items } = this.state;
    const media = _.find(items, item => item.id === mediaId);

    // Delete Firestore
    const mediaRef = storage.refFromURL(media.ref);
    this.setState({ focusedImageId: null });
    mediaRef.delete()
      .then(() => db.collection('cic_spaces').doc(spaceId).collection('medias').doc(mediaId).delete())
      .then(() => {
        notify.show(`Deleted ${mediaId}`, 'success', 4000);
      })
      .catch(error => {
        console.log('delete error', error.message);
        notify.show(`Error ${error.message}`, 'danger', 4000);
      })
  }

  handleChangeTag = (e, value) => this.setState({ currentTag: value })

  updateMediaInfo = (e) => {
    const { spaceId } = this.props;
    const id = this.inputID.current.value;
    const title = this.inputTitle.current.value;
    const tags = this.inputTags.current.value;
    // console.log('save', id, title, tags);
    db.collection('cic_spaces').doc(spaceId).collection('medias').doc(id)
      .set({
        id,
        title,
        tags: _.map(_.split(tags, ','), _.trim),
      }, {
        merge: true
      })
      .then(() => {
        notify.show(`Medias update`, 'success', 4000);
      });
  }

  /* ReUpload */
  reUpload = (doc) => {
    // console.log('doc', doc);
    this.reUploadFileRef = storage.refFromURL(doc.url);
    this.reUploadDocId = doc.id;
    this.reUploadDropzoneRef.open();
  }

  reUploadOnDrop = (acceptedFiles, rejectedFiles) => {
    const { spaceId } = this.props;
    const uploadedFile = acceptedFiles[0];
    // console.log('uploadedFile', uploadedFile);
    this.reUploadFileRef.put(uploadedFile)
      .then((snapshot) => {
        console.log('Uploaded a blob or file!', snapshot.ref.toString());
        return Promise.all([
          Promise.resolve(uploadedFile),
          Promise.resolve(snapshot.ref.toString()),
          snapshot.ref.getDownloadURL(),
        ]);
      })
      .then(([ file, ref, url ]) => {
        console.log('file, ref, url', file, ref, url);
        return { ...file, ref, url };
      })
      .then(fileDoc => {
        console.log('reupload fileDoc', fileDoc);

        // Update Doc
        return db.collection('cic_spaces').doc(spaceId).collection('medias').doc(this.reUploadDocId).update({
          ref: fileDoc.ref,
          url: fileDoc.url,
        });
      })
      .then(() => {
        notify.show(`ReUpload ${this.reUploadDocId} complete`, 'success', 4000);
        // Clean up
        this.reUploadDocId = null;
        this.reUploadFileRef = null;

      });
  }

  renderMediaItem = (item) => {
    const ext = _.last(_.split(item, '.'));
    switch (ext) {
      case 'mp4':
        return (
          <div style={{ width: '100%', paddingTop: '62.5%' }}>
            <video style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
              <source src={item.url} type="video/mp4" />
            </video>
          </div>
        );
      default:
        return (
          <React.Fragment>
            <img src={item.url} alt="" style={{ maxWidth: '100%' }} />
            <span>{item.title}</span>
          </React.Fragment>
        );
    }
  }

  applyZIndex = el => {
    el.style.zIndex = 1300;
  }

  removeZIndex = el => {
    el.style.zIndex = "";
  }

  
  render() {
    const { spaceId } = this.props;
    
    const allTags = _.compact(_.flatten(_.map(this.state.items, item => item.tags)));

    const { focusedImageId, dropzoneActive, uploadedFiles, currentTag, showMediaDialog } = this.state;
    const items = currentTag === 'all' ? this.state.items : _.filter(this.state.items, item => _.includes(item.tags, currentTag));

    const focusedImage = focusedImageId !== null ? _.find(items, item => item.id === focusedImageId) : null;
    return (
      <div>
        <PageHeader
          title="Medias"
          renderFloatingActionButtons={() => (
            <Button color="primary" variant="extendedFab" onClick={() => { this.dropzoneRef.open() }}>
              <AddIcon />Upload
            </Button>
          )}
        />
        <Dropzone
          ref={(node) => { this.dropzoneRef = node; }}
          disableClick
          accept={accept}
          style={{position: 'relative' }}
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
        >
          <DropzoneActive visible={dropzoneActive}>Drop files...</DropzoneActive>
          <Flipper flipKey={focusedImageId}>
            {focusedImageId === null && (
              <div style={{ padding: 24 }}>
                <div>
                  <Grid container spacing={24}>
                    <Grid item sm={12}>
                      {currentTag !== 'all' && (
                        <Chip
                          label={currentTag}
                          variant="outlined"
                          onDelete={e => this.handleChangeTag(e, 'all')}
                          color="secondary"
                        />
                      )}
                    </Grid>
                    <Grid item sm={9}>
                      {/* <Tabs
                        value={this.state.currentTag}
                        onChange={this.handleChangeTag}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollable
                      >
                        <Tab key="all" value="all" label="All Photos" />
                        {_.map(allTags, tag => <Tab key={tag} value={tag} label={tag} />)}
                      </Tabs> */}
                      <Grid container spacing={24} style={{ marginTop: 12 }}>
                        {_.map(items, item => (
                          <Grid item sm={4} key={item.ref}>
                            <Flipped flipId={`media-${item.id}`} onStart={this.applyZIndex} onComplete={this.removeZIndex}>
                              <Image onClick={() => this.setState({ focusedImageId: item.id })}>
                                {this.renderMediaItem(item)}
                              </Image>
                            </Flipped>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        <List
                          subheader={<ListSubheader component="div">Tags</ListSubheader>}
                        >
                          {_.map(_.uniq(allTags), tag => (
                            <ListItem button selected={currentTag === tag} onClick={e => this.handleChangeTag(e, tag)}>
                              <ListItemText primary={tag} />
                              <ListItemSecondaryAction>
                                <Chip label={_.sumBy(allTags, t => t === tag)} variant="outlined" />
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {focusedImageId !== null && (
              <Flipped flipId={`media-${focusedImageId}`} onStart={this.applyZIndex}>
                <div style={styles.fullscreen}>
                  <img
                    onClick={() => this.setState({ focusedImageId: null })}
                    src={_.get(_.find(items, item => item.id === focusedImageId), 'url')}
                    alt=""
                    style={{ maxWidth: '100%', height: 'auto' }}
                  /> 
                  <AppBar position="fixed" style={{ background: 'rgba(0, 0, 0, 0.8)' }}>
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <IconButton onClick={() => this.setState({ focusedImageId: null })} style={{ color: 'white' }}><BackIcon /></IconButton>
                      <Typography variant="title" color="inherit">
                        {focusedImage.title}
                      </Typography>
                      <IconButton></IconButton>
                    </Toolbar>
                  </AppBar>
                  <InfoPanel>
                    <Paper style={{ padding: 20, height: '100%' }}>
                      <Paper elevation={0} style={{ margin: '20px 0' }}>
                        <Dropzone
                          ref={(node) => { this.reUploadDropzoneRef = node; }}
                          multiple={false}
                          accept={accept}
                          disableClick style={{ position: 'relative' }}
                          onDrop={this.reUploadOnDrop}
                        >
                          <Typography style={{ fontSize: '0.8em', opacity: 0.6 }}>If you wanna just reupload the file, but keep everything the same.</Typography>                   
                          <Button variant="outlined" color="default" fullWidth onClick={e => this.reUpload(focusedImage)}>Reupload</Button>
                        </Dropzone>
                      </Paper>
                      <Divider />
                      <Paper elevation={0} style={{ margin: '20px 0' }}>
                        <FormControl fullWidth style={{ marginBottom: 20 }}>
                          <TextField
                            variant="outlined"
                            key={`id-${focusedImageId}`}
                            inputRef={this.inputID}
                            fullWidth
                            label="ID"
                            defaultValue={focusedImageId}
                            disabled
                          />
                        </FormControl>
                        <FormControl fullWidth style={{ marginBottom: 20 }}>
                          <TextField
                            variant="outlined"
                            key={`title-${focusedImageId}`}
                            inputRef={this.inputTitle}
                            fullWidth
                            label="Title"
                            defaultValue={focusedImage.title}
                          />
                        </FormControl>
                        <FormControl fullWidth style={{ marginBottom: 20 }}>
                          <TextField
                            variant="outlined"
                            key={`tags-${focusedImageId}`}
                            inputRef={this.inputTags}
                            fullWidth
                            label="Tags"
                            defaultValue={_.join(focusedImage.tags, ',')}
                          />
                        </FormControl>
                        <Button variant="contained" fullWidth color="primary" onClick={this.updateMediaInfo}>Save</Button>
                      </Paper>
                      <Divider />
                      <Paper elevation={0} style={{ margin: '20px 0' }}>
                        <Confirm
                          render={() => <Button color="primary" variant="outlined" fullWidth>Delete</Button>}
                          onOk={() => this.deleteMedia(focusedImageId)}
                          content="Confirm Delete this?"
                        />
                        
                      </Paper>
                    </Paper>
                  </InfoPanel>
                </div>
              </Flipped>
            )}
          </Flipper>
          <AddMediaDialog open={showMediaDialog} onClose={this.handleCloseDialog} files={uploadedFiles} spaceId={spaceId} />
        </Dropzone>
      </div>
    );
  }
}

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import FirestoreDocEditor from "../../../../components/FirestoreDocEditor";
import {
  getSections,
  getMapDocDataToFields,
  getMapFieldValuesToDocData
} from "../../utils/getCustomfield";

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, "projectId");
  const setting = _.get(state, ["projects", projectId, "setting"]);
  return {
    setting,
    medias: _.get(state, ["projects", projectId, "medias"])
  };
};

export default connect(mapStateToProps)(props => {
  const { projectId, medias, docCustomFieldRef, setting } = props;
  const [customField, setCustomField] = useState({});
  const [updateVersionForRender, setUpdateVersionForRender] = useState(0);

  useEffect(() => {
    docCustomFieldRef.onSnapshot(doc => {
      const docData = doc.data();
      setUpdateVersionForRender(updateVersionForRender + 1);
      setCustomField(docData);
    });
  }, []);

  const customSections = getSections(customField, {
    languages: _.get(setting, "languages", [])
  });
  return (
    <FirestoreDocEditor
      key={`updateVersionForRender-${_.size(
        customField
      )}-${updateVersionForRender}`}
      {...props}
      ediaEngine="firebase"
      mediaEngineConfig={{
        storagePath: `ombres/projects/${projectId}`,
        firestorePath: `ombres_projects/${projectId}/medias`
      }}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => {
        const cuntomMapDocDataToFields = getMapDocDataToFields(customField, {
          data,
          medias,
          languages: _.get(setting, "languages", [])
        });
        const languages = _.get(setting, "languages", []);
        const nameFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`name-${lang}`)]: {
              label: `Name (${lang})`,
              value: _.get(data, `fields.name.${lang}`, "")
            }
          }),
          {}
        );
        const descriptionFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`description-${lang}`)]: {
              label: `Description (${lang})`,
              value: _.get(data, `fields.description.${lang}`, "")
            }
          }),
          {}
        );
        return {
          ...nameFields,
          ...descriptionFields,
          category: {
            label: "Category",
            value: _.get(data, "fields.category"),
            disabled: true
          },
          featureImage: {
            label: "Feature Image",
            type: "Image",
            value: _.get(data, "fields.featureImage"),
            options: _.map(medias, (media, key) => ({
              value: key,
              label: key,
              url: media.url
            }))
          },
          status: {
            label: "Status",
            type: "Dropdown",
            value: _.get(data, "fields.status", "draft"),
            options: [
              { value: "Draft", label: "Draft" },
              { value: "Published", label: "Published" },
              { value: "Archived", label: "Archived" }
            ]
          },
          ...cuntomMapDocDataToFields
        };
      }}
      mapFieldValuesToDocData={fieldValues => {
        const languages = _.get(setting, "languages", []);
        const nameValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`name-${lang}`)]
          }),
          {}
        );
        const descriptionValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`description-${lang}`)]
          }),
          {}
        );

        const cuntomMapFieldValuesToDocData = getMapFieldValuesToDocData(
          customField,
          {
            fieldValues,
            languages: _.get(setting, "languages", [])
          }
        );
        return {
          fields: {
            name: nameValues,
            description: descriptionValues,
            category: fieldValues.category,
            featureImage: fieldValues.featureImage,
            status: fieldValues.status,
            ...cuntomMapFieldValuesToDocData
          }
        };
      }}
      sections={[
        {
          title: "Info",
          fields: [
            "category",
            ..._.map(_.get(setting, "languages", []), lang =>
              _.camelCase(`name-${lang}`)
            ),
            ..._.map(_.get(setting, "languages", []), lang =>
              _.camelCase(`description-${lang}`)
            )
          ]
        },
        {
          title: "Media",
          fields: ["featureImage"]
        },
        ...customSections,
        {
          title: "Publish",
          fields: ["status"]
        }
      ]}
    />
  );
});

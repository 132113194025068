import React from 'react';
import _ from 'lodash';
import { Switch, Route } from 'react-router';
import { connect } from 'react-redux';

// Screens
import LoginScreen from './screens/LoginScreen';
import ProjectExplorerScreen from './screens/ProjectExplorerScreen';
import UserProfile from './screens/UserProfile';

import Header from '../../components/Header';

const mapStateToProps = state => ({
  user: state.user,
})
export const Root = connect(mapStateToProps)(({ user, match, engine }) => (
  <div>
    {_.get(user, 'uid') && <Header />}
    <Switch>
      <Route exact path={`${match.url}/login`} render={routerProps => <LoginScreen engine={engine} {...routerProps} />} />
      <Route exact path={`${match.url}/projects`} render={routerProps => <ProjectExplorerScreen engine={engine} {...routerProps} />} />
      <Route exact path={`${match.url}/profile`} render={routerProps => <UserProfile {...routerProps} />} />
    </Switch>
  </div>
));

import React from 'react';
import { NavLink, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Grid from '@material-ui/core/Grid';

import allApps from './constants';

import { updateAccount } from '../../actions/user-actions';
import { enableApp, disableApp } from '../../apps/iam/api';

export default connect()(class extends React.Component {
  // componentDidMount() {
  //   const { match, history, account } = this.props;

  //   const apps = _.get(account, 'apps', []);
      
  //   if (_.size(apps) === 1) {
  //     history.push(`${_.replace(match.url, '/apps', '')}/${_.get(Object.keys(apps), '0')}`);
  //   }
  // }

  disableApp = (appId) => {
    const { dispatch, account } = this.props;
    disableApp(account.id, appId).then(() => {
      dispatch(updateAccount(account.id));
    });
  }
  enableApp = (appId) => {
    const { dispatch, account } = this.props;
    enableApp(account.id, appId).then(() => {
      dispatch(updateAccount(account.id));
    });
  }

  render() {
    const { match, account } = this.props;
    const url = _.get(this.props, 'location.pathname');
    const enabledApps = _.filter(allApps, app => _.get(account, `apps.${app.id}.enabled`) === true);
     
    return (
      <div>
        <Grid container spacing={2} justify="center" style={{ margin: '0 auto' }}>
          <Grid item sm={3} md={2}>
            <List>
              <ListItem button component={NavLink} selected={url === `${match.url}`} to={match.url}><ListItemText primary="Your app" /></ListItem>
              <ListItem button component={NavLink} selected={url === `${match.url}/store`} to={`${match.url}/store`}><ListItemText primary="Explore more app" /></ListItem>
            </List>
          </Grid>
          <Grid item sm={9} md={10}>
            <Route
              path={`${match.url}/`}
              exact
              render={routerProps => (
                <Grid container spacing={2}>
                  {_.map(enabledApps, (app) => (
                    <Grid item key={app.id} sm={4}>
                      <Card>
                        <CardActionArea component={Link} to={`${_.replace(match.url, '/apps', '')}/${app.id}`}>
                          <CardMedia
                            style={{ height: 180, backgroundColor: app.imgBackground || '#202A30' }}
                            image={app.img}
                            title={app.description}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                              {app.name}
                            </Typography>
                            <Typography component="p">
                              {app.description}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            />
            <Route
              path={`${match.url}/store`}
              render={routerProps => (
                <Grid container spacing={2}>
                  {_.map(allApps, (app) => {
                    const isEnabled = _.get(_.get(account, 'apps'), `${app.id}.enabled`) === true;
                    return (
                      <Grid item key={app.id} sm={12}>
                        <Card style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'flex-start' }}>
                          <CardMedia
                            style={{ width: 200, height: 150, backgroundColor: app.img || '#202A30' }}
                            image={app.img}
                            title="App Avatar"
                          />
                          <CardContent style={{ flexGrow: 1 }}>
                            <Typography gutterBottom variant="h5" component="h2">
                              {app.name}
                            </Typography>
                            <Typography component="p">
                              {app.description}
                            </Typography>
                          </CardContent>
                          <div style={{ flexGrow: 0, padding: 40 }}>
                            {isEnabled
                            ?
                            <Button color="default" variant="contained" onClick={e => this.disableApp(app.id)}>Disable</Button>
                            :
                            <Button color="primary" variant="contained" onClick={e => this.enableApp(app.id)}>Enable</Button>
                            }
                          </div>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
});

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/RemoveCircle';

const Container = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  margin-bottom: 20px;

  .list-label {
    display: inline-block;
    background: white;
    position: relative;
    top: -16px;
    left: 8px;
    padding: 5px;
    span {
      color: rgba(0, 0, 0 , 0.54);
    }
  }

  .listfield-list {
    margin-bottom: 20px;
  }
`;

const List = styled.ol`
  list-style: none;
  padding: 4px 10px;
  margin: 0 0 16px;
  li {
    display: flex;
    align-items: top;

    .list-number {
      flex: 0;
      margin-right: 5px;
      opacity: 0.78;
      font-size: 0.8em;
    }
  }
`;

const sanitizeValue = (value) => _.size(value) === 0 ? [''] : value;

export default class extends React.Component {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    render: PropTypes.func,
  }
  
  state = {
    focusIndex: 0,
  }

  handleSingleChange = index => e => {
    const v = e.target ? _.get(e, 'target.value') : e;
    const { value } = this.props;
    const _value = sanitizeValue(value);
    const updatedValue = _.map(_value, (singleValue, valueIndex) => {
      if (index === valueIndex) return v;
      return singleValue;
    });

    this.props.onChange(updatedValue);
  }

  addItem = () => {
    const { value } = this.props;
    // const value = _.get(this.props, 'value', ['']);

    const _value = sanitizeValue(value);
    const newValue = [..._value, ''];
    this.setState({
      focusIndex: _.size(newValue),
    });
    this.props.onChange(newValue);
  }

  deleteItem = index => e => {
    const { value } = this.props;
    const updatedValue = _.filter(value, (v, i) => i !== index);
    this.props.onChange(updatedValue);
  }

  render() {
    const { label, render, value } = this.props;

    const _value = _.size(value) === 0 ? [''] : value;
    return (
      <Container>
        <label className="list-label"><Typography variant="caption">{label}</Typography></label>
        <List>
          {_.map(_value, (singleValue, index) => {
            return (
              <li key={`field-${index}`} className="listfield-list">
                <div style={{ flex: 0 }}>
                  <IconButton onClick={this.deleteItem(index)} style={{ marginTop: 8 }}>
                    <CloseIcon style={{ color: '#ab003c' }} />
                  </IconButton>
                </div>
                <div style={{ flexGrow: 1 }}>
                  {render({
                    inputProps: {
                      autoFocus: this.state.focusIndex === index,
                      value: singleValue,
                      onChange: this.handleSingleChange(index),
                    }
                  })}
                </div>
                
              </li>
            );
          })}
          <li key={`field-new`} style={{ padding: '8px 0', borderTop: '1px solid #ececec', marginLeft: 40 }}>
            <Button onClick={this.addItem} variant="contained" color="primary" size="small">Add {label || 'Item'}</Button>
          </li>
        </List>
      </Container>
    );
  }
}
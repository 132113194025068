import _ from 'lodash';

const initialState = {
  accounts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'USER/RECEIVED':
      return {
        ...state,
        ...action.user,
      };

    case 'USER/RECEIVED/ACCOUNTS':
      return {
        ...state,
        accounts: action.accounts,
      };
    
    case 'USER/RECEIVED/ACCOUNT':
      return {
        ...state,
        accounts: _.map(state.accounts, acc => {
          if (acc.id === action.account.id) return action.account;
          return acc;
        })
      }
    
    case 'USER/LOGGED_OUT':
      return initialState;
      
    default: return state;
  }
}
export const updateProject = (projectId, key, data) => ({
  type: 'PROJECT/UPDATE',
  id: projectId,
  data: {
    [key]: data,
  }
});

export const updateUnits = (projectId, units) => ({
  type: 'PROJECT/UNITS/UPDATE',
  id: projectId,
  units,
});
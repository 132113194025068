import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import CircularProgress from '@material-ui/core/CircularProgress';

import firebase, { db } from '../../engine';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
}

export default class extends React.PureComponent {
  static defaultProps = {
    defaultRole: 'mi-treasure.sale',
  }

  state = {
    open: false,
    loading: false,
    role: this.props.defaultRole,
  }

  handleClose = (e) => {
    this.setState({ open: false });
  }

  handleCancel = (e) => {
    this.handleClose(e);
  }

  handleChangeRole = (e) => {
    this.setState({
      role: e.target.value,
    });
  }

  save = (e) => {
    const { spaceId, model } = this.props;
    const entryId = this.input.value;
    this.setState({
      loading: true,
    });
    const docRef = db.collection('cic_spaces')
      .doc(spaceId)
      .collection('entries')
      .doc(entryId);
    

    docRef.get()
      .then(doc => {
        if (doc.exists) {
          this.props.onEntryCreatedError('Duplicate ID');
        } else {
          docRef.set({
            fields: {},
            model: model.id,
            id: entryId,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            this.props.onEntryCreatedSuccess(entryId);
          });
        }

        this.setState({
          loading: false,
        });

      })
      .finally(() => {
        this.input.value = '';
        this.handleClose();
      });
  }
  
  render() {
    const { loading } = this.state;
    return (
      <React.Fragment>
        <Button
            variant="extendedFab"
            color="primary"
            onClick={() => this.setState({ open: true })}
          >
            <AddIcon /> New Entry
          </Button>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Add new {this.props.label}
          </DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', flexDirection: 'column', width: 500, maxWidth: '100%' }}>
              <FormControl component="fieldset" style={{ marginBottom: 12 }}>
                <FormLabel component="legend">Entry ID</FormLabel>
                <Input
                  inputRef={node => this.input = node}
                  autoFocus
                  type="text"
                  fullWidth
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.save} color="primary" disabled={loading}>
              {!loading ? `Create ${this.props.label}` : <CircularProgress size={24} />}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>

    );
  }
}
import _ from 'lodash';
import EventEmitter from './utils/EventEmitter';

const storageKey = 'FORVIZ-CONSOLE';

class LocalIamEngine extends EventEmitter {
  constructor() {
    super();
    this.type = 'local';
    this.currentUser = this.auth.getAuthenticatedUser();
    this.addListener('authStateChange', this.handleAuthStateChange);

    // console.log('constructor::currentUser', this.currentUser);
    if (!!this.currentUser) {
      setTimeout(() => {
        this.emit('authStateChange', this.currentUser);
      }, 400);
    }
  }

  handleAuthStateChange = (user) => {
    if (user && typeof user === 'object') {
      localStorage.setItem(storageKey, JSON.stringify(user));
    } else {
      localStorage.removeItem(storageKey);
    }
  }

  auth = {
    signInWithEmailAndPassword: (email, password) => {
      // console.log('signInWithEmailAndPassword', email, password);
      return fetch(`${process.env.REACT_APP_BASEURL_AUTH}/signIn`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      })
        .then(res => res.json())
        .then(res => {
          // console.log('signIn res', res);
          if (res.data && res.data.uid) {
            this.currentUser = res.data;
            this.emit('authStateChange', this.currentUser);
          } else {
            throw { code: 401, message: _.get(res, 'message.message') };
          }
          return this.currentUser;
        });
    },

    // signInWithGoogle: () => {},
    // signInWithFacebook: () => {},
    signOut: () => {
      return fetch(`${process.env.REACT_APP_BASEURL_AUTH}/signOut`, {
        method: 'POST',
      })
        .then(res => res.json())
        .then(res => {
          this.currentUser = null;
          this.emit('authStateChange', this.currentUser);
          return res.data;
        });
    },

    onAuthStateChanged: (cb) => {
      this.addListener('authStateChange', cb);
    },
    isAuthenticated: () => !!this.currentUser || !!localStorage.getItem(storageKey),
    getAuthenticatedUser: () => {
      const parsed = JSON.parse(localStorage.getItem(storageKey));
      return parsed;
    },
  }

  project = {
    getAccount: (accountId) => {
      return {
        users: {
          1: true,
        }
      }
    },
    getUserProjects: (uid) => {
      return new Promise(resolve => resolve([]));
    }
  }
}

export default LocalIamEngine;
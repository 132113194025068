import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InputLabel from '@material-ui/core/InputLabel';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Icon from '@material-ui/core/Icon';

// import FirebaseStorageUploader from '../FirebaseStorageUploader';

import MediaSelector from '../../components/MediaSelector';

// import firebase from '../../engine';

const styles = theme => ({
  iconHover: {
    position: 'absolute',
    top: '-7px',
    right: '-7px',
    cursor: 'pointer',
    fontSize: 30,
    '&:hover': {
      color: red[800]
    }
  }
});

const SelectMedia = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const StyledFormControl = styled(FormControl)`
  && {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
  }
  &&:hover {
    border: 1px solid black;
  }
`;
const StyledInputLabel = styled(InputLabel)`
  && {
    background-color: white;
    padding: 0 5px;
  }
`;

const ContainerMedia = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .boder-box-media {
    width: 147px;
    height: 147px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .box-media {
    width: 140px;
    height: 140px;
  }
`;

const mapStateToProps = (state, ownProps) => {
  const { spaceId, mediaId } = ownProps;
  const { medias } = state.spaces[spaceId];
  return {
    media: _.get(medias, mediaId)
  };
};

const MediaPreview = connect(mapStateToProps)(({ mediaId, media, classes, onDelete }) => (
  <div className="boder-box-media" key={mediaId}>
    <div className="box-media">
      <Icon className={classes.iconHover} color="error" onClick={() => onDelete(mediaId)}>
        remove_circle
      </Icon>
      <img src={_.get(media, 'url')} alt="" />
    </div>
  </div>
));

export default withStyles(styles)(
  class extends Component {
    static defaultProps = {
      multiple: false
    };

    static propTypes = {
      multiple: PropTypes.bool
    };

    constructor(props) {
      super(props);
      this.labelRef = React.createRef();
    }

    state = {
      selectedValue: []
    };
    handleInputChange = media => {
      this.props.onChange(media);
    };

    removeMedia = mediaId => {
      const { value } = this.props;
      const newValue = _.filter(value, val => val !== mediaId);
      this.props.onChange(newValue);
    };

    render() {
      const { label, spaceId, multiple, classes, value } = this.props;
       const newValue = multiple ? this.props.value : [this.props.value];

      return (
        <StyledFormControl variant="outlined" fullWidth>
          <StyledInputLabel shrink variant="outlined" htmlFor="name" ref={this.labelRef}>
            {label}
          </StyledInputLabel>
          <SelectMedia>
            <MediaSelector spaceId={spaceId} onSelect={this.handleInputChange} multiple={multiple} value={value} />
            {newValue && (
              <ContainerMedia>
                {_.map(newValue, mediaId => {
                  return <MediaPreview mediaId={mediaId} classes={classes} onDelete={this.removeMedia} spaceId={spaceId} />;
                })}
              </ContainerMedia>
            )}
          </SelectMedia>
        </StyledFormControl>
      );
    }
  }
);

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';

const darkTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'dark',
  },
});

const StatCard = ({ label, value, variant = "h5" }) => (
  <Card>
    <CardContent style={{ textAlign: 'right' }}>
      <Typography style={{ fontSize: 12 }} color="textSecondary" gutterBottom>{label}</Typography>
      <Typography variant={variant}>{value}</Typography>
    </CardContent>
  </Card>
);


const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;
  const project = _.get(state, ['projects', projectId]);

  const countMedias = _.size(_.get(project, 'medias'));
  const countShops = _.size(_.get(project, 'shops'));
  const countCategories = _.size(_.get(project, 'categories'));

  return {
    contentVersion: _.get(project, 'metadata.version'),
    lastUpdate: moment(_.get(project, 'metadata.updatedAt.seconds'), 'X').format('D/MM/YYYY HH:mm:ss'),
    countMedias,
    countShops,
    countCategories,
    users: _.get(state, ['projects', projectId, 'users']),
  }
}
export default connect(
  mapStateToProps,
)(class extends React.Component {

  state = {
    activeStep: 0,
  }

  handleSelectStep = (index) => this.setState({ activeStep: index })

  render() {
    const projectId = _.get(this.props, 'projectId');
    const { contentVersion, lastUpdate, countMedias, countShops, countCategories, users } = this.props;

    return (
      <Grid container style={{ background: '#f7f7f7' }}>
        <Grid item sm={12}>
          <Grid container style={{ margin: '32px 0 8px' }} justify="space-between" alignItems="center">
            <Grid item sm={6}>
              <Typography variant="h4" gutterBottom style={{ marginLeft: 16 }}>Overview</Typography>
            </Grid>
            <Grid item sm={6}>
              <MuiThemeProvider theme={darkTheme}>
                <Card>
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item xs={3}><StatCard label="Content Version" value={contentVersion} /></Grid>
                    <Grid item xs={6}><StatCard label="Last Update" value={lastUpdate} /></Grid>
                    <Grid item xs={3} style={{ textAlign: 'center' }}>
                      <Button
                        color="primary"
                        variant="contained"
                        component="a"
                        href={`${process.env.REACT_APP_CLOUDFUNCTIONURL}/aspen/projects/${projectId}/json`} target="_blank"
                      >Download JSON</Button>
                    </Grid>
                  </Grid>
                </Card>
              </MuiThemeProvider>
            </Grid>
          </Grid>
          <Grid container style={{ padding: 16 }}>
            <Grid item xs={12} sm={3}><StatCard label="Media" value={countMedias} /></Grid>
            <Grid item xs={12} sm={3}><StatCard label="Categories" value={countCategories} /></Grid>
            {/* <Grid item xs={12} sm={3}><StatCard label="Shops" value={countShops} /></Grid> */}
            {/* <Grid item xs={12} sm={3}><StatCard label="Users" value={_.size(users)} /></Grid> */}
          </Grid>
        </Grid>
      </Grid>
    );
  }
});

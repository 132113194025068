import _ from 'lodash';

/**
 * Parse duration - hours is not supported.
 * 
 * @param  {Number} durationInSecond
 * @return string as x:xx if receive the position number and -x:xx if receive the negative number
 */
export default (durationInSecond) => {
  const minute = Math.abs(Math.trunc(durationInSecond / 60));
  const second = _.padStart(Math.abs(durationInSecond % 60), 2, '0');
  const sign = Math.sign(durationInSecond) < 0 ? '-' : '';

  return `${sign}${minute}:${second}`;
};
import React from 'react';
import _ from 'lodash';
import { notify } from 'react-notify-toast';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

// import {
//   saveMediaToFirestore,
// } from 'forviz-mediajs';

export default class AddMediaDialog extends React.Component {

  state = {
    items: [],
    idPrefix: '',
    prevFiles: null,
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.files, state.prevFiles)) {
      return {
        items: _.map(props.files, file => ({
          ref: file.ref,
          url: file.url,
          id: _.kebabCase(_.replace(_.get(file, 'ref.name'), /(.jpg|.png|.gif|.svg)/ig, '')),
          title: _.get(file, 'ref.name'),
          tags: '',
        })),
        prevFiles: props.files,
      }
    }
    return null;
  }
  
  handleChange = (name, index, value) => {
    this.setState({
      items: _.map(this.state.items, (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      }),
    });
  }

  save = () => {
    const { client } = this.props;
    const { items } = this.state;
    console.log('save', items);
    
    Promise.all(
      _.map(items, item => {
        return client.saveMediaToFirestore(item.id, item.ref.toString(), {
          url: item.url,
          title: item.title,
          tags: _.map(_.split(item.tags, ','), _.trim),
        });
      }),
    ).then(() => {
      this.props.onClose();
      notify.show(`Medias add`, 'success', 4000);
    }).catch(error => {
      console.log('error', error.message);
      notify.show(`Error ${error.message}`, 'danger', 4000);
    });
  }

  render() {
    const { open, onClose } = this.props;
    const { items } = this.state;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Media</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          {_.map(items, (item, index) => (
            <div key={`${item.url}-${item.index}`}>
              <Grid container spacing={24}>
                <Grid item sm={6}>
                  <img style={{ maxWidth: '100%' }} src={item.url} alt="" />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    margin="dense"
                    id="id"
                    label="ID"
                    type="text"
                    value={item.id}
                    onChange={e => this.handleChange('id', index, e.target.value)}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="title"
                    label="Title"
                    type="text"
                    value={item.title}
                    onChange={e => this.handleChange('title', index, e.target.value)}
                    fullWidth
                  />
                  <TextField
                    margin="dense"
                    id="tags"
                    label="Tags"
                    type="text"
                    value={item.tags}
                    onChange={e => this.handleChange('tags', index, e.target.value)}
                    helperText="Separate by comma"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Divider style={{ margin: '24px 0' }} />
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.save} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
    
  }
}
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Cell, PieChart, Pie, Tooltip } from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      style={{ fontSize: "14px", marginBottom: 5 }}
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default class extends PureComponent {
  static propTypes = {
    dataKey: PropTypes.string,
    width: PropTypes.number,
    source: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };
  static defaultProps = {
    dataKey: "value",
    width: 800,
  };
  state = {};
  // ======================================================
  // Commit Phase
  // Can work with DOM, run side effects, schedule updates.
  // ======================================================
  componentDidMount = () => {};
  componentDidUpdate = (prevProps, prevState, snapshot) => {};
  componentWillUnmount = () => {};
  // ======================================================
  // Pre-commit Phase
  // Can read the DOM
  // ======================================================
  getSnapshotBeforeUpdate = (prevProps, prevState) => {
    return {};
  };
  // ======================================================
  // Render Phase
  // Pure and no side effects. May be paused, aborted or restarted by React.
  // ======================================================
  static getDerivedStateFromProps(props, state) {
    return {};
  }
  // ======================================================
  // Private Methods
  // ======================================================
  render() {
    const { width, source, dataKey } = this.props;
    return (
      <PieChart width={width} height={width / 2}>
        <Pie
          isAnimationActive
          data={source}
          cx={width / 4}
          cy={width / 4}
          outerRadius={width / 4.5}
          label={renderCustomizedLabel}
          labelLine={false}
          dataKey={dataKey}
        />
        {source.map((entry, index) => (
          <Cell key={`cell-${index}-${entry.name}`} fill={entry.fill} />
        ))}
        {this.props.children}
        <Tooltip />
      </PieChart>
    );
  }
}

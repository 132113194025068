import _ from 'lodash';

const initialState = {
  entities: {},
  fetchStatuses: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ENTITIES/APPS/RECEIVED':
      return {
        ...state,
        entities: _.reduce(action.items, (sum, item) => {
          return { ...sum, [item.id]: item }
        }, state.entities),
      }
    default: return state;
  }
}

export default reducer;
import excel from "./excel";
import getDateDisplay from "./getDateDisplay";
import getDateTimeDisplay from "./getDateTimeDisplay";
import getWeekOfMonth from "./getWeekOfMonth";
import randomWithoutDuplicated from "./randomWithoutDuplicated";
import jsonToCsvConvertor from "./jsonToCsvConvertor";
import permission from "./permission";
import slugify from "./slugify";

export default {
  excel,
  getDateDisplay,
  getDateTimeDisplay,
  getWeekOfMonth,
  randomWithoutDuplicated,
  jsonToCsvConvertor,
  permission,
  slugify,
};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';


import Typography from '@material-ui/core/Typography';
// import Container, { Row, Col } from '../../components/Grid';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CreateDocButton from '../../components/CreateDocButton';

import faceImg from './images/face.png';
import eyesImg from './images/eyes.png';

import engine from '../../engine';

import { createAccount } from '../../apps/iam/api';


const Robot = (props) => (
  <svg {...props} viewBox="0 0 495 357" preserveAspectRatio="xMidYMid meet">
    <g>
      <image href={faceImg} x="0" y="0" width="495" height="357" />
      <image href={eyesImg} x="110" y="98" width="250" height="138" />
    </g>
  </svg>
)

class SelectAccount extends Component {

  constructor(props) {
    super(props);
    this.createAccountModal = React.createRef();
  }

  componentDidMount() {
    const { history, accounts } = this.props;
    if (_.size(accounts) === 1) {
      history.push(`/p/${_.get(accounts, '0.id')}`);
    }
  }

  openCreateAccountModal = () => {
    this.createAccountModal.current.openDialog();
  }

  submitCreateAccountModal = ({ name }) => {
    const { history, user } = this.props;
    console.log('createAccount', user, name);
    createAccount(user.uid, name)
      .then(res => res.body)
      .then(result => {
        console.log('createAccount result', result);
        history.push(`/p/${_.get(accounts, '0.id')}`);
      })
      .catch(error => {
        console.log('error', error.message);
      });
  }

  render() {
    const { accounts } = this.props;
    return (
      <div>
        <Grid container justify="center" style={{ padding: '40px 0 0' }}>
          <Grid item sm={2}>
            <h2>OH! It’s you... again<br/>How’s things going ? </h2>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ padding: '0 0 40px' }}>
          <Grid item sm={2}>
            <Robot width="100%" />
          </Grid>
        </Grid>        
        <Grid container justify="center">
          <Grid item>
            <Typography gutterBottom variant="h4">Select Account</Typography>
            <Card>
              <List>
              {_.map(accounts, account => (
                <ListItem key={account.id} button component={Link} to={`/p/${account.id}`}>
                  <ListItemIcon>
                    <Avatar>{_.get(account, 'name', '0').charAt(0)}</Avatar>
                  </ListItemIcon>
                  <ListItemText primary={account.name} />
                </ListItem>
              ))}
              </List>
            </Card>
            <Card style={{ marginTop: 30 }}>
              <List>
                <ListItem key="create-account" button ContainerProps={{ activeClassName: undefined }}>
                  <ListItemText primary="Create another Account" onClick={this.openCreateAccountModal} />
                </ListItem>
                <ListItem key="logout" button>
                  <ListItemText primary="Logout" onClick={engine.auth.signOut} />
                </ListItem>
              </List>
            </Card>
            <CreateDocButton
              ref={this.createAccountModal}
              fields={[
                { name: 'name', label: 'Account Name' },
              ]}
              onCreate={this.submitCreateAccountModal}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default SelectAccount;

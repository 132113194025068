import _ from 'lodash';

import * as MinkApi from '../apps/mink/api';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export const storageKey = 'FORVIZ-CONSOLE';

const APPS_COLLECTION = 'iam_apps';
const PROJECTS_COLLECTION = 'iam_projects';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
};
firebase.initializeApp(config);

class FirebaseEngine {
  type = 'firebase';
  db = firebase.firestore();
  
  auth = {
    user: null,
    signInWithEmailAndPassword: (email, password) =>
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(async result => {
          // console.log('Engine', user);
          const user = result.user;
          const userDoc = await firebase.firestore().collection('iam_users').doc(user.uid).get();
          return { ...user.toJSON(), ...userDoc.data() };
        }),
    signInWithGoogle: () => {
      const provider = new firebase.auth.GoogleAuthProvider();
      return firebase.auth().signInWithPopup(provider)
        .then(async result => {
          const user = result.user;
          const uid = user.uid;
          const userDoc = await firebase.firestore().collection('iam_users').doc(uid).get();
          return { ...user.toJSON(), ...userDoc.data() };
        });
    },
    signInWithFacebook: () => {
      const provider = new firebase.auth.FacebookAuthProvider();
      return firebase.auth().signInWithPopup(provider)
        .then(async result => {
          const user = result.user;
          const uid = result.user.uid;
          const userDoc = await firebase.firestore().collection('iam_users').doc(uid).get();
          return { ...user.toJSON(), ...userDoc.data() };
        });
    },
    signOut: () => {
      return firebase.auth().signOut();
    },
    // onAuthStateChanged: (cb) => firebase.auth().onAuthStateChanged(cb),
    onAuthStateChanged: (cb) => firebase.auth().onAuthStateChanged(async user => {
      if (user === null) cb(user);
      else {
        const userWithInfo = await firebase.auth().currentUser.getIdTokenResult()
          .then((idTokenResult) => ({ ...user.toJSON(), claims: idTokenResult.claims }));
        this.auth.user = userWithInfo;
        cb(userWithInfo);
      }
    }),

    isAuthenticated: () => !!window.localStorage.getItem(storageKey),
    getAuthenticatedUser: () => {
      return this.auth.user;
    },
  }

  app = {
    getApps: () => {
      return this.db.collection(APPS_COLLECTION)
        .get()
        .then(querySnapshot => {
          return _.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() }));
        });
    },
    activateApp: async (projectId, appId, options = {}) => {
      const user = this.auth.getAuthenticatedUser();

      
      // Activate App Script
      switch (appId) {
        case 'mink':
          // Create new space
          const space = await MinkApi.createSpace(user)(`${projectId}.main`, 'Default');
          options.defaultSpace = space.id;
          break;
        default:
          break;
      }

      // Update Project
      const projectRef = this.db
        .collection(PROJECTS_COLLECTION)
        .doc(projectId);
      
      projectRef
        .get()
        .then(doc => {
          if (!doc.exists) throw new Error({ message: 'Project not exists' });
          const project = doc.data();

          projectRef.update({
            apps: {
              ...project.apps,
              [appId]: {
                enabled: true,
                ...options,
              }
            } 
          })
        });
    
    }
  }

  project = {
    createProject: ({ id, name }) => {
      if (_.isEmpty(id) || _.isEmpty(name)) return Promise.reject({ message: 'required id, name' });

      return this.db.collection(PROJECTS_COLLECTION)
        .doc(id)
        .set({
          id,
          name,
        });
    },
    getAccount: (accountId) => {
      if (accountId) {
        return firebase.firestore().collection('iam_projects').doc(accountId)
          .get()
          .then(doc => {
            return { id: doc.id, ...doc.data() };
          })
      }
    },
    getUserProjects: (uid) => {
      const userProjectsRef = firebase.firestore().collection('iam_projects');
      return userProjectsRef
        .where(`users.${uid}`, '==', true)
        .get()
        .then((querySnapshot) => {
          return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        });
    }
  }
}

export default FirebaseEngine;
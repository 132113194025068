import React from 'react';
import _ from 'lodash';
import FirestoreDocEditor from '../../../../components/FirestoreDocEditor';
import { connect } from 'react-redux';

// const cleanObject = obj => _.pickBy(obj, v => v !== undefined);

// const cleanObject = (obj) => {
//   Object.keys(obj).forEach(key => {
//     if (obj[key] && typeof obj[key] === 'object') cleanObject(obj[key]);
//     else if (obj[key] === undefined) delete obj[key];
//   });
//   return obj;
// };


const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, 'projectId');
  // console.log('Directory mapStateToProps', projectId, state);
  const setting = _.get(state, ['projects', projectId, 'setting']);

  const units = _.get(state, ['projects', projectId, 'units']);
  const unitOptions = _.map(units, (unit, unitId) => ({
    value: { floorId: unit.floorId, unitId: unit.id },
    label: unitId,
  }));
  
  const categories = _.get(state, ['projects', projectId, 'categories']);
  const categoriesOptions = _.map(categories, (cat, id) => ({
    id,
    value: id,
    label: _.get(cat, 'fields.name.en'),
    parent: _.get(cat, 'fields.parent'),
  }));

  const zones = _.get(state, ['projects', projectId, 'zones']);
  const zoneOptions = _.map(zones, (zone, zoneId) => ({
    value: zoneId,
    label: _.get(zone, 'fields.name.en'),
  }));

  const floors = _.get(state, ['projects', projectId, 'floors']);
  const floorOptions = _.map(floors, (floor, floorId) => ({
    value: floorId,
    label: _.get(floor, 'fields.name.en'),
  }));

  const nodes = _.get(state, ['projects', projectId, 'nodes']);
  const nodeOptions = _.map(nodes, (node, nodeId) => ({
    value: nodeId,
    label: nodeId,
  }));

  return {
    medias: _.get(state, ['projects', projectId, 'medias']),
    unitOptions,
    categoriesOptions,
    zoneOptions,
    floorOptions,
    nodeOptions,
    setting,
  }
};
export default connect(mapStateToProps)((props) => {
  const { projectId, setting, medias, categoriesOptions, zoneOptions, floorOptions, unitOptions, nodeOptions } = props;
  return (
    <FirestoreDocEditor
      {...props}
      mediaEngine="firebase"
      mediaEngineConfig={{
        storagePath: `aspen/projects/${projectId}`,
        firestorePath: `aspen_projects/${projectId}/medias`
      }}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => {
        
        const languages = _.get(setting, 'languages', []);
        const defaultObjectTextFieldValue = _.reduce(languages, (acc, lang) => ({ ...acc, [lang]: '' }), {});
        return {
          name: {
            type: 'Object',
            layout: 'horizontal',
            label: 'Name',
            value: _.get(data, 'fields.name'),
            defaultValue: defaultObjectTextFieldValue,
          },
          description: {
            type: 'Object',
            layout: 'horizontal',
            label: 'Description',
            value: _.get(data, 'fields.description'),
            defaultValue: defaultObjectTextFieldValue,
          },
          category: {
            type: 'Dropdown',
            label: 'Category',
            value: _.get(data, 'fields.category'),
            options: _.filter(categoriesOptions, cat => _.isEmpty(_.get(cat, 'parent'))),
          },
          subcategory: {
            type: 'Dropdown',
            label: 'Sub Category',
            value: _.get(data, 'fields.subcategory'),
            options: _.filter(categoriesOptions, catOption => _.get(catOption, 'parent') === _.get(data, 'fields.category')),
          },
          tags: { label: 'Keywords/Tags', value: _.join(_.get(data, 'fields.tags', [])) },
          icon: {
            label: 'Icon',
            type: 'Image',
            value: _.get(data, 'fields.icon'),
            options: _.map(medias, (media, key) => ({ value: key, label: key, url: media.url }))
          },
          gallery: {
            label: 'Gallery',
            type: 'Gallery',
            value: _.get(data, 'fields.gallery'),
            options: _.map(medias, (media, key) => ({ value: key, label: key, url: media.url }))
          },
          website: { label: 'Website', value: _.get(data, 'fields.website') },
          phone: { label: 'Phone', value: _.get(data, 'fields.phone') },
          floor: {
            label: 'Floor',
            type: 'Dropdown',
            value: _.get(data, 'fields.floor'),
            options: floorOptions,
          },
          unit: { label: 'Unit', value: _.get(data, 'fields.unit') },
          units: {
            label: 'Units',
            type: 'AutocompleteMultiple',
            value: _.map(
              _.filter(_.get(data, 'fields.units'), ({ floorId, unitNo }) => (floorId !== undefined || unitNo !== undefined)),
              unitObj => `${_.get(unitObj, 'floorId')}!${_.get(unitObj, 'unitNo')}`),
            suggestions: unitOptions,
          },
          node: {
            label: 'Node',
            value: _.get(data, 'fields.node'),
            type: 'Autocomplete',
            placeholder: 'Select Node',
            suggestions: nodeOptions,
          },
          zone: {
            label: 'Zone',
            type: 'Dropdown',
            value: _.get(data, 'fields.zone'),
            options: zoneOptions,
          },
        }
      }}
      mapFieldValuesToDocData={fieldValues => {
        // console.log('fieldValues', fieldValues);
        
        return {
          fields: {
            name: fieldValues.name,
            description: fieldValues.description,
            category: fieldValues.category,
            subcategory: fieldValues.subcategory,
            tags: _.map(_.split(fieldValues.tags, ','), _.trim),
            icon: fieldValues.icon,
            gallery: fieldValues.gallery,
            floor: fieldValues.floor,
            unit: fieldValues.unit,
            units: _.map(fieldValues.units, unit => {
              const [ floorId, unitNo ] = _.split(unit, '!');
              return { floorId, unitNo };
            }),
            node: fieldValues.node,
            zone: fieldValues.zone,
            website: fieldValues.website,
            phone: fieldValues.phone,
          }
        }
      }}
      sections={[
        {
          title: 'Info',
          fields: [
            'name',
            'description',
            'website',
            'phone'
          ]
        },
        {
          title: 'Location',
          fields: ['floor', 'units', 'node', 'zone'],
        },
        {
          title: 'Media',
          fields: ['icon', 'gallery'],
        },
      ]}
    />
  );
});

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { withRouter, NavLink, Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

import DarkThemeIcon from '@material-ui/icons/Brightness4';
import LightThemeIcon from '@material-ui/icons/Brightness7';

import engine from '../../engine';

import { utility } from '../../lib'

const { permission } = utility;
const {
  getProjectRole,
} = permission;

const isSingleProject = process.env.REACT_APP_IS_SINGLE_PROJECT === 'true';

const drawerWidth = 240;

const backgroundColor = '#263238';
const textColor = '#ffffff';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    // marginLeft: drawerWidth,
    background: backgroundColor,
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

const mapDispatchToProps = dispatch => {
  return {
    toggleTheme: () => {
      dispatch({ type: 'THEME/TOGGLE' })
    }
  }
}
const mapStateToProps = state => {
  return {
    theme: _.get(state, 'theme'),
    user: _.get(state, 'user'),
  };
}

const Header = withStyles(styles)(class extends React.Component {
  state = {
    mobileOpen: false,
    anchorProjectEl: null,
    anchorUserEl: null,
    showCreateAccountDialog: false,
    openUserMenu: false,
  }

  componentWillUnmount() {
    this.setState({
      anchorUserEl: null,
      openUserMenu: false,
    });
  }

  /* Project */
  handleOpenProject = event => {
    this.setState({
      anchorProjectEl: event.currentTarget
    });
  }

  handleCloseProject = () => {
    this.setState({ anchorProjectEl: null });
  }

  showCreateAccountDialog = () => {
    this.setState({
      showCreateAccountDialog: true,
    })
  }

  closeCreateProjectDialog = () => {
    this.setState({
      showCreateAccountDialog: false,
    })
  }
  /* End of Project */

  /* User */
  handleOpenUser = event => {
    this.setState({
      anchorUserEl: event.currentTarget,
      openUserMenu: true,
    });
  }

  handleCloseUser = () => {
    this.setState({ anchorUserEl: null, openUserMenu: false });
  }
  /* End of User */

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  }

  handleSignOut = (e) => {
    const { history } = this.props; 
    this.setState({
      openUserMenu: false,
    });
    engine.auth.signOut().then(result => {
      history.push('/iam/login');
    });
  }

  getAppId = () => {
    const { location: { pathname }} = this.props;
    // example path /p/the-mall/ombres/the-em-district
    return pathname.split('/')[3];
  }

  getProjectId = () => {
    const { location: { pathname }} = this.props;
    // example path /p/the-mall/ombres/the-em-district
    // example path /p/sena/meerkat/b/sena.sena-piti-sukhumvit-101
    const params = pathname.split('/');
    if (params[4] && params[4] === 'b') return params[5]
    return params[4];
  }

  render() {
    const { classes, theme, toggleTheme } = this.props;
    const { user } = this.props;
    const { accounts } = this.props.user;
    const currentAccountId = _.get(this.props, 'match.params.accountId');
    const appId = this.getAppId()
    const projectId = this.getProjectId()
    
    const userRole = getProjectRole(user, currentAccountId, appId, projectId)
    let displayUserRole = ''
    if (userRole) displayUserRole = `(${userRole})`

    const { anchorUserEl, anchorProjectEl, openUserMenu } = this.state;

    const openProject = Boolean(anchorProjectEl);

    const secondMenu = isSingleProject
    ? (
      <React.Fragment>
        <Button style={{ color: textColor }} component={NavLink} to="/mink">CMS</Button>
        <Button style={{ color: textColor }} component={NavLink} to="/stats">Stats</Button>
      </React.Fragment>
    )
    : (
      <div>
        <Button
          style={{ color: textColor }} 
          ref={node => {
            this.button = node;
          }}
          onClick={this.handleOpenProject}
        >
          {currentAccountId || 'Select Project'}
        </Button>
        <Menu
          anchorEl={anchorProjectEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={openProject}
          onClose={this.handleCloseProject}
        >
          <List subheader={<ListSubheader>{_.toUpper(currentAccountId)}</ListSubheader>}>
            <MenuItem key="apps" component={Link} to={`/p/${currentAccountId}/apps`}>All Apps</MenuItem>
            <MenuItem key="members" component={Link} to={`/p/${currentAccountId}/members`}>Members</MenuItem>
            <MenuItem key="apikeys" component={Link} to={`/p/${currentAccountId}/apikeys`}>API Keys</MenuItem>
          </List>
          <Divider />
          <List subheader={<ListSubheader>Switch Account</ListSubheader>}>
            {_.map(accounts, (account) => (
              <MenuItem key={account.id} component={Link} to={`/p/${account.id}`}>{account.name}</MenuItem>
            ))}
          </List>
        </Menu>
      </div>
    );
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={this.handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Button style={{ color: textColor }} component={NavLink} to={process.env.REACT_APP_PATH_DEFAULT}>Home</Button>
          {_.get(user, 'uid') && secondMenu}
          <Typography variant="h6" color="inherit" noWrap style={{ flexGrow: 1 }}>
            
          </Typography>
          <Tooltip title={`Switch to ${theme === 'light' ? 'dark' : 'light'} theme`}>
            <IconButton style={{ color: textColor }} onClick={toggleTheme}>
              {theme === 'light' ? <DarkThemeIcon /> : <LightThemeIcon />}
            </IconButton>
          </Tooltip>
          {_.get(user, 'uid') && (
            <div>
              <Button style={{ color: textColor }} onClick={this.handleOpenUser}>{user.displayName || user.email} {displayUserRole}</Button>
              <Menu
                anchorEl={anchorUserEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openUserMenu}
                onClose={this.handleCloseUser}
              >
                <MenuItem component={Link} to={`/iam/profile`}>User Setting</MenuItem>
                <MenuItem onClick={this.handleSignOut}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));

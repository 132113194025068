import React from 'react';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuWithNested from '../../../../components/Menu/Menu';


export default class extends React.Component {

  static defaultProps = {
    menus: [
      { key: 'addto-playlist' },
      { key: 'edit' },
      { key: 'delete' },
    ]
  }

  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  handleAddToPlaylist = (playlistId) => {
    const { client, mediaId } = this.props;
    client.addMediaToPlaylist(playlistId, mediaId);
    this.setState({ anchorEl: null });
  }

  render () {
    const { mediaId, menus, playlists } = this.props;
    const { anchorEl } = this.state;

    const menuAddtoPlaylist = _.find(menus, m => m.key === 'addto-playlist');
    const menuEdit = _.find(menus, m => m.key === 'edit');
    const menuDelete = _.find(menus, m => m.key === 'delete');

    const menuItems = _.compact([
      menuAddtoPlaylist ? {
        key: "addto-playlist",
        title: "Add to playlist",
        items: _.map(playlists, playlist => ({
          key: playlist.id,
          title: playlist.name,
          onClick: () => {
            this.handleAddToPlaylist(playlist.id);
            this.handleClose();
          }
        }))
      } : undefined,
      menuEdit ? {
        key: "edit",
        title: "Edit media",
        onClick: e => {
          menuEdit.onClick(mediaId);
          this.handleClose();
        }
      } : undefined,
      menuDelete ? {
        key: "delete",
        title: menuDelete.title || 'Delete media',
        onClick: e => {
          menuDelete.onClick(mediaId);
          this.handleClose();
        }
      } : undefined,
    ]);
    return (
      <div>
        <IconButton onClick={this.handleClick}><MoreVertIcon /></IconButton>
        <MenuWithNested
          open={Boolean(anchorEl)}
          menuItems={menuItems}
          anchorElement={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        />
      </div>
    )
  }
}
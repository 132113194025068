import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputField from '../InputField';

export default class extends React.Component {
  static propTypes = {
    onCreate: PropTypes.func,
  }

  static defaultProps = {
    title: undefined,
    okText: undefined,
    label: 'Document',
    fields: [
      { type: 'TextField', name: 'title', label: 'Title', required: true }
    ],
    renderButton: () => <span />,
    additionalFields: [],
  }
  constructor (props) {
    super(props);
    this.state = {
      open: false,
      values: _.reduce(props.fields, (acc, field) => ({ ...acc, [field.name]: '' }), {})
    }
  
    _.forEach(props.fields, field => {
      this[`input${_.capitalize(field.name)}`] = React.createRef();
    });
  }

  openDialog = () => this.setState({ open: true })
  closeDialog = () => {
    this.setState({ open: false });

    this.setState({
      values: _.reduce(this.props.fields, (acc, field) => ({ ...acc, [field.name]: '' }), {}),
    })
  }

  handleOk = () => {
    const values = this.state.values;
    this.props.onCreate(values);
    this.closeDialog();
    
  }

  handleChange = (name, e) => {
    this.setState({
      values: {
        ...this.state.values,
        [name]: e.target.value,
      }
    })
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleOk();
    }
  }

  renderDefaultButton = ({ onClick }) => {
    return (
      <Button
        variant="extendedFab"
        color="primary"
        aria-label="Add"
        onClick={onClick}
      >
        <AddIcon />
      </Button>
    );
  }

  render() {
    const { title, okText, label, fields } = this.props;
    const { values } = this.state;
    const renderButton = typeof this.props.renderButton === 'function' ? this.props.renderButton : this.renderDefaultButton;
    return (
      <div>
        {React.cloneElement(renderButton(), { onClick: this.openDialog })}
        <Dialog
          open={this.state.open}
          onClose={this.closeDialog}
          maxWidth="sm"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{title ? title : `Add a ${label}`}</DialogTitle>
          <DialogContent>
            {fields.map((field, index) => (
              <div style={{ margin: '5px 0 15px' }} key={field.name}>
                <InputField
                  key={field.name}
                  {...field}
                  autoFocus={index === 0}
                  value={values[field.name]}
                  onChange={e => this.handleChange(field.name, e)}
                  onKeyDown={index === _.size(fields) - 1 ? this.handleKeyDown : undefined}
                />
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeDialog}>Cancel</Button>
            <Button color="primary" onClick={this.handleOk}>{okText ? okText : `Create ${label}`}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
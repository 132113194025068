import React from 'react';
import _ from 'lodash';

export default ({ status }) => {
  const label = _.capitalize(status);
  switch (_.toLower(status)) {
    case 'published': return <span style={{ color: '#4caf50' }}>{label}</span>;
    case 'draft': return <span style={{ color: '#b28704' }}>{label}</span>;
    case 'archived': return <span style={{ color: '#787878' }}>{label}</span>;
    default: return <span>No {status}</span>;
  }
}
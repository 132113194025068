import React from 'react';

export default (props) => (
  <svg {...props} viewBox="0 0 800 542.27">
    <defs>
      <linearGradient id="linear-gradient" x1="400" y1="542.27" x2="400" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#ffba1a"/>
        <stop offset="1" stopColor="#ffa400"/>
      </linearGradient>
    </defs>
    <title>jelly</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M0,542.27S49.92,383.44,184.54,339.58,405.38,250.33,481,158.06,632.27-8.32,703.36.75s92.27,101.34,95.29,276.81,0,264.71,0,264.71Z" fill="url(#linear-gradient)"/>
      </g>
    </g>
  </svg>
)
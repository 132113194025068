"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createClient = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _firebase = _interopRequireDefault(require("./firebase"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var createClient = function createClient(config) {
  var accountId = config.accountId;

  var db = _firebase["default"].firestore();

  var accountRef = db.collection('splitr_accounts').doc(accountId);

  var getAccountRef = function getAccountRef() {
    return accountRef;
  };

  var getDeviceCollectionRef = function getDeviceCollectionRef() {
    return accountRef.collection('devices');
  };

  var getDeviceDocRef = function getDeviceDocRef(deviceId) {
    return getDeviceCollectionRef().doc(deviceId);
  };

  var getPlaylistCollectionRef = function getPlaylistCollectionRef() {
    return accountRef.collection('playlists');
  };

  var getMediaDocRef = function getMediaDocRef(mediaId) {
    return accountRef.collection('medias').doc(mediaId);
  };

  var getPlaylistDocRef = function getPlaylistDocRef(playlistId) {
    return accountRef.collection('playlists').doc(playlistId);
  }; // metadata


  var patchVersion = function patchVersion(playlistId) {
    var playlistDocRef = getPlaylistDocRef(playlistId);
    return playlistDocRef.get().then(function (doc) {
      var _doc$data = doc.data(),
          metadata = _doc$data.metadata;

      var version = _lodash["default"].get(metadata, 'version', 0);

      var nextVersion = version + 1;
      return playlistDocRef.set({
        metadata: {
          version: nextVersion,
          updatedAt: (0, _moment["default"])().format()
        }
      }, {
        merge: true
      });
    });
  }; // Device


  var createDevice = function createDevice(data) {
    var collectionRef = getDeviceCollectionRef();
    collectionRef.add(data);
  };

  var createPlaylist = function createPlaylist(name) {
    return accountRef.collection('playlists').add({
      name: name,
      medias: []
    });
  };

  var addMediaToPlaylist = function addMediaToPlaylist(playlistId, mediaId, expireAt) {
    var mediaRef = getMediaDocRef(mediaId);
    var playlistDocRef = getPlaylistDocRef(playlistId);
    Promise.all([mediaRef.get(), playlistDocRef.get()]).then(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          mediaDoc = _ref2[0],
          playlistDoc = _ref2[1];

      var media = mediaDoc.data();
      var playlist = playlistDoc.data();

      var medias = _lodash["default"].get(playlist, 'medias', []);

      var updatedMedias = [].concat(_toConsumableArray(medias), [{
        mediaId: mediaId,
        startAt: 0,
        endAt: 0,
        duration: 5,
        expireAt: media.expireAt || (0, _moment["default"])().add(1, 'M').format()
      }]);
      playlistDocRef.set({
        medias: updatedMedias
      }, {
        merge: true
      });
      patchVersion(playlistId);
    });
  };

  var updatePlaylist = function updatePlaylist(playlistId, playlistData) {
    var playlistDocRef = getPlaylistDocRef(playlistId);
    playlistDocRef.set(playlistData, {
      merge: true
    });
    patchVersion(playlistId);
  };
  /**
   * @param  {String} mediaId = id of medias
   */


  var getPlaylistsThatContainMedia = function getPlaylistsThatContainMedia(mediaId) {
    // loop in playlist collection (documents)
    var playlistRef = getPlaylistCollectionRef();
    return playlistRef.get().then(function (querySnapshot) {
      return _lodash["default"].chain(querySnapshot.docs).filter(function (playlistDoc) {
        var data = playlistDoc.data();

        var isContainMediaId = _lodash["default"].some(data.medias, function (media) {
          return media.mediaId === mediaId;
        });

        return isContainMediaId;
      }).reduce(function (acc, playlistDoc) {
        return Object.assign({}, acc, _defineProperty({}, playlistDoc.id, playlistDoc.data()));
      }, {}).value();
    });
  };

  var removeMediaFromPlaylists = function removeMediaFromPlaylists(mediaId) {
    var playlistRef = getPlaylistCollectionRef();
    return new Promise(function (resolve, reject) {
      playlistRef.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          var playlistId = doc.id;
          var data = doc.data();

          var remainMedias = _lodash["default"].filter(data.medias, function (media) {
            return media.mediaId !== mediaId;
          }); // console.log("deleteMediaFromLibrary >>> remainMedias", remainMedias)


          var playlistDocRef = getPlaylistDocRef(playlistId);
          playlistDocRef.set({
            medias: remainMedias
          }, {
            merge: true
          });
          patchVersion(playlistId);
        });
        resolve(true);
      });
    });
  };

  var deleteMediaFromLibrary = function deleteMediaFromLibrary(mediaId) {
    console.log("deleteMediaFromLibrary", mediaId); // remove media from playlists

    removeMediaFromPlaylists(mediaId).then(function () {
      // find mediaRef in firebase
      var mediaDocRef = getMediaDocRef(mediaId);
      mediaDocRef["delete"]().then(function () {
        console.log("Document successfully deleted!");
      })["catch"](function (error) {
        console.error("Error removing document: ", error);
      });
    });
  };

  var saveSetting = function saveSetting(setting) {
    accountRef.set({
      setting: setting
    }, {
      merge: true
    });
  };

  var deletePlaylist = function deletePlaylist(playlistId) {
    return getPlaylistDocRef(playlistId)["delete"]().then(function () {
      return "".concat(playlistId, " is deleted successfully.");
    });
  };

  return {
    getAccountRef: getAccountRef,
    getDeviceCollectionRef: getDeviceCollectionRef,
    getDeviceDocRef: getDeviceDocRef,
    getPlaylistCollectionRef: getPlaylistCollectionRef,
    getPlaylistDocRef: getPlaylistDocRef,
    createDevice: createDevice,
    addMediaToPlaylist: addMediaToPlaylist,
    createPlaylist: createPlaylist,
    updatePlaylist: updatePlaylist,
    getPlaylistsThatContainMedia: getPlaylistsThatContainMedia,
    deleteMediaFromLibrary: deleteMediaFromLibrary,
    saveSetting: saveSetting,
    deletePlaylist: deletePlaylist
  };
};

exports.createClient = createClient;
import LocalEngine from './local';
import FirebaseEngine from './firebase';

export const storageKey = 'FORVIZ-CONSOLE';

const initEngine = (type) => {
  switch (type) {
    case 'local':
      const localEngine = new LocalEngine();
      return localEngine;

    case 'firebase':
    default:
      const firebaseEngine = new FirebaseEngine();
      return firebaseEngine;
  }
}

export default initEngine(process.env.REACT_APP_ENGINE);
import React from 'react';
import _ from 'lodash';
import qs from 'query-string';

import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import NewEntry from './NewEntry';
import EntryTableRow from './EntryTableRow';

import PageHeader from '../../../../components/PageHeader';
import { db } from '../../engine';

const Page = styled.div``;

const ContentMain = styled.div`
  flex: 1;
  margin-right: 40px;
`;

const ContentSide = styled.div`
  padding: 20px;

  button {
    margin-bottom: 10px;
  }
`;
export default class extends React.PureComponent {
  state = {
    query: {},
    entries: [],
  }

  static getDerivedStateFromProps (props, state) {
    
    const query = { ...qs.parse(props.location.search), model: _.get(props, 'model.id') };
    if (!_.isEqual(query, state.query)) {
      return {
        query,
        entries: null,
      }
    }
    return null;
  }

  componentDidMount() {
    this.loadEntries(this.state.query);
  }

  componentDidUpdate() {
    if (this.state.entries === null) {
      this.loadEntries(this.state.query);
    }
  }

  loadEntries = (query) => {
    const { spaceId } = this.props;
    let queryRef = db.collection('cic_spaces').doc(spaceId).collection('entries');

    if (query.model) queryRef = queryRef.where('model', '==', query.model);

    queryRef.orderBy('id')
      .limit(50)
      .onSnapshot(querySnapshot => {
        this.setState({
          entries: _.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() })),
          lastEntry: querySnapshot.docs[querySnapshot.docs.length - 1],
        })
      });
  }

  loadMore = () => {
    const { spaceId } = this.props;
    db.collection('cic_spaces')
      .doc(spaceId)
      .collection('entries')
      .orderBy('id')
      .limit(50)
      .startAfter(this.state.lastEntry)
      .onSnapshot(querySnapshot => {
        // console.log('querySnapshot', querySnapshot.docs)
        this.setState({
          entries: [
            ...this.state.entries,
            ..._.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() })),
          ],
          lastEntry: querySnapshot.docs[querySnapshot.docs.length - 1],
        });
      });
  }

  handleSelectEntry = (entryId) => {
    const { history, match } = this.props;
    history.push(`${match.url}/${entryId}`);
  }

  afterEntryCreated = (entryId) => {
    const { history, match } = this.props;
    history.push(`${match.url}/${entryId}`);
  }

  render() {
    const { model, spaceId } = this.props;
    const { entries } = this.state;
    const columns = _.filter(_.get(model, 'fields', []), field => field.showInList === true || field.showInList === '1');
    if (!model) return (<div />);
    return (
      <Page>
        <PageHeader
          title={_.get(model, 'title', 'No Title')}
          renderFloatingActionButtons={() => (
            <NewEntry
              label={_.get(model, 'title')}
              spaceId={spaceId}
              model={model}
              onEntryCreatedError={reason => alert(reason)}
              onEntryCreatedSuccess={this.afterEntryCreated}
            />
          )}
        />
        <div style={{ margin: '0 40px' }}>
          <Grid container>
            <Grid item sm={12}>
              <ContentMain>
                <Table>
                  <TableHead>
                    <TableRow>
                      {_.map(columns, column => <TableCell key={column.id}>{column.label}</TableCell>)}
                      <TableCell>Updated At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(entries || []).map(entry =>
                      <EntryTableRow
                        key={entry.id}
                        columns={columns}
                        {...entry}
                        onClick={e => this.handleSelectEntry(entry.id)}
                      />
                    )}
                  </TableBody>
                </Table>
                <div style={{ paddingTop: 20 }}>
                  <Button variant="outlined" size="small" onClick={this.loadMore}>Load More</Button>
                </div>
              </ContentMain>
            </Grid>
            <Grid item sm={4}>
              <ContentSide>
              </ContentSide>
            </Grid>
          </Grid>
        </div>
      </Page>
    );
  }
}
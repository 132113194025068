"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.setRenderOptions = exports.options = exports.entityToHTML = exports.blockToHTML = exports.styleToHTML = exports.Entity = exports.Inline = exports.Block = void 0;

var _react = _interopRequireDefault(require("react"));

var _draftConvert = require("draft-convert");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Block = {
  UNSTYLED: 'unstyled',
  PARAGRAPH: 'unstyled',
  OL: 'ordered-list-item',
  UL: 'unordered-list-item',
  H1: 'header-one',
  H2: 'header-two',
  H3: 'header-three',
  H4: 'header-four',
  H5: 'header-five',
  H6: 'header-six',
  CODE: 'code-block',
  BLOCKQUOTE: 'blockquote',
  PULLQUOTE: 'pullquote',
  ATOMIC: 'atomic',
  BLOCKQUOTE_CAPTION: 'block-quote-caption',
  CAPTION: 'caption',
  TODO: 'todo',
  IMAGE: 'atomic:image',
  BREAK: 'atomic:break'
};
exports.Block = Block;
var Inline = {
  BOLD: 'BOLD',
  CODE: 'CODE',
  ITALIC: 'ITALIC',
  STRIKETHROUGH: 'STRIKETHROUGH',
  UNDERLINE: 'UNDERLINE',
  HIGHLIGHT: 'HIGHLIGHT'
};
exports.Inline = Inline;
var Entity = {
  LINK: 'LINK'
};
exports.Entity = Entity;

var styleToHTML = function styleToHTML(style) {
  switch (style) {
    case Inline.ITALIC:
      return _react.default.createElement("em", null);

    case Inline.BOLD:
      return _react.default.createElement("strong", null);

    case Inline.STRIKETHROUGH:
      return _react.default.createElement("strike", null);

    case Inline.UNDERLINE:
      return _react.default.createElement("u", null);

    case Inline.HIGHLIGHT:
      return _react.default.createElement("span", {
        className: "md-inline-".concat(style.toLowerCase())
      });

    case Inline.CODE:
      return _react.default.createElement("code", null);

    default:
      return null;
  } // switch (style) {
  //   case Inline.ITALIC:
  //     return <em className={`md-inline-${style.toLowerCase()}`} />;
  //   case Inline.BOLD:
  //     return <strong className={`md-inline-${style.toLowerCase()}`} />;
  //   case Inline.STRIKETHROUGH:
  //     return <strike className={`md-inline-${style.toLowerCase()}`} />;
  //   case Inline.UNDERLINE:
  //     return <u className={`md-inline-${style.toLowerCase()}`} />;
  //   case Inline.HIGHLIGHT:
  //     return <span className={`md-inline-${style.toLowerCase()}`} />;
  //   case Inline.CODE:
  //     return <code className={`md-inline-${style.toLowerCase()}`} />;
  //   default:
  //     return null;
  // }

};

exports.styleToHTML = styleToHTML;

var blockToHTML = function blockToHTML(block) {
  var blockType = block.type;

  switch (blockType) {
    case Block.H1:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return _react.default.createElement("h1", null);

    case Block.H2:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return _react.default.createElement("h2", null);

    case Block.H3:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return _react.default.createElement("h3", null);

    case Block.H4:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return _react.default.createElement("h4", null);

    case Block.H5:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return _react.default.createElement("h5", null);

    case Block.H6:
      // eslint-disable-next-line jsx-a11y/heading-has-content
      return _react.default.createElement("h6", null);

    case Block.BLOCKQUOTE_CAPTION:
    case Block.CAPTION:
      return {
        start: "<p><caption>",
        end: '</caption></p>'
      };

    case Block.IMAGE:
      {
        var imgData = block.data;
        var text = block.text;
        var extraClass = text.length > 0 ? ' md-block-image-has-caption' : '';
        return {
          start: "<figure class=\"md-block-image".concat(extraClass, "\"><img src=\"").concat(imgData.src, "\" alt=\"").concat(block.text, "\" /><figcaption className=\"md-block-image-caption\">"),
          end: '</figcaption></figure>'
        };
      }

    case Block.ATOMIC:
      return {
        start: "<figure class=\"md-block-".concat(blockType.toLowerCase(), "\">"),
        end: '</figure>'
      };

    case Block.TODO:
      {
        var checked = block.data.checked || false;
        var inp = '';
        var containerClass = '';

        if (checked) {
          inp = '<input type=checkbox disabled checked="checked" />';
          containerClass = 'todo-checked';
        } else {
          inp = '<input type=checkbox disabled />';
          containerClass = 'todo-unchecked';
        }

        return {
          start: "<div class=\"md-block-".concat(blockType.toLowerCase(), " ").concat(containerClass, "\">").concat(inp, "<p>"),
          end: '</p></div>'
        };
      }

    case Block.BREAK:
      return _react.default.createElement("hr", null);

    case Block.BLOCKQUOTE:
      return _react.default.createElement("blockquote", null);

    case Block.OL:
      return {
        element: _react.default.createElement("li", null),
        nest: _react.default.createElement("ol", null)
      };

    case Block.UL:
      return {
        element: _react.default.createElement("li", null),
        nest: _react.default.createElement("ul", null)
      };

    case Block.UNSTYLED:
      if (block.text.length < 1) {
        return _react.default.createElement("p", null, _react.default.createElement("br", null)); // return <p className={`md-block-${blockType.toLowerCase()}`}><br /></p>;
      }

      return _react.default.createElement("p", null);
    // return <p className={`md-block-${blockType.toLowerCase()}`} />;

    default:
      return null;
  }
};

exports.blockToHTML = blockToHTML;

var entityToHTML = function entityToHTML(entity, originalText) {
  if (entity.type === Entity.LINK) {
    return _react.default.createElement("a", {
      href: entity.data.url,
      target: "_blank",
      rel: "noopener noreferrer"
    }, originalText);
  }

  return originalText;
};

exports.entityToHTML = entityToHTML;
var options = {
  styleToHTML: styleToHTML,
  blockToHTML: blockToHTML,
  entityToHTML: entityToHTML
};
exports.options = options;

var setRenderOptions = function setRenderOptions() {
  var htmlOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : options;
  return (0, _draftConvert.convertToHTML)(htmlOptions);
};

exports.setRenderOptions = setRenderOptions;

var _default = function _default(contentState) {
  var htmlOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : options;
  return (0, _draftConvert.convertToHTML)(htmlOptions)(contentState);
};

exports.default = _default;
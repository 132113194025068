import _ from 'lodash';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PROJECT/MEDIAS':
      return {
        ...state,
        [action.spaceId]: {
          medias: _.reduce(action.data, (acc, media) => {
            return { ...acc, [media.id]: media };
          }, {})
        }
      }
    default: return state;
  }
}

import React, { Fragment, useState, useEffect, useMemo } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import moment from "moment";
import styled from "styled-components";

import { Legend, Bar, LabelList } from "recharts";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
// import FormLabel from '@material-ui/core/FormLabel';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import Radio from '@material-ui/core/Radio';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Filter1Icon from "@material-ui/icons/Filter1";
import Filter2Icon from "@material-ui/icons/Filter2";
import Filter3Icon from "@material-ui/icons/Filter3";
import Filter4Icon from "@material-ui/icons/Filter4";
import Filter5Icon from "@material-ui/icons/Filter5";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { DatePicker } from "@material-ui/pickers";

import { colors, utility } from "../../../../lib";

import {
  EVENT_NAMES,
  SEARCH_EVENT,
  SEARCH_ORIGIN_EVENT,
  SEARCH_DESTINATION_EVENT,
  SEARCH_KEYWORDS_EVENT,
  SEARCH_CATEGORIES_EVENT,
  HEAT_MAP_INTENSITY_RANGE,
  DEFAULT_NUMBER_OF_COMPARE_SHOP,
  ALL,
} from "../../config";

import LogsModule, { getLogsByEventName } from "../../reducers/logs";

import mockupLogs from "../../mockdata/logs.json";
import mockupShops from "../../mockdata/shops.json";
import mockupCategories from "../../mockdata/categories.json";

import Recharts from "../../components/Recharts";
import ApexCharts from "../../components/ApexCharts";

const { PieChart } = Recharts;
const { HeatMap } = ApexCharts;

const {
  getDateDisplay,
  getWeekOfMonth,
  randomWithoutDuplicated,
  slugify,
} = utility;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  control: {
    padding: theme.spacing(2),
  },
  skeleton: {
    backgroundColor: theme.palette.primary.primaryColor,
  },
  listItemText: {
    color: "white",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const renderTop5Icon = (rank) => {
  switch (rank) {
    default:
    case 1:
      return <Filter1Icon />;
    case 2:
      return <Filter2Icon />;
    case 3:
      return <Filter3Icon />;
    case 4:
      return <Filter4Icon />;
    case 5:
      return <Filter5Icon />;
  }
};

const getCompareColorByIndex = (index) => {
  switch (index) {
    case 0:
      return "#f2ca51";
    case 1:
      return "#ee7874";
    case 2:
      return "#d9a93d";
    case 3:
      return "#c14a4e";
    case 4:
      return "#5ccabf";
    case 5:
      return "#4eab99";
    case 6:
      return "#56ab68";
    case 7:
      return "#417fb2";
    case 8:
      return "#cca19a";
    case 9:
      return "#a44871";
    case 10:
      return "#11161c";
    case 11:
      return "#6f535f";
    case 12:
      return "#793250";
    case 13:
      return "#313e4f";
    case 14:
      return "#d85744";
    case 15:
      return "#ef8367";
    case 16:
      return "#ecf0f1";
    case 17:
      return "#b24433";
    case 18:
      return "#404146";
    case 19:
      return "#bfc2c7";
    case 20:
      return "#e5e5e5";
    default:
      return "#000";
  }
};

const getMonthOptions = () => {
  return moment.months(); // long names
  // return moment.monthsShort() // short names
};

const getYearOptions = () => {
  const options = [];
  const startYear = moment().year();
  let currentYear = startYear;
  while (2020 <= currentYear) {
    options.push(`${currentYear}`);
    currentYear -= 1;
  }
  return options;
};

const mapStateToProps = (state, ownProps) => {
  return {
    logs: state.logs,
  };
};

const options = ["Option 1", "Option 2"];

const Dashboard = ({ clients, dispatch, logs, location, history }) => {
  // console.log("Run dashboard logs >>>> ", logs);

  const [spacing, setSpacing] = useState(2);
  const [numberOfCompareShop] = useState(DEFAULT_NUMBER_OF_COMPARE_SHOP);
  const [barGraphWidth] = useState("100%");
  const [selectedCountShopEnName, setSelectedCountShopEnName] = useState(ALL);
  const [
    selectedCountShopMonthlyEnName,
    setSelectedCountShopMonthlyEnName,
  ] = useState("");
  const [selectedBarChartShopEnName, setSelectedBarChartShopEnName] = useState(
    ALL
  );
  //const [ selectedMonthCountShopMonthlyEnName, setSelectedMonthCountShopMonthlyEnName ] = useState(moment().week());
  const [selectedStartDate, setSelectedStartDate] = useState(moment());
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [compareShopEnNames, setCompareShopEnNames] = useState(
    _.fill(Array(numberOfCompareShop), "")
  );
  // const [ compareShopEnNames, setCompareShopEnNames] = useState(_.fill(Array(numberOfCompareShop), 'kfc'))

  const [selectedYear, setSelectedYear] = useState(`${moment().year()}`);
  const [selectedMonth, setSelectedMonth] = useState(
    `${moment().format("MMMM")}`
  );

  const [ombresShops, setOmbresShops] = useState([]);
  const [ombresCategories, setOmbresCategories] = useState([]);

  const params = new URLSearchParams(location.search);

  const classes = useStyles();

  const getOmbresShop = (shopEnName) => {
    const target = ombresShops[shopEnName];
    return target;
  };

  const getOmbreShopUnit = (shopEnName) => {
    const ombresShop = getOmbresShop(shopEnName);
    return `(${_.get(ombresShop, "fields.unit", "N/A")})`;
  };

  const getDiffDays = (startDateMoment, endDateMoment) => {
    return endDateMoment
      .startOf("days")
      .diff(startDateMoment.startOf("days"), "days");
  };
  const getRecordsGroupByDate = (records) => {
    return _.groupBy(records, "date");
  };
  const getRecordsWithInDateRange = (records) => {
    const recordGroupByDate = getRecordsGroupByDate(records);
    const numberOfDay = getDiffDays(selectedStartDate, selectedEndDate);
    const dataRange = _.range(0, numberOfDay + 1);
    if (dataRange.length === 0) {
      const date = moment(selectedStartDate).format("DD/MM/YYYY");
      return recordGroupByDate[date] || [];
    }
    return _.reduce(
      dataRange,
      (acc, i) => {
        const date = moment(selectedStartDate)
          .add(i, "days")
          .format("DD/MM/YYYY");
        const recordGroup = recordGroupByDate[date];
        if (!recordGroup) return acc;
        return [...acc, ...recordGroup];
      },
      []
    );
  };

  /*
  const updateBarChart = () => {
    const records = getRecordsByEventNamesWithDate();
    const recordGroupByShopEnName = _.groupBy(records, "shopNameEn");
    const selectedShopRecords = _.isEmpty(selectedBarChartShopEnName)
      ? []
      : recordGroupByShopEnName[selectedBarChartShopEnName];
    const recordGroupByDate =
      selectedBarChartShopEnName === ALL
        ? getRecordsGroupByDate(records)
        : getRecordsGroupByDate(selectedShopRecords);
    // console.log('selectedShopRecords', { recordGroupByShopEnName, selectedShopRecords })
    const numberOfDay = getDiffDays(selectedStartDate, selectedEndDate);
    // console.log('numberOfDay', numberOfDay)
    const dataRange = _.range(1, numberOfDay + 1);
    const categories = _.map(dataRange, (i) => {
      return moment(selectedStartDate)
        .add(i, "days")
        .format("DD/MM/YYYY");
    });
    const seriesData = _.map(dataRange, (i) => {
      const date = moment(selectedStartDate)
        .add(i, "days")
        .format("DD/MM/YYYY");
      return (recordGroupByDate[date] && recordGroupByDate[date].length) || 0;
    });

    setBarChartData({
      options: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories,
        },
      },
      series: [
        {
          name: "count",
          data: seriesData,
        },
      ],
    });
  };
  */

  const updateGetDirectionBarChartData = () => {
    const searchRecordsWithDate = getRecordsByEventNameWithDate("search");
    const targetMonthYear = `${selectedMonth}-${selectedYear}`;
    const filterSearchRecords = _.reduce(
      searchRecordsWithDate,
      (acc, searchRecord, recordId) => {
        const monthYear = moment(searchRecord.date, "DD/MM/YYYY").format(
          "MMMM-YYYY"
        );
        if (monthYear !== targetMonthYear) return acc;
        return [...acc, searchRecord];
      },
      []
    );
    // console.log("renderGetDirectionLineChart", {
    //   filterSearchRecords,
    //   searchRecordsWithDate,
    //   targetMonthYear,
    // });
    const recordGroupByDate = getRecordsGroupByDate(filterSearchRecords);
    const targetMonthYearMoment = moment(
      `01/${selectedMonth}/${selectedYear}`,
      "DD/MMMM/YYYY"
    );
    const numberOfDay = targetMonthYearMoment.daysInMonth();
    const startDate = targetMonthYearMoment
      .startOf("month")
      .format("DD/MM/YYYY");
    const dataRange = _.range(0, numberOfDay);
    const categories = _.map(dataRange, (i) => {
      const m = moment(startDate, "DD/MM/YYYY");
      return m.add(i, "days").format("DD/MM/YYYY");
    });
    const seriesData = _.map(dataRange, (i) => {
      const m = moment(startDate, "DD/MM/YYYY");
      const date = m.add(i, "days").format("DD/MM/YYYY");
      return (recordGroupByDate[date] && recordGroupByDate[date].length) || 0;
    });
    // console.log("monthNumberOfDay", {
    //   targetMonthYearMoment: targetMonthYearMoment.format("DD/MM/YYYY"),
    //   numberOfDay,
    //   startDate,
    //   endDate,
    //   dataRange,
    //   categories,
    //   seriesData,
    // });
    setBarChartData({
      options: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories,
        },
      },
      series: [
        {
          name: "count",
          data: seriesData,
        },
      ],
    });
  };

  const [barChartData, setBarChartData] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "count",
        data: [],
      },
    ],
  });

  const [donutCompareChartConfig, setDonutCompareChartConfig] = useState({
    data: [],
  });

  const [isLoading, setLoading] = useState(false);

  const fetchData = async () => {
    return new Promise(async (resolve, reject) => {
      const { zentryClient, ombresClient } = clients;
      setLoading(true);
      const eventLogPromises = _.map(EVENT_NAMES, (eventName) =>
        zentryClient.queryEvents(eventName)
      );
      try {
        // const manyLogs = mockupLogs;
        // const shops = mockupShops;
        // const categories = mockupCategories;
        const [manyLogs, shops, categories] = await Promise.all([
          Promise.all(eventLogPromises),
          ombresClient.getShops(),
          ombresClient.getCategories(),
        ]);
        console.debug("fetchData", {
          manyLogs,
          shops,
          categories,
        });
        setOmbresCategories(
          _.reduce(
            categories,
            (acc, category) => {
              const { id, fields } = category;
              const nameEn = _.get(fields, "name.en", id);
              if (fields.parent || _.isEmpty(nameEn)) return acc;
              const slugifyNameEn = slugify(nameEn);
              return {
                ...acc,
                [slugifyNameEn]: category,
              };
            },
            {}
          )
        );
        setOmbresShops(
          _.reduce(
            shops,
            (acc, shop) => {
              const { id, fields } = shop;
              const shopNameEn = _.get(fields, "name.en", id);
              return {
                ...acc,
                [shopNameEn]: shop,
              };
            },
            {}
          )
        );
        dispatch(LogsModule.setLogs({ data: manyLogs }));
        setLoading(false);
        resolve(true);
      } catch (error) {
        console.error("fetchData", error);
        setLoading(false);
        reject(false);
      }
    });
  };

  /*
   * params
   * shop: Array({ shopNameEn, count })
   * return { shopNameEn, count }
   */
  // const getRandomShop = (shops) => {
  //   const randomShopIndex = _.random(0, shops.length - 1);
  //   const shop = shops[randomShopIndex];
  //   return shop;
  // };

  // const setDefaultCompareShops = () => {
  //   const shops = getAllRecordedWithCountShops();
  //   // console.debug('setDefaultCompareShops', { shops })
  //   const randomShopNameEns = [];
  //   let BreakException = {};
  //   try {
  //     compareShopEnNames.forEach((compareShopEnName) => {
  //       let isDuplicated = true;
  //       let randomShop = { shopNameEn: "", count: 0 };
  //       while (isDuplicated) {
  //         randomShop = getRandomShop(shops);
  //         if (!randomShop) break;
  //         const duplicatedShop = _.find(
  //           randomShopNameEns,
  //           (randomShopNameEn) => randomShop.shopNameEn === randomShopNameEn
  //         );
  //         if (!duplicatedShop) {
  //           isDuplicated = false;
  //         }
  //       }
  //       if (!randomShop) throw BreakException;
  //       randomShopNameEns.push(randomShop.shopNameEn);
  //     });
  //     // console.debug("randomShopNameEns", randomShopNameEns);
  //     setCompareShopEnNames(randomShopNameEns);
  //   } catch (e) {
  //     if (e !== BreakException) throw e;
  //   }
  // };

  const getTop10searchByTime = () => {
    const records = getRecordsByEventNamesWithDate([
      SEARCH_EVENT,
      SEARCH_ORIGIN_EVENT,
      SEARCH_DESTINATION_EVENT,
    ]);
    const recordsWithInDateRange = getRecordsWithInDateRange(records);
    const shops = _.chain(recordsWithInDateRange)
      .countBy("shopNameEn")
      .reduce((acc, count, shopNameEn) => {
        return [
          ...acc,
          {
            shopNameEn,
            count,
          },
        ];
      }, [])
      .sortBy("count")
      .reverse()
      .take(10)
      .value();
    return shops;
  };

  const updateCategoryPieChart = () => {
    const categoryRecordsWithDate = getRecordsByEventNameWithDate(
      "search.categories"
    );
    const targetMonthYear = `${selectedMonth}-${selectedYear}`;
    const categoriesWithCount = _.chain(categoryRecordsWithDate)
      .reduce((acc, record, recordId) => {
        const monthYear = moment(record.date, "DD/MM/YYYY").format("MMMM-YYYY");
        if (monthYear !== targetMonthYear) return acc;
        return [...acc, record];
      }, [])
      .groupBy((record) => record.shopNameEn)
      .value();
    let i = 0;
    const newPieChartData = _.reduce(
      categoriesWithCount,
      (acc, records, categoryKey) => {
        return [
          ...acc,
          {
            name: categoryKey,
            value: records.length,
            fill: getCompareColorByIndex(i++),
          },
        ];
      },
      []
    );
    setDonutCompareChartConfig({
      data: newPieChartData,
    });
  };

  const initialize = async () => {
    console.debug("------ Run Initialize ------");
    await fetchData();
    console.debug("------ Run setParams ------");
    const startDateYYYYMMDD = params.get("start-date");
    const endDateYYYYMMDD = params.get("end-date");
    if (startDateYYYYMMDD) setSelectedStartDate(moment(startDateYYYYMMDD));
    if (endDateYYYYMMDD) setSelectedEndDate(moment(endDateYYYYMMDD));
    console.debug("------ End Initialize ------", {
      startDateYYYYMMDD,
      endDateYYYYMMDD,
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    // updateBarChart()
    getTop10searchByTime();
  }, [selectedStartDate, selectedEndDate, selectedBarChartShopEnName]);

  useEffect(() => {
    updateGetDirectionBarChartData();
    updateCategoryPieChart();
  }, [selectedMonth, selectedYear]);

  // useEffect(() => {
  //   const shopCounts = _.map(compareShopEnNames, (compareShopEnName) =>
  //     getSingleShopSearchCount(compareShopEnName)
  //   );
  //   const  = randomWithoutDuplicated(
  //     _.range(0, 10),
  //     numberOfCompareShop
  //   );
  //   const newPieChartData = _.reduce(
  //     compareShopEnNames,
  //     (acc, compareShopEnName, index) => {
  //       const count = shopCounts[index];
  //       if (count <= 0) return acc;
  //       return [
  //         ...acc,
  //         {
  //           name: `${compareShopEnName}`,
  //           value: count,
  //           fill: getCompareColorByIndex([index]).hex,
  //         },
  //       ];
  //     },
  //     []
  //   );
  //   setDonutCompareChartConfig({
  //     data: newPieChartData,
  //   });
  // }, [compareShopEnNames]);

  useEffect(() => {
    getTop10searchByTime();
    updateGetDirectionBarChartData();
    updateCategoryPieChart();
  }, [logs]);

  const getRecordsByEventNamesWithDate = (eventNames = EVENT_NAMES) => {
    return _.reduce(
      logs,
      (acc, eventRecords, index) => {
        // eventRecords = array of object record of eventName
        const eventName = EVENT_NAMES[index];
        if (!_.includes(eventNames, eventName)) return acc;
        const eventRecordsWithDate = _.reduce(
          eventRecords,
          (acc2, record, logId) => {
            const submitSeconds = record.timestamp.seconds;
            const jsDate = new Date(submitSeconds * 1000);
            const date = getDateDisplay(jsDate, "x");
            const destinationShopTitle = _.get(record, "destination", null);
            const originShopTitle = _.get(record, "origin", null);
            if (!destinationShopTitle && !originShopTitle) {
              return acc2;
            } else if (destinationShopTitle && !originShopTitle) {
              return [
                ...acc2,
                {
                  eventName,
                  date,
                  shopNameEn: destinationShopTitle,
                },
              ];
            } else if (!destinationShopTitle && originShopTitle) {
              return [
                ...acc2,
                {
                  eventName,
                  date,
                  shopNameEn: originShopTitle,
                },
              ];
            }
            return [
              ...acc2,
              {
                eventName,
                source: "destination",
                date,
                shopNameEn: destinationShopTitle,
              },
              {
                eventName,
                source: "origin",
                date,
                shopNameEn: originShopTitle,
              },
            ];
          },
          []
        );
        return [...acc, ...eventRecordsWithDate];
      },
      []
    );
  };

  const getRecordsByEventNameWithDate = (eventName) => {
    console.log("getRecordsByEventNameWithDate", eventName);
    return getRecordsByEventNamesWithDate([eventName]);
  };

  const getRecordsByEventName = (eventName) => {
    const eventIndex = _.indexOf(EVENT_NAMES, eventName);
    const records = logs[eventIndex];
    return records || [];
  };

  const getAllSearchTexts = () => {
    return _.reduce(
      logs,
      (acc, eventRecords, index) => {
        // eventRecords = array of object record of eventName
        const eventTotalSearchCount = _.reduce(
          eventRecords,
          (acc2, record, logId) => {
            const destinationShopTitle = _.get(record, "destination", "");
            const originShopTitle = _.get(record, "origin", "");
            if (!destinationShopTitle || !originShopTitle) return acc2;
            return [...acc2, destinationShopTitle, originShopTitle];
          },
          []
        );
        return [...acc, ...eventTotalSearchCount];
      },
      []
    );
  };

  const getAllRecordedShops = () => {
    const shops = _.uniq(getAllSearchTexts());
    return shops;
  };

  const getAllRecordedWithCountShops = (sort = true) => {
    const shopWithCounts = _.filter(
      getTotalShopSearchCountObjects(),
      ({ count }) => {
        return count > 0;
      }
    );
    // console.debug('shopWithCounts', getTotalShopSearchCountObjects(), shopWithCounts)
    if (sort)
      return _.chain(shopWithCounts)
        .sortBy("count")
        .reverse()
        .value();
    return shopWithCounts;
  };

  const getSingleShopSearchCount = (shopEnName) => {
    const totalSearchCount = getTotalShopSearchCountObjects();
    const targetShopSearch = _.find(
      totalSearchCount,
      ({ shopNameEn, count }) => shopNameEn === shopEnName
    );
    const shopCount = _.get(targetShopSearch, "count", 0);
    return shopCount;
  };

  const getTotalShopSearchCountObjects = () => {
    const totalSearchCount = _.countBy(getAllSearchTexts());
    const reformatSearchTexts = _.reduce(
      totalSearchCount,
      (acc, count, shopNameEn) => {
        return [
          ...acc,
          {
            shopNameEn,
            count,
          },
        ];
      },
      []
    );
    return reformatSearchTexts;
  };

  const getTopSearchGraph = () => {
    // const searchTextObjects = getTotalShopSearchCountObjects()
    // const top5SearchTextObjects = _.chain(searchTextObjects).sortBy('count').reverse().take(5).value()
    const top10SearchTextObjects = getTop10searchByTime();
    return (
      <Paper className={classes.paper}>
        <Typography className={classes.text} variant="h5" gutterBottom>
          Top 10 Shop Search
        </Typography>
        <List className={classes.root}>
          {_.map(top10SearchTextObjects, (top5SearchTextObject, index) => {
            return (
              <ListItem key={top5SearchTextObject.shopNameEn}>
                <ListItemAvatar>
                  <Avatar>{renderTop5Icon(index + 1)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  className={classes.listItemText}
                  primary={top5SearchTextObject.shopNameEn}
                  secondary={`${top5SearchTextObject.count} times`}
                />
              </ListItem>
            );
          })}
        </List>
      </Paper>
    );
  };

  const handleChangeCountShop = (event, newValue) => {
    const [shopEnName] = _.split(newValue, " (");
    setSelectedCountShopEnName(shopEnName);
  };

  const handleChangeCountShopMonthly = (event, newValue) => {
    // setSelectedCountShopMonthlyEnName(event.target.value);
    setSelectedCountShopMonthlyEnName(newValue);
  };

  const handleChangeCompareShop = (event, index, newValue) => {
    const shopEnName = newValue; //event.target.value;
    const nextCompareShopEnNames = _.clone(compareShopEnNames);
    nextCompareShopEnNames[index] = shopEnName;
    setCompareShopEnNames(nextCompareShopEnNames);
  };

  const handleChangeBarChartShopEnName = (event, newValue) => {
    // setSelectedBarChartShopEnName(event.target.value);
    setSelectedBarChartShopEnName(newValue);
  };

  const handleStartDateChange = (moment) => {
    // validation
    if (moment.isAfter(selectedEndDate)) {
      alert("start date must be less than end date");
      return;
    }
    params.set("start-date", moment.format("YYYY-MM-DD"));
    setSelectedStartDate(moment);
    history.push({ search: params.toString() });
  };

  const handleEndDateChange = (moment) => {
    if (moment.isBefore(selectedStartDate)) {
      alert("end date must be more than start date");
      return;
    }
    params.set("end-date", moment.format("YYYY-MM-DD"));
    setSelectedEndDate(moment);
    history.push({ search: params.toString() });
  };

  const renderFormItemAutoComplete = ({
    sort = true,
    label,
    width = 300,
    defaultValue,
    onChange,
    useSourceData,
  }) => (
    <FormControl variant="outlined" className={classes.formControl}>
      <Autocomplete
        id="combo-box-demo"
        options={sort ? useSourceData.sort() : useSourceData}
        onChange={onChange}
        style={{ width }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            defaultValue={defaultValue}
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );

  const renderFormItemSelect = ({
    label,
    value,
    hasAll = true,
    onChange,
    renderOptions = () => null,
  }) => (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={onChange} label={label}>
        {hasAll && (
          <MenuItem value={ALL}>
            <em>{_.capitalize(ALL)}</em>
          </MenuItem>
        )}
        {renderOptions()}
      </Select>
    </FormControl>
  );

  const renderShopSearchCount = () => {
    const shopEnNames = _.map(ombresShops, (ombresShop) => {
      const nameEn = _.get(ombresShop, "fields.name.en", null);
      return nameEn ? `${nameEn} ${getOmbreShopUnit(nameEn)}` : null;
    }).filter(Boolean);
    const records = getRecordsByEventNamesWithDate([
      SEARCH_EVENT,
      SEARCH_ORIGIN_EVENT,
      SEARCH_DESTINATION_EVENT,
    ]);
    const recordsWithInDateRange = getRecordsWithInDateRange(records);
    const totalSearchCount =
      _.countBy(recordsWithInDateRange, "shopNameEn")[
        selectedCountShopEnName
      ] || 0;
    return (
      <Paper className={classes.paper}>
        <Typography className={classes.text} variant="h5" gutterBottom>
          Shop Search Count
        </Typography>
        <br />
        {renderFormItemAutoComplete({
          label: "Shop",
          useSourceData: shopEnNames,
          onChange: handleChangeCountShop,
        })}
        <br />
        <Typography className={classes.text} variant="h6" gutterBottom>
          was searched {totalSearchCount} times
        </Typography>
      </Paper>
    );
  };

  const renderShopGetDirectionCount = () => {
    const searchRecords = getRecordsByEventNameWithDate("search");
    const searchRecordsInDateRange = getRecordsWithInDateRange(searchRecords);
    const count = _.keys(searchRecordsInDateRange).length;
    console.log(">>>>>>>>", {
      searchRecords,
      searchRecordsInDateRange,
      count,
    });
    return (
      <Paper className={classes.paper}>
        <Typography className={classes.text} variant="h5" gutterBottom>
          Get Direction Count
        </Typography>
        <Typography className={classes.text} variant="h6" gutterBottom>
          was press {count} times
        </Typography>
      </Paper>
    );
  };

  const renderGetDirectionLineChart = () => {
    const showGraph = barChartData.series.length > 0;
    return (
      <Paper className={classes.paper}>
        <Typography className={classes.text} variant="h5" gutterBottom>
          Get Direction By Month
        </Typography>
        <br />
        <Grid container justify="center" spacing={spacing}>
          <Grid item xs={12}>
            <Grid container justify="flex-start" spacing={spacing}>
              <Grid item xs={2}>
                {renderFormItemSelect({
                  label: "Month",
                  hasAll: false,
                  width: 150,
                  value: selectedMonth,
                  renderOptions: () =>
                    _.map(getMonthOptions(), (month) => (
                      <MenuItem
                        title={month}
                        key={`${month}-${_.uniqueId()}`}
                        value={month}
                      >
                        {month}
                      </MenuItem>
                    )),
                  onChange: (event) => setSelectedMonth(event.target.value),
                })}
              </Grid>
              <Grid item xs={2}>
                {renderFormItemSelect({
                  label: "Year",
                  hasAll: false,
                  width: 150,
                  value: selectedYear,
                  renderOptions: () =>
                    _.map(getYearOptions(), (year) => (
                      <MenuItem
                        title={year}
                        key={`${year}-${_.uniqueId()}`}
                        value={year}
                      >
                        {year}
                      </MenuItem>
                    )),
                  onChange: (event) => setSelectedYear(event.target.value),
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        {showGraph && (
          <Chart
            options={barChartData.options}
            series={barChartData.series}
            type="bar"
            width={barGraphWidth}
          />
        )}
        {renderCategoryPieChart()}
      </Paper>
    );
  };

  /*
  const renderSearchByTimeLineChart = () => {
    const shops = getAllRecordedShops();
    const showGraph = shops.length > 0;
    return (
      <Paper className={classes.paper}>
        <Typography className={classes.text} variant="h5" gutterBottom>
          Shop Search by Time
        </Typography>
        <br />
        <Grid container justify="center" spacing={spacing}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={spacing}>
              <Grid item xs={3}>
                {renderFormItemAutoComplete({
                  label: "Shop",
                  useSourceData: _.map(shops, (shopName) => shopName),
                  onChange: handleChangeBarChartShopEnName,
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        {showGraph && (
          <Chart
            options={barChartData.options}
            series={barChartData.series}
            type="bar"
            width={barGraphWidth}
          />
        )}
      </Paper>
    );
  };
  */

  /*
  const renderIntensitySearchByMonthHeatMap = () => {
    const shops = getAllRecordedShops();
    const records = getRecordsByEventNamesWithDate();
    const recordGroupByShopEnName = _.groupBy(records, "shopNameEn");
    const selectedShopRecords = _.isEmpty(selectedCountShopMonthlyEnName)
      ? []
      : recordGroupByShopEnName[selectedCountShopMonthlyEnName];

    // const calendars = getWeekOfMonth(selectedMonthCountShopMonthlyEnName)
    // console.log('calendars', calendars)

    // const recordToSeries = (count, yrange) => {
    //   var i = 0;
    //   var series = [];
    //   while (i < count) {
    //     var x = (i + 1).toString();
    //     var y =
    //       Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    //     series.push({
    //       x: x,
    //       y: y
    //     });
    //     i++;
    //   }
    //   return series;
    // };
    return (
      <Paper className={classes.paper}>
        <Typography className={classes.text} variant="h5" gutterBottom>
          Shop Search Count Monthly
        </Typography>
        <br />
        {renderFormItemAutoComplete({
          label: "Shop",
          useSourceData: _.map(shops, (shopName) => shopName),
          onChange: handleChangeCountShopMonthly,
        })}
        <br />
        <br />
        <HeatMap
          // series={recordToSeries()}
          enabledDataLabels={false}
          enableShades={false}
          colors={["#ffc222"]}
          colorScaleRanges={HEAT_MAP_INTENSITY_RANGE}
          showToolBar={false}
          tooltipXFormatter={{ formatter: (val) => val }}
          tooltipYFormatter={{ formatter: (val) => val }}
        />
      </Paper>
    );
  };
  */

  // const renderCompareShopChart = () => {
  //   const shops = getAllRecordedWithCountShops();
  //   const shopCounts = _.map(compareShopEnNames, (compareShopEnName) =>
  //     getSingleShopSearchCount(compareShopEnName)
  //   );
  //   const willRenderChart =
  //     _.some(
  //       compareShopEnNames,
  //       (compareShopEnName) => compareShopEnName !== ""
  //     ) || _.some(shopCounts, (shopCount) => shopCount !== 0);
  //   // console.debug('renderCompareShopChart', { willRenderChart, shopCounts, compareShopEnNames, donutCompareChartConfig })
  //   return (
  //     <Paper className={classes.paper}>
  //       <Typography className={classes.text} variant="h5" gutterBottom>
  //         Compare Shop Count
  //       </Typography>
  //       <br />
  //       <Grid container justify="center" spacing={spacing}>
  //         <Grid item xs={12}>
  //           <Grid container justify="center" spacing={spacing}>
  //             {_.map(compareShopEnNames, (compareShopEnName, index) => (
  //               <Grid item xs={4}>
  //                 {renderFormItemAutoComplete({
  //                   label: "Shop",
  //                   useSourceData: _.map(
  //                     shops,
  //                     (shop) => `${shop.shopNameEn} (${shop.count})`
  //                   ),
  //                   onChange: (event, newValue) =>
  //                     handleChangeCompareShop(event, index, newValue),
  //                 })}
  //               </Grid>
  //             ))}
  //           </Grid>
  //         </Grid>
  //       </Grid>
  //       <br />
  //       {willRenderChart && (
  //         <Grid container justify="center" spacing={spacing}>
  //           <Grid item xs={12}>
  //             <Paper className={classes.paper}>
  //               <PieChart
  //                 width={600}
  //                 height={450}
  //                 source={donutCompareChartConfig.data}
  //                 dataKey="value"
  //               >
  //                 <Legend
  //                   dataKey="value"
  //                   layout="vertical"
  //                   align="center"
  //                   verticalAlign="middle"
  //                   height={20}
  //                   iconType="circle"
  //                   wrapperStyle={{
  //                     right: 0,
  //                   }}
  //                 />
  //               </PieChart>
  //             </Paper>
  //           </Grid>
  //         </Grid>
  //       )}
  //     </Paper>
  //   );
  // };

  const renderCategoryPieChart = () => {
    return (
      <div>
        <Typography className={classes.text} variant="h5" gutterBottom>
          Category Explored
        </Typography>
        <br />
        <Grid container justify="center" spacing={spacing}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <PieChart
                width={1000}
                height={600}
                source={donutCompareChartConfig.data}
                dataKey="value"
              >
                <Legend
                  dataKey="value"
                  layout="vertical"
                  align="center"
                  verticalAlign="middle"
                  height={20}
                  iconType="circle"
                  wrapperStyle={{
                    fontSize: "20px",
                    top: 30,
                    right: 0,
                  }}
                />
              </PieChart>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Grid item xs={12}>
        <Grid container justify="center" spacing={spacing}>
          <Grid item xs={6}>
            <Skeleton
              className={classes.skeleton}
              animation="wave"
              variant="rect"
              width="100%"
              height={400}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="center" spacing={spacing}>
              <Grid item xs={12}>
                <Skeleton
                  className={classes.skeleton}
                  animation="wave"
                  variant="rect"
                  width="100%"
                  height={200}
                />
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  className={classes.skeleton}
                  animation="wave"
                  variant="rect"
                  width="100%"
                  height={100}
                />
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  className={classes.skeleton}
                  animation="wave"
                  variant="rect"
                  width="100%"
                  height={100}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDashboard = () => {
    return (
      <Fragment>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={spacing}>
            <Grid item xs={6}>
              <Grid item xs={12}>
                {getTopSearchGraph()}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justify="center" spacing={spacing}>
                <Grid item xs={12}>
                  {renderShopSearchCount()}
                </Grid>
                <Grid item xs={12}>
                  {renderShopGetDirectionCount()}
                </Grid>
                {/* <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.text} variant="h6" gutterBottom>
                      There are 3 graphs below.
                    </Typography>
                    <Typography className={classes.text} variant="h6" gutterBottom>
                      Please scroll down <ArrowDownwardIcon />
                    </Typography>
                  </Paper>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Grid container justify="center" spacing={spacing}>
            <Grid item xs={12}>
              {renderGetDirectionLineChart()}
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <Grid container justify="center" spacing={spacing}>
            <Grid item xs={12}>
              {renderIntensitySearchByMonthHeatMap()}
            </Grid>
          </Grid>
        </Grid> */}
        {/* <Grid item xs={12}>
          <Grid container justify="center" spacing={spacing}>
            <Grid item xs={12}>
              {renderCompareShopChart()}
            </Grid>
          </Grid>
        </Grid> */}
      </Fragment>
    );
  };

  const render = () => {
    return (
      <Wrapper>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.text} variant="h4" gutterBottom>
              Dashboard
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              autoOk
              label="Start Date"
              disableFuture
              format="DD/MM/YYYY"
              value={selectedStartDate}
              onChange={handleStartDateChange}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              autoOk
              label="End Date"
              disableFuture
              format="DD/MM/YYYY"
              value={selectedEndDate}
              onChange={handleEndDateChange}
            />
          </Grid>
          {isLoading ? renderLoading() : renderDashboard()}
        </Grid>
      </Wrapper>
    );
  };

  return render();
};

const Wrapper = styled.div`
  .apexcharts-xaxis-texts-g > text,
  .apexcharts-yaxis-texts-g > text {
    fill: white;
  }
  .apexcharts-menu.apexcharts-menu-open {
    background-color: #333333;
    color: white;
  }
  .apexcharts-tooltip {
    .apexcharts-tooltip-text,
    .apexcharts-tooltip-title {
      color: red;
    }
  }
`;

export default connect(mapStateToProps)(Dashboard);

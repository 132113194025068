import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { notify } from 'react-notify-toast';

import styled from 'styled-components';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dropzone from 'react-dropzone';

import FloorplanLoader from '../../../../components/FloorplanLoader';

const DropArea = styled.div`
  textarea { 
    width: 100%;
    height: 400px;
    border: 1px solid #999;
    border-radius: 6px;
  }

  div {
    position: absolute;
    z-index: 1;
    top: 20px;
    left: 20px;
    right: 20px;
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    pointer-events: none;
    font-size: 12px;
  }

  &.drop-ok {
    border-color: #4caf50;
    div {
      color: #4caf50;
    }
  }

  &.drop-not-ok {
    border-color: #ab003c;
    div {
      color: #ab003c;
    }
  }
  &.has-error div {
    color: #ff3d00;
  }
`;


const mapStateToProps = (state, ownProps) => {
  // const { projectId } = ownProps;
  return {
    // medias: _.get(state, ['projects', projectId, 'medias', mediaId, 'url']),
  };
}

export default connect(mapStateToProps)(class extends React.Component {
  static defaultProps = {
    floorId: '',
  }

  state = {
    floorplate: {},
    step: 0,
    acceptedUnits: [
      // { id: 'A01', type: 'rect', x: 0, y: 0, width: 100, height: 100 }
    ],
    error: null,
  }
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log('React catch error', error, info);
  }


  
  componentDidMount() {
    // Get Floor
    const { docRef } = this.props;
    docRef.get()
      .then(doc => {
        this.setState({
          floorplate: doc.data(),
        });
      });
  }

  onDropStart = () => {
    this.setState({ error: null });
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    const _self = this;
    const cantFindElements = [];
    // Closure to capture the file information.
    try {
        
      if (acceptedFiles.length > 0) {
        reader.onloadend = function () {
          // console.log(reader.result);
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(reader.result, "text/xml");
          const group = xmlDoc.getElementById('unit');
          if (!group) {
            _self.setState({ error: 'Cannot find node ID = unit' });
          } else {

            const unitElements = xmlDoc.getElementById('unit').children;
            // console.log('unitElements', unitElements);

                
            if (unitElements.length === 0) {
              _self.setState({ error: 'No elements under element ID = unit' })
            } else {
              const units =  Array.from(unitElements).map(unitElement => {
                // console.log('unitElement', unitElement, unitElement.attributes);
                const id = _.get(_.find(unitElement.attributes, attr => attr.nodeName === 'id'), 'nodeValue');
                // console.log('id', id);
                const logoId = _.replace(`logo_${id}`, '__', '_');
                const logoElement = xmlDoc.getElementById(logoId);
                // console.log('logoElement', logoElement.nodeName, logoElement.attributes);
                if (!logoElement) {
                  // _self.setState({ error: `Cannot find element id ${logoId}` })
                  // cantFindElements.push(logoId);
                }

                // console.log('logoElement', logoId, logoElement);
                const logoAttributes = logoElement ? {
                    type: logoElement.nodeName,
                    ..._.reduce(logoElement.attributes, (acc, attr) => {
                    return { ...acc, [attr.nodeName]: attr.nodeValue };
                  }, {}),
                } : {};

                const shopId = _.replace(`shop_${id}`, '__', '_');
                const shopElementById = xmlDoc.getElementById(shopId);
                let _elements;
                if (!shopElementById) {
                  // _self.setState({ error: `Cannot find element id ${shopId}` });
                  // throw new Error(`Cannot find element id ${shopId}`);
                  // cantFindElements.push(shopId);
                } else {
                  const shopElements = xmlDoc.getElementById(shopId).querySelectorAll('polygon, polyline, path, rect, ellipse, circle');
                  //  console.log('shopElements', shopId, shopElements);
                  _elements =  Array.from(shopElements).map(shopElement => {
                    // console.log('shopEl', shopElement.nodeName, shopElement.attributes);
                    return {
                      type: shopElement.nodeName,
                      ..._.reduce(shopElement.attributes, (acc, attr) => {
                        return { ...acc, [attr.nodeName]: attr.nodeValue };
                      }, {}),
                    }
                  });
                }

                // console.log('_elements', _elements);

                return {
                  ..._.find(_elements, elem => elem.fill !== 'none'),
                  id: _.replace(id, /^_/, ''),
                  elements: {
                    outline: _.find(_elements, elem => elem.fill === 'none') || null,
                    logo: logoAttributes,
                  },
                };
              });
              // console.log('units', units);
              // console.log('cantFindElements', cantFindElements);
              if (cantFindElements.length > 0) {
                // _self.setState({
                //   error: `Cant find element with id ${_.join(cantFindElements, ', ')}`,
                // })
              } else {
                const filterError = _.reduce(units, (result, value) => {
                  if(!value.type) {
                    result.noneOfType.push(value.id);
                  }
                  return result;
                }, {noneOfType: []});
                if(_.size(filterError.noneOfType) > 0) {
                  alert(`Error None Of Type: ${_.join(filterError.noneOfType, ', ')}`)
                }else{
                  _self.setState({
                    acceptedUnits: units,
                    step: 1,
                  });
                }
              }
            }
          }
        }

        // Read in the image file as a data URL.
        reader.readAsText(acceptedFiles[0]);
      }
    } catch (e) {
      console.log('catch error', e.message);
    }
  }

  handleReset = () => {
    this.setState({
      step: 0,
      acceptedUnits: [],
    });
  }

  updateUnitType = (id, type) => {
    this.setState({
      acceptedUnits: _.map(this.state.acceptedUnits, unit => {
        if (unit.id === id) return { ...unit, unitType: type };
        return unit;
      })
    });
  }

  handleSave = () => {
    const { docRef } = this.props;
    
    if (docRef && this.state.acceptedUnits) {
      // Delete Old Units
      docRef.collection('units').get().then(querySnapshot => {
        
        const oldIds = _.map(querySnapshot.docs, doc => doc.id);
        const newIds = _.map(this.state.acceptedUnits, unit => unit.id);
        const toRemoveIds = _.difference(oldIds, newIds);
        // console.log('oldIds', oldIds);
        // console.log('newIds', newIds);
        // console.log('toRemoveIds', toRemoveIds);
        // Delete
        _.forEach(toRemoveIds, id => {
          docRef.collection('units').doc(id).delete();
        });

        // Update
        _.forEach(this.state.acceptedUnits, unit => {
          docRef.collection('units').doc(unit.id).set(unit, { merge: false});
        });

        // Save update;
        docRef.update({
          'fields.unitImported': moment().format()
        });
        
      });
    } 


    notify.show('Saved', 'success');
    if (this.props.onSaved) this.props.onSaved();
    if (this.props.onClose) this.props.onClose();
  }

  render() {
    const { onClose, open, medias, docRef } = this.props;
    const { floorplate, step, acceptedUnits, error } = this.state;

    const floorMediaId = _.get(floorplate, 'fields.floorplan');
    const floorplanMedia = _.get(medias, floorMediaId);
    const floorId = docRef.id;

    // console.log('FloorplateUnitEditor', floorMediaId, floorplanMedia);
    return (
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Floor {floorId} : Unit Importer</DialogTitle>
        <DialogContent>
          {step === 0 &&
            <Grid container>
              <Grid item sm={12}>
                <Dropzone
                  accept={["image/svg+xml"]}
                  disableClick
                  style={{ width: 600, height: 400 }}
                  onDropStart={this.onDropStart}
                  onDrop={this.onDrop}
                >
                  {({ isDragActive, isDragReject }) => {
                    let text = 'Copy SVG code or drop SVG file.';
                    if (isDragActive) text = 'This is SVG file, Looks goods to me';
                    if (isDragReject) text = 'This is not SVG you dump ass';

                    if (error) text = error;
                    return (
                      <DropArea className={`${isDragActive ? 'drop-ok' : ''} ${isDragReject ? 'drop-not-ok' : ''} ${error !== null ? 'has-error' : ''}`}>
                        <textarea></textarea>
                        <div>{text}</div>
                      </DropArea>
                    );
                  }}
                </Dropzone>
              </Grid>
            </Grid>
          }
          {step === 1 &&
            <Grid container style={{ width: 1180, maxWidth: '100%' }}>
              <Grid item sm={9}>
                <div>
                  <FloorplanLoader
                    {...floorplate.fields}
                    src={_.get(floorplanMedia, 'url')}
                    docRef={docRef}
                    units={acceptedUnits}
                  />
                </div>
              </Grid>
              <Grid item sm={3}>
                <List>
                  {acceptedUnits.map(unit =>
                    <ListItem key={unit.id}>
                      <ListItemText primary={unit.id} />
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          {step === 0 && <Button onClick={this.props.onClose}>Cancel</Button>}
          {step === 1 && (
            <div>
              <Button onClick={this.handleReset}>Back</Button>
              <Button onClick={this.handleSave} color="primary" autoFocus>
                Continue
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }
});


// {_.map(roomtypes, option =>
// <MenuItem key={option} value={option}>{option}</MenuItem>)}
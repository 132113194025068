import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

import Switch from '@material-ui/core/Switch';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';

import DragHandleIcon from '@material-ui/icons/DragHandle';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import InputList from  '../InputList';

import ConfirmDialog from '../../../../components/ConfirmDialog';

import fieldTypes, {
  TEXTFIELD,
  TEXTAREA,
  DROPDOWN,
  RADIO,
  CHECKBOX,
} from '../../constants/fieldTypes';


const fieldUIs = _.reduce(fieldTypes, (sum, fieldType) => {
  return [
    ...sum,
    ..._.map(fieldType.ui, ui => ({ ...ui, type: fieldType.name })),
  ];
}, []);

const mapDataToField = props => ({
  id: props.id,
  label: props.label,
  type: props.type,
  ui: props.ui,
  options: props.options,
  validation: {},
});

const mapFieldToData = field => ({
  ...field,
})

export default class extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    ui: PropTypes.string,
  }

  static defaultProps = {
    focus: false,
  }

  static getDerivedStateFromProps (props, state) {
    if (props.fieldIndex !== state.fieldIndex) {
      return {
        fieldIndex: props.fieldIndex,
        field: mapDataToField(props),
      };
    }
    return null;
  }

  state = {
    fieldIndex: null,
    field: null,
  }

  handleChangeLabel = (event) => {
    const value = event.target.value;
    this.setState({
      field: {
        ...this.state.field,
        id: _.camelCase(value),
        label: value,
      }
    });
  }

  handleChangeUI = (event) => {
    this.setState({
      field: {
        ...this.state.field,
        ui: event.target.value
      }
    });
  }

  handleChangeOptions = (value) => {
    this.setState({
      field: {
        ...this.state.field,
        options: value,
      }
    });
  }

  handleChangeRequired = (event) => {
    this.setState({
      field: {
        ...this.state.field,
        required: event.target.checked,
      }
    })
  }

  handleChangeLocalization = (event) => {
    this.setState({
      field: {
        ...this.state.field,
        localized: event.target.checked,
      }
    })
  }

  save = () => {
    const { fieldIndex, field } = this.state;
    this.props.onFieldChange(fieldIndex, mapFieldToData(field));
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    // console.log('nextProps', nextProps);
    // console.log('this.props', this.props);
    // console.log('_.isEqual(nextState, this.state)', _.isEqual(nextState, this.state));
    return !_.isEqual(_.omitBy(nextProps, _.isFunction), _.omitBy(this.props, _.isFunction)) || !_.isEqual(nextState, this.state);
  }

  renderFieldOptions = (ui) => {
    const { field } = this.state;
    const { localized, options } = field;
    switch (ui) {
      case DROPDOWN:
        return (
          <div>
            <InputList
              value={options}
              onChange={this.handleChangeOptions}
              render={({ inputProps }) => (
                <TextField margin="none" inputProps={{ style: { fontSize: '0.8em' } }} style={{ marginBottom: 0 }} fullWidth {...inputProps} />
              )}
            />
          </div>
        );
      
      case RADIO:
        return (
          <div>
            <InputList
              value={options}
              onChange={this.handleChangeOptions}
              render={({ inputProps }) => (
                <div style={{ display: 'flex' }}>
                  <RadioButtonUncheckedIcon style={{ opacity: 0.6, marginRight: 5 }} />
                  <TextField margin="none" inputProps={{ style: { fontSize: '0.8em' } }} style={{ marginBottom: 0 }} fullWidth {...inputProps} />
                </div>
              )}
            />
          </div>
        );
      case CHECKBOX:
        return (
          <div>
            <InputList
              value={options}
              onChange={this.handleChangeOptions}
              render={({ inputProps }) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <CheckBoxOutlineBlankIcon style={{ opacity: 0.6, marginRight: 5 }} />
                    <TextField margin="none" inputProps={{ style: { fontSize: '0.8em' } }} style={{ marginBottom: 0 }} fullWidth {...inputProps} />
                  </div>
                );
              }}
            />
          </div>
        );
      case TEXTFIELD:
      case TEXTAREA:
        return (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!localized}
                  onChange={this.handleChangeLocalization}
                  value="checkedA"
                />
              }
              label="Enable localization of this field"
            />
          </div>
        );
      default:
        return (<div />);
    }
  }

  render() {
    const { focus } = this.props;
    const { field } = this.state;
    const { label, ui, required } = field;
    const uiIcon = _.find(fieldUIs, fieldUI => fieldUI.name === ui);
    const fieldUIItems = _.reduce(fieldTypes, (acc, fieldType) => {
      return [
        ...acc,
        ..._.map(fieldType.ui, option => (
          <MenuItem key={option.name} value={option.name}>
            {option.icon && <ListItemIcon>{<option.icon />}</ListItemIcon>}
            <ListItemText inset primary={option.name} />
          </MenuItem> 
        )),
        <Divider key={`${fieldType.name}-divider`} />
      ];
    }, []);

    return (
      <div style={{ position: 'relative' }}>
        {focus &&
          <div style={{ position: 'absolute', top: 0, left: '50%', marginLeft: -20, zIndex: 3, cursor: 'pointer' }}>
            <DragHandleIcon
              onMouseDown={this.props.onDragStart}
              onTouchStart={this.props.onDragStart}
            />
          </div>
        }
        <ExpansionPanel expanded={this.props.focus}>
          <ExpansionPanelSummary>
            <ListItem component="div" style={{ padding: 0 }}>
              {uiIcon && uiIcon.icon && <ListItemIcon><uiIcon.icon /></ListItemIcon>}
              <ListItemText primary={label} secondary={ui} />
            </ListItem>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'block' }}>
            <div style={{ padding: '0 8px'}}>
              <Grid container spacing={8}>
                <Grid item sm={8}>
                  <TextField fullWidth variant="outlined" label="Label" value={label} onChange={this.handleChangeLabel} />
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="UI"
                    inputProps={{
                      name: 'ui',
                      renderValue: value => value,
                    }}
                    select
                    value={ui}
                    onChange={this.handleChangeUI}
                  >
                    {fieldUIItems}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={12}>
                  {this.renderFieldOptions(ui)}
                </Grid>
              </Grid>
            </div>
          </ExpansionPanelDetails>
          <Divider />
          <ExpansionPanelActions>
            <Button onClick={this.save}>Save</Button>
            <FormControlLabel
              labelPlacement="start"
              control={
                <Switch
                  checked={required}
                  color="primary"
                  onChange={this.handleChangeRequired}
                  value="antoine"
                />
              }
              label="Required"
            />
            <ConfirmDialog
                title="Delete this field?"
                onOk={this.props.onActionDelete}
                render={() => (
                  <IconButton><DeleteIcon /></IconButton>
                )}
              />
            <IconButton><MoreVertIcon /></IconButton>
          </ExpansionPanelActions>
        </ExpansionPanel>
        {focus &&
          <div style={{ position: 'absolute', right: -50, top: 30 }}>
            <Tooltip title="Add Field" placement="right">
              <Button variant="fab" color="default" mini onClick={this.props.onActionAdd}><AddIcon /></Button>
            </Tooltip>
          </div>}
      </div>
    );
  }
}
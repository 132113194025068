import { combineReducers } from 'redux';
import apps from './entities/apps';
import user from './user';
import theme from './theme';

export default combineReducers({
  entities: combineReducers({
    apps,
  }),
  user,
  theme,
});
import React from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import { utility } from '../../../../lib'

import Navigation from "../../components/Navigation";

import * as Ombres from "forviz-ombresjs";
import * as Media from "forviz-mediajs";

// Actions
import { updateProject, updateUnits } from "../../actions";

// Screens
import ProjectDashboardScreen from "../ProjectDashboardScreen";
// import BrandScreen from '../BrandScreen';
import DirectoryScreen from "../DirectoryScreen";
import CategoryScreen from "../CategoryScreen";
import FloorScreen from "../FloorScreen";
import ZoneScreen from "../ZoneScreen";
import PromotionScreen from "../PromotionScreen";
import EventScreen from "../EventScreen";
import AppConfigScreen from "../AppConfigScreen";
import KioskScreen from "../KioskScreen";
import MediaScreen from "../MediaScreen";
import InformationScreen from "../InformationScreen";
import SignageScreen from "../SignageScreen";

import UserManagement from "../../../iam/screens/UserManagementScreen";
import SettingScreen from "../SettingScreen";
import ImportData from "../../../../components/ImportData";
import BuildingScreen from "../BuildingScreen";
import CustomFieldScreen from "../CustomFieldScreen";

import Loading from "../../../../components/Loading";
import UnAuthorization from "../../../../components/UnAuthorization";
import moment from "moment";

const { permission } = utility;
const {
  verifyUserHasAccessToProject,
  getProjectRole
} = permission;

const Container = styled.div`
  flex: 1;
  flex-direction: row;
  height: 100%;
`;

const Main = styled.div`
  flex: 1;
  margin-left: 240px;
  height: 100%;
`;

const createProjectMediaClient = projectId => {
  return Media.createClient({
    storagePath: `ombres/projects/${projectId}`,
    firestorePath: `ombres_projects/${projectId}/medias`
  });
};

const createProjectContentClient = projectId => {
  return Ombres.createClient({ projectId });
};

export default connect()(
  class extends React.Component {
    floorUnitsUnsubscribe = {};

    state = {
      loading: false
    }

    componentDidMount() {
      const { match, user, accountId, dispatch } = this.props;
      const projectId = _.get(match, "params.projectId");
      const _self = this;

      if (!verifyUserHasAccessToProject(user, accountId, 'ombres', projectId)) {
        return;
      }

      // loading
      this.setLoading(true);

      // Get Project Medias
      const mediaClient = createProjectMediaClient(projectId);

      mediaClient.getMediasRef().onSnapshot(querySnapshot => {
        // sort by updated desc
        const medias = _.chain(querySnapshot.docs)
        .reduce((acc, doc) => ([
            ...acc,
            {
              id: doc.id,
              ...doc.data()
            }
          ]), [])
        .sortBy(media => moment(media.metadata.updated).format('x') * -1).value()
        .reduce((acc, doc) => ({
          ...acc,
          [doc.id]: doc
        }), {})
        dispatch(updateProject(projectId, "medias", medias));
      });

      const dbClient = createProjectContentClient(projectId);

      // Get Project
      dbClient.getProjectRef().onSnapshot(doc => {
        const projectData = doc.data();
        dispatch(updateProject(projectId, "metadata", projectData.metadata));
        dispatch(updateProject(projectId, "setting", projectData.setting));
      });

      // Get Categories
      dbClient.getCategoriesCollectionRef().onSnapshot(querySnapshot => {
        const categories = _.reduce(
          querySnapshot.docs,
          (acc, doc) => ({ ...acc, [doc.id]: doc.data() }),
          {}
        );
        dispatch(updateProject(projectId, "categories", categories));
      });

      // Get Shops
      dbClient.getShopsCollectionRef().onSnapshot(querySnapshot => {
        const shops = _.reduce(
          querySnapshot.docs,
          (acc, doc) => ({ ...acc, [doc.id]: doc.data() }),
          {}
        );
        this.setLoading(false);
        dispatch(updateProject(projectId, "shops", shops));
      });

      // Get Floors
      dbClient
        .getFloorsCollectionRef()
        .orderBy("order", "desc")
        .onSnapshot(querySnapshot => {
          const floors = _.reduce(
            querySnapshot.docs,
            (acc, doc) => ({ ...acc, [doc.id]: doc.data() }),
            {}
          );
          dispatch(updateProject(projectId, "floors", floors));

          querySnapshot.docChanges().forEach(function(change) {
            if (change.type === "added") {
              // console.log("New floor: ", change.doc.data());
              const floorId = change.doc.id;
              _self.floorUnitsUnsubscribe[floorId] = dbClient
                .getFloorDocRef(change.doc.id)
                .collection("units")
                .onSnapshot(unitSnapshot => {
                  const units = _.reduce(
                    unitSnapshot.docs,
                    (acc, doc) => ({
                      ...acc,
                      [`${floorId}!${doc.id}`]: { ...doc.data(), floorId }
                    }),
                    {}
                  );
                  dispatch(updateUnits(projectId, units));
                });
            }
            if (change.type === "modified") {
              // console.log("Modified floor: ", change.doc.data());
            }
            if (change.type === "removed") {
              // console.log("Removed floor: ", change.doc.data());
              const floorId = change.doc.id;
              if (typeof _self.floorUnitsUnsubscribe[floorId] === "function")
                _self.floorUnitsUnsubscribe[floorId]();
            }
          });
        });

      // Get Nodes
      dbClient.getNodesCollectionRef().onSnapshot(querySnapshot => {
        const nodes = _.reduce(
          querySnapshot.docs,
          (acc, doc) => ({ ...acc, [doc.id]: doc.data() }),
          {}
        );
        dispatch(updateProject(projectId, "nodes", nodes));
      });

      // Get Elements
      dbClient.getCollectionRef("elements").onSnapshot(querySnapshot => {
        const elements = _.reduce(
          querySnapshot.docs,
          (acc, doc) => ({ ...acc, [doc.id]: { id: doc.id, ...doc.data() } }),
          {}
        );
        dispatch(updateProject(projectId, "elements", elements));
      });

      // Get Zones
      dbClient
        .getProjectRef()
        .collection("zones")
        .onSnapshot(querySnapshot => {
          const zones = _.reduce(
            querySnapshot.docs,
            (acc, doc) => ({ ...acc, [doc.id]: doc.data() }),
            {}
          );
          dispatch(updateProject(projectId, "zones", zones));
        });

      // Get Building
      dbClient
        .getProjectRef()
        .collection("building")
        .onSnapshot(querySnapshot => {
          const building = _.reduce(
            querySnapshot.docs,
            (acc, doc) => ({ ...acc, [doc.id]: doc.data() }),
            {}
          );
          dispatch(updateProject(projectId, "building", building));
        });

      // Get Custom Fields
      this.getCustomFields();
    }

    initCustomFields = () => {
      const { match } = this.props;
      const projectId = _.get(match, "params.projectId");

      const dbClient = createProjectContentClient(projectId);
      const data = {
        shops: {
          label: "shops",
          fields: {
            privilege: {
              label: "Privilage",
              default: true,
              show: true
            },
            zone: {
              label: "Zone",
              default: true,
              show: true
            }
          }
        },
        category: { label: "category", fields: {} },
        zone: { label: "zone", fields: {} },
        floor: { label: "floor", fields: {} },
        promotion: { label: "promotion", fields: {} },
        event: { label: "event", fields: {} },
        informations: { label: "informations", fields: {} },
        banners: { label: "banners", fields: {} }
      };
      _.forEach(data, (o, key) => {
        const docRef = dbClient.getCustomFieldDocRef(key);
        docRef.get().then(docSnapShot => {
          if (!docSnapShot.exists) {
            console.log("done");
            docRef.set(o);
          } else {
            console.log("error");
          }
        });
      });
    };

    getCustomFields = () => {
      const { match, dispatch } = this.props;
      const projectId = _.get(match, "params.projectId");
      const dbClient = createProjectContentClient(projectId);
      dbClient.getCustomFieldsCollectionRef().onSnapshot(querySnapshot => {
        if (querySnapshot.size < 8) {
          this.initCustomFields();
          return false;
        }
        const customFields = _.reduce(
          querySnapshot.docs,
          (acc, doc) => ({ ...acc, [doc.id]: doc.data() }),
          {}
        );
        dispatch(updateProject(projectId, "customFields", customFields));
      });
    };

    setLoading = (loading) => {
      this.setState({ loading });
    }

    render() {
      const { loading } = this.state;
      const { match, user, accountId } = this.props;
      const projectId = _.get(match, "params.projectId");

      const dbClient = createProjectContentClient(projectId);

      if (!verifyUserHasAccessToProject(user, accountId, 'ombres', projectId)) {
        return (
          <UnAuthorization
            message={`Sorry, you don't have permission to access [${projectId}] project`}
          />
        );
      }

      const userProjectRole = getProjectRole(user, accountId, 'ombres', projectId)

      return (
        <Container>
          <Helmet>
            <title>{projectId} | Ombrés</title>
          </Helmet>
          <Navigation role={userProjectRole} />
          <Loading open={loading} onClose={() => this.setLoading(false) } />
          <Main>
            <Switch>
              <Route
                exact
                path={`${match.url}`}
                render={routerProps => (
                  <ProjectDashboardScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/directory`}
                render={routerProps => (
                  <DirectoryScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                    idEditable={false}
                  />
                )}
              />
              <Route
                path={`${match.url}/categories/import`}
                render={routerProps => (
                  <ImportData
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/categories`}
                render={routerProps => (
                  <CategoryScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/zones`}
                render={routerProps => (
                  <ZoneScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/floors`}
                render={routerProps => (
                  <FloorScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/building`}
                render={routerProps => (
                  <BuildingScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/promotions`}
                render={routerProps => (
                  <PromotionScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/events`}
                render={routerProps => (
                  <EventScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/informations`}
                render={routerProps => (
                  <InformationScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/kiosks`}
                render={routerProps => (
                  <KioskScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/banners`}
                render={routerProps => (
                  <SignageScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                  />
                )}
              />
              <Route
                path={`${match.url}/medias`}
                render={routerProps => (
                  <MediaScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                    client={createProjectMediaClient(projectId)}
                  />
                )}
              />
              <Route
                path={`${match.url}/config`}
                render={routerProps => (
                  <AppConfigScreen
                    {...routerProps}
                    user={user}
                    accountId={accountId}
                    projectId={projectId}
                    client={createProjectContentClient(projectId)}
                  />
                )}
              />
              <Route
                path={`${match.url}/users`}
                exact
                render={routerProps => {
                  return (
                    <UserManagement
                      {...routerProps}
                      user={user}
                      accountId={accountId}
                      projectId={projectId}
                      resourceName={`forviz:${accountId}:ombres:${projectId}`}
                    />
                  );
                }}
              />
              <Route
                path={`${match.url}/settings`}
                exact
                render={routerProps => {
                  return (
                    <SettingScreen
                      {...routerProps}
                      user={user}
                      accountId={accountId}
                      projectId={projectId}
                      docRef={dbClient.getProjectRef()}
                    />
                  );
                }}
              />
              <Route
                path={`${match.url}/custom-fields`}
                render={routerProps => {
                  return (
                    <CustomFieldScreen
                      {...routerProps}
                      user={user}
                      accountId={accountId}
                      projectId={projectId}
                      docRef={dbClient.getProjectRef()}
                    />
                  );
                }}
              />
            </Switch>
          </Main>
        </Container>
      );
    }
  }
);

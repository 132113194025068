import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";

import FirestoreDocEditor from "../../../../components/FirestoreDocEditor";
import {
  getSections,
  getMapDocDataToFields,
  getMapFieldValuesToDocData
} from "../../utils/getCustomfield";

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, "projectId");
  const setting = _.get(state, ["projects", projectId, "setting"]);
  return {
    setting,
    medias: _.get(state, ["projects", projectId, "medias"])
  };
};
export default connect(mapStateToProps)(props => {
  const { projectId, medias, setting, docCustomFieldRef } = props;
  const currentDate = moment().format("YYYY-MM-DD");
  const [customField, setCustomField] = useState({});
  const [updateVersionForRender, setUpdateVersionForRender] = useState(0);

  useEffect(() => {
    docCustomFieldRef.onSnapshot(doc => {
      const docData = doc.data();
      setUpdateVersionForRender(updateVersionForRender + 1);
      setCustomField(docData);
    });
  }, []);

  const customSections = getSections(customField, {
    languages: _.get(setting, "languages", [])
  });
  return (
    <FirestoreDocEditor
      key={`updateVersionForRender-${_.size(
        customField
      )}-${updateVersionForRender}`}
      {...props}
      mediaEngine="firebase"
      mediaEngineConfig={{
        storagePath: `ombres/projects/${projectId}`,
        firestorePath: `ombres_projects/${projectId}/medias`
      }}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => {
        const cuntomMapDocDataToFields = getMapDocDataToFields(customField, {
          data,
          medias,
          languages: _.get(setting, "languages", [])
        });
        return {
          title: {
            label: "Banner Title",
            value: _.get(data, "fields.name.en")
          },
          media: {
            label: "Media",
            type: "Image",
            value: _.get(data, "fields.media"),
            options: _.map(medias, (media, key) => ({
              value: key,
              label: key,
              url: media.url
            }))
          },
          type: {
            label: "Type",
            type: "Radio",
            options: [
              { value: "video", label: "Video" },
              { value: "image", label: "Image" }
            ],
            value: _.get(data, "fields.type")
          },
          duration: {
            label: "Duration",
            value: _.get(data, "fields.duration")
          },
          startDate: {
            label: "Start Date",
            type: "Date",
            defaultValue: currentDate,
            value: _.get(data, "fields.startDate", currentDate)
          },
          endDate: {
            label: "End Date",
            type: "Date",
            defaultValue: currentDate,
            value: _.get(data, "fields.endDate", currentDate)
          },
          status: {
            label: "Status",
            type: "Dropdown",
            value: _.get(data, "fields.status", "Draft"),
            options: [
              { value: "Draft", label: "Draft" },
              { value: "Published", label: "Published" },
              { value: "Archived", label: "Archived" }
            ]
          },
          ...cuntomMapDocDataToFields
        };
      }}
      mapFieldValuesToDocData={fieldValues => {
        // const fieldValues = _.mapValues(fields, field => field.value);

        const cuntomMapFieldValuesToDocData = getMapFieldValuesToDocData(
          customField,
          {
            fieldValues,
            languages: _.get(setting, "languages", [])
          }
        );
        return {
          fields: {
            name: {
              en: fieldValues.title
            },
            media: fieldValues.media,
            type: fieldValues.type,
            duration: fieldValues.duration,
            startDate: fieldValues.startDate,
            endDate: fieldValues.endDate,
            status: fieldValues.status,
            ...cuntomMapFieldValuesToDocData
          }
        };
      }}
      sections={[
        {
          title: "Event Info",
          fields: [
            "title",
            "media",
            "type",
            "duration",
            "startDate",
            "endDate",
            "status"
          ]
        },
        ...customSections,
        {
          title: "Publish",
          fields: ["status"]
        }
      ]}
    />
  );
});

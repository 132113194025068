import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { Route, Switch, Link, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DevicesIcon from '@material-ui/icons/Devices';
import AddIcon from '@material-ui/icons/Add';

import DashboardScreen from '../Dashboard';
import TransactionScreen from '../Transaction';
import SettingScreen from '../SettingScreen';

import { updateAccount } from '../../actions';
import UnAuthorization from '../../../../components/UnAuthorization';
import { utility } from '../../../../lib'
import * as Zentry from 'forviz-zentryjs';
import * as Ombres from 'forviz-ombresjs';

const pages = [
  {
    path: 'search',
    label: 'Get Direction',
    q: 'search'
  },
  {
    path: 'search-origin',
    label: 'Origin',
    q: 'search.origin'
  },
  {
    path: 'search-destination',
    label: 'Destination',
    q: 'search.destination'
  },
  {
    path: 'search-keywords',
    label: 'Keywords',
    q: 'search.keywords'
  },
  {
    path: 'search-categories',
    label: 'Categories',
    q: 'search.categories'
  },
]

const { permission } = utility;
const {
  verifyUserHasAccessToProject,
} = permission;

const createZentryContentClient = (accountId) => {
  return Zentry.createClient({ accountId });
}

const createOmbresContentClient = (projectId) => {
  return Ombres.createClient({ projectId });
}

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: 'calc(100vh - 64px)',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    'overflow-y': 'scroll',
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
});

const mapStateToProps = (state, ownProps) => {
  const hasNoLog = state.logs.lengths <= 0
  return {
    hasNoLog
  }
}

const AccountRoot = ({
  user,
  match,
  accountId,
  classes,
  dispatch,
  history,
  hasNoLog,
  location,
}) => {

  const [ clients, setClients ] = useState({})
  const params = new URLSearchParams(location.search)
  const projectId = _.get(match, "params.projectId");

  useEffect(() => {
    const zentryClient = createZentryContentClient(accountId)
    const ombresClient = createOmbresContentClient(projectId)
    zentryClient.getAccountRef().onSnapshot(doc => {
      const accountData = doc.data();
      if (accountData) {
        dispatch(updateAccount(accountId, 'metadata', accountData.metadata));
        dispatch(updateAccount(accountId, 'setting', accountData.setting));
      }
    })
    setClients({
      zentryClient,
      ombresClient
    });
    if (!hasNoLog) {
      history.push(match.url)
      return <div />
    };
  }, [])

  if (!verifyUserHasAccessToProject(user, accountId, 'zentry', projectId)) {
    return (
      <UnAuthorization
        message={`Sorry, you don't have permission to access [${projectId}] project`}
      />
    );
  }

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          <ListItem
            button
            key='dashboard'
            component={Link}
            to={{
              pathname: match.url,
              search: params.toString(),
            }}
            to={`${match.url}${params.toString() !== '' ? `?${params.toString()}` : ''}`}>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary='Dashboard' />
          </ListItem>
        </List>
        <Divider />
        <List subheader={<ListSubheader>Transaction</ListSubheader>}>
          {pages.map(({ label, q, path}, index) => (
            <ListItem button key={label} component={Link} to={`${match.url}/transactions/${path}${params.toString() !== '' ? `?${params.toString()}` : ''}`}>
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <main className={classes.content}>
        {!_.isEmpty(clients) &&
          <Switch>
            <Route
              path={`${match.url}/transactions/:eventName`}
              render={routerProps => <TransactionScreen {...routerProps} clients={clients} accountId={accountId} />}
            />
            <Route
              path={`${match.url}`}
              render={routerProps => <DashboardScreen {...routerProps} clients={clients} accountId={accountId} />}
            />
          </Switch>
        }
      </main>
    </div>
  )
}

export default withStyles(styles)(connect(mapStateToProps)(AccountRoot))

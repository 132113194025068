import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import logger from 'redux-logger';

import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import _ from 'lodash';

import { MuiThemeProvider } from '@material-ui/core/styles';

// Screens
import HomeScreen from './screens/Home';
import BuildingScreen from './screens/BuildingScreen';

import { getProjectsRef } from './api/realtime';

import rootReducer from './reducers';
import theme from './theme';
// store
const store = createStore(rootReducer, applyMiddleware(logger));

export default class extends React.Component {
  state = {
    meerkatProjects: [],
  }

  componentDidMount() {
    const { project } = this.props;
    this.loadMeerkatProjects(project.id);
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.project.id !== prevProps.project.id) {
  //     this.loadMeerkatProjects(this.props.project.id);
  //   }
  // }

  loadMeerkatProjects = (accountId) => {
    getProjectsRef(accountId).onSnapshot(querySnapshot => {
      const meerkatProjects =  _.reduce(querySnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: { id: doc.id, ...doc.data() }}), {});
      this.setState({
        meerkatProjects,
      });
      store.dispatch({
        type: 'RECEIVED/PROJECTS',
        projects: meerkatProjects,
      });
    });
  }

  render() {
    const { user, match, project } = this.props;
    const { meerkatProjects } = this.state;
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <title>{project.name} | Meerkat App</title>
          </Helmet>
          <div>
            <Route exact path={`${match.url}/`} render={routerProps => <HomeScreen {...routerProps} user={user} projects={meerkatProjects} account={project} />} />
            <Route
              path={`${match.url}/b/:buildingId`}
              render={routerProps => <BuildingScreen {...routerProps} homeURL={match.url} user={user} projectId={project.id} accountId={project.id} />}
            />
          </div>
        </MuiThemeProvider>
      </Provider>
    );
  }
}
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import FirestoreDocEditor from '../../../../components/FirestoreDocEditor';

const mapStateToProps = (state, ownProps) => {
  return {}
};

export default connect(mapStateToProps)((props) => {
  return (
    <FirestoreDocEditor
      {...props}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => ({
        name: { label: 'Name', value: _.get(data, 'name', id) },
        location: { label: 'Location', value: _.get(data, 'location') },
        layout: { label: 'Layout', value: _.get(data, 'layout') },
        screenSize: { label: 'Screen Size', value: _.get(data, 'screenSize') },
        'block-1': { label: 'Block 1', type: 'Object', value: _.get(data, 'blocks.0') },
        'block-2': { label: 'Block 2', type: 'Object', value: _.get(data, 'blocks.1') },
      })}
      mapFieldValuesToDocData={fieldValues => {
        // const fieldValues = _.mapValues(fields, field => field.value);
        return {
          name: fieldValues.name,
          location: fieldValues.location,
          layout: fieldValues.layout,
          screenSize: fieldValues.screenSize,
          blocks: [
            fieldValues['block-1'],
            fieldValues['block-2'],
          ]
        }
      }}
      sections={[
        {
          title: 'Media Setting',
          fields: ['name', 'location', 'layout', 'screenSize'],
        },
        {
          title: 'Blocks',
          fields: ['block-1', 'block-2'],
        },
      ]}
    />
  );
});
import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';

import getSuggestions from './utils/getSuggestions';
import renderInput from './utils/renderInput';
import renderSuggestion from './utils/renderSuggestion';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 10,
    marginTop: theme.spacing.unit,
    top: 50,
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

export default withStyles(styles)(class extends React.Component {
  static defaultProps = {
    value: '',
    placeholder: `Select Item`,
  }

  static getDerivedStateFromProps (props, prevState) {
    if (props.value !== prevState.value) {
      const { suggestions } = props;
      const inputText = _.get(_.find(suggestions, suggest => suggest.value === props.value), 'label');
      return {
        inputValue: inputText,
        value: props.value,
      }
    }
    return null;
  }

  state = {
    inputValue: null,
    value: '',
  };

  handleChange = item => {
    const { value } = this.state;
    if (_.isEqual(value, item)) {
      const { suggestions } = this.props;
      const inputText = _.get(_.find(suggestions, suggest => suggest.value === item), 'label');
      this.setState({
        inputValue: inputText
      })
    }
    this.props.onChange(item);
  }

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  render() {
    const { classes, suggestions, placeholder } = this.props;
    const { inputValue, value } = this.state;
    // console.log('Single:value', value);
    // console.log('Single:inputValue', inputValue);
    return (
      <Downshift
        inputValue={inputValue}
        onChange={this.handleChange}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue: inputValue2,
          isOpen,
          selectedItem,
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                value: inputValue !== null ? inputValue : value,
                onChange: this.handleInputChange,
                placeholder,
              }),
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {getSuggestions(suggestions, inputValue2).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion.value }),
                      highlightedIndex,
                      selectedItem,
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    );
  }
});


import React from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import logger from 'redux-logger';

import { MuiThemeProvider } from '@material-ui/core/styles';

// Screens
import AppRoot from './screens/AppRoot';
import ProjectRoot from './screens/ProjectRoot';
import theme from './theme';

import rootReducer from './reducers';
const store = createStore(rootReducer, applyMiddleware(logger));

export default class extends React.Component {

  render() {
    const { match, project: account } = this.props;
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <title>Aspen</title>
          </Helmet>
          <Route exact path={`${match.url}`} render={routerProps => <AppRoot {...routerProps} accountId={account.id} />} />
          <Route path={`${match.url}/:projectId`} render={routerProps => <ProjectRoot {...routerProps} accountId={account.id} />} />
        </MuiThemeProvider>
      </Provider>
    );
  }
}


export const updateAccount = (accountId, key, data) => ({
  type: 'ACCOUNT/UPDATE',
  id: accountId,
  data: {
    [key]: data,
  }
});

export const rearrangePlaylist = () => {
  
}
export const SEARCH_EVENT = 'search'
export const SEARCH_ORIGIN_EVENT = 'search.origin'
export const SEARCH_DESTINATION_EVENT = 'search.destination'
export const SEARCH_KEYWORDS_EVENT = 'search.keywords'
export const SEARCH_CATEGORIES_EVENT = 'search.categories'
export const WAYFINDER_OVERVIEW_EVENT = 'wayfinder.overview'
export const WAYFINDER_STEP_AND_MORE_EVENT = 'wayfinder.stepAndMore'

export const EVENT_NAMES = [
  SEARCH_EVENT,
  SEARCH_ORIGIN_EVENT,
  SEARCH_DESTINATION_EVENT,
  SEARCH_KEYWORDS_EVENT,
  SEARCH_CATEGORIES_EVENT,
  WAYFINDER_OVERVIEW_EVENT,
  WAYFINDER_STEP_AND_MORE_EVENT
]

export const HEAT_MAP_INTENSITY_RANGE = [
  {
    from: 0,
    to: 100,
    color: "#ffc222"
  },
  {
    from: 101,
    to: 500,
    color: "#ffb74d"
  },
  {
    from: 501,
    to: 1000,
    color: "#ff9800"
  },
  {
    from: 1001,
    to: 100000000,
    color: "#f57c00"
  }
]

export const DEFAULT_NUMBER_OF_COMPARE_SHOP = 4
export const ALL = 'all'
export const DRAWER_WIDTH = 240

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';

const darkTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'dark',
  },
});

const StatCard = ({ label, value, variant = "h5" }) => (
  <Card>
    <CardContent style={{ textAlign: 'right' }}>
      <Typography style={{ fontSize: 12 }} color="textSecondary" gutterBottom>{label}</Typography>
      <Typography variant={variant}>{value}</Typography>
    </CardContent>
  </Card>
);


const mapStateToProps = (state, ownProps) => {
  const { match, buildingId } = ownProps;
  const building = _.get(state, ['projects', buildingId]);

  const countMedias = _.size(_.get(building, 'medias'));
  const countUnitTypes = _.size(_.get(building, 'unitTypes'));
  const countFloorplates = _.size(_.get(building, 'floorplates'));

  return {
    contentVersion: _.get(building, 'metadata.version'),
    lastUpdate: moment(_.get(building, 'metadata.updatedAt.seconds'), 'X').format('D/MM/YYYY HH:mm:ss'),
    countMedias,
    countUnitTypes,
    countFloorplates,
    users: _.get(state, ['projects', buildingId, 'users']),
    steps: [
      {
        label: 'Upload Gallery',
        content: 'อัพโหลดภาพที่จะใช้เป็น Gallery, กำหนด tags (exterior, interior, facility, etc.)',
        actions: [{ label: 'Upload Media', to: `${match.url}/medias` }],
        completed: countMedias > 0,
      },
      {
        label: 'Create Unit Types',
        content: 'สร้าง Unit Types, เลือกห้องนอน/น้ำ, กำหนดขนาดห้อง',
        actions: [
          { label: 'Upload Unitplans', to: `${match.url}/medias` },
          { label: 'Create Unit Types', to: `${match.url}/unit-types` },
        ],
        completed: countUnitTypes > 0,
      },
      {
        label: 'Create Floorplates',
        content: 'สร้าง Floorplates, เลือกชั้น',
        actions: [
          { label: 'Upload Floorplates', to: `${match.url}/medias` },
          { label: 'Create Floorplates', to: `${match.url}/floorplates` },
        ],
        completed: countFloorplates > 0,
      },
      { label: 'Import Unit SVG',
        actions: [{ label: 'Go', to: `${match.url}/floorplates` }],
        completed: false,
      },
      { label: 'Create Location Map',
        actions: [{ label: 'Go', to: `${match.url}/maps` }],
        completed: false,
      },
    ]
  }
}
export default connect(
  mapStateToProps,
)(class extends React.Component {

  state = {
    activeStep: 0,
  }

  handleSelectStep = (index) => this.setState({ activeStep: index })

  render() {
    const { buildingId } = _.get(this.props, 'match.params');
    const { contentVersion, lastUpdate, countMedias, countUnitTypes, countFloorplates, users, steps } = this.props;

    return (
      <Grid container style={{ background: '#f7f7f7' }}>
        <Grid item sm={12}>
          <Grid container style={{ margin: '32px 0 8px' }} justify="space-between" alignItems="center">
            <Grid item sm={6}>
              <Typography variant="h4" gutterBottom style={{ marginLeft: 16 }}>Overview</Typography>
            </Grid>
            <Grid item sm={6}>
              <MuiThemeProvider theme={darkTheme}>
                <Card>
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item xs={3}><StatCard label="Content Version" value={contentVersion} /></Grid>
                    <Grid item xs={6}><StatCard label="Last Update" value={lastUpdate} /></Grid>
                    <Grid item xs={3} style={{ textAlign: 'center' }}>
                      <Button
                        color="primary"
                        variant="contained"
                        component="a"
                        href={`${process.env.REACT_APP_CLOUDFUNCTIONURL}/meerkat/projects/${buildingId}/json`} target="_blank"
                      >Download JSON</Button>
                    </Grid>
                  </Grid>
                </Card>
              </MuiThemeProvider>
            </Grid>
          </Grid>
          <Grid container style={{ padding: 16 }}>
            <Grid item xs={12} sm={3}><StatCard label="Media" value={countMedias} /></Grid>
            <Grid item xs={12} sm={3}><StatCard label="Floorplates" value={countFloorplates} /></Grid>
            <Grid item xs={12} sm={3}><StatCard label="UnitTypes" value={countUnitTypes} /></Grid>
            <Grid item xs={12} sm={3}><StatCard label="Users" value={_.size(users)} /></Grid>
          </Grid>
          <Grid container style={{ padding: 16 }}>
            <Grid item sm={6}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Content Todos</Typography>
                  <Stepper nonLinear orientation="vertical" activeStep={this.state.activeStep}>
                    {steps.map((step, index) => {
                      return (
                        <Step key={step.label}>
                          <StepButton completed={step.completed} onClick={e => this.handleSelectStep(index)}>{step.label}</StepButton>
                          <StepContent>
                            <Typography gutterBottom>{step.content}</Typography>
                            <div>
                              <div>
                                {_.map(step.actions, action => (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    style={{ marginRight: 4 }}
                                    component={Link}
                                    to={action.to}
                                  >
                                    {action.label}
                                  </Button>
                                ))}
                              </div>
                            </div>
                          </StepContent>
                        </Step>
                      );
                    })}
                  </Stepper>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={6}>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
});

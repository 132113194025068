import _ from 'lodash';
import XLSX from 'xlsx'
import { saveAs } from 'file-saver';

const downloadExcel = ({
  data = [],
  headers = [],
  fileName = 'excel',
  wsName = 'Sheet 1',
  toExcelRow
}) => {
  // console.log('downloadExcel', {
  //   data,
  //   headers,
  //   fileName,
  //   wsName,
  //   toExcelRow
  // })
  const dataRows = _.reduce(
    data,
    (result, transaction) => {
      const row = _.isFunction(toExcelRow) && toExcelRow !== undefined ? toExcelRow(transaction) : transaction
      return [
        ...result,
        row
      ];
    },
    []
  );

  console.log({ headers, dataRows })

  /* create a new blank workbook */
  const wb = XLSX.utils.book_new(); // eslint-disable-line
  /* make worksheet */
  const wsData = [headers, ...dataRows];
  const ws = XLSX.utils.aoa_to_sheet(wsData);

  /* Add the worksheet to the workbook */
  XLSX.utils.book_append_sheet(wb, ws, wsName);

  /* bookType can be any supported output type */
  const wopts = { bookType: 'xlsx', bookSST: false, type: 'array' };

  const wbout = XLSX.write(wb, wopts);

  /* the saveAs call downloads a file on the local machine */
  saveAs(
    new Blob([wbout], { type: 'application/octet-stream' }),
    `${fileName}.xlsx`
  );
}

export default {
  downloadExcel
}

import React from 'react';
import _ from 'lodash';
import FirestoreDocForm from '../../components/FirestoreDocForm';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  const buildingId = _.get(ownProps, 'buildingId');
  return {
    medias: _.get(state, ['projects', buildingId, 'medias']),
  }
};

export default connect(mapStateToProps)((props) => {
  const { medias } = props;
  return (
    <FirestoreDocForm
      {...props}
      docRef={props.docRef}
      resources={{
        medias,
      }}
      mapDocDataToFields={(data, id) => ({
        code: { label: 'Code', value: _.get(data, 'code', id) },
        title: { label: 'Title', value: _.get(data, 'title', id) },
        description: { label: 'Description', value: _.get(data, 'description', '') },
        color: { label: 'Color', value: _.get(data, 'color', '') },
        category: { label: 'Category', value: _.get(data, 'category', '') },
        tags: { label: 'Tags', value: _.join(_.get(data, 'tags', [])) },
        unitplan: {
          type: 'media',
          label: 'Unitplan',
          value: _.get(data, 'unitplan', ''),
          options: _.map(medias, (media, key) => ({ value: key, label: key, url: media.url }))
        },
        bed: { label: 'Bedroom', value: _.get(data, 'functions.bed', 1) },
        bath: { label: 'Bathroom', value: _.get(data, 'functions.bath', 1) },
        floor: { label: 'Floor', value: _.get(data, 'functions.floor', 1) },
        area: { label: 'Area', value: _.get(data, 'functions.area', '') },
      })}
      mapFieldsToDocData={fields => {
        const fieldValues = _.mapValues(fields, field => field.value);
        return {
          code: fieldValues.code,
          title: fieldValues.title,
          description: fieldValues.description,
          color: fieldValues.color,
          category: fieldValues.category,
          tags: _.split(fieldValues.tags, ','),
          functions: _.pick(fieldValues, ['bed', 'bath', 'floor', 'area']),
          unitplan: fieldValues.unitplan,
        }
      }}
      sections={[
        {
          title: 'Info',
          fields: ['code', 'title', 'description', 'color']
        },
        {
          title: 'Room Functions',
          fields: ['bed', 'bath', 'floor', 'area'],
        },
        {
          title: 'Taxonomy',
          fields: ['category', 'tags']
        },
        {
          title: 'Media',
          fields: ['unitplan']
        }
      ]}
    />
  );
});
import _ from 'lodash';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVED/PROJECTS':
      return _.mapValues(action.projects, (project, projectId) => ({ ...state[projectId], ...project }));
      /*
      return {
        ...state,
        ..._.mapValues(action.projects, (project, projectId) => ({ ...state[projectId], ...project }))
      }*/

    case 'PROJECT/UPDATE':
      return {
        ...state,
        [action.id]: { ...state[action.id], ...action.data }
      }
    default: return state;
  }
}
import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { Provider, connect } from 'react-redux';

import styled from 'styled-components';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from '@material-ui/core/CircularProgress';

import Notifications from 'react-notify-toast';
import { ToastContainer, Slide } from 'react-toastify';

import { MuiThemeProvider } from '@material-ui/core/styles';
import engine from './engine';

import rootReducer from './reducers';

// Actions
import { receivedUser, receivedUserAccounts, userLoggedOut } from './actions/user-actions';

import NoMatch from './screens/404';
import SelectAccount from './screens/SelectAccount';
import { Root as IamApp } from './apps/iam';

import AccountRoot from './screens/AccountRoot';

import PublicUnitDetail from './apps/meerkat/screens/UnitDetailScreen';

import 'react-toastify/dist/ReactToastify.min.css';
import './flatpickr.css';
import './App.css';

import themes from './theme';

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

const AuthenticatedContext = React.createContext(null);

const FullscreenOverlay = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

class PrivateRoute extends React.PureComponent {
  render() {
    return (
      <AuthenticatedContext.Consumer>
        {({ user, userLoaded }) => {
          // Hasn't load user.
          if (!userLoaded) {
            return (
              <FullscreenOverlay>
                <CircularProgress color="primary" />
                <br />
                <div>Checking User</div>
              </FullscreenOverlay>
            );
          }

          // Hasn't login.
          if (!user) return (<Redirect to={{pathname: '/iam/login', state: { from: this.props.location }}} />);

          // Everything ok
          return <Route {...this.props} />
        }}
      </AuthenticatedContext.Consumer>
    );
  }
}

const mapStateToProps = state => {
  return {
    theme: state.theme,
  }
}

const App = connect(mapStateToProps)(class extends Component {
  state = {
    user: null,
    userAccounts: [],
    userLoaded: false,
  }
  componentDidMount() {
    engine.auth.onAuthStateChanged(user => {
      if (user !== null) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${_.get(user, 'stsTokenManager.accessToken')}`;
        store.dispatch(receivedUser(user.uid, user));
        if (process.env.NODE_ENV !== 'production') console.log({ user })
        // // Get User Project
        const userClaims = _.keys(_.get(user, 'claims.@'));
        const hasAllAccess = userClaims.indexOf('*') !== -1;

        if (hasAllAccess) {
          engine.db.collection('iam_projects').get()
          .then((snapshot) => {
            const accounts = snapshot.docs.map(doc => {
              return { id: doc.id, ...doc.data() }
            });
            this.setState({
              userAccounts: accounts,
            });
            store.dispatch(receivedUserAccounts(user.uid, accounts));
          })
        } else {
          Promise.all(
            _.map(userClaims, accountId => {
              return engine.db.collection('iam_projects').doc(accountId).get()
                .then(doc => ({ id: doc.id, ...doc.data() }));
            }),
          ).then((userAccounts) => {
            console.log('userAccounts', { 'claims': userClaims, userAccounts })
            const accounts = _.filter(userAccounts, acc => !_.isEmpty(acc.name));
            this.setState({
              userAccounts: accounts,
            });
            store.dispatch(receivedUserAccounts(user.uid, accounts));
          });
        }
      } else {
        store.dispatch(userLoggedOut());
      }

      this.setState({
        user,
        userLoaded: true,
      });

    });

  }
  render() {
    const { theme } = this.props;
    const { user, userLoaded, userAccounts } = this.state;
    return (
      <AuthenticatedContext.Provider value={{ user, userLoaded }}>
        <MuiThemeProvider theme={themes[theme]}>
          <CssBaseline />
          <Notifications options={{ zIndex: 99999 }}/>
          <ToastContainer
            className={'app-toast-container'}
            toastClassName="app-toast"
            closeButton={false}
            draggable={false}
            closeOnClick={false}
            transition={Slide}
            hideProgressBar
          />
          <Router>
            <div className="App" style={{ height: '100%', boxSizing: 'border-box' }}>
              <Switch>
                <PrivateRoute exact path="/" render={routerProps => <SelectAccount {...routerProps} user={user} accounts={userAccounts} />} />
                <Route
                  path="/iam"
                  render={routerProps => <IamApp {...routerProps} engine={engine} />}
                />
                <PrivateRoute
                  path="/p/:accountId"
                  component={AccountRoot}
                />
                <Route path="/public/meerkat/:buildingId/units/:unitId" component={PublicUnitDetail} />
                <Route path="/mk/b/:buildingId/u/:unitId" component={PublicUnitDetail} />
                <Route component={NoMatch} />
              </Switch>
            </div>
          </Router>
        </MuiThemeProvider>
      </AuthenticatedContext.Provider>
    );
  }
})

const AppContainer = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

export default AppContainer;

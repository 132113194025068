import _ from "lodash";

const getMapDocDataToField = (customFieldData, id, data, medias) => {
  const keyOfMapDocDataToField = _.camelCase(id);
  const valueOfMapDocDataToField = {
    [keyOfMapDocDataToField]: {
      type: _.upperFirst(customFieldData.type),
      label: customFieldData.label,
      value: _.get(data, `fields.${id}`)
    }
  };
  switch (customFieldData.type) {
    case "checkbox":
    case "dropdown":
    case "radio":
      const options = _.map(customFieldData.option, o => {
        return {
          value: o.value,
          label: o.label
        };
      });
      _.set(
        valueOfMapDocDataToField,
        [keyOfMapDocDataToField, "options"],
        options
      );
      break;
    case "gallery":
    case "image":
      const mediaOptions = _.map(medias, (media, key) => ({
        value: key,
        label: key,
        url: media.url
      }));
      _.set(
        valueOfMapDocDataToField,
        [keyOfMapDocDataToField, "options"],
        mediaOptions
      );
      break;
    default:
      break;
  }
  return valueOfMapDocDataToField;
};

export const getMapDocDataToFields = (docDataData, otherData) => {
  const { medias } = otherData;
  const customFields = _.get(docDataData, ["customFeild"]);
  const mapDocDataToFields = _.reduce(
    customFields,
    (result, value, key) => {
      let mapDocDataToField = {};
      if (value.languageEnable) {
        mapDocDataToField = _.reduce(
          otherData.languages,
          (acc, lang) => {
            const cloneValue = _.cloneDeep(value);
            _.set(cloneValue, "label", `${value.label} (${lang})`);
            return {
              ...acc,
              ...getMapDocDataToField(
                cloneValue,
                `${key}.${lang}`,
                otherData.data,
                medias
              )
            };
          },
          {}
        );
      } else {
        mapDocDataToField = getMapDocDataToField(value, key, otherData.data, medias);
      }
      return { ...result, ...mapDocDataToField };
    },
    {}
  );
  return mapDocDataToFields;
};

export const getMapFieldValuesToDocData = (docDataData, otherData) => {
  // name: nameValues,
  const customFields = _.get(docDataData, ["customFeild"]);
  const { fieldValues, languages } = otherData;
  const mapFieldValuesToDocData = _.reduce(
    customFields,
    (result, value, key) => {
      let mapFieldValueToDocData = {};
      if (value.languageEnable) {
        _.forEach(languages, lang => {
          _.set(
            mapFieldValueToDocData,
            [_.camelCase(key), lang],
            _.get(fieldValues, [_.camelCase(`${key}.${lang}`)])
          );
        });
      } else {
        _.set(
          mapFieldValueToDocData,
          [_.camelCase(key)],
          _.get(fieldValues, [_.camelCase(key)])
        );
      }
      return { ...result, ...mapFieldValueToDocData };
    },
    {}
  );
  return mapFieldValuesToDocData;
};

export const getSections = (docDataData, otherData) => {
  const customFields = _.get(docDataData, ["customFeild"]);
  const { languages } = otherData;

  const sections = _.chain(customFields)
    .map((value, key) => {
      return { ...value, id: key };
    })
    .orderBy(["order"])
    .map(value => {
      if (value.languageEnable) {
        return _.map(languages, lang => {
          return _.camelCase(`${value.id}.${lang}`);
        });
      }
      return _.camelCase(value.id);
    })
    .flatten()
    .value();
  if (_.size(customFields) > 0) {
    return [
      {
        title: "Other",
        fields: sections
      }
    ];
  }
  return [];
};

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Route, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import AppsIcon from '@material-ui/icons/ArrowBack';

import Icon from '@material-ui/core/Icon';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Drawer from '@material-ui/core/Drawer';

import SpaceDashboard from './SpaceDashboard';
import EntryList from '../EntryList';
import EntryDetail from '../EntryDetail';
import EntryPreview from '../EntryPreview';
import ModelList from '../ModelList';
import ModelDetail from '../ModelDetail';
import MediaScreen from '../MediaScreen';

import UserManagement from '../../../iam/screens/UserManagementScreen';
import ApiKeyManagement from '../../../iam/screens/ApiKeyManagementScreen';
// import UserManagement from '../UserManagement';

import UserManagementIcon from '@material-ui/icons/People';
import ModelIcon from '@material-ui/icons/Ballot';
import ApiKeyIcon from '@material-ui/icons/VpnKey';

import { db } from '../../engine';

import { primaryColor } from '../../theme';

const logo = require('../../firemink-logo.png');

const SpaceContext = React.createContext();

const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 60px);
  background: #fafafa;
  box-sizing: border-box;
  display: flex;
`;

const BrandHeader = styled.div`
  background: transparent;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
`;

const Sidebar = styled.div`
  flex: 0 0 240px;
  border-right: 1px solid #dadada;
  height: 100%;
  background: #001529;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Content = styled.div`
  flex-grow: 1;
  box-sizing: border-box;
  background: white;
  height: 100%;
  overflow: scroll;
`;

// Override default MaterialUI
const StyledListSubheader = styled(ListSubheader)`
  &&& {
    color: white;
    opacity: 0.5;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &&& {
    color: white;
    span { color: white; }
    svg {
      fill: white;
    }
    &:hover {
      color: ${primaryColor};
      span {
        color: ${primaryColor};
      }
      svg {
        fill: ${primaryColor};
      }
    }

    &.active {
      background: ${primaryColor};
      color: black;
      span { color: black; }
      svg {
        fill: black;
      }
    }

    ${props => props.selected && css`
      background: ${primaryColor};
      color: black;
      span { color: black; }
      svg {
        fill: black;
      }
    `}
  }
`;

const mapStateToProps = () => {
  return {};
}

export default connect(mapStateToProps)(class extends React.Component {
  state = {
    space: {},
    models: [], 
  }
  componentDidMount() {
    this.fetchMedias(this.props);
    const { spaceId } = _.get(this.props, 'match.params');
    db.collection('cic_spaces')
      .doc(spaceId)
      .get()
      .then(spaceDoc => {
        this.setState({ space: spaceDoc.data() });
      });

    this.modelUnsubscribe = db.collection('cic_spaces')
      .doc(spaceId)
      .collection('models')
      .onSnapshot(querySnapshot => {
        this.setState({
          models: _.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() })),
        })
      });
  }
  componentWillUnmount() {
    this.modelUnsubscribe();
    this.mediaUnsubscrible();
  }
  fetchMedias = (props) => {
    const { dispatch } = props;
    const { spaceId } = _.get(props, 'match.params');
    this.mediaUnsubscrible = db.collection('cic_spaces').doc(spaceId).collection('medias').onSnapshot(querySnapshot => {
      const data = _.map(querySnapshot.docs, doc => {
        return { id: doc.id, ...doc.data() };
      })
      const orderedData = _.orderBy(data, ['metadata.updated'], ['desc']);
      dispatch({
        type: 'PROJECT/MEDIAS',
        data: orderedData,
        spaceId,
      });
    });
  }
  render() {
    const { match, history, account } = this.props;
    const accountId = account.id;

    const { spaceId } = _.get(this.props, 'match.params');
    const { space, models } = this.state;
    return (
      <SpaceContext.Provider spaceId={spaceId}>
        <Wrapper>
          <Sidebar>
            <BrandHeader>
              <img src={logo} alt="Firemink Logo" />
            </BrandHeader>
            <MenuList>
              <StyledMenuItem exact button component={NavLink} to={_.replace(match.url, `/spaces/${spaceId}`, '')}>
                <ListItemIcon><AppsIcon /></ListItemIcon>
                <ListItemText disableTypography inset>All spaces</ListItemText>
              </StyledMenuItem>
              <StyledMenuItem exact button component={NavLink} to={match.url}>
                <ListItemIcon><Icon>home</Icon></ListItemIcon>
                <ListItemText disableTypography inset>Home</ListItemText>
              </StyledMenuItem>
            </MenuList>
            <MenuList subheader={<StyledListSubheader component="div">Content</StyledListSubheader>}>
              {_.map(models, (model, modelIndex) => {
                return (
                  <StyledMenuItem button component={NavLink} key={`menu-model-${model.id}-${modelIndex}`} to={`${match.url}/entries/${model.id}`}>
                    {model.icon && <ListItemIcon><Icon>{model.icon}</Icon></ListItemIcon>}
                    <ListItemText disableTypography inset>{model.title}</ListItemText>
                  </StyledMenuItem>
                );
              })}
              <StyledMenuItem button component={NavLink} key={`menu-model-medias`} to={`${match.url}/medias`}>
                <ListItemIcon><Icon>photo</Icon></ListItemIcon>
                <ListItemText disableTypography inset>Medias</ListItemText>
              </StyledMenuItem>
            </MenuList>
            <MenuList subheader={<StyledListSubheader component="div">Admin</StyledListSubheader>}>
              <StyledMenuItem button component={NavLink} key={'admin/models'} to={`${match.url}/models`}>
                <ListItemIcon><ModelIcon /></ListItemIcon>
                <ListItemText disableTypography inset>Content Models</ListItemText>
              </StyledMenuItem>
              <StyledMenuItem button component={NavLink} key={'admin/apikeys'} to={`${match.url}/apikeys`}>
                <ListItemIcon><ApiKeyIcon /></ListItemIcon>
                <ListItemText disableTypography inset>API Keys</ListItemText>
              </StyledMenuItem>
              <StyledMenuItem button component={NavLink} key={'admin/users'} to={`${match.url}/users`}>
                <ListItemIcon><UserManagementIcon /></ListItemIcon>
                <ListItemText disableTypography inset>User Management</ListItemText>
              </StyledMenuItem>
            </MenuList>
          </Sidebar>
          <Content>
            <Route
              path={match.url}
              exact
              render={routerProps => <SpaceDashboard {...routerProps} space={space} spaceId={spaceId} />} />
            <Route
              exact
              path={`${match.url}/entries/:modelId`}
              render={routerProps => {
                const modelId = _.get(routerProps, 'match.params.modelId');
                return <EntryList {...routerProps} spaceId={spaceId} model={_.find(models, model => model.id === modelId)} />
              }}
            />
            {/* 
            <Route path={`${match.url}/entries/:modelId/:entryId`} children={({ match, ...rest }) => {
              const entryId = _.get(match, 'params.entryId');
              const modelId = _.get(match, 'params.modelId');
              const model = _.find(models, model => model.id === modelId);
              return (
                <Drawer anchor="right" open={entryId !== undefined} onClose={() => rest.history.goBack()}>
                  <EntryDetail {...rest} spaceId={spaceId} entryId={entryId} model={model} />
                </Drawer>
              );
            }} />
            */}
            <Route
              exact
              path={`${match.url}/entries/:modelId/:entryId`}
              render={routerProps => {
                const subMatch = routerProps.match;
                const entryId = _.get(subMatch, 'params.entryId');
                const modelId = _.get(subMatch, 'params.modelId');
                const model = _.find(models, model => model.id === modelId);
                return (
                  <EntryDetail
                    {...routerProps}
                    onDelete={() => history.push(`${match.url}/entries/${modelId}`)}
                    onCancel={() => history.push(`${match.url}/entries/${modelId}`)}
                    spaceId={spaceId}
                    entryId={entryId}
                    model={model}
                  />);
              }}
            />
            <Route
              path={`${match.url}/entries/:modelId/:entryId/preview`}
              render={routerProps => {
                const { match } = routerProps;
                const entryId = _.get(match, 'params.entryId');
                const modelId = _.get(match, 'params.modelId');
                const model = _.find(models, model => model.id === modelId);
                return (<EntryPreview {...routerProps} spaceId={spaceId} entryId={entryId} model={model} />);
              }}
            />
            {/*
            <Route
              path={`${match.url}/models/:modelId`}
              children={({ match, ...rest }) => {
                const modelId = _.get(match, 'params.modelId');
                const model = _.find(models, model => model.id === modelId);
                return (
                  <Drawer anchor="right" open={modelId !== undefined} onClose={() => rest.history.goBack()}>
                    <ModelDetail {...rest} spaceId={spaceId} model={model} />
                  </Drawer>
                );
              }}
            />*/}
            <Route
              path={`${match.url}/models`}
              exact
              render={routerProps => {
                return (
                 <ModelList {...routerProps} spaceId={spaceId} models={models} />
                );
              }}
            />
            <Route
              path={`${match.url}/models/:modelId`}
              render={routerProps => {
                const modelId = _.get(routerProps, 'match.params.modelId');
                const model = _.find(models, model => model.id === modelId);
                return (
                 <ModelDetail {...routerProps} spaceId={spaceId} model={model} />
                );
              }}
            />
            <Route
              path={`${match.url}/medias`}
              exact
              render={routerProps => {
                return (
                   <MediaScreen {...routerProps} spaceId={spaceId} />
                );
              }}
            />
            <Route
              path={`${match.url}/apikeys`}
              exact
              render={routerProps => {
                return (
                   <ApiKeyManagement {...routerProps} resourceName={`forviz:${accountId}:cic:${spaceId}`} />
                );
              }}
            />
            <Route
              path={`${match.url}/users`}
              exact
              render={routerProps => {
                return (
                   <UserManagement {...routerProps} resourceName={`forviz:${accountId}:cic:${spaceId}`} />
                );
              }}
            />
            {/* <Route
              path={`${match.url}/models/:modelId/:fieldId`}
              children={({ match, ...rest }) => {
                const modelId = _.get(match, 'params.modelId');
                const fieldId = _.get(match, 'params.fieldId');
                const model = _.find(models, model => model.id === modelId);
                const field = _.find(_.get(model, 'fields', []), field => field.id === fieldId);
                return (
                  <Drawer anchor="right" open={field !== undefined} onClose={() => rest.history.goBack()}>
                    <FieldDetail {...rest} spaceId={spaceId} model={model} field={field} />
                  </Drawer>
                );
              }}
            /> */}
          </Content>
        </Wrapper>
      </SpaceContext.Provider>
    );
  } 
})
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { notify } from 'react-notify-toast';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import InputField from '../../components/InputField';
import PageHeader from '../../../../components/PageHeader';

import ConfirmDialog from '../../../../components/ConfirmDialog';

import firebase from 'firebase/app'; 
import { db } from '../../engine';

const Section = styled.section`
  margin: 25px 0;
`;

const ActionBar = styled.div`
  box-sizing: border-box;
  padding: 20px;
  position: fixed;
  z-index: 2;
  right: 0;
  left: 240px;
  bottom: 0;
  background: #fafafa;
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: flex-end;
`;

export default class extends React.PureComponent {

  static defaultProps = {
    id: 'teemp',
    defaultLanguage: 'en',
    languages: ['en', 'th'],
  }

  state = {
    language: 'en',
    entry: null,
  }

  loadEntry = (entryId) => {
    const { spaceId } = this.props;
    if (entryId) {
      this.unsubscribeEntry = db.collection('cic_spaces').doc(spaceId).collection('entries')
        .doc(entryId)
        .onSnapshot(doc => {
          this.setState({
            entry: doc.data(),
          });
        });
    }
  }

  componentDidMount() {
    this.loadEntry(this.props.entryId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.entryId !== prevProps.entryId) {
      this.loadEntry(this.props.entryId);
    }
  }

  componentWillUnmount() {
    this.unsubscribeEntry();
  }

  save = async (e) => {
    const { entryId, spaceId } = this.props;
    e.preventDefault();
    const { entry } = this.state;
    // console.log('save', spaceId, entryId, entry);
    notify.show('Saving', 'success');
    await db.collection('cic_spaces').doc(spaceId).collection('entries').doc(entryId).set({
      ...entry,
      meta: {
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }
    }, { merge: true });
    notify.show('Entry Saved', 'success');

  }

  deleteDoc = () => {
    // console.log('delete Doc');
    const { entryId, spaceId, onDelete } = this.props;
    const docRef = db.collection('cic_spaces').doc(spaceId).collection('entries').doc(entryId);
    docRef.delete().then(() => {
      onDelete(docRef.id);
    });
  }


  handleInputChange = (name, eventOrValue) => {
    const value = _.get(eventOrValue, 'target.value', eventOrValue);
    // console.log('handleInputChange', name, value);
    this.setState({
      entry: {
        ...this.state.entry,
        fields: {
          ...this.state.entry.fields,
          [name]: value,
        }
      }
    })
  }


  renderField = (field) => {
    const { spaceId } = this.props;
    const { id, label } = field;
    const { entry, language } = this.state;
    const value = _.get(entry, `fields.${id}`);
    return (
      <div key={field.id} style={{ display: 'flex', padding: '6px 0', justifyContent: 'space-between', alignItems: 'center' }}>
        <InputField
          type={field.ui || 'TextField'}
          spaceId={spaceId}
          label={label}
          defaultLanguage={'en'}
          language={language}
          value={value}
          options={field.options}
          onChange={e => this.handleInputChange(id, e)}
        />
      </div>
    );
  }

  render() {
    const { model, languages, defaultLanguage, onCancel } = this.props;
    const { entry } = this.state;
    if (!entry || !model) return (<div />);

    // console.log('EntryDetail', model, entry);
    const fields = _.map(model.fields, field => {
      return {
        ...field,
        value: _.get(entry, `fields.${field.id}`),
      }
    });

    const titleFieldId = model.titleField || 'id';
    const titleField = _.find(fields, field => field.id === titleFieldId);

    return (
      <div>
        <PageHeader
          title={`${_.get(model, 'title', '')} / ${_.get(titleField, `value.${defaultLanguage}`, 'No Title')}`}
          renderHeaderRight={() => (
            <div style={{ display: 'flex' }}>
              {_.map(languages, lang => (
                <IconButton onClick={e => this.setState({ language: lang })}>
                  <img
                    src={`https://www.countryflags.io/${lang === 'en' ? 'gb' : lang}/flat/32.png`}
                    style={{ filter: this.state.language === lang ? 'grayscale(0%)' : 'grayscale(100%)'}}
                    alt="lang"
                  />
                </IconButton>
              ))}
            </div>
          )}
        />
        <Paper elevation={0} style={{ padding: '40px', boxSizing: 'border-box' }}>
          <Grid container>
            <Grid item sm={9}>
              <Section style={{ marginTop: 24 }}>
                <div>
                  {model && _.map(fields, (field) => this.renderField(field))}
                </div>
              </Section>
            </Grid>
            <Grid item sm={3}></Grid>
          </Grid>
        </Paper>
        <ActionBar>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <ConfirmDialog
              title="Delete Document"
              content="This will permanently delete this document, are you sure?"
              onOk={this.deleteDoc}
              render={() => <Button style={{ color: '#ff1744' }}>Delete</Button>}
            />
            <div style={{ display: 'flex' }}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button color="primary" onClick={this.save}>Save</Button>
            </div>
          </div>
        </ActionBar>
      </div>
    );
  }
}
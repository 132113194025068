import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import SwipeableViews from 'react-swipeable-views';

import CloseIcon from '@material-ui/icons/Close';

import {
  fetchUnits,
  fetchSales,
  reserveUnit,
  /*updateUnitStatus,*/
  cancelReserveUnit,
  updateUnit
} from '../../api';

// const notice = (msg, type = 'success', time = 5000) => {
//   notify.show(msg, type, time);
// }

// const showLoading = (msg, type = 'warning') => {
//   notify.show(msg, type, -1);
// }

// const hideLoading = () => {
//   notify.hide();
// }

import { notify } from 'react-notify-toast';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CheckIcon from '@material-ui/icons/Check';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import db from '../../engine/db';

import PageHeader from '../../../../components/PageHeader';


const notice = (msg, type = 'success', time = 5000) => {
  notify.show(msg, type, time);
}

const showLoading = (msg, type = 'warning') => {
  notify.show(msg, type, -1);
}

const hideLoading = () => {
  notify.hide();
}

const getUnitBackgroundColor = (status) => {
  switch (status) {
    case 'S': return '#EF5350';
    case 'R': return '#d09910';
    case 'L': return '#9E9E9E';
    default: return '#e5e5e5';
  }
}
const getUnitTextColor = (status) => {
  switch (status) {
    case 'S':
    case 'R':
      return '#fff';
    default: return '#000';
  }
}

const Container = styled.div`
  box-sizing: border-box;
  position: relative;

  &::before {
    content: '';
    height: 100%;
    width: 10px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    box-shadow: inset 2px 0 2px rgba(0, 0, 0, 0.1);
  }
`;
const MatrixContainer = styled.div`
  padding-top: 40px;
  box-sizing: border-box;
  overflow: scroll;
`;

const BuildingContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const FloorContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const UnitItem = styled.button`
  border: none;
  outline: none;
  height: 50px;
  background: ${props => getUnitBackgroundColor(props.status)};
  color: ${props => getUnitTextColor(props.status)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;
  flex: 0 0 80px;
  font-size: 16px;
  cursor: pointer;
  transition: all .4s;
  position: relative;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  }
`;

const ReleaseAtBox = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 9px;
  font-weight: normal;
  color: white;
  width: 100%;
  left: 0;
`;

export default class extends React.Component {
  state = {
    selectedUnit: null,
    open: false,
    salePopupOpen: false,
    selectedSaleId: null,
    value: 0,
    sales: [],
    units: [],
    show: notify.createShowQueue(),
    loaded: false,
  }

  anchorEl = null;

  async componentDidMount() {
    const { user } = this.props;
    const buildingId = _.get(this.props, 'match.params.buildingId');

    const buildingDoc = await db.collection('meerkat_projects').doc(buildingId).get();
    const buildingData = buildingDoc.data();

    this.setState({
      building: buildingData,
    });
    const unitSource = _.get(buildingData, ['setting', 'unit.source']);

    // console.log('componentDidMount', {
    //   buildingId,
    //   buildingData,
    //   unitSource
    // })

    switch (unitSource) {
      case 'cic':
        const spaceId = _.get(buildingData, ['setting', 'unit.source.spaceId']);
        Promise.all([
          fetchUnits(user)(spaceId),
          fetchSales(user)(spaceId)
        ]).then((response) => {
            const [units, sales] = response
            console.log('componentDidMount', response)
            this.setState({ units, sales });
          })
          .catch(error => {
            console.log('error', error);
          })
          .finally(() => {
            this.setState({ loaded: true });
          })
        break;
      case 'meerkat':
        const sales = []
        const units = []
        const projectId = _.get(buildingData, ['setting', 'unit.source.spaceId']);
        try {
          const salesSnapshot = await db.collection('meerkat_projects').doc(projectId).collection('sales').get();
          salesSnapshot.docs.forEach(doc => {
            sales.push(doc.data());
          })
          const unitsSnapshot = await db.collection('meerkat_projects').doc(projectId).collection('units').get();
          unitsSnapshot.docs.forEach(doc => {
            const data = doc.data()
            // if (doc.id === '1001') {
            //   db.collection('meerkat_projects').doc(projectId).collection('units').doc(doc.id).set({
            //     fields: {
            //       area: 58.96,
            //       building: "A",
            //       direction: "",
            //       floor: 10,
            //       id: "1001",
            //       instalment: "",
            //       mirror: "",
            //       no: 1,
            //       phase: "",
            //       price: NaN,
            //       'price-booking': "",
            //       'price-booking-contract': "",
            //       'price-contract': "",
            //       'price-facility-sqm': "",
            //       'price-fund': "",
            //       'price-per-sqm': NaN,
            //       'room-code': "",
            //       'room-no': "1001",
            //       'room-type': "B1",
            //       'root-type': "",
            //       status: "S",
            //       type: "2B1",
            //       view: "-79"
            //     },
            //     id: "1001",
            //     model: "unit",
            //     status: "A"
            //   })
            // }
            // db.collection('meerkat_projects').doc(projectId).collection('units').doc(doc.id).set({
            //   fields: {
            //     ...data.fields,
            //     building: 'A'
            //   },
            // }, { merge: true })
            units.push(data);
          })
          console.log('units', units)
          // console.log('componentDidMount', sales, units)
          this.setState({ units, sales });
        } catch (error) {
          console.log('error', error);
        }
        this.setState({ loaded: true });
        break;
      default:
        break;
    }
  }

  getProjectId = () => {
    const { building } = this.state
    const projectId = _.get(building, ['setting', 'unit.source.spaceId']);
    return projectId
  }

  getUnitSource = () => {
    const { building } = this.state;
    const unitSource = _.get(building, ['setting', 'unit.source']);
    return unitSource
  }

  handleChange = (event, value) => {
    this.setState({ value });
  }

  handleChangeIndex = index => {
    this.setState({ value: index });
  }

  handleCloseSaleSelectionPopup = () => {
    this.setState({
      salePopupOpen: false,
    });
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  }

  handleClickUnit = (e, unit) => {
    e.preventDefault();
    this.anchorEl = e.currentTarget;
    this.setState({
      open: true,
      selectedUnit: unit
    })
  }

  handleSelectSale = (e) => {
    this.setState({
      selectedSaleId: e.target.value
    })
  }

  handleConfirmReserveUnit = () => {
    const buildingId = _.get(this.props, 'match.params.buildingId');
    // const spaceId = _.get(this.state.building, ['setting', 'unit.source.spaceId']);

    const { units, selectedUnit, selectedSaleId } = this.state;
    const { user } = this.props;
    const status = 'R'
    showLoading(`Saving Please wait...`);
    this.setState({
      selectedUnit: null,
      salePopupOpen: false
    })
    reserveUnit(user)({
      // spaceId,
      projectId: buildingId,
      unitId: selectedUnit.id,
      saleId: selectedSaleId,
    }).then((responseData) => {
      hideLoading()
      notice(`Unit ${selectedUnit.id} status updated to ${status}`, 'success', 5000)
      this.setState({
        units: _.map(units, (unit) => {
          if (unit.id === selectedUnit.id) {
            return {
              ...unit,
              fields: {
                ...unit.fields,
                reserveId: responseData.reserveId,
                reservedAt: '',
                reservedBy: selectedSaleId,
                status: 'R'
              }
            }
          }
          return unit
        })
      })
    })
  }

  handleCancelReserveUnit = () => {
    const { units, selectedUnit } = this.state;
    const { user } = this.props;
    const buildingId = _.get(this.props, 'match.params.buildingId');
    const status = 'A'
    showLoading(`Saving Please wait...`);
    this.setState({
      selectedUnit: null
    })
    cancelReserveUnit(user)({
      projectId: buildingId,
      unitId: selectedUnit.id,
      reserveId: _.get(selectedUnit, 'fields.reserveId', '')
    }).then(() => {
      hideLoading()
      notice(`Unit ${selectedUnit.id} cancel reservation success and status = ${status}`, 'success', 5000)
      this.setState({
        units: _.map(units, (unit) => {
          if (unit.id === selectedUnit.id) {
            return {
              ...unit,
              fields: {
                ...unit.fields,
                reserveId: '',
                reservedAt: '',
                reservedBy: '',
                status
              }
            }
          }
          return unit
        })
      })
    })
  }

  handleUpdateUnitStatus = async (status) => {
    const spaceId = _.get(this.state.building, ['setting', 'unit.source.spaceId']);
    const { units, selectedUnit } = this.state;
    const { user } = this.props;
    const unitSource = this.getUnitSource()

    this.state.show(`Saving Please wait...`, 'warning', 2500);
    if (unitSource === 'cic') {
      await updateUnit(user)({
        spaceId,
        unitId: selectedUnit.id,
        dataToUpdate: {
          status
        }
      })
    } else if (unitSource === 'meerkat') {
      await db.collection('meerkat_projects').doc(this.getProjectId()).collection('units').doc(selectedUnit.id).set({
        status,
      }, { merge: true })
    }
    hideLoading()
    notice(`Unit ${selectedUnit.id} status updated to ${status}`, 'success', 5000)
    this.setState({
      units: _.map(units, (unit) => {
        if (unit.id === selectedUnit.id) {
          return {
            ...unit,
            fields: {
              ...unit.fields,
              status
            }
          }
        }
        return unit
      }),
      selectedUnit: null
    })
  }

  handleSelectUnitStatus = (status) => {
    // const { units, selectedUnit } = this.state;
    // const { user } = this.props;
    if (status === 'R') {
      // popup sales selection for admin
      this.setState({
        salePopupOpen: true
      })
    } else {
      // fire update
      this.handleUpdateUnitStatus(status)
    }
  }

  renderStatusSelectionPopup = () => {
    const { sales, open, selectedUnit } = this.state
    if (selectedUnit === null) return
    const baseStatusOptions = [
      {
        label: 'Available',
        value: 'A'
      },
      {
        label: 'Sold',
        value: 'S'
      },
      {
        label: 'Lock',
        value: 'L'
      },{
        label: 'Reserve',
        value: 'R'
      }
    ];
    const isReserved = selectedUnit.fields.status === 'R'
    const statusOptions = isReserved
    ? _.filter(baseStatusOptions, option => option.value === 'R')
    : baseStatusOptions
    const title = isReserved
    ? _.get(_.find(sales, sale => sale.id === _.get(selectedUnit, 'fields.reservedBy', '')), 'fields.nick-name', 'Anonymous')
    : 'Info'
    return (
      <Menu
        open={open}
        anchorEl={this.anchorEl}
        onClose={this.handleClose}
      >
        <MenuItem
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {title} {isReserved && <CloseIcon onClick={this.handleCancelReserveUnit} color="red" size="18" />}
        </MenuItem>
        <Divider />
        <ListSubheader>Unit Status</ListSubheader>
        <List dense>
          {
            _.map(statusOptions, option => {
              return (
                <ListItem button onClick={() => !isReserved && this.handleSelectUnitStatus(option.value)}>
                  {
                    option.value === selectedUnit.fields.status &&
                    <ListItemIcon>
                      <CheckIcon />
                    </ListItemIcon>
                  }
                  <ListItemText inset primary={option.label} />
                </ListItem>
              )
            })
          }
        </List>
      </Menu>
    )
  }

  renderSaleSelectionPopup = () => {
    const { salePopupOpen, selectedSaleId, sales } = this.state
    return (
      <Menu
        open={salePopupOpen}
        anchorEl={this.anchorEl}
        onClose={this.handleCloseSaleSelectionPopup}
      >
        <MenuItem>
          <ListSubheader>Sale</ListSubheader>
          <Divider />
          <FormControl style={{ minWidth: 200, margin: '0 15px' }}>
            <Select
              value={selectedSaleId}
              onChange={this.handleSelectSale}
              inputProps={{
                name: 'sale',
                id: 'sale-selection',
              }}
            >
              {
                _.map(sales, sale => <MenuItem value={sale.id}>{_.get(sale, 'fields.nick-name', '')}</MenuItem>)
              }
            </Select>
            <FormHelperText>Please select sale</FormHelperText>
          </FormControl>
          <Button onClick={this.handleConfirmReserveUnit} variant="contained" color="primary">
            Save
          </Button>
        </MenuItem>
      </Menu>
    )
  }
  render() {

    const { loaded, units } = this.state;
    if (!loaded) return (<LinearProgress />);

    const groupByBuildings =  _.groupBy(units, unit => _.get(unit, 'fields.building'));
    // pick building key
    // const selectedBuilding = _.pick(groupByBuildings, ['A', 'B', 'C'])
    const selectedBuilding = groupByBuildings;
    const result = _.map(selectedBuilding, (buildingUnits, building) => {
      return {
        building,
        floors: _.groupBy(buildingUnits, unit => _.get(unit, 'fields.floor')),
      }
    });
    const soldUnits = _.filter(units, unit => _.get(unit, 'fields.status') === 'S');
    return (
      <Container>
        <PageHeader
          title="Dashboard"
        />
        <div style={{ margin: 15 }}>
          <p>Sold Progress {_.size(soldUnits)} / {_.size(units)}</p>
          <LinearProgress variant="determinate" value={100 * (_.size(soldUnits) / _.size(units))} />
        </div>
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          {
            _.map(result, ({ building, floors }) => <Tab key={building} label={`Building ${building}`} />)
          }
        </Tabs>
        <MatrixContainer>
          <SwipeableViews
            axis={'x'}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
          >
            {_.map(result, ({ building, floors }) => {
              return (
                <BuildingContainer key={building}>
                  {_.map(floors, (units, floor) =>
                    <FloorContainer key={floor}>
                      {_.map(units, unit =>
                        <UnitItem
                          key={_.get(unit, 'id')}
                          status={_.get(unit, 'fields.status')}
                          onClick={(e) => this.handleClickUnit(e, unit)}
                        >
                          {_.get(unit, 'id')}
                          <ReleaseAtBox>{_.get(unit, 'fields.releaseAt') && moment(_.get(unit, 'fields.releaseAt'), 'x').fromNow()}</ReleaseAtBox>
                        </UnitItem>
                      )}
                    </FloorContainer>
                  )}
                </BuildingContainer>
              );
            })}
          </SwipeableViews>
        </MatrixContainer>
        {this.renderStatusSelectionPopup()}
        {this.renderSaleSelectionPopup()}
      </Container>
    );
  }
}

import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#EB6323',
    },
    secondary: {
      main: '#333333',
    }
  },
  props: {
    MuiListItem: {
      activeClassName: 'active'
    }
  }
});
import React from 'react';
import { Route, Link } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import AddIcon from '@material-ui/icons/Add';

import CreateDocButton from '../../../../components/CreateDocButton';
import PageHeader from '../../../../components/PageHeader';

import FormSetting from '../FormSetting';
import FormSubmissions from '../FormSubmissions';

import { getFormByAccountId, createForm } from '../../api';
import { receivedForms } from '../../actions';

const mapStateToProps = state => {
  return {
    forms: state.forms,
  }
}
export default connect(mapStateToProps)(class extends React.Component {
  state = {
    forms: [],
  }

  componentDidMount() {
    const { dispatch, accountId } = this.props;
    // const accountId = 'default';
    getFormByAccountId(accountId)
      .then(forms => {
        // this.setState({ forms });
        dispatch(receivedForms(forms));
      });
  }

  handleCreate = (values) => {
    const { accountId, dispatch } = this.props;
    const { title } = values;

    const formData = {
      name: title,
      account_id: accountId,
    }
  
    createForm(formData).then((response) => {
      const form = _.head(response);
      const id = _.get(form, 'id');

      dispatch({ type: 'FORM/CREATE', id, form });
      const { match, history } = this.props;
      history.push(`${match.url}/${id}/setting`);
    });

  }


  render() {
    const { history, match } = this.props;
    const listScreenUrl = match.url;
    const { forms } = this.props;
    return (
      <div>
         <PageHeader
          title="Forms"
          renderFloatingActionButtons={() => (
            <CreateDocButton
              label="Form"
              renderButton={() => <Button variant="extendedFab" color="primary"><AddIcon /> Add form</Button>}
              onCreate={this.handleCreate}
            />
          )}
        />
        <Grid container spacing={16} justify="center" style={{ width: 1200, maxWidth: '100%', margin: '0 auto' }}>
          {_.map(forms, form => {
            const { id } = form;
            return (
              <Grid item sm={6} md={4} lg={3} key={id}>
                <Card>
                  <CardContent style={{ height: 140 }}>
                    <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>{id}</Typography>
                    <Typography variant="h5">{form.name}</Typography>
                  </CardContent>
                  <CardActions>
                    {/* <Button component={Link} to={`${match.url}/${id}`}>Edit</Button> */}
                    <Button component={Link} to={`${match.url}/${id}/setting`}>Setting</Button>
                    <Button component={Link} to={`${match.url}/${id}/submissions`}>Submissions</Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Route
          exact
          path={`${match.url}/:id/setting`}
          children={({ match, ...rest }) => {
            const id = _.get(match, 'params.id');
            const form = _.find(forms, form => _.toString(form.id) === _.toString(id));
            const open = form !== undefined;
            return (
              <Drawer anchor="right" open={open} onClose={history.goBack}>
                <FormSetting
                  accountId={this.props.accountId}
                  width="1200px"
                  {...rest}
                  id={id}
                  data={form}
                  onClose={() => {
                    history.push(listScreenUrl);
                  }}
                />
              </Drawer>
            );
          }}
        />
        <Route
          exact
          path={`${match.url}/:id/submissions`}
          children={({ match, ...rest }) => {
            const id = _.get(match, 'params.id');
            const form = _.find(forms, form => _.toString(form.id) === _.toString(id));
            const open = form !== undefined;
            return (
              <Drawer anchor="right" open={open} onClose={history.goBack}>
                <FormSubmissions width="800px" id={id} />
              </Drawer>
            );
          }}
        />
      </div>
    );
  }
});

import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Input from '@material-ui/core/Input';
import NativeSelect from '@material-ui/core/NativeSelect';

import TableCell from '@material-ui/core/TableCell';

const ValueWrapper = styled.div`
  ${props => props.disabled ? css` color: #999; ` : ``}
`;

export default class extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    submitOnBlur: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    submitOnBlur: false,
  }

  state = {
    edit: false,
  }
  enterEditMode = () => {
    this.setState({ edit: true });
  }

  submit = (value) => {
    this.setState({ edit: false });
    this.props.onFieldChange(value, this.props.name, this.props.rowId);
  }

  handleKeyPress = (e) => {
    console.log('keyPress', e.key);
    if (e.key === 'Enter') {
      this.submit(this.input.value);
    }
  }

  handleBlur = (e) => {
    if (this.props.submitOnBlur) {
      this.submit(this.input.value);
    } else {
      this.setState({ edit: false });
    }
  }

  handleSelectChange = (evt) => {
    this.submit(evt.target.value);
  }
  renderEditMode = () => {
    const { value, type, options } = this.props;
    switch (type) {
      case 'select':
        return (
          <NativeSelect
            value={value}
            onChange={this.handleSelectChange}
          >
            {_.map(options, opt => <option key={opt.value || opt} value={opt.value || opt}>{opt.label || opt}</option>)}
          </NativeSelect>
        );
      case 'text':
      default:
        return (
          <Input type="text"
            style={{ width: 80 }}
            inputRef={c => { this.input = c; }}
            defaultValue={value}
            autoFocus
            onKeyPress={this.handleKeyPress}
            onBlur={this.handleBlur}
          />
        );
    }
    
  }
  renderValue = () => {
    const { value, disabled } = this.props;
    return (<ValueWrapper disabled={disabled}>{value}</ValueWrapper>);
  }
  render() {
    const { disabled } = this.props;
    const { edit } = this.state;
    const cellProps = (edit || disabled) ? {} : { onClick: this.enterEditMode };
    return (
      <TableCell {...cellProps}>
        {edit ? this.renderEditMode() : this.renderValue()}
      </TableCell>
    );
  }
}
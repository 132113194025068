import React, { useEffect } from "react";
import uuidv4 from "uuid/v4";
import { createClient } from "forviz-ombresjs";

const ImportData = (props) => {
  useEffect(() => {
    const { projectId } = props;

    const id = uuidv4();
    const client = createClient({ projectId });
    const docRef = client.getCategoryDocRef("id");
    console.log({ projectId, client, docRef });
    // docRef
    //   .set({
    //     fields: {
    //       name: {
    //         en: title
    //       }
    //     }
    //   })
    //   .then(() => {
    //     const { match, history } = this.props;
    //     history.push(`${match.url}/${id}`);
    //   });
  }, []);
  return <div>asdasd</div>;
};

export default ImportData;

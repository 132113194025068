import React from 'react';
import _ from 'lodash';
import PT from 'prop-types';

export default class extends React.Component {

  static propTypes = {
    name: PT.string,
    x: PT.number,
    y: PT.number,
    width: PT.number,
    height: PT.number,
    sort: PT.number,
    pressX: PT.number,
    pressY: PT.number,
    tempX: PT.number,
    tempY: PT.number,
    tempWidth: PT.number,
    tempHeight: PT.number,
  }

  static defaultProps = {
    name: 'box',
    x: 0,
    y: 0,
    width: 200,
    height: 200,
    sort: 1,
  }

  constructor(props) {
    super(props);
    const { x, y, width, height, name, sort } = props;
    this.state = {
      x,
      y,
      width,
      height,
      name,
      sort,
      pressX: 0,
      pressY: 0,
      tempX: 0,
      tempY: 0,
      tempWidth: 0,
      tempHeight: 0,
    };
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   // console.log('nextProps', nextProps)
  //   const { x, y, width, height, name, sort } = nextProps;
  //   return { x, y, width, height, name, sort }
  // }

  getParentDimensions = () => {
    const parentEl = this.ref.parentNode;
    const { width: parentWidth } = parentEl.getBoundingClientRect();
    const parentBox = parentEl.getBoundingClientRect();
    const viewBox = parentEl.getAttribute('viewBox');
    const viewBoxArr = _.split(viewBox, ' ', 4);
    const viewBoxWidth = _.get(viewBoxArr, 2, 0);
    const viewBoxHeight = _.get(viewBoxArr, 3, 0);
    const ratio = viewBoxWidth / parentWidth;

    return {
      viewBox: {
        width: _.parseInt(viewBoxWidth),
        height: _.parseInt(viewBoxHeight),
      },
      parentBox,
      ratio,
    };
  }

  handleMouseUp = (e) => {
    // console.log('mouseUp', e.clientX, e.clientY);
    const { x, y, width, height, pressX, pressY } = this.state;
    const { clientX, clientY } = e;
    const { id } = this.props;
    if (clientX === pressX && clientY === pressY) {
      // console.log('handleClick')
      // this.props.onOpenMediaPopup(true)
      // call field for insert name shelve
    }
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('touchend', this.handleMouseUp);
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('touchmove', this.handleTouchMove);
    this.props.onUpdate({ id, x, y, width, height });
  }

  handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    const { pressX, pressY, tempX, tempY, width, height } = this.state;
    const { ratio , viewBox } = this.getParentDimensions();
    const newX = ((clientX - pressX) * ratio) + tempX;
    const newY = ((clientY - pressY) * ratio) + tempY;
    const maxWidth = viewBox.width - width;
    const maxHeight = viewBox.height - height;

    this.setState({
      x: _.parseInt(_.clamp(newX, 0, maxWidth)),
      y: _.parseInt(_.clamp(newY, 0, maxHeight)),
    });
  }

  handleTouchMove = (e) => {
    e.preventDefault();
    this.handleMouseMove(e.touches[0]);
  }

  handleMouseDown = (e) => {
    // console.log('mousDown', e.clientX, e.clientY);
    const { x, y } = this.state;
    document.addEventListener('mouseup', this.handleMouseUp);
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('touchend', this.handleMouseUp);
    document.addEventListener('touchmove', this.handleTouchMove);
    this.setState({
      pressX: e.clientX,
      pressY: e.clientY,
      tempX: x,
      tempY: y,
    });
  }

  handleTouchStart = (e) => {
    this.handleMouseDown(e.touches[0]);
  }

  handleAnchorMouseUp = e => {
    document.removeEventListener('mouseup', this.handleAnchorMouseUp);
    document.removeEventListener('mousemove', this.handleAnchorMouseMove);
    // console.log('handleAnchorMouseUp');
    const { x, y, width, height } = this.state;
    const { id } = this.props;

    this.props.onUpdate({ id, x, y, width, height });
  };

  handleAnchorMouseMove = e => {
    try {

      const { clientX, clientY } = e
      const { x, y, pressX, pressY, tempWidth, tempHeight } = this.state;
      const { ratio , viewBox } = this.getParentDimensions();
      // console.log('viewBox', viewBox);
      // console.log('ratio', ratio);
      // console.log('e', e);
      // console.log('this.state', this.state);
      // console.log('clientX - pressX', clientX - pressX);

      const newWidth = ((clientX - pressX) * ratio) + tempWidth;
      const newHeight = ((clientY - pressY) * ratio) + tempHeight;
      const maxWidth = viewBox.width - x;
      const maxHeight = viewBox.height - y;
      // console.log('move', newX, newY, newWidth, newHeight);
      // console.log('max', maxWidth, maxHeight);
      // console.log('maxWidth', maxWidth);
      this.setState({
        width: _.parseInt(_.clamp(newWidth, 100, maxWidth)),
        height: _.parseInt(_.clamp(newHeight, 100, maxHeight)),
      });
    } catch (error) {
      console.log('handleAnchorMouseMove error : ', error);
    }
  };

  handleAnchorTouchMove = (e) => {
    e.preventDefault();
    this.handleMouseMove(e.touches[0]);
  };

  handleAnchorMouseDown = (e, i) => {
    document.addEventListener('mouseup', this.handleAnchorMouseUp);
    document.addEventListener('mousemove', this.handleAnchorMouseMove);
    const { width, height } = this.state;
    this.setState({
      pressX: e.clientX,
      pressY: e.clientY,
      tempWidth: width,
      tempHeight: height,
    });
  };

  handleAnchorTouchStart = e => this.handleAnchorMouseDown(e.touches[0]);

  render() {
    const { x, y, width, height } = this.state;
    return (
      <g
        ref={ref => (this.ref = ref)}
      >
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          onMouseDown={this.handleMouseDown}
          onTouchStart={this.handleTouchStart}
        />
        <rect
          x={x + width - 40}
          y={y + height - 40}
          width={40}
          height={40}
          fill='white'
          onMouseDown={this.handleAnchorMouseDown}
          onTouchStart={this.handleAnchorTouchStart}
        />
      </g>
    )
  }
}
import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import AddIcon from '@material-ui/icons/Add';

import CreateScreen from './create';
import DetailScreen from './detail';

import PageHeader from '../../../../components/PageHeader';
import Table from '../../../../components/Table';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const renderState = state => {
  switch (state) {
    case 'offline':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ display: 'inline-block', width: 8, height: 8, background: '#f5222d', borderRadius: 4, marginRight: 10 }} /> Offline
        </div>
      );
    case 'online':
    default:
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ display: 'inline-block', width: 8, height: 8, background: '#52c41a', borderRadius: 4, marginRight: 10 }} /> Online
        </div>
      );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { accountId } = ownProps;
  const devices = _.get(state, ['accounts', accountId, 'devices']);

  return {
    devices
  }
}

export default connect(mapStateToProps)(class extends React.Component {

  state = {
    showCreateModal: false,
  }

  handleCloseCreateModal = e => this.setState({ showCreateModal: false })

  handleSelectRow = (record, selected, selectedRows, e) => {
    const { match, history } = this.props;
    history.push(`${match.url}/${record.id}`)
  }

  handleCreate = (deviceData) => {
    const { client } = this.props;
    client.createDevice(deviceData);
    this.handleCloseCreateModal();
  }

  render() {

    const { match, history, client, accountId, devices } = this.props;
    const listScreenUrl = match.url;

    const dataSource = _.map(devices, device => {
      return {
        id: device.id,
        name: device.name,
        location: device.location,
        screenSize: device.screenSize,
        macaddress: '',
        ipaddress: '',
        state: 'online',
      };
    });

    return (
      <div>
        <div>
          <PageHeader
            title="Devices"
            renderFloatingActionButtons={() => (
              <Fab variant="extended" color="primary" onClick={e => this.setState({ showCreateModal: true })}><AddIcon /> Add a device</Fab> 
            )}
          />
          <Table
            columns={[
              { key: 'id', dataIndex: 'id', title: 'ID',
                render: (id, row) => (
                  <div>
                    <Typography>{row.name}</Typography>
                    <span style={{ opacity: 0.5 }}>{id}</span>
                  </div>
                )
              },
              { key: 'screen', dataIndex: 'screenSize', title: 'Screen size' },
              { key: 'state', dataIndex: 'state', title: 'State', render: renderState },
              { key: 'macaddress', dataIndex: 'macaddress', title: 'Mac Address' },
              { key: 'ipaddress', dataIndex: 'ipaddress', title: 'IP Address' },
            ]}
            dataSource={dataSource}
            onSelect={this.handleSelectRow}
          />
        </div>
         
        <Dialog
          open={this.state.showCreateModal}
          TransitionComponent={Transition}
          maxWidth="lg"
          keepMounted
          onClose={this.handleCloseCreateModal}
        >
          <DialogTitle>
            Create new Device
          </DialogTitle>
          <DialogContent>
            <CreateScreen accountId={accountId} onCreate={this.handleCreate} />
          </DialogContent>
        </Dialog>

        <Route
          path={`${match.url}/:deviceId`}
          children={({ match, ...rest }) => {
            const deviceId = _.get(match, 'params.deviceId');
            const deviceRef = !_.isEmpty(deviceId) ? client.getDeviceDocRef(deviceId) : undefined;
            const open = deviceRef !== undefined;
            return (
              <Drawer anchor="right" open={open} onClose={() => history.goBack()}>
                {deviceId === 'create'
                ? <CreateScreen />
                :
                  <DetailScreen
                    width="600px"
                    {...rest}
                    docRef={deviceRef}
                    onClose={() => {
                      history.push(listScreenUrl);
                    }}
                  />
                }
              </Drawer>
            );
          }}
        />
      </div>
    );
  }
});

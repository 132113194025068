import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import styled from "styled-components";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Route, Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";

import Dialog from "@material-ui/core/Dialog";

import CreateDocButton from "../../../../components/CreateDocButton";
import PageHeader from "../../../../components/PageHeader";
import FloorplanEditor from "../../../../components/FloorplanEditor";
import FirestoreDocEditor from "../../../../components/FirestoreDocEditor";

// import client from '../../engine';
import { createClient } from "forviz-ombresjs";

import DetailScreen from "./detail";

import FloorplateUnitImporter from "./FloorplateUnitImporter";
import FloorplateNodeImporter from "./FloorplateNodeImporter";

import { utility } from '../../../../lib'

const { permission } = utility;
const {
  getProjectRole,
  isProjectSuperAdmin,
  isProjectAdmin,
} = permission;

const greenTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      main: "#6fbf73"
    }
  }
});

const FloorCard = styled(Card)`
  display: flex;
  margin-bottom: 24px;

  .card-inner {
    display: flex;
    flex: 1;
    flex-direction: column;

    .card-content {
      padding: 8px 16px;
      flex: 1;
    }

    .action-bar {
      background: #f7f7f7;
      border-top: 1px solid #ececec;
      width: 100%;
      padding: 6px 12px;
    }
  }
`;

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;

  const medias = _.get(state, ["projects", projectId, "medias"]);
  const mediaOptions = _.chain(medias)
    .map((media, key) => ({ value: key, label: key, url: media.url }))
    .value();

  return {
    nodes: _.get(state, ["projects", projectId, "nodes"]),
    shops: _.get(state, ["projects", projectId, "shops"]),
    units: _.get(state, ["projects", projectId, "units"]),
    floors: _.get(state, ["projects", projectId, "floors"]),
    elements: _.get(state, ["projects", projectId, "elements"]),
    medias,
    mediaOptions
  };
};

export default connect(mapStateToProps)(
  class extends React.Component {
    state = {
      items: [],
      page: 0,
      rowsPerPage: 10,
      firstVisible: null,
      lastVisible: null
    };

    handleSelectRow = (record, selected, selectedRows, e) => {
      const { match, history } = this.props;
      history.push(`${match.url}/${record.id}`);
    };

    handleCreateFloor = values => {
      const { projectId } = this.props;

      const { title } = values;
      // const id = _.kebabCase(title);

      const client = createClient({ projectId });
      const docRef = client.getFloorDocRef(title);
      docRef
        .set({
          fields: {
            name: title
          },
          order: 0,
          sys: {
            id: title
          }
        })
        .then(() => {
          const { match, history } = this.props;
          history.push(`${match.url}/${title}`);
        });
    };

    handleCreateFloorElement = (floorId, values) => {
      const { history, match, projectId } = this.props;
      // console.log('createFloorElement', floorId, values);
      const { title } = values;
      const elementId = `${floorId}!${_.kebabCase(title)}`;
      const client = createClient({ projectId });

      client
        .getCollectionRef("elements")
        .doc(elementId)
        .set({
          id: elementId,
          title,
          fields: { ...values, floorId }
        })
        .then(() => {
          history.push(`${match.url}/${floorId}/editor/elements/${elementId}`);
        });
    };

    handleSelectFloorItem = (floorId, itemId, itemType) => {
      const { history, match } = this.props;
      history.push(`${match.url}/${floorId}/editor/elements/${itemId}`);
    };

    renderChip = (type, active) => {
      return (
        <Chip
          label={active ? `${type}` : `${type} not imported`}
          color={active ? "primary" : "default"}
          style={{
            marginLeft: 8,
            opacity: active ? 1 : 0.5
          }}
          variant={active ? "default" : "outlined"}
          onDelete={active ? console.log : undefined}
          deleteIcon={active ? <DoneIcon /> : undefined}
        />
      );
    };

    render() {
      const {
        user,
        match,
        history,
        accountId,
        projectId,
        medias,
        mediaOptions,
        floors,
        shops,
        units,
        nodes,
        elements
      } = this.props;
      const listScreenUrl = match.url;
      const client = createClient({ projectId });
      const userProjectRole = getProjectRole(user, accountId, 'ombres', projectId)
      const isSuperAdmin = isProjectSuperAdmin(userProjectRole)
      const isAdmin = isProjectAdmin(userProjectRole)

      return (
        <div>
          <PageHeader
            title="Floors"
            renderFloatingActionButtons={() => (
              <CreateDocButton
                label="Floor"
                renderButton={() => isSuperAdmin ? (
                  <Button variant="contained" color="primary">
                    <AddIcon /> Add a floor
                  </Button>
                ) : <React.Fragment />}
                onCreate={this.handleCreateFloor}
              />
            )}
          />
          <div style={{ padding: 16 }}>
            {_.map(floors, (floor, floorId) => {
              const floorplanMediaId = _.get(floor, "fields.floorplan");
              const floorplan = _.get(medias, floorplanMediaId);
              return (
                <FloorCard key={floorId}>
                  {floorplan && (
                    <CardMedia
                      style={{ width: 200, height: 150 }}
                      image={floorplan.url}
                      title="Floor"
                    />
                  )}
                  <div className="card-inner">
                    <div className="card-content">
                      <Grid container spacing={16} alignItems="center">
                        <Grid item xs>
                          <Typography component="h5" variant="h5">
                            Floor {floorId}
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <MuiThemeProvider theme={greenTheme}>
                            {this.renderChip(
                              "Units",
                              _.get(floor, "fields.unitImported") !== undefined
                            )}
                            {this.renderChip(
                              "Nodes",
                              _.get(floor, "fields.nodeImported") !== undefined
                            )}
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="action-bar">
                     {isSuperAdmin && (
                       <React.Fragment>
                        <Button component={Link} to={`${match.url}/${floorId}`}>
                          Edit Info
                        </Button>
                        <Button
                          component={Link}
                          to={`${match.url}/${floorId}/units`}
                        >
                          Import Units
                        </Button>
                        <Button
                          component={Link}
                          to={`${match.url}/${floorId}/nodes`}
                        >
                          Import Nodes
                        </Button>
                      </React.Fragment>)}
                      <Button
                        component={Link}
                        color="primary"
                        to={`${match.url}/${floorId}/editor`}
                      >
                        Floorplan Editor
                      </Button>
                    </div>
                  </div>
                </FloorCard>
              );
            })}
          </div>
          <Route
            path={`${match.url}/:id`}
            exact
            children={({ match, ...rest }) => {
              const id = _.get(match, "params.id");
              const docRef = !_.isEmpty(id) ? client.getFloorDocRef(id) : null;
              const open = docRef !== null;
              const docCustomFieldRef = client.getCustomFieldDocRef("floor");
              return (
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={() => history.goBack()}
                >
                  <DetailScreen
                    width="400px"
                    {...rest}
                    idEditable={false}
                    mediaEngine="firebase"
                    mediaEngineConfig={{
                      storagePath: `ombres/projects/${projectId}/medias`,
                      firestorePath: `ombres_projects/${projectId}/medias`
                    }}
                    projectId={projectId}
                    docRef={docRef}
                    docCustomFieldRef={docCustomFieldRef}
                    onClose={() => {
                      history.push(listScreenUrl);
                    }}
                  />
                </Drawer>
              );
            }}
          />
          <Route
            path={`${match.url}/:id/units`}
            children={routerProps => {
              const id = _.get(routerProps, "match.params.id");
              const floorDocRef = id ? client.getFloorDocRef(id) : undefined;
              const projectDocRef = client.getProjectRef();
              if (floorDocRef) {
                return (
                  <FloorplateUnitImporter
                    open={id !== undefined}
                    docRef={floorDocRef}
                    projectDocRef={projectDocRef}
                    medias={medias}
                    onClose={() => history.push(`${match.url}`)}
                  />
                );
              }
              return null;
            }}
          />
          <Route
            path={`${match.url}/:id/nodes`}
            children={routerProps => {
              const id = _.get(routerProps, "match.params.id");
              const floorDocRef = id ? client.getFloorDocRef(id) : undefined;
              const projectDocRef = client.getProjectRef();
              if (floorDocRef) {
                return (
                  <FloorplateNodeImporter
                    open={id !== undefined}
                    docRef={floorDocRef}
                    projectDocRef={projectDocRef}
                    medias={medias}
                    onClose={() => history.push(`${match.url}`)}
                  />
                );
              }
              return null;
            }}
          />
          <Route
            path={`${match.url}/:id/editor`}
            children={routerProps => {
              const floorId = _.get(routerProps, "match.params.id");
              const floorDocRef = floorId
                ? client.getFloorDocRef(floorId)
                : undefined;
              const floor = _.get(floors, floorId);
              const floorNodes = _.filter(
                nodes,
                node => node.floorId === floorId
              );

              const floorUnits = _.filter(
                units,
                unit => unit.floorId === floorId
              );
              const floorUnitsWithShops = _.map(floorUnits, floorUnit => {
                // console.log('floorUnit', floorUnit);
                const _shop = _.find(shops, (shop, shopId) => {
                  return _.some(_.get(shop, "fields.units"), unit => {
                    // console.log(shop.id, unit.floorId, unit.unitNo);
                    return (
                      unit.floorId === floorId && unit.unitNo === floorUnit.id
                    );
                  });
                });
                return {
                  ...floorUnit,
                  elements: {
                    outline: _.get(floorUnit, "elements.outline"),
                    logo: {
                      ..._.get(floorUnit, "elements.logo"),
                      type: "image",
                      xlinkHref: _.get(
                        medias,
                        `${_.get(_shop, `fields.logo`)}.url`
                      )
                    }
                  }
                };
              });

              const floorElements = _.chain(elements)
                .filter(element => _.get(element, "fields.floorId") === floorId)
                .map(element => ({
                  type: "image",
                  id: element.id,
                  ...element.fields,
                  xlinkHref: _.get(medias, [
                    _.get(element, ["fields", "icon"]),
                    "url"
                  ])
                }))
                .value();

              // console.log('floorUnits', floorUnitsWithShops);
              return (
                <Dialog open={floorDocRef !== undefined} fullScreen>
                  <AppBar color="default" position="static">
                    <Toolbar>
                      <IconButton
                        color="inherit"
                        onClick={() => history.push(`${match.url}`)}
                        aria-label="Close"
                      >
                        <CloseIcon />
                      </IconButton>
                      <Typography variant="h6" color="inherit">
                        {floorId}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  {floorDocRef && (
                    <div
                      style={{
                        flex: 1,
                        background: "#333",
                        overflow: "hidden"
                      }}
                    >
                      <FloorplanEditor
                        id={floorId}
                        floorId={floorId}
                        client={client}
                        svgWidth={_.get(floor, "fields.svgWidth")}
                        svgHeight={_.get(floor, "fields.svgHeight")}
                        docRef={client.getFloorDocRef(floorId)}
                        background={_.get(medias, [
                          _.get(floor, "fields.floorplan"),
                          "url"
                        ])}
                        units={floorUnitsWithShops}
                        nodes={floorNodes}
                        elements={floorElements}
                        canCreateElement={isSuperAdmin}
                        onSelectItem={this.handleSelectFloorItem}
                        onCreate={this.handleCreateFloorElement}
                      />
                    </div>
                  )}
                </Dialog>
              );
            }}
          />
          <Route
            path={`${match.url}/:floorId/editor/elements/:elementId`}
            children={routerProps => {
              const floorId = _.get(routerProps, "match.params.floorId");
              const elementId = _.get(routerProps, "match.params.elementId");
              const elementDocRef = elementId
                ? client.getDocRef("elements", elementId)
                : undefined;

              return (
                <Drawer
                  open={elementDocRef !== undefined}
                  anchor="right"
                  onClose={() => history.push(`${match.url}/${floorId}/editor`)}
                >
                  <FirestoreDocEditor
                    width="300px"
                    mediaEngine="firebase"
                    mediaEngineConfig={{
                      storagePath: `ombres/projects/${projectId}`,
                      firestorePath: `ombres_projects/${projectId}/medias`
                    }}
                    docRef={elementDocRef}
                    mapDocDataToFields={(data, id) => ({
                      title: {
                        label: "Title",
                        value: _.get(data, "fields.title", id)
                      },
                      floorId: {
                        label: "FloorId",
                        value: _.get(data, "fields.floorId")
                      },
                      icon: {
                        label: "Icon",
                        type: "Image",
                        value: _.get(data, "fields.icon", ""),
                        options: mediaOptions
                      },
                      tags: {
                        label: "Tags",
                        value: _.join(_.get(data, "fields.tags", []))
                      },
                      x: { label: "X", value: _.get(data, "fields.x", 0) },
                      y: { label: "Y", value: _.get(data, "fields.y", 0) },
                      width: {
                        label: "Width",
                        value: _.get(data, "fields.width", 36.541)
                      },
                      height: {
                        label: "Height",
                        value: _.get(data, "fields.height", 48.025)
                      }
                    })}
                    mapFieldValuesToDocData={fieldValues => {
                      return {
                        fields: {
                          title: fieldValues.title,
                          floorId: fieldValues.floorId,
                          icon: fieldValues.icon,
                          x: fieldValues.x,
                          y: fieldValues.y,
                          width: fieldValues.width,
                          height: fieldValues.height,
                          tags: _.split(fieldValues.tags, ",")
                        }
                      };
                    }}
                    sections={[
                      {
                        title: "Info",
                        fields: ["title", "description", "icon"]
                      },
                      {
                        title: "Location",
                        fields: ["x", "y", "width", "height"]
                      },
                      {
                        title: "Taxonomy",
                        fields: ["tags"]
                      }
                    ]}
                    onClose={() =>
                      history.push(`${match.url}/${floorId}/editor`)
                    }
                  />
                </Drawer>
              );
            }}
          />
        </div>
      );
    }
  }
);

import React, { Fragment } from "react";
import _ from "lodash";
import styled from 'styled-components';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";

import InputField from '../index.js'

// import {
//   saveMediaToFirestore,
// } from 'forviz-mediajs';

const DEFAULT_UPLOAD_STORAGE_METHOD = 'default'

export default class AddMediaDialog extends React.Component {
  state = {
    items: [],
    prevFiles: null
  };

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.files, state.prevFiles)) {
      // console.log("getDerived", props.files);
      return {
        items: _.map(props.files, file => {
          const id = _.replace(_.get(file, "name"), /(.jpg|.png|.gif)/, "");
          return {
            id,
            title: id,
            preview: file.preview,
            tags: "",
            existingFile: file.existingFile,
            uploadStorageMethod: DEFAULT_UPLOAD_STORAGE_METHOD,
            file
          };
        }),
        prevFiles: props.files
      };
    }
    return null;
  }

  handleChange = (name, index, value) => {
    this.setState({
      items: _.map(this.state.items, (item, itemIndex) => {
        if (itemIndex === index) {
          return {
            ...item,
            [name]: value
          };
        }
        return item;
      })
    });
  };

  discardUploadFile = (index) => {
    const { items } = this.state
    const { onClose } = this.props
    const nextStateItems = _.filter(items, (item, itemIndex) => index !== itemIndex)
    if (_.size(nextStateItems) <= 0) onClose()
    this.setState({
      items: nextStateItems
    });
  }

  save = () => {
    const { items } = this.state;
    // console.log('save', buildingId, items);

    this.props.onSave(
      _.map(items, item => ({
        ...item,
        tags: _.map(_.split(item.tags, ","), _.trim)
      }))
    );
  };

  renderFields = (item, index) => {
    const { mediaTags } = this.props
    return (
      <Fragment>
        {/* <TextField
          margin="dense"
          id="id"
          label="ID"
          type="text"
          value={item.id}
          onChange={e => this.handleChange("id", index, e.target.value)}
          fullWidth
        /> */}
        <TextField
          margin="dense"
          id="title"
          label="Title"
          type="text"
          value={item.title}
          onChange={e =>
            this.handleChange("title", index, e.target.value)
          }
          fullWidth
        />
        <br />
        <br />
        <InputField
          type="AutocompleteMultiple"
          label="Tags"
          value={item.tags}
          suggestions={_.map(mediaTags, (mediaTag) => ({
            value: mediaTag,
            label: mediaTag
          }))}
          id="tags"
          onChange={(values) => this.handleChange('tags', index, values)}
          helperText="Separate by comma"
          fullWidth
        />
        {/* <TextField
          margin="dense"
          id="tags"
          label="Tags"
          type="text"
          value={item.tags}
          onChange={e => this.handleChange("tags", index, e.target.value)}
          helperText="Separate by comma"
          fullWidth
        /> */}
      </Fragment>
    )
  }

  renderMediaItems = () => {
    const { items } = this.state;
    return _.map(items, (item, index) => {
      if (_.isEmpty(item.existingFile)) return this.renderFreshMediaItem(item, index);
      return this.renderDuplicatedMediaItem(item, index);
    });
  };

  renderDuplicatedMediaItem = (item, index) => {
    return (
      <div key={`${item.id || _.uniqueId()}-${item.index}`}>
        <Grid container spacing={24}>
          <Grid item sm={6}>
            {item.uploadStorageMethod === DEFAULT_UPLOAD_STORAGE_METHOD
            ? (
              <Fragment>
                <img
                  style={{ maxWidth: "100%" }}
                  src={item.existingFile}
                  alt=""
                />
                <span>ภาพเก่า</span>
              </Fragment>
            ) : (
              <Fragment>
                <img style={{ maxWidth: "100%" }} src={item.preview} alt="" />
                <span>ภาพใหม่</span>
              </Fragment>
            )}
          </Grid>
          {item.uploadStorageMethod === DEFAULT_UPLOAD_STORAGE_METHOD ? (
            <Fragment>
              <Grid item sm={6}>
                <img
                  style={{ maxWidth: "100%" }}
                  src={item.preview}
                  alt=""
                />
                <span>ภาพใหม่</span>
              </Grid>
              <Grid item sm={12}>
                <ButtonGroup>
                  <div>
                    <Button
                      onClick={() => this.discardUploadFile(index)}
                      color="secondary"
                      variant="contained"
                    >
                      Discard
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => this.handleChange('uploadStorageMethod', index, 'keepBoth')}
                      color="primary"
                      variant="contained"
                    >
                      Keep Both
                    </Button>
                    <Button
                      onClick={() => this.handleChange('uploadStorageMethod', index, 'replace')}
                      color="primary"
                      variant="contained"
                    >
                      Replace
                    </Button>
                  </div>
                </ButtonGroup>
              </Grid>
            </Fragment>
          ) : (
            <Grid item sm={6}>
              {this.renderFields(item, index)}
            </Grid>
          )}
        </Grid>
        <Divider style={{ margin: "24px 0" }} />
      </div>
    );
  };

  renderFreshMediaItem = (item, index) => {
    return (
      <div key={`${item.id || _.uniqueId()}-${item.index}`}>
        <Grid container spacing={24}>
          <Grid item sm={6}>
            <img style={{ maxWidth: "100%" }} src={item.preview} alt="" />
          </Grid>
          <Grid item sm={6}>
            {this.renderFields(item, index)}
          </Grid>
        </Grid>
        <Divider style={{ margin: "24px 0" }} />
      </div>
    );
  };

  render() {
    const { open, onClose } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Media</DialogTitle>
        <DialogContent>
          <DialogContentText />
          {this.renderMediaItems()}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.save} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  div > button:not(:first-child) {
    margin-left: 10px;
  }
`

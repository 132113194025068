import React from 'react';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

export default class extends React.Component {
  state = {}
  render() {
    return (
      <div style={{ margin: '30px' }}>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <h1>App Explorer</h1>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Card style={{ maxWidth: 345 }}>
              <CardMedia
                style={{ height: 200 }}
                image="https://source.unsplash.com/400x200/?nature,water"
                title="Contemplative Reptile"
              />
              <CardContent>
                <h2>
                  Meerkat
                </h2>
                <p>
                  A property radar
                </p>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary">
                  Share
                </Button>
                <Button size="small" color="primary">
                  Go
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }
}
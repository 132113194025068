import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import PageHeader from '../../../../components/PageHeader';


import { getModelsRef } from '../../api/models';

export default class extends React.Component {
  state = {
    showCreateDialog: false,
    models: [],
  }

  componentDidMount() {
    const { spaceId } = this.props;
    const modelsRef = getModelsRef(spaceId);
    modelsRef.onSnapshot(querySnapshot => {
      this.setState({
        models: _.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() }))
      });
    });
  }

  render() {
    const { space, match } = this.props;
    const { models } = this.state;
    return (
      <div>
        <PageHeader
          title={space.name || 'Space Dashboard'}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(models, model => (
              <TableRow key={model.id}>
                <TableCell>{model.title}</TableCell>
                <TableCell>
                  <Button size="small" component={Link} to={`${match.url}/entries/${model.id}`}>Entries</Button> 
                  <Button size="small" component={Link} to={`${match.url}/models/${model.id}`}>Edit Model</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

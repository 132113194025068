import React from 'react';
import axios from 'axios';

export default class extends React.Component {
  componentDidMount() {
    axios.get('http://localhost:5000/forviz-2017/us-central1/climbr/positions')
      .then(res => {
        console.log('climbr', res.data);
      })
  }
  render() {
    return (<div>Climbr</div>);
  }
}
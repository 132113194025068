import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';

export default class extends React.Component {
  static propTypes = {
    additionalFields: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    })),
    onCreate: PropTypes.func,
  }

  static defaultProps = {
    label: 'Document',
    additionalFields: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }

    this.inputDocId = React.createRef();
    this.inputFields = {};
    _.forEach(props.additionalFields, field => {
      this.inputFields[field.name] = React.createRef();
    })
  }

  openDialog = () => this.setState({ open: true })
  closeDialog = () => {
    this.setState({ open: false });
  }

  handleOk = () => {
    const value = this.inputDocId.current.value;
    const fieldValues = _.mapValues(this.inputFields, fieldRef => {
      return fieldRef.current.value;
    })
    this.props.onCreate({ title: value, ...fieldValues });
    this.closeDialog();
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleOk();
    }
  }

  renderDefaultButton = ({ onClick }) => {
    return (
      <Button
        variant="fab"
        color="primary"
        aria-label="Add"
        style={{ position: 'absolute', right: 100, bottom: -25 }}
        onClick={onClick}
      >
        <AddIcon />
      </Button>
    );
  }

  render() {
    const { label, additionalFields } = this.props;
    const renderButton = typeof this.props.renderButton === 'function' ? this.props.renderButton : this.renderDefaultButton;
    return (
      <div>
        {React.cloneElement(renderButton(), { onClick: this.openDialog })}
        <Dialog
          open={this.state.open}
          onClose={this.closeDialog}
          maxWidth="sm"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add a {label}</DialogTitle>
          <DialogContent>
            <div style={{ paddingTop: 5 }}>
              <TextField variant="outlined" inputRef={this.inputDocId} label={`${label} ID`} fullWidth autoFocus onKeyDown={this.handleKeyDown} />
              {additionalFields.map(field => <TextField variant="outlined" fullWidth key={field.name} inputRef={this.inputFields[field.name]} {...field} />)}
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeDialog}>Cancel</Button>
            <Button color="primary" onClick={this.handleOk}>Create {label}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';

import AddIcon from '@material-ui/icons/Add';

import CreateDocButton from '../../../../components/CreateDocButton';
import FirestoreCollectionViewer from '../../../../components/FirestoreCollectionViewer';
import PageHeader from '../../../../components/PageHeader';

// import client from '../../engine';
import { createClient } from 'forviz-aspenjs';

import DetailScreen from './detail';

const COLLECTION_NAME = 'promotions';

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;

  return {
    medias: _.get(state, ['projects', projectId, 'medias']),
  }
}

export default connect(mapStateToProps)(class extends React.Component {
  
  handleSelectRow = (record, selected, selectedRows, e) => {
    const { match, history } = this.props;
    history.push(`${match.url}/${record.id}`)
  }

  handleCreate = (values) => {
    const { projectId } = this.props;

    const { title } = values;
    const id = _.kebabCase(title);

    const client = createClient({ projectId });
    const docRef = client.getDocRef(COLLECTION_NAME, id);
    docRef.set({
      fields: {
        name: {
          en: title,
        }
      }
    }).then(() => {
      const { match, history } = this.props;
      history.push(`${match.url}/${id}`);  
    });
  }

  render() {
    const { match, history, projectId } = this.props;
    const listScreenUrl = match.url;
    const client = createClient({ projectId });
    return (
      <div>
        <PageHeader
          title={'Promotions'}
          renderFloatingActionButtons={() =>
            <CreateDocButton
              label="Promotion"
              renderButton={() => <Button variant="extendedFab" color="primary"><AddIcon /> Add a Promotion</Button>}
              onCreate={this.handleCreate}
            />
          }
        />
        <FirestoreCollectionViewer
          columns={[
            { title: 'Name', dataIndex: 'fields.name.en' },
            { title: 'Category', dataIndex: 'fields.category' },
            { title: 'Status', dataIndex: 'fields.status' },
          ]}
          collectionRef={client.getCollectionRef(COLLECTION_NAME)}
          onSelect={this.handleSelectRow}
        />
        <Route
          path={`${match.url}/:id`}
          children={({ match, ...rest }) => {
            const id = _.get(match, 'params.id');
            const docRef = !_.isEmpty(id) ? client.getDocRef(COLLECTION_NAME, id) : null;
            const open = docRef !== null;
            return (
              <Drawer anchor="right" open={open} onClose={() => history.goBack()}>
                <DetailScreen
                  width="600px"
                  {...rest}
                  projectId={projectId}
                  docRef={docRef}
                  onClose={() => {
                    history.push(listScreenUrl);
                  }}
                />
              </Drawer>
            );
          }}
        />
      </div>
    );
  }
});

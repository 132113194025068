import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { notify } from "react-notify-toast";
import _ from "lodash";
import {
  Paper,
  Grid,
  Typography,
  IconButton,
  Button,
  Divider,
  Switch,
  TextField,
  MenuItem,
  Fab
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../../../../components/ConfirmDialog";

const DRAWER_WIDTH = 500;

const Section = styled.section`
  margin: 25px 0;
`;

const ButtonBlock = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const PaperContent = styled.div`
  padding: 40px;
  box-sizing: border-box;
`;

const ActionBar = styled.div`
  box-sizing: border-box;
  padding: 20px;
  position: fixed;
  right: 0;
  bottom: 0;
  width: ${DRAWER_WIDTH}px;
  background: #fafafa;
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: flex-end;
`;

const inputType = [
  "checkbox",
  "date",
  "dropdown",
  "gallery",
  "image",
  "radio",
  "textfield",
  "textarea",
  "time",
  "switch"
];

export default ({ docRef, onClose = () => undefined }) => {
  const show = notify.createShowQueue();

  const [data, setData] = useState({});
  const [dynamiceField, setDynamiceField] = useState([]);
  const width = "700px";

  const deleteDoc = () => {};

  const handleInputChange = (key, value) => {
    const tempData = _.cloneDeep(data);
    _.set(tempData, key, value);
    setData(tempData);
  };

  const renderDefaultInput = fieldData => {
    return _.map(fieldData, (field, fieldKey) => {
      return (
        <Grid
          key={fieldKey}
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={2}>
            {_.get(field, "label", "")}
          </Grid>
          <Grid item xs={10}>
            <Switch
              color="primary"
              checked={field.show}
              onChange={e =>
                handleInputChange(`fields.${fieldKey}.show`, e.target.checked)
              }
            />
          </Grid>
        </Grid>
      );
    });
  };

  const addField = () => {
    const tempValue = _.cloneDeep(dynamiceField);
    tempValue.push({});
    setDynamiceField(tempValue);
  };

  const removeField = ({ inputIndex }) => {
    const tempValue = _.cloneDeep(dynamiceField);
    _.pullAt(tempValue, [inputIndex]);
    setDynamiceField(tempValue);
  };

  const addOption = ({ inputIndex, fieldValue }) => {
    const tempValue = fieldValue || _.cloneDeep(dynamiceField);
    const valueOption = _.get(tempValue, [inputIndex, "option"], []);
    valueOption.push({});
    _.set(tempValue, `${inputIndex}.option`, valueOption);
    setDynamiceField(tempValue);
  };

  const removeOptionKey = ({ inputIndex, fieldValue }) => {
    const tempValue = fieldValue || _.cloneDeep(dynamiceField);
    const removeOption = _.omit(_.get(tempValue, [inputIndex], {}), ["option"]);
    _.set(tempValue, `${inputIndex}`, removeOption);

    setDynamiceField(tempValue);
  };

  const removeOptionByIndex = ({ fieldIndex, optionIndex }) => {
    const tempValue = _.cloneDeep(dynamiceField);
    const valueOption = _.get(tempValue, [fieldIndex, "option"], []);
    _.pullAt(valueOption, [optionIndex]);
    _.set(tempValue, `${fieldIndex}.option`, valueOption);
    setDynamiceField(tempValue);
  };

  const handleChangeType = ({ index, inputName }) => event => {
    const inputType = event.target.value;
    const tempValue = _.cloneDeep(dynamiceField);
    const oldInputType = _.get(tempValue, inputName);
    if (inputType !== oldInputType) {
      _.set(tempValue, inputName, inputType);
      // setDynamiceField(tempValue);
      switch (inputType) {
        case "checkbox":
        case "dropdown":
        case "radio":
          addOption({ inputIndex: index, fieldValue: tempValue });
          break;

        default:
          removeOptionKey({ inputIndex: index, fieldValue: tempValue });
          break;
      }
    }
    // setValues({ ...values, [name]: event.target.value });
  };

  const handleDynamiceFieldChange = inputName => event => {
    const tempValue = _.cloneDeep(dynamiceField);
    _.set(tempValue, inputName, event.target.value);
    setDynamiceField(tempValue);
  };

  const handleToggleInputSwitchField = inputName => event => {
    const tempValue = _.cloneDeep(dynamiceField);
    _.set(tempValue, inputName, event.target.checked);
    setDynamiceField(tempValue);
  };

  const renderOption = fieldIndex => {
    const options = _.get(dynamiceField, [fieldIndex, "option"]);
    return (
      <Grid xs={12}>
        {_.map(options, (option, optionIndex) => {
          return (
            <Grid container xs={12}>
              <Grid item xs={5}>
                <TextField
                  label="Option Label"
                  style={{ width: "100%" }}
                  value={option.label}
                  onChange={handleDynamiceFieldChange(
                    `${fieldIndex}.option.${optionIndex}.label`
                  )}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Option Value"
                  style={{ width: "100%" }}
                  value={option.value}
                  onChange={handleDynamiceFieldChange(
                    `${fieldIndex}.option.${optionIndex}.value`
                  )}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <ButtonBlock>
                  <Fab
                    color="secondary"
                    aria-label="delete"
                    onClick={removeOptionByIndex.bind(this, {
                      fieldIndex,
                      optionIndex
                    })}
                  >
                    <DeleteIcon />
                  </Fab>
                </ButtonBlock>
              </Grid>
            </Grid>
          );
        })}
        {options && (
          <div style={{ margin: "16px 0" }}>
            <Grid xs={12}>
              <Fab
                color="primary"
                variant="extended"
                aria-label="add"
                onClick={addOption.bind(this, { inputIndex: fieldIndex })}
              >
                <AddIcon />
                Add Option
              </Fab>
            </Grid>
          </div>
        )}
      </Grid>
    );
  };

  const renderDynamiceInput = () => {
    return (
      <React.Fragment>
        {_.map(dynamiceField, (field, fieldIndex) => {
          return (
            <React.Fragment>
              <Grid
                // key={fieldKey}
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <p style={{ marginTop: 16 }}>{_.get(field, "label", "")}</p>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Field Name"
                    style={{ width: "100%" }}
                    value={_.get(field, "label", "")}
                    onChange={handleDynamiceFieldChange(`${fieldIndex}.label`)}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Field ID"
                    style={{ width: "100%" }}
                    value={field.id}
                    onChange={handleDynamiceFieldChange(`${fieldIndex}.id`)}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    label="Field Type"
                    value={field.type}
                    onChange={handleChangeType({
                      index: fieldIndex,
                      inputName: `${fieldIndex}.type`
                    })}
                    style={{ width: "100%" }}
                    // helperText="Please select your currency"
                    margin="normal"
                    variant="outlined"
                  >
                    {_.map(inputType, option => (
                      <MenuItem key={option} value={option}>
                        {_.upperCase(option)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  {renderOption(fieldIndex)}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Order"
                    style={{ width: "100%" }}
                    value={field.order}
                    type="number"
                    onChange={handleDynamiceFieldChange(`${fieldIndex}.order`)}
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    Language Enable
                  </Grid>
                  <Grid item xs={12}>
                    <Switch
                      color="primary"
                      checked={field.languageEnable}
                      // onChange={handleDynamiceFieldChange(
                      //   `${fieldIndex}.languageEnable`
                      // )}
                      onChange={handleToggleInputSwitchField(
                        `${fieldIndex}.languageEnable`
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <div style={{ margin: "16px 0" }}>
                  <Fab
                    color="secondary"
                    variant="extended"
                    aria-label="remove"
                    onClick={removeField.bind(this, { inputIndex: fieldIndex })}
                  >
                    <DeleteIcon />
                    Delete Field
                  </Fab>
                </div>
              </Grid>
              <Divider />
            </React.Fragment>
          );
        })}
        <div style={{ margin: "16px 0" }}>
          <Grid xs={12}>
            <Fab
              color="primary"
              variant="extended"
              aria-label="add"
              onClick={addField}
            >
              <AddIcon />
              Add Field
            </Fab>
          </Grid>
        </div>
      </React.Fragment>
    );
  };

  const renderSections = sectionType => {
    const fieldData = _.chain(_.get(data, "fields", {}))
      .pickBy(o => {
        return sectionType === "default" ? o.default : !o.default;
      })
      .value();

    if (sectionType === "default" && _.size(fieldData) <= 0) {
      return null;
    }
    return (
      <Section key={sectionType}>
        <Typography variant="subtitle1" style={{ marginBottom: 20 }}>
          {_.upperFirst(sectionType)}
        </Typography>
        <div>
          {sectionType === "default"
            ? renderDefaultInput(fieldData)
            : renderDynamiceInput(fieldData)}
        </div>
        <Divider />
      </Section>
    );
  };

  const analyceDataForDownload = () => {
    const analyceData = _.reduce(
      dynamiceField,
      (result, o) => {
        if (o.id && o.label && o.type) {
          const dataForSave = _.omit(o, ["id"]);
          return {
            ...result,
            [o.id]: {
              ...dataForSave,
              order: _.toSafeInteger(o.order)
            }
          };
        }
        return result;
      },
      {}
    );
    return analyceData;
  };

  const save = async e => {
    e.preventDefault();
    const dynamiceFieldForSave = analyceDataForDownload();
    if (docRef) {
      const doc = await docRef.get();
      if (doc.exists) {
        docRef.update({ ...data, customFeild: dynamiceFieldForSave });
      } else {
        docRef.set({ ...data, customFeild: dynamiceFieldForSave });
      }
    }
    show("Saved", "success", 4000);
    if (typeof onClose === "function") onClose();
  };
  useEffect(() => {
    docRef.onSnapshot(doc => {
      const docData = doc.data();
      setData(_.omit(docData, ["customFeild"]));
      setDynamiceField(
        _.map(_.get(docData, "customFeild"), (o, key) => ({
          ...o,
          id: key
        }))
      );
      // const fields = mapDocDataToFields(doc.data(), doc.id);
      // this.setState({
      //   id: doc.id,
      //   docData: doc.data(),
      //   currentTab: 0,
      //   // fields,
      //   fieldValues: _.mapValues(fields, field => _.get(field, "value"))
      // });
    });
  }, []);

  return (
    <div style={{ height: "100vh", position: "relative", overflow: "scroll" }}>
      <Paper
        elevation={0}
        style={{ width, padding: "40px 0", boxSizing: "border-box" }}
      >
        <PaperContent>
          <div>
            <Typography variant="h4" gutterBottom>
              {_.upperFirst(_.get(data, "label", ""))}
            </Typography>
          </div>
          {renderSections("default")}
          {renderSections("dynamice")}
        </PaperContent>
      </Paper>
      <ActionBar style={{ width, zIndex: 10 }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <ConfirmDialog
            title="Delete Document"
            content="This will permanently delete this document, are you sure?"
            onOk={deleteDoc}
            render={() => <Button style={{ color: "#ff1744" }}>Delete</Button>}
          />
          <div style={{ display: "flex" }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" onClick={save}>
              Save
            </Button>
          </div>
        </div>
      </ActionBar>
    </div>
  );
};

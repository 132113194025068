import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default class extends PureComponent {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    backgroundColor: PropTypes.string,
  };
  static defaultProps = {
    title: 'Title',
    children: <div />,
    backgroundColor: undefined
  };
  state = {};
  /* eslint-disable */
  // ======================================================
  // Commit Phase
  // Can work with DOM, run side effects, schedule updates.
  // ======================================================
  componentDidMount = () => {};
  componentDidUpdate = (prevProps, prevState, snapshot) => {};
  componentWillUnmount = () => {};
  // ======================================================
  // Pre-commit Phase
  // Can read the DOM
  // ======================================================
  getSnapshotBeforeUpdate = (prevProps, prevState) => ({});
  // ======================================================
  // Render Phase
  // Pure and no side effects. May be paused, aborted or restarted by React.
  // ======================================================
  static getDerivedStateFromProps(props, state) {
    return {};
  }
  /* eslint-enable */
  // ======================================================
  // Private Methods
  // ======================================================
  renderItem = () => {};
  render() {
    const { title, children, backgroundColor } = this.props;
    return (
      <Styled backgroundColor={backgroundColor}>
        <div className="title"><h2>{title}</h2></div>
        {children}
      </Styled>
    );
  }
}

const Styled = styled.div`
  box-sizing: border-box;
  width: 100vw;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  background-color: ${(props) => props.theme.primary};
  
  background-color: ${(props) => props.theme.primary};
  .title {
    background-color: ${(props) => props.theme.secondary};
    padding-bottom: 50px;
    position: relative;
    text-align: center;
    overflow: hidden;
  }
  .title h2 {
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 8px;
    font-weight: 100;
    margin: 20px 0 0;
    
  }
  .title:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 100vw solid ${(props) => props.theme.secondary};
    border-bottom: 50px solid ${(props) => props.theme.primary};
    position: absolute;
    left: 0;
    top: 50px;
  }
  .children {
  }
`
// bangna
// #1e3f61

// #395774


// #396072
// #3e6779
// white
// #c6a887
// #fce0b6
import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "./Menu";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";

const styles = {
  subMenuItem: {
    display: "flex",
    justifyContent: "space-between"
  }
};

class SubMenuItem extends React.Component {

  state = {
    menuOpen: false,
    anchorElement: null,
  }

  setAnchorElement = node => {
    // this.anchorElement = node;
    this.setState({ anchorElement: node });

  };

  handleItemClick = (event) => {
    if (!this.state.anchorElement) {
      this.setAnchorElement(event.currentTarget);
    }
    // this.menuOpen = !this.menuOpen;
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  handleSubMenuClose = () => {
    // this.menuOpen = false;
    this.setState({ menuOpen: false });
  }

  render() {
    const { title, menuItems, classes } = this.props;
    const { anchorElement, menuOpen } = this.state;

    return (
      <React.Fragment>
        <MenuItem
          onClick={this.handleItemClick}
          className={classNames(classes.subMenuItem)}
        >
          {title}
          <ArrowRightIcon />
        </MenuItem>
        <Menu
          open={menuOpen}
          menuItems={menuItems}
          anchorElement={anchorElement}
          onClose={this.handleSubMenuClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        />
      </React.Fragment>
    );
  }
}

SubMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired
};

export default withStyles(styles)(SubMenuItem);

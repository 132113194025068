import _ from 'lodash';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVED/PROJECTS':
      return _.mapValues(action.projects, (project, projectId) => ({ ...state[projectId], ...project }));

    case 'PROJECT/UPDATE':
      return {
        ...state,
        [action.id]: { ...state[action.id], ...action.data }
      }
    
    case 'PROJECT/UNITS/UPDATE':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          units: {
            ..._.get(state, `${action.id}.units`),
            ...action.units,
          }
        }
      };
    default: return state;
  }
}
import _ from 'lodash';

const BASEURL = 'https://forviz-form.herokuapp.com';

export const getFormByAccountId = (accountId) => {
  return fetch(`${BASEURL}/api/v2/forms/account/${accountId}`)
    .then(res => res.json())
    .then(res => {
      if (res.data) return res.data;
    })
}

export const getForm = (accountId, formId) => {
  return getFormByAccountId(accountId)
    .then(forms => _.find(forms, form => form.id == formId));
}
export const createForm = (data) => {
  return fetch(`${BASEURL}/api/v2/forms/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  .then(res => res.json())
  .then(res => {
    if (!res.data) throw new Error(_.get(res, 'errors.0.message'));
    return res.data;
  })
}

export const updateForm = (formId, data) => {
  return fetch(`${BASEURL}/api/v2/forms/${formId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  .then(res => res.json())
  .then(res => {
    if (!res.data) throw new Error(_.get(res, 'errors.0.message'));
    return res.data;
  })
}

export const deleteForm = (formId) => {
  return fetch(`${BASEURL}/api/v2/forms/${formId}`, {
    method: 'DELETE',
  })
  .then(res => res.json())
  .then(res => {
    if (!res.data) throw new Error(_.get(res, 'errors.0.message'));
    return res.data;
  })
}

export const getFormSubmissions = (formId) => {
  return fetch(`${BASEURL}/api/v2/forms/${formId}/submission`)
    .then(res => res.json())
    .then(res => {
      // console.log('getFormSubmissions', res);
      if (!res.data) throw Error(_.get(res, 'errors.0.message'));
      return res.data;
    })
}
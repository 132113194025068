import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { Route, Switch, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DevicesIcon from '@material-ui/icons/Devices';
import AddIcon from '@material-ui/icons/Add';

import Devices from '../Devices';
import PlaylistScreen from '../PlaylistScreen';
import MediaScreen from '../MediaScreen';
import SettingScreen from '../SettingScreen';

// Actions
import { updateAccount } from '../../actions';
import CreateDocButton from '../../../../components/CreateDocButton';

import * as Media from 'forviz-mediajs';
import * as Splitr from 'forviz-splitrjs';


const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: 'calc(100vh - 64px)',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    overflowY: 'scroll',
  },
  nested: {
    paddingLeft: theme.spacing(6),
  },
});


const createProjectMediaClient = (accountId) => {
  return Media.createClient({
    storagePath: `splitr/${accountId}/medias`,
    firestorePath: `splitr_accounts/${accountId}/medias`
  });
}

const createSplitrContentClient = (accountId) => {
  return Splitr.createClient({ accountId });
}

const mapStateToProps = (state, ownProps) => {
  const accountId = _.get(ownProps, 'accountId');
  const playlists = _.get(state, ['accounts', accountId, 'playlists']);

  return {
    playlists,
  }
}
export default withStyles(styles)(connect(mapStateToProps)(class extends React.Component {

  state = {
    clientCreated: false,
  }

  componentDidMount() {
    const { dispatch, accountId } = this.props;
    this.client = createSplitrContentClient(accountId);
    this.mediaClient = createProjectMediaClient(accountId);
    this.setState({
      clientCreated: true,
    });

    /**
     * Get first 8 medias from firestore and dispatch to redux store
     */
    // this.mediaClient.getMedia('metadata.timeCreated', 8, 'desc').onSnapshot(snapshot => {
    //   const medias = _.reduce(snapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: { id: doc.id, ...doc.data() }}), {});
    //   dispatch(updateAccount(accountId, 'medias', medias));
    // });

    // Get Project
    this.client.getAccountRef().onSnapshot(doc => {
      const accountData = doc.data();
      if (accountData) {
        dispatch(updateAccount(accountId, 'metadata', accountData.metadata));
        dispatch(updateAccount(accountId, 'setting', accountData.setting));
      }
    });

    // Get Playlists
    this.client.getPlaylistCollectionRef().onSnapshot(querySnapshot => {
      const playlists = _.reduce(querySnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: { ...doc.data(), id: doc.id }}), {});
      dispatch(updateAccount(accountId, 'playlists', playlists));
    });

    // Get Devices
    this.client.getDeviceCollectionRef().onSnapshot(querySnapshot => {
      const devices = _.reduce(querySnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: { ...doc.data(), id: doc.id }}), {});
      console.log('getDeviceCollectionRef', devices);
      dispatch(updateAccount(accountId, 'devices', devices));
    });
  }

  handleCreatePlaylist = (values) => {
    this.client.createPlaylist(values.title);
  }

  render() {
    const { match, accountId, classes, playlists } = this.props;
    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <div className={classes.toolbar} />
          <Divider />
          <List>
            {[{ label: 'Dashboard', to: 'dashboard' }, { label: 'Devices', to: 'devices' }].map((item, index) => (
              <ListItem button key={item.to} component={Link} to={`${match.url}/${item.to}`}>
                <ListItemIcon>{index % 2 === 0 ? <DashboardIcon /> : <DevicesIcon />}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List subheader={<ListSubheader>Media Library</ListSubheader>}>
            {['All'].map((text, index) => (
              <ListItem button key={text} component={Link} to={`${match.url}/medias`}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List subheader={<ListSubheader>Playlist</ListSubheader>}>
            {_.map(playlists, (playlist, index) => (
              <ListItem button key={playlist.id} component={Link} to={`${match.url}/playlist/${playlist.id}`}>
                <ListItemText primary={playlist.name} />
              </ListItem>
            ))}
              <CreateDocButton
                title="Create new playlist"
                onCreate={this.handleCreatePlaylist}
                renderButton={() => (
                  <ListItem button key={'add-playlist'} onClick={this.createPlaylistModal}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Create Playlist" />
                  </ListItem>
                )}
              />
          </List>
          <Divider />
          <List>
            <ListItem button key={'setting'} component={Link} to={`${match.url}/settings`}>
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Setting" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          {this.state.clientCreated &&
            <Switch>
              <Route
                path={`${match.url}/devices`}
                render={routerProps => <Devices {...routerProps} client={this.client} accountId={accountId} />}
              />
              <Route
                path={`${match.url}/playlist/:playlistId`}
                render={routerProps => {
                  const playlistId = _.get(routerProps, 'match.params.playlistId');
                  return (
                    <PlaylistScreen
                      {...routerProps}
                      client={this.client}
                      mediaClient={this.mediaClient}
                      accountId={accountId}
                      playlistId={playlistId}
                    />
                  );
                }} />
              <Route
                path={`${match.url}/medias`}
                render={routerProps => (
                  <MediaScreen {...routerProps} accountId={accountId} client={this.client} mediaClient={this.mediaClient} />
                )} />
              <Route
                path={`${match.url}/settings`}
                render={routerProps => (
                  <SettingScreen {...routerProps} accountId={accountId} client={this.client} />
                )}
              />
            </Switch>
          }
        </main>
      </div>
    );
  }
}));

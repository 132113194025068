import React from 'react';
import _ from 'lodash';
import { notify } from 'react-notify-toast';

import styled from 'styled-components';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Dropzone from 'react-dropzone';

import FloorplanLoader from '../../components/FloorplanLoader';

const DropArea = styled.div`
  textarea { 
    width: 100%;
    height: 400px;
    border: 1px solid #999;
    border-radius: 6px;
  }

  p {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    pointer-events: none;
  }

  &.drop-ok {
    border-color: #4caf50;
    p {
      color: #4caf50;
    }
  }

  &.drop-not-ok {
    border-color: #ab003c;
    p {
      color: #ab003c;
    }
  }
  &.has-error p {
    color: #ff3d00;
  }
`;


class UnitListItem extends React.Component {

  state = {
    open: false,
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  selectItem = (type) => {
    const { id } = this.props;
    this.handleClose();
    this.props.onSelectUnitType(id, type);
  }
  render() {
    const { id, unitTypes, unitType } = this.props;
    const { open } = this.state;
    return (
      <ListItem key={id}>
        <ListItemText primary={id} />
        <ListItemSecondaryAction>
          <Button
            variant="outlined"
            size="small"
            buttonRef={node => {
              this.anchorEl = node;
            }}
            onClick={this.handleOpen}
          >
            {unitType ? unitType : 'Room Type'}
            <ArrowDropDownIcon />
          </Button>
        </ListItemSecondaryAction>
        <Menu
          open={open}
          anchorEl={this.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.handleClose}
        >
          {_.map(unitTypes, type =>
            <MenuItem key={type.code} value={type.code} onClick={() => this.selectItem(type.code)}>{type.title}</MenuItem>
          )}
        </Menu>
      </ListItem>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { buildingId } = ownProps;
  return {
    unitTypes: _.get(state, ['projects', buildingId, 'unitTypes']),
  }
}

export default connect(mapStateToProps)(class extends React.Component {
  static defaultProps = {
    floorId: '',
  }

  state = {
    floorplate: {},
    step: 0,
    acceptedUnits: [
      // { id: 'A01', type: 'rect', x: 0, y: 0, width: 100, height: 100 }
    ],
    error: null,
  }
  
  componentDidMount() {
    // Get Floor
    const { docRef } = this.props;
    docRef.get()
      .then(doc => {
        this.setState({
          floorplate: doc.data(),
        });
      });
  }

  onDropStart = () => {
    this.setState({ error: null });
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    console.log('drop', acceptedFiles);
    const reader = new FileReader();
    const _self = this;
    // Closure to capture the file information.
    if (acceptedFiles.length > 0) {
      reader.onloadend = function () {
        // console.log(reader.result);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(reader.result, "text/xml");

        const group = xmlDoc.getElementById('unit');
        if (!group) {
          _self.setState({ error: 'Cannot find node ID = unit' });
        } else {

          const unitElements = xmlDoc.getElementById('unit').children;
          if (unitElements.length === 0) {
            _self.setState({ error: 'No elements under element ID = unit' })
          } else {
            const units =  Array.from(unitElements).map(unitElement => {
              // console.log('unitElement', unitElement.attributes);
              return {
                type: unitElement.nodeName,
                ..._.reduce(unitElement.attributes, (acc, attr) => {
                  return { ...acc, [attr.nodeName]: attr.nodeValue };
                }, {}),
              };
            });

            _self.setState({
              acceptedUnits: units,
              step: 1,
            });
          }
        }
      }

      // Read in the image file as a data URL.
      reader.readAsText(acceptedFiles[0]);
    }
  }

  handleReset = () => {
    this.setState({
      step: 0,
      acceptedUnits: [],
    });
  }

  updateUnitType = (id, type) => {
    this.setState({
      acceptedUnits: _.map(this.state.acceptedUnits, unit => {
        if (unit.id === id) return { ...unit, unitType: type };
        return unit;
      })
    });
  }

  handleSave = () => {
    const { docRef } = this.props;
    if (docRef && this.state.acceptedUnits) {
      // Delete Old Units
      docRef.collection('units').get().then(querySnapshot => {
        
        const oldIds = _.map(querySnapshot.docs, doc => doc.id);
        const newIds = _.map(this.state.acceptedUnits, unit => unit.id);
        const toRemoveIds = _.difference(oldIds, newIds);
        console.log('oldIds', oldIds);
        console.log('newIds', newIds);
        console.log('toRemoveIds', toRemoveIds);
        // Delete
        _.forEach(toRemoveIds, id => {
          docRef.collection('units').doc(id).delete();
        });

        // Update
        _.forEach(this.state.acceptedUnits, unit => {
          docRef.collection('units').doc(unit.id).set(unit);
        });
      });
      
    }
    notify.show('Saved', 'success');
    this.props.onSaved();
  }

  render() {
    const { onClose, open, buildingId, floorId, unitTypes, docRef } = this.props;
    const { floorplate, step, acceptedUnits, error } = this.state;
    return (
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Floor {floorId} : Unit Importer</DialogTitle>
        <DialogContent>
          {step === 0 &&
            <Grid container>
              <Grid item sm={12}>
                <Dropzone
                  accept={["image/svg+xml"]}
                  disableClick
                  style={{ width: 600, height: 400 }}
                  onDropStart={this.onDropStart}
                  onDrop={this.onDrop}
                >
                  {({ isDragActive, isDragReject }) => {
                    let text = 'Copy SVG code or drop SVG file.';
                    if (isDragActive) text = 'This is SVG file, Looks goods to me';
                    if (isDragReject) text = 'This is not SVG you dump ass';

                    if (error) text = error;
                    return (
                      <DropArea className={`${isDragActive ? 'drop-ok' : ''} ${isDragReject ? 'drop-not-ok' : ''} ${error !== null ? 'has-error' : ''}`}>
                        <textarea></textarea>
                        <p>{text}</p>
                      </DropArea>
                    );
                  }}
                </Dropzone>
              </Grid>
            </Grid>
          }
          {step === 1 &&
            <Grid container style={{ width: 1180, maxWidth: '100%' }}>
              <Grid item sm={9}>
                <div>
                  <FloorplanLoader
                    {...floorplate}
                    buildingId={buildingId}
                    mediaId={floorplate.floorplan}
                    docRef={docRef}
                    units={acceptedUnits}
                  />
                </div>
              </Grid>
              <Grid item sm={3}>
                <List>
                  {acceptedUnits.map(unit =>
                    <UnitListItem {...unit} unitTypes={unitTypes} onSelectUnitType={this.updateUnitType} />
                  )}
                </List>
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          {step === 0 && <Button onClick={this.props.onClose}>Cancel</Button>}
          {step === 1 && (
            <div>
              <Button onClick={this.handleReset}>Back</Button>
              <Button onClick={this.handleSave} color="primary" autoFocus>
                Continue
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }
});


// {_.map(roomtypes, option =>
// <MenuItem key={option} value={option}>{option}</MenuItem>)}
import React from "react";
import Chart from "react-apexcharts";

const generateData = (count, yrange) => {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = (i + 1).toString();
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
    series.push({
      x: x,
      y: y
    });
    i++;
  }
  return series;
};

const DEFAULT_SERIES = [
  {
    name: "Professional",
    data: generateData(12, {
      min: 0,
      max: 90
    })
  },
  {
    name: "Advance",
    data: generateData(12, {
      min: 0,
      max: 90
    })
  },
  {
    name: "Upper Intermediate",
    data: generateData(12, {
      min: 0,
      max: 90
    })
  },
  {
    name: "Intermediate",
    data: generateData(12, {
      min: 0,
      max: 90
    })
  },
  {
    name: "Beginner",
    data: generateData(12, {
      min: 0,
      max: 90
    })
  }
]
const DEFAULT_RANGES = [
  {
    from: 0,
    to: 25,
    color: "rgba(0, 0, 255, 0.6)"
  },
  {
    from: 26,
    to: 50,
    color: "rgba(0, 0, 255, 0.7)"
  },
  {
    from: 51,
    to: 75,
    color: "rgba(0, 0, 255, 0.8)"
  },
  {
    from: 76,
    to: 100,
    color: "rgba(0, 0, 255, 1)"
  }
]
const DEFAULT_COLORS = ["#008FFB"]
const DEFAULT_TOOLTIP_X_FORMATTER = () => {
  formatter: val => {
    return "Chapter " + val;
  }
}
const DEFAULT_TOOLTIP_Y_FORMATTER = () => {
  formatter: val => {
    return "ผ่านแล้ว " + val + " คน";
  }
}

/*
 * series = [{ x: rowIndex , y: IntensityValue }]
 */
const HeatMap = ({
  series,
  enabledDataLabels = false,
  enableShades = false,
  colors = DEFAULT_COLORS,
  colorScaleRanges = DEFAULT_RANGES,
  showToolBar = false,
  tooltipXFormatter = DEFAULT_TOOLTIP_X_FORMATTER,
  tooltipYFormatter = DEFAULT_TOOLTIP_Y_FORMATTER,
}) => {
  return (
    <Chart
      options={{
        dataLabels: {
          enabled: enabledDataLabels
        },
        plotOptions: {
          heatmap: {
            enableShades,
            colorScale: {
              ranges: colorScaleRanges
            }
          }
        },
        colors: DEFAULT_COLORS,
        chart: {
          toolbar: {
            show: showToolBar
          }
        },
        tooltip: {
          x: tooltipXFormatter,
          y: tooltipYFormatter
        },
      }}
      series={series || DEFAULT_SERIES || []}
      type="heatmap"
      height="250"
    />
  )
}

export default HeatMap

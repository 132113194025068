import React from 'react';
import _ from 'lodash';
import FirestoreDocForm from '../../components/FirestoreDocForm';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  const buildingId = _.get(ownProps, 'buildingId');
  return {
    medias: _.get(state, ['projects', buildingId, 'medias']),
  }
};

export default connect(mapStateToProps)((props) => {
  const { medias } = props;
  const fileOptions = _
    .chain(medias)
    .map((media, key) => ({ value: key, label: key, url: media.url }))
    .value();

  return (
    <FirestoreDocForm
      {...props}
      docRef={props.docRef}
      resources={{
        medias,
      }}
      mapDocDataToFields={(data, id) => ({
        title: { label: 'Title', value: _.get(data, 'title', id) },
        description: { label: 'Description', value: _.get(data, 'description', '') },
        icon: { label: 'Icon', type: 'media', value: _.get(data, 'icon', ''), options: fileOptions },
        tags: { label: 'Tags', value: _.join(_.get(data, 'tags', [])) },
        x: { label: 'X', value: _.get(data, 'x', 0) },
        y: { label: 'Y', value: _.get(data, 'y', 0) },
        width: { label: 'Width', value: _.get(data, 'width', 20) },
        height: { label: 'Height', value: _.get(data, 'height', 30) },
        path: { label: 'Path', value: _.get(data, 'path') }
      })}
      mapFieldsToDocData={fields => {
        const fieldValues = _.mapValues(fields, field => field.value);
        return {
          title: fieldValues.title,
          description: fieldValues.description,
          icon: fieldValues.icon,
          x: fieldValues.x,
          y: fieldValues.y,
          width: fieldValues.width,
          height: fieldValues.height,
          path: fieldValues.path,
          tags: _.split(fieldValues.tags, ','),
        }
      }}
      sections={[
        {
          title: 'Info',
          fields: ['title', 'description', 'icon', 'path']
        },
        {
          title: 'Location',
          fields: ['x', 'y', 'width', 'height'],
        },
        {
          title: 'Taxonomy',
          fields: ['tags']
        },
      ]}
    />
  );
});
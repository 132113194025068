import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import AddIcon from '@material-ui/icons/Add';
import VisibleIcon from '@material-ui/icons/Visibility';
import InvisibleIcon from '@material-ui/icons/VisibilityOff';

import { connect } from 'react-redux';

import db from '../../engine/db';

import PageHeader from '../../../../components/PageHeader';

const mapStateToProps = (state, ownProps) => {
  const buildingId = _.get(ownProps, 'buildingId');
  return {
    medias: _.get(state, ['projects', buildingId, 'medias']),
  }
};

export default connect(mapStateToProps)(class extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      svgWidth: 1920,
      svgHeight: 1080,
      openNewDialog: false,
      places: [],
      paths: [],
      layers: [],
    }
  
    this.inputType = React.createRef();
    this.inputTitle = React.createRef();

  }

  componentDidMount() {
    const { buildingId } = this.props;
    const buildingRef = db.collection('meerkat_projects').doc(buildingId);
    this.mapPlacesUnsubscribe = buildingRef.collection('mapPlaces').onSnapshot(querySnapshot => {
      this.setState({
        places: _.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() })),
      });
    });
    this.mapPathsUnsubscribe = buildingRef.collection('mapPaths').onSnapshot(querySnapshot => {
      this.setState({
        paths: _.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() })),
      });
    });
    this.mapLayersUnsubscribe = buildingRef.collection('mapLayers').onSnapshot(querySnapshot => {
      this.setState({
        layers: _.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() })),
      });
    });
  }

  componentWillUnmount() {
    this.mapPlacesUnsubscribe();
    this.mapPathsUnsubscribe();
    this.mapLayersUnsubscribe();
  }

  openDialog = () => this.setState({ openNewDialog: true })
  closeDialog = () => {
    this.setState({ openNewDialog: false });
  }

  togglePlaceVisible = (placeId) => {
    this.setState({
      ...this.state,
      places: _.map(this.state.places, place => {
        if (place.id === placeId) return { ...place, hidden: !!!place.hidden };
        return place;
      })
    })
  }

  togglePathVisible = (pathId) => {
    this.setState({
      ...this.state,
      paths: _.map(this.state.paths, path => {
        if (path.id === pathId) return { ...path, hidden: !!!path.hidden };
        return path;
      })
    })
  }


  toggleLayerVisible = (layerId) => {
    this.setState({
      ...this.state,
      layers: _.map(this.state.layers, layer => {
        if (layer.id === layerId) return { ...layer, hidden: !!!layer.hidden };
        return layer;
      })
    })
  }

  handleSubmit = () => {
    const title = this.inputTitle.current.value;
    const type = this.inputType.current.value;

    const id = _.kebabCase(title);

    const { buildingId, match, history } = this.props;
    const buildingRef = db.collection('meerkat_projects').doc(buildingId);

    // const itemRef = type === 'place' ? buildingRef.collection('mapPlaces') : buildingRef.collection('mapLayers');
    console.log('documentPath', `map${_.capitalize(type)}`);
    const itemRef = buildingRef.collection(`map${_.capitalize(type)}`);

    itemRef.doc(`${id}`)
      .set({
        title,
      })
      .then(() => {
        this.closeDialog();
        history.push(`${match.url}/${type}/${id}`);
      });
  }

  render() {
    const { match, medias } = this.props;
    const { places, paths, layers } = this.state;
    return (
      <div>
        <Grid container>
          <Grid item sm={3}>
            <div style={{ height: 'calc(100vh - 60px)', background: '#fff', overflow: 'scroll' }}>
              <PageHeader
                title="Map"
                renderFloatingActionButtons={() => (
                  <Button variant="fab" color="primary" onClick={this.openDialog}><AddIcon /></Button>
                )}
              >
                <div>
                  <Grid container spacing={8}>
                    <Grid item xs={6}>
                      <TextField
                        label="W"
                        variant="outlined"
                        value={this.state.svgWidth}
                        onChange={e => this.setState({ svgWidth: e.target.value })}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="H"
                        variant="outlined"
                        value={this.state.svgHeight}
                        onChange={e => this.setState({ svgHeight: e.target.value })}
                      />
                    </Grid>
                  </Grid>
                </div>
              </PageHeader>
              <List
                component="nav"
                dense
                subheader={<ListSubheader component="div">Places</ListSubheader>}
              >
                {_.map(_.orderBy(places, ['zIndex'], ['desc']), place => (
                  <ListItem button component={Link} key={place.id} to={`${match.url}/places/${place.id}`}>
                    <ListItemText primary={place.title} secondary={`${place.tags}`} />
                    <ListItemSecondaryAction>
                      <IconButton aria-label="Hide" onClick={e => this.togglePlaceVisible(place.id)}>
                        {place.hidden ? <InvisibleIcon /> : <VisibleIcon />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <List
                component="nav"
                dense
                subheader={<ListSubheader component="div">Paths</ListSubheader>}
              >
                {_.map(_.orderBy(paths, ['zIndex'], ['desc']), path => (
                  <ListItem button component={Link} key={path.id} to={`${match.url}/paths/${path.id}`}>
                    <ListItemText primary={path.title} secondary={`${_.get(path, 'tags', 'No tags')}`} />
                    <ListItemSecondaryAction>
                      <IconButton aria-label="Hide" onClick={e => this.togglePathVisible(path.id)}>
                        {path.hidden ? <InvisibleIcon /> : <VisibleIcon />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <List
                component="nav"
                dense
                subheader={<ListSubheader component="div">Layers</ListSubheader>}
              >
                {_.map(_.orderBy(layers, ['zIndex'], ['desc']), layer => (
                  <ListItem button component={Link} key={layer.id} to={`${match.url}/layers/${layer.id}`}>
                    <ListItemText primary={layer.title} secondary={`${_.get(layer, 'tags', 'No tags')}`} />
                    <ListItemSecondaryAction>
                      <IconButton aria-label="Hide" onClick={e => this.toggleLayerVisible(layer.id)}>
                        {layer.hidden ? <InvisibleIcon /> : <VisibleIcon />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
          <Grid item sm={9}>
            <div style={{ height: 'calc(100vh - 60px)', background: '#ececec' }}>
              <svg viewBox={`0 0 ${this.state.svgWidth} ${this.state.svgHeight}`} style={{ width: '100%', height: '100%' }}>
                {_.map(_.orderBy(layers, ['zIndex'], ['asc']), layer => {
                  const url = _.get(medias, [layer.src, 'url']);
                  return (
                    <image
                      key={layer.src}
                      xlinkHref={url}
                      alt={layer.title}
                      width={this.state.svgWidth}
                      height={this.state.svgHeight}
                      style={{ opacity: layer.hidden ? 0 : 1 }}
                    />
                  );
                })}
                {_.map(_.orderBy(paths, ['zIndex'], ['asc']), path => {
                  return (<path d={path.d} fill="none" stroke="yellow" style={{ strokeWidth: 3 }} />);
                })}
                {_.map(_.orderBy(places, ['zIndex'], ['asc']), place => {
                  const url = _.get(medias, [place.icon, 'url']);
                  if (url) {
                    return (
                      <g transform={`translate(${place.x} ${place.y})`}>
                        <image
                          key={place.id}
                          x={-.5 * place.width}
                          y={-1 * place.height}
                          xlinkHref={url}
                          alt={place.title}
                          width={place.width}
                          height={place.height}
                          style={{ opacity: place.hidden ? 0 : 1 }}
                        />
                      </g>
                    );
                  }
                  return <circle cx={place.x} cy={place.y} r={6} strokeWidth={2} style={{ stroke: 2, fill: 'red', opacity: place.hidden ? 0 : 1 }} />
                })}
              </svg>
            </div>
          </Grid>
        </Grid>

        <Dialog
          open={this.state.openNewDialog}
          onClose={this.closeDialog}
          maxWidth="sm"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Invite a member</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <TextField label="Type" select SelectProps={{ native: true }} inputRef={this.inputType} fullWidth defaultValue="place">
                  <option value="places">Place</option>
                  <option value="paths">Path</option>
                  <option value="layers">Layer</option>
                </TextField>
              </Grid>
              <Grid item sm={8}>
                <TextField label="Title" inputRef={this.inputTitle} fullWidth autoFocus />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeDialog}>Cancel</Button>
            <Button color="primary" onClick={this.handleSubmit}>Add Item</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
});

import React from 'react'

export default props => {
  const { x, y, width, height, value } = props
  const radius = height / 2
  return (
    <g>
      {value > 0 && (
        <text
          x={x + width / 2}
          y={y + radius}
          fill='#ffffff'
          textAnchor='middle'
          dominantBaseline='middle'
        >
          {value}
        </text>
      )}
    </g>
  )
}

import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import AddIcon from "@material-ui/icons/Add";
import VisibleIcon from "@material-ui/icons/Visibility";
import InvisibleIcon from "@material-ui/icons/VisibilityOff";

import PageHeader from "../PageHeader";
import FirestoreDocEditor from "../FirestoreDocEditor";
import CreateDocButton from "../CreateDocButton";

const idleColor = "#357a38";
const selectedColor = "#ff784e";
const linkColor = "#A1887F";

const Container = styled.div`
  background: #333;
`;

class Node extends React.PureComponent {
  static defaultProps = {
    selected: false
  };
  handleClick = e => {
    const { id, onSelect } = this.props;
    onSelect(id, "node");
  };
  render() {
    const { id, x, y, links, selected } = this.props;
    const isLinkNode = _.size(links) > 0;
    return (
      <Tooltip key={id} placement="top" title={id} interactive={true}>
        <g>
          <circle
            key={id}
            cx={x}
            cy={y}
            r={6}
            fill={selected ? selectedColor : isLinkNode ? linkColor : idleColor}
            opacity="0.5"
            onClick={this.handleClick}
          />
          {isLinkNode && (
            <g>
              <line
                x1={x}
                y1={y}
                x2={x}
                y2={20 * (1 + _.size(links))}
                stroke={linkColor}
                strokeWidth={2}
                strokeDasharray="4 2 4 2"
              />
              <text
                x={x}
                y={20}
                textAnchor="middle"
                fill={linkColor}
                style={{ fontSize: 11 }}
              >
                <tspan x={x} dy={0}>
                  Link to
                </tspan>
                {_.map(links, link => (
                  <tspan key={link} x={x} dy="1.1em">
                    {link}
                  </tspan>
                ))}
              </text>
            </g>
          )}
        </g>
      </Tooltip>
    );
  }
}

class Unit extends React.PureComponent {
  static defaultProps = {
    selected: false
  };
  handleClick = e => {
    const { id, onSelect } = this.props;
    onSelect(id, "unit");
  };
  render() {
    const { id, selected, elements } = this.props;
    const Component = this.props.type;

    const logo = elements.logo;
    return (
      <Tooltip
        key={id}
        TransitionComponent={Zoom}
        placement="top"
        title={id}
        interactive={true}
      >
        <g>
          <Component
            key={id}
            {..._.omit(this.props, ["elements"])}
            fill={selected ? selectedColor : idleColor}
            opacity="0.5"
            onClick={this.handleClick}
          />
          {(logo && logo.x && logo.y) && <logo.type {...logo} />}
        </g>
      </Tooltip>
    );
  }
}

export default class extends React.Component {
  static defaultProps = {
    canCreateElement: true,
    svgWidth: 1920,
    svgHeight: 1080
  };

  state = {
    units: [],
    selectedItemId: null,
    selectedItemType: null
  };

  componentDidMount() {}

  selectItem = (itemId, itemType) => {
    // console.log('selectItem', itemId, itemType);
    this.setState({
      selectedItemId: !this.state.selectedItemId === itemId ? null : itemId,
      selectedItemType: !this.state.selectedItemId === itemId ? null : itemType
    });
  };

  selectElement = elementId => {
    const { id: floorId } = this.props;
    this.props.onSelectItem(floorId, elementId, "element");
  };

  handleCreateElement = values => {
    const { id: floorId } = this.props;
    this.props.onCreate(floorId, { type: "element", ...values });
  };

  unselectItem = () =>
    this.setState({ selectedItemId: null, selectedItemType: null });

  renderLine = line => {
    return (
      <line
        key={line.id}
        {...line}
        stroke="#357a38"
        strokeWidth={2}
        opacity="0.5"
      />
    );
  };

  renderNode = node => {
    const { selectedItemId } = this.state;
    return (
      <Node
        key={node.id}
        {...node}
        onSelect={this.selectItem}
        selected={selectedItemId === node.id}
      />
    );
  };

  renderUnit = unit => {
    const { selectedItemId } = this.state;
    return (
      <Unit
        key={unit.id}
        {...unit}
        onSelect={this.selectItem}
        selected={selectedItemId === unit.id}
      />
    );
  };

  renderElement = element => {
    const { id } = element;
    return (
      <g transform={`translate(${element.x} ${element.y})`}>
        <circle cx={0} cy={0} r={4} fill="#ff5722" />
        <element.type
          {...element}
          x={-0.5 * element.width}
          y={-1 * element.height}
          onClick={() => this.selectElement(id)}
        />
      </g>
    );
  };

  getItemDocRef = (type, id) => {
    const { client, floorId } = this.props;
    switch (type) {
      case "node":
        return client.getNodeDocRef(id);
      case "unit":
        return client
          .getFloorDocRef(floorId)
          .collection("units")
          .doc(id);
      default:
        return null;
    }
  };

  getInitialScale = () => {
    const svgHeight = document.getElementById("image-pinch-zoom")
      ? document.getElementById("image-pinch-zoom").getBoundingClientRect()
          .height
      : "";
    return window.innerHeight / svgHeight > 1
      ? "auto"
      : window.innerHeight / svgHeight;
  };

  render() {
    const {
      id,
      svgWidth,
      svgHeight,
      background,
      nodes,
      units,
      elements,
      canCreateElement,
    } = this.props;
    const { selectedItemType, selectedItemId } = this.state;
    const nodeDatas = _.reduce(
      nodes,
      (acc, node) => ({ ...acc, [node.id]: node }),
      {}
    );
    const nodeLines = _.compact(
      _.reduce(
        nodes,
        (acc, node) => {
          return [
            ...acc,
            ..._.map(node.neighbor, nodeId => {
              const neighborNode = _.get(nodeDatas, nodeId);
              if (neighborNode) {
                return {
                  id: `${node.id}-${nodeId}`,
                  x1: node.x,
                  y1: node.y,
                  x2: _.get(neighborNode, "x", 0),
                  y2: _.get(neighborNode, "y", 0)
                };
              } else {
                console.log("Not found Node", nodeId);
              }
              return null;
            })
          ];
        },
        []
      )
    );

    const selectedItemDocRef = this.getItemDocRef(
      selectedItemType,
      selectedItemId
    );

    return (
      <Container>
        <Grid container>
          <Grid item sm={3}>
            <div
              style={{
                height: "calc(100vh - 60px)",
                background: "#fff",
                overflow: "scroll"
              }}
            >
              <PageHeader
                title={`Floor ${id}`}
                renderFloatingActionButtons={() => (
                  <CreateDocButton
                    label="Element"
                    onCreate={this.handleCreateElement}
                    renderButton={() => canCreateElement ? (
                      <Button variant="fab" color="primary">
                        <AddIcon />
                      </Button>
                    ) : <React.Fragment />}
                  />
                )}
              />
              <List
                component="nav"
                dense
                subheader={
                  <ListSubheader
                    component="div"
                    style={{ background: "rgba(255, 255, 255, 1" }}
                  >
                    Elements
                  </ListSubheader>
                }
              >
                {_.map(elements, element => (
                  <ListItem
                    button
                    key={element.id}
                    onClick={e => this.selectElement(element.id)}
                  >
                    <ListItemText primary={element.id} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Hide"
                        onClick={e => this.togglePlaceVisible(element.id)}
                        style={{ opacity: 0.5 }}
                      >
                        {element.hidden ? (
                          <InvisibleIcon fontSize="small" />
                        ) : (
                          <VisibleIcon fontSize="small" />
                        )}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <List
                component="nav"
                dense
                subheader={
                  <ListSubheader
                    component="div"
                    style={{ background: "rgba(255, 255, 255, 1" }}
                  >
                    Units
                  </ListSubheader>
                }
              >
                {_.map(units, unit => (
                  <ListItem
                    button
                    key={unit.id}
                    onClick={e => this.selectItem(unit.id, "unit")}
                  >
                    <ListItemText primary={unit.id} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Hide"
                        onClick={e => this.togglePathVisible(unit.id)}
                        style={{ opacity: 0.5 }}
                      >
                        {unit.hidden ? (
                          <InvisibleIcon fontSize="small" />
                        ) : (
                          <VisibleIcon fontSize="small" />
                        )}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <List
                component="nav"
                dense
                subheader={
                  <ListSubheader
                    component="div"
                    style={{ background: "rgba(255, 255, 255, 1" }}
                  >
                    Nodes
                  </ListSubheader>
                }
              >
                {_.map(nodes, node => (
                  <ListItem
                    button
                    key={node.id}
                    onClick={e => this.selectItem(node.id, "node")}
                  >
                    <ListItemText primary={node.id} />
                    <ListItemSecondaryAction>
                      <IconButton
                        aria-label="Hide"
                        onClick={e => this.toggleLayerVisible(node.id)}
                        style={{ opacity: 0.5 }}
                      >
                        {node.hidden ? (
                          <InvisibleIcon fontSize="small" />
                        ) : (
                          <VisibleIcon fontSize="small" />
                        )}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
          <Grid item sm={9}>
            <PinchZoomPan
              minScale={0.5}
              maxScale={5}
              initialScale={this.getInitialScale()}
            >
              <div>
                <svg
                  width="100%"
                  height="100%"
                  viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                >
                  <image
                    x={0}
                    y={0}
                    width={svgWidth}
                    height={svgHeight}
                    xlinkHref={background}
                    id="image-pinch-zoom"
                  />
                  <g>{_.map(nodeLines, line => this.renderLine(line))}</g>
                  <g>{_.map(nodes, node => this.renderNode(node))}</g>
                  <g>{_.map(units, unit => this.renderUnit(unit))}</g>
                  <g>
                    {_.map(elements, element => this.renderElement(element))}
                  </g>
                </svg>
              </div>
            </PinchZoomPan>
          </Grid>
        </Grid>
        <Drawer
          open={selectedItemType === "node" && selectedItemDocRef !== null}
          anchor="right"
          onClose={this.unselectItem}
        >
          <FirestoreDocEditor
            width="300px"
            docRef={selectedItemDocRef}
            mapDocDataToFields={(data, id) => ({
              x: { label: "X", value: _.get(data, "x") },
              y: { label: "Y", value: _.get(data, "y") },
              neighbor: {
                label: "Neighbor",
                value: _.get(data, "neighbor", "")
              },
              links: {
                label: "Links (บันไดเลื่อน)",
                value: _.get(data, "links")
              },
              linksLift: {
                label: "Links (ลิฟท์)",
                value: _.get(data, "linksLift")
              },
              lift: {
                label: "Lift",
                type: "Switch",
                value: _.get(data, "lift")
              },
              escalator: {
                label: "Escalator",
                type: "Switch",
                value: _.get(data, "escalator")
              }
              // tags: { label: 'Tags', value: _.join(_.get(data, 'fields.tags', [])) },
            })}
            mapFieldValuesToDocData={fieldValues => {
              const links = _.compact(
                _.map(_.split(fieldValues.links, ","), _.trim)
              );
              const linksLift = _.compact(
                _.map(_.split(fieldValues.linksLift, ","), _.trim)
              );
              return {
                x: fieldValues.x,
                y: fieldValues.y,
                neighbor: _.compact(
                  _.map(_.split(fieldValues.neighbor, ","), _.trim)
                ),
                links,
                linksLift,
                isLift: _.size(links) > 0,
                isEsalator: _.size(linksLift) > 0
                // tags: _.map(_.split(fieldValues.tags, ','), _.trim),
              };
            }}
            sections={[
              {
                title: "Info",
                fields: ["x", "y", "neighbor"]
              },
              {
                title: "Links",
                fields: ["links", "linksLift"]
              }
            ]}
            onClose={this.unselectItem}
          />
        </Drawer>
        <Drawer
          open={selectedItemType === "unit" && selectedItemDocRef !== null}
          anchor="right"
          onClose={this.unselectItem}
        >
          <FirestoreDocEditor
            width="300px"
            docRef={selectedItemDocRef}
            mapDocDataToFields={(data, id) => {
              let attrs = {};
              switch (_.get(data, "type")) {
                case "path":
                  attrs.d = { label: "d", value: _.get(data, "d") };
                  break;
                case "polygon":
                case "polyline":
                  attrs.points = {
                    label: "points",
                    value: _.get(data, "points")
                  };
                  break;
                case "rect":
                  attrs = {
                    x: { label: "x", value: _.get(data, "x") },
                    y: { label: "y", value: _.get(data, "y") },
                    width: { label: "width", value: _.get(data, "width") },
                    height: { label: "height", value: _.get(data, "height") }
                  };
                  break;
                default:
                  break;
              }
              const fields = {
                type: { label: "Type", value: _.get(data, "type") },
                ...attrs,
                logoX: { label: "X", value: _.get(data, "elements.logo.x") },
                logoY: { label: "Y", value: _.get(data, "elements.logo.y") },
                logoWidth: {
                  label: "Width",
                  value: _.get(data, "elements.logo.width")
                },
                logoHeight: {
                  label: "Height",
                  value: _.get(data, "elements.logo.height")
                }
                // test: { label: 'Test', value: _.get(data, 'test') },
              };
              return fields;
            }}
            mapFieldValuesToDocData={fieldValues => {
              return {
                type: fieldValues.type,
                ...(fieldValues.type === "path" ? { d: fieldValues.d } : {}),
                ...(fieldValues.type === "polygon"
                  ? { points: fieldValues.points }
                  : {}),
                ...(fieldValues.type === "polyline"
                  ? { points: fieldValues.points }
                  : {}),
                ...(fieldValues.type === "rect"
                  ? {
                      x: fieldValues.x,
                      y: fieldValues.y,
                      width: fieldValues.width,
                      height: fieldValues.height
                    }
                  : {}),
                "elements.logo.x": fieldValues.logoX,
                "elements.logo.y": fieldValues.logoY,
                "elements.logo.width": fieldValues.logoWidth,
                "elements.logo.height": fieldValues.logoHeight
              };
            }}
            getSections={fields => {
              let unitFields = [];
              switch (fields.type.value) {
                case "path":
                  unitFields = ["type", "d"];
                  break;
                case "polygon":
                case "polyline":
                  unitFields = ["type", "points"];
                  break;
                case "rect":
                  unitFields = ["type", "x", "y", "width", "height"];
                  break;
                default:
                  break;
              }
              return [
                {
                  title: "Unit",
                  fields: unitFields
                },
                {
                  title: "Logo",
                  fields: ["logoX", "logoY", "logoWidth", "logoHeight"]
                }
              ];
            }}
            onClose={this.unselectItem}
          />
        </Drawer>
      </Container>
    );
  }
}

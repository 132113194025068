import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import FirestoreDocForm from '../../components/FirestoreDocForm';

const mapStateToProps = (state, ownProps) => {
  const buildingId = _.get(ownProps, 'buildingId');
  return {
    medias: _.get(state, ['projects', buildingId, 'medias']),
  }
};
export default connect(mapStateToProps)((props) => {
  const { medias } = props;
  const floorplanOptions = _
    .chain(medias)
    .map((media, key) => ({ value: key, label: key, url: media.url }))
    // .filter(media => _.includes(media.tags, 'floorplan'))
    .value();
  
  return (
    <FirestoreDocForm
      {...props}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => ({
        code: { label: 'Code', value: _.get(data, 'code', id) },
        title: { label: 'Title', value: _.get(data, 'title', id) },
        floorplan: {
          type: 'media',
          label: 'Floorplan',
          value: _.get(data, 'floorplan', ''),
          options: floorplanOptions
        },
        tags: { label: 'Tags', value: _.join(_.get(data, 'tags', [])) },
        floorMin: { label: 'Floor Min', value: _.min(_.get(data, 'floors')) || _.get(data, 'floors', _.replace(id, /[a-zA-Z]/g, '')) },
        floorMax: { label: 'Floor Max', value: _.max(_.get(data, 'floors')) || _.get(data, 'floors', _.replace(id, /[a-zA-Z]/g, '')) },
        svgWidth: { label: 'SVG Width', value: _.get(data, 'svgWidth', 1920) },
        svgHeight: { label: 'SVG Height', value: _.get(data, 'svgHeight', 1080) },
      })}
      mapFieldsToDocData={fields => {
        const fieldValues = _.mapValues(fields, field => field.value);
        return {
          code: fieldValues.code,
          title: fieldValues.title,
          floorplan: fieldValues.floorplan,
          floors: _.range(_.toNumber(fieldValues.floorMin), _.toNumber(fieldValues.floorMax) + 1),
          svgWidth: _.toNumber(fieldValues.svgWidth),
          svgHeight: _.toNumber(fieldValues.svgHeight),
          tags: _.split(fieldValues.tags, ','),
        }
      }}
      sections={[
        {
          title: 'Info',
          fields: ['code', 'title']
        },
        {
          title: 'Floors',
          fields: ['floorMin', 'floorMax', 'tags']
        },
        {
          title: 'Media',
          fields: ['floorplan', 'svgWidth', 'svgHeight']
        }
      ]}
    />
  );
});

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';

import MediaDetailScreen from './media-detail';
import DurationBar from './DurationBar';

import { updateAccount } from '../../actions'

import MediaItemAction from '../../components/MediaItemAction';
import parseDuration from '../../utils/parseDuration';

import PageHeader from '../../../../components/PageHeader';
import Table from '../../../../components/Table';

const mapStateToProps = (state, ownProps) => {
  const { accountId, playlistId } = ownProps;
  const playlist = _.get(state, ['accounts', accountId, 'playlists', playlistId]);
  const playlistEntities = _.get(state, ['accounts', accountId, 'playlists']);
  const mediaEntities = _.get(state, ['accounts', accountId, 'medias'])
  const setting = _.get(state, [ 'accounts', accountId, 'setting' ])

  return {
    ...playlist,
    playlistEntities,
    mediaEntities,
    setting,
  }
}

export default connect(mapStateToProps)(class extends React.Component {

  static defaultProps = {
    name: 'Playlist',
  }

  componentDidMount = () => {
    const { accountId, mediaClient, medias, dispatch } = this.props
    const mediaIds = _.map(medias, 'mediaId')
    mediaClient.getBulkMedias(mediaIds).then(medias => {
      dispatch(updateAccount(accountId, 'medias', medias))
    });
  }

  componentDidUpdate = (prevProps) => {
    const { accountId, mediaClient, medias, dispatch } = this.props
    if (!_.isEqual(prevProps.medias, medias)) {
      // query
      const mediaIds = _.map(medias, 'mediaId')
      // console.log('medias', { mediaClient, medias, mediaIds })
      mediaClient.getBulkMedias(mediaIds).then(medias => {
        // console.log('medias', medias)
        dispatch(updateAccount(accountId, 'medias', medias))
      });
    }
  }

  handleSortEnd = (medias) => {
    const { client, playlistId } = this.props;
    console.log('sortend', { medias });
    const updatedMedias = _.map(medias, media => _.omit(media, ['media']));
    client.updatePlaylist(playlistId, { medias: updatedMedias });
  }

  editMedia = mediaId => {
    const { match, history } = this.props;
    history.push(`${match.url}/medias/${mediaId}`);
  }

  removeMedia = mediaId => {
    const { client, playlistId, medias } = this.props;
    const updatedMedias = _.filter(medias, media => media.mediaId !== mediaId);
    // console.log({ mediaId, playlistId, updatedMedias });
    client.updatePlaylist(playlistId, { medias: updatedMedias });
  }

  handleSaveMedia = (mediaId, data) => {
    console.log('save', { mediaId, data });
    const { client, playlistId, medias } = this.props;
    const updatedMedia = _.map(medias, media => {
      if (media.mediaId === mediaId) return { ...media, ...data, updatedAt: moment().format() };
      return media;
    });
    console.log('updatedMedia', updatedMedia);
    client.updatePlaylist(playlistId, { medias: updatedMedia });
  }

  handleDeletePlaylist = () => {
    const { match, history, dispatch, client, accountId, playlistId, playlistEntities } = this.props;
    const isConfirm = window.confirm(`Are you sure to delete this playlist ${playlistId}`)
    if (isConfirm) {
      client.deletePlaylist(playlistId).then(() => {
        const remainingPlaylist = _.omit(playlistEntities, (playlist, key) => key !== playlistId)
        dispatch(updateAccount(accountId, 'playlists', remainingPlaylist ))
        const urlTokens = _.split(match.url, '/')
        const prevLocation = _.dropRight(urlTokens)
        const redirectUrl = `${_.join(prevLocation, '/')}`
        history.push(`${_.join(prevLocation, '/')}`)
      })
    }
  }

  render() {
    const { match, history, client, name, playlistEntities, medias = [], mediaEntities, setting } = this.props;
    const playlistMedias = _.map(this.props.medias, playlistQueue => {
      const _media = _.get(mediaEntities, [playlistQueue.mediaId]);
      return {
        ...playlistQueue,
        media: _media,
      }
    });
    // console.log({ playlistMedias, medias });
    const listScreenUrl = match.url;
    return (
      <React.Fragment>
        <div>
          <PageHeader
            title={name}
            renderFloatingActionButtons={() => (
              <Fab aria-label="Delete" onClick={this.handleDeletePlaylist}>
                <DeleteIcon />
              </Fab>
            )}
          />
          <DurationBar
            duration={_.reduce(medias, (duration, media) => duration + media.duration, 0)}
            maxDuration={_.get(setting, 'maxPlaylistDuration', 0)}
          />

          <Table
            columns={[
              {
                key: 'preview',
                dataIndex: 'media.url',
                title: 'Preview',
                render: (url, queue) => {
                  switch (_.get(queue, 'media.metadata.contentType')) {
                    case 'image/png':
                    case 'image/jpg':
                    case 'image/jpeg':
                      return (
                        <img key={url} src={url} alt="" style={{ width: 150 }} />
                      );
                    default:
                      return (
                        <video key={url} style={{ width: 150 }} muted>
                          <source src={url} type={queue.type} />
                        </video>
                      );
                  }
                }
              },
              { key: 'type', dataIndex: 'media.metadata.contentType', title: 'Type' },
              { key: 'title', dataIndex: 'media.title', title: 'Title' },
              { key: 'duration', dataIndex: 'duration', title: 'Duration', render: parseDuration },
              { key: 'expireAt', dataIndex: 'expireAt', title: 'Expire', render: time => moment(time).format('DD/MM/YYYY') },
              { key: 'action',
                dataIndex: 'action',
                title: 'Action',
                render: (action, media) => (
                  <MediaItemAction
                    client={client}
                    mediaId={media.mediaId}
                    playlists={playlistEntities}
                    menus={[
                      { key: 'addto-playlist'},
                      {
                        key: 'edit',
                        onClick: this.editMedia,
                      },
                      {
                        key: 'delete',
                        title: 'Remove from playlist',
                        onClick: this.removeMedia,
                      }
                    ]}
                  />
                )
              },
            ]}
            dataSource={playlistMedias}
            sortable={true}
            onSortEnd={this.handleSortEnd}
          />
        </div>
        <Route
          path={`${match.url}/medias/:mediaId`}
          children={({ match, ...rest }) => {
            const mediaId = _.get(match, 'params.mediaId');
            const media = _.find(medias, media => media.mediaId === mediaId);
            const open = media !== undefined;
            return (
              <Drawer anchor="right" open={open} onClose={() => history.goBack()}>
                <MediaDetailScreen
                  width="600px"
                  {...rest}
                  id={mediaId}
                  data={media}
                  onSave={this.handleSaveMedia}
                  canDelete={false}
                  onClose={() => {
                    history.push(listScreenUrl);
                  }}
                />
              </Drawer>
            );
          }}
        />
      </React.Fragment>
    );
  }
});

import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Route, Link, Redirect } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import engine from '../../engine';

import Header from '../../components/Header';
import PageHeader from '../../components/PageHeader';
import UnAuthorization from '../../components/UnAuthorization';
import AccountApps from './AccountApps';
import AccountMembers from './AccountMembers';
import AccountAPIKeys from './AccountAPIKeys';

import DirectoryApp from '../../apps/directory';
import FireMinkApp from '../../apps/firemink';
import MeerkatApp from '../../apps/meerkat';
import OmbresApp from '../../apps/ombres';
import PlumApp from '../../apps/plum';
import ClimbrApp from '../../apps/climbr';
import AspenApp from '../../apps/aspen';
import SplitrApp from '../../apps/splitr';
import ZentryApp from '../../apps/zentry';
import AtApp from '../../apps/@';

import allApps from './constants';
import { utility } from '../../lib'

const { permission } = utility;
const {
  verifyUserHasAccessToAccount,
} = permission;

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
})

const mapStateToProps = (state, ownProps) => {
  const accountId = _.get(ownProps, 'match.params.accountId');
  const accounts = _.get(state, `user.accounts`);
  const account = _.find(accounts, acc => acc.id === accountId);
  // console.log('accountId', accountId, account);
  return {
    account,
  }

}
export default withStyles(styles)(connect(mapStateToProps)((props) => {

  useEffect(() => {
    const fetchAccount = async () => {
      const accountId = _.get(props, 'match.params.accountId');
      // const account = await engine.project.getAccount(accountId);
      axios.defaults.headers.common['X-Forviz-Account'] = accountId;

    }
    fetchAccount();
  }, [props.match.params.accountId]);

  const { account } = props;
  const { match, classes } = props;
  const user = engine.auth.getAuthenticatedUser();
  const accountId = _.get(props, 'match.params.accountId');
  const appProps = { project: account, account, user, engine };

  if (!verifyUserHasAccessToAccount(user, accountId)) {
    return (
      <UnAuthorization
        message={`Sorry, you don't have permission to access [${accountId}] account`}
      />
    );
  }
  if (!account) return <LinearProgress />;

  if (account === null) return <LinearProgress />;

  return (
    <div className={classes.root}>
      <Header engine={engine} className={classes.appBar} />
      <div id="container" style={{ paddingTop: 64, flex: 1 }}>
        <Route
          path={`${match.url}/(apps|members|apikeys)`}
          render={routerProps => {
            const page = _.last(_.split(routerProps.match.url, '/'));
              return (
                <PageHeader position="static" style={{ marginBottom: 0, paddingBottom: 0 }} title={_.get(account, 'name', accountId)}>
                  <Tabs value={page || 'apps'} indicatorColor="primary">
                    <Tab value="apps" label="Apps" component={Link} to={`/p/${accountId}`} />
                    <Tab value="members" label="Members" component={Link} to={`/p/${accountId}/members`} />
                    <Tab value="apikeys" label="API Keys" component={Link} to={`/p/${accountId}/apikeys`} />
                  </Tabs>
                </PageHeader>
              );
          }}
        />
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            const { match, account } = props;
            const enabledApps = _.filter(allApps, app => _.get(account, `apps.${app.id}.enabled`) === true);

            // If only one app, redirect to that app.
            if (_.size(enabledApps) === 1) {
              return <Redirect to={`${match.url}/${_.get(enabledApps, '0.id')}`} />
            }

            // Else, goto apps dashboard
            return <Redirect to={`${match.url}/apps`} />
          }}
        />
        <Route
          path={`${match.url}/apps`}
          render={routerProps =>
            <AccountApps {...routerProps} {...appProps} />}
        />
        <Route path={`${match.url}/members`} render={() => <AccountMembers accountId={accountId} resourceName={`forviz:${accountId}:*:*`} />} />
        <Route path={`${match.url}/apikeys`} render={() => <AccountAPIKeys accountId={accountId} resourceName={`forviz:${accountId}:*:*`} />} />
        <Route path={`${match.url}/directory`} render={routerProps => <DirectoryApp {...routerProps} {...appProps} />} />
        <Route path={`${match.url}/firemink`} render={routerProps => <FireMinkApp {...routerProps} {...appProps} />} />
        <Route path={`${match.url}/meerkat`} render={routerProps => <MeerkatApp {...routerProps} {...appProps} />} />
        <Route path={`${match.url}/ombres`} render={routerProps => <OmbresApp {...routerProps} {...appProps} />} />
        <Route path={`${match.url}/plum`} render={routerProps => <PlumApp {...routerProps} {...appProps} />} />
        <Route path={`${match.url}/climbr`} render={routerProps => <ClimbrApp {...routerProps} {...appProps} />} />
        <Route path={`${match.url}/aspen`} render={routerProps => <AspenApp {...routerProps} {...appProps} />} />
        <Route path={`${match.url}/splitr`} render={routerProps => <SplitrApp {...routerProps} {...appProps} />} />
        <Route path={`${match.url}/zentry`} render={routerProps => <ZentryApp {...routerProps} {...appProps} />} />
        <Route path={`${match.url}/@`} render={routerProps => <AtApp {...routerProps} {...appProps} />} />
      </div>
    </div>
  );
}));

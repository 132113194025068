import ShortTextIcon from '@material-ui/icons/ShortText';
import LongTextIcon from '@material-ui/icons/Note';
import DraftJSIcon from '@material-ui/icons/ViewAgenda';
import MultipleChoiceIcon from '@material-ui/icons/RadioButtonChecked';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DropdownIcon from '@material-ui/icons/ArrowDropDownCircle';
import ImageIcon from '@material-ui/icons/Image';
import GalleryIcon from '@material-ui/icons/PhotoLibrary';
import DateIcon from '@material-ui/icons/InsertInvitation';
import TimerIcon from '@material-ui/icons/Timer';

export const TEXTFIELD = 'TextField';
export const TEXTAREA = 'TextArea';
export const DRAFTJS = 'Draft';
export const RADIO = 'Radio';
export const CHECKBOX = 'Checkbox';
export const DROPDOWN = 'Dropdown';
export const IMAGE = 'Image';
export const GALLERY = 'Gallery';
export const DATE = 'Date';
export const TIME = 'Time';

export default [
  {
    name: 'Text',
    ui: [
      { name: TEXTFIELD, icon: ShortTextIcon, img: require('./img/field-text.png') },
      { name: TEXTAREA, icon: LongTextIcon, img: require('./img/field-textarea.png') },
      { name: DRAFTJS, icon: DraftJSIcon, img: require('./img/field-textarea.png') },
    ]
  },
  {
    name: 'Choice',
    ui: [
      { name: RADIO,  icon: MultipleChoiceIcon, img: require('./img/field-radio-button.png') },
      { name: CHECKBOX,  icon: CheckBoxIcon, img: require('./img/field-checkbox.png') },
      { name: DROPDOWN,  icon: DropdownIcon, img: require('./img/field-checkbox.png') },
      // { name: 'TrueFalse', img: require('./img/field-true-false.png') },
    ]
  },
  {
    name: 'Image',
    ui: [
      { name: IMAGE, icon: ImageIcon, img: require('./img/field-image.png') },
      { name: GALLERY, icon: GalleryIcon, img: require('./img/field-gallery.png') },
      // { name: 'File', img: require('./img/field-file.png') },
    ]
  },
  {
    name: 'Date/Time',
    ui: [
      { name: 'Date', icon: DateIcon, img: require('./img/field-date-picker.png') },
      { name: 'Time', icon: TimerIcon, img: require('./img/field-date-picker.png') },
    ]
  },
];
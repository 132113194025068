import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

const Container = styled.div`
  position: relative;
  svg {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
  }
`;

const mapStateToProps = (state, ownProps) => {
  const { mediaId, buildingId } = ownProps;
  return {
    src: _.get(state, ['projects', buildingId, 'medias', mediaId, 'url']),
  }
}
export default connect(
  mapStateToProps,
)(class extends React.Component {

  static defaultProps = {
    svgWidth: 1920,
    svgHeight: 1080,
  }

  state = {
    units: [],
  }
  componentDidMount() {
    const { docRef } = this.props;
    this.unsubscribe = docRef.collection('units').onSnapshot(querySnapshot => {
      const units = _.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() }));
      this.setState({
        units,
      })
    })
  }

  componentWillUnmount() {
    this.unsubscribe();
  }
  renderUnit = (unit) => {
    return (
      <Tooltip key={unit.id} TransitionComponent={Zoom} placement="top" title={unit.id}>
        <unit.type key={unit.id} {..._.omit(unit, ['unitType'])} fill="red" opacity="0.5" />
      </Tooltip>
    );
  }
  render() {
    const { units } = this.state;
    const { svgWidth, svgHeight } = this.props;
    return (
      <Container style={{ backgroundColor: '#202A30' }}>
        <img src={this.props.src} width="100%" alt="" />
        <svg viewBox={`0 0 ${svgWidth} ${svgHeight}`} width="100%">
          <g>
            {_.map(units, unit => this.renderUnit(unit))}
          </g>
          <g>
            {_.map(this.props.units, unit => this.renderUnit(unit))}
          </g>
        </svg>
      </Container>
    );
  }
});

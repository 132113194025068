import React from 'react';
import _ from 'lodash';
import { createClient, renderContent } from 'forviz-cicjs';

export default class extends React.Component {
  state = {
    name: '',
    image: '',
    content: '',
  }
  async componentDidMount() {
    console.log('this.props', this.props);
    const { spaceId, entryId } = this.props;
    if (spaceId && entryId) {
      try {
        console.log('createClient', createClient);
        const client = createClient({ spaceId });
        const entry = await client.getEntry(entryId);
        console.log('entry', entry);
        this.setState({ ...entry.fields });

        const models = await client.getModels();
        console.log('models', models);
      } catch (error) {
        console.log('error', error);
      }
    }
  }
  render() {
    const { name, image, content } = this.state;
    return (
      <div>
        <div style={{ padding: '20px 80px' }}><h1>{name}</h1></div>
        <img src={_.get(image, '0.url')} style={{ width: '100%' }} alt="Cover" />
        <div style={{ padding: '40px 80px' }}>
          {content && <span dangerouslySetInnerHTML={{ __html: renderContent(content) }} />}
        </div>
      </div>
    );
  }
}
import React from 'react';
import _ from 'lodash';
import FirestoreDocForm from '../../components/FirestoreDocForm';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  const buildingId = _.get(ownProps, 'buildingId');
  console.log('UnitType mapStateToProps', buildingId, ownProps);
  return {
    medias: _.get(state, ['projects', buildingId, 'medias']),
  }
};

export default connect(mapStateToProps)((props) => {
  const { medias } = props;

  const fileOptions = _
    .chain(medias)
    .map((media, key) => ({ value: key, label: key, url: media.url }))
    .value();
    
  return (
    <FirestoreDocForm
      {...props}
      docRef={props.docRef}
      resources={{
        medias,
      }}
      mapDocDataToFields={(data, id) => ({
        title: { label: 'Title', value: _.get(data, 'title', id) },
        description: { label: 'Description', value: _.get(data, 'description', '') },
        tags: { label: 'Tags', value: _.join(_.get(data, 'tags', [])) },
        code: { label: 'Code', type: 'code', value: _.get(data, 'code') },
        src: {
          type: 'media',
          label: 'Source',
          value: _.get(data, 'src', ''),
          options: fileOptions
        },
        zIndex: { label: 'Z Index', value: _.get(data, 'zIndex', 0) },
      })}
      mapFieldsToDocData={fields => {
        const fieldValues = _.mapValues(fields, field => field.value);
        return {
          title: fieldValues.title,
          description: fieldValues.description,
          zIndex: _.toNumber(fieldValues.zIndex),
          code: fieldValues.code,
          src: fieldValues.src,
          tags: _.split(fieldValues.tags, ','),
        }
      }}
      sections={[
        {
          title: 'Info',
          fields: ['title', 'description']
        },
        {
          title: 'View',
          fields: ['src', 'code', 'zIndex'],
        },
        {
          title: 'Taxonomy',
          fields: ['tags']
        },
      ]}
    />
  );
});
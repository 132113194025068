import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';


import { MuiThemeProvider } from '@material-ui/core/styles';

// import { getDevicesRealTimeAction } from "./actions/devices";

// Screens
import AccountRoot from './screens/AccountRoot';
import theme from './theme';

import rootReducer from './reducers';

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}
const store = createStore(rootReducer, applyMiddleware(...middlewares));

export default class extends React.Component {
  componentDidMount() {
    // store.dispatch(getDevicesRealTimeAction());
  }
  render() {
    const { match, project: account } = this.props;
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <title>Splitr</title>
          </Helmet>
          <Switch>
            <Route path={`${match.url}`} render={routerProps => <AccountRoot {...routerProps} accountId={account.id} />} />
          </Switch>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  &:hover {
    background: #fafafa;
  }
`;

export default class extends React.PureComponent {

  renderValue = (value) => {
    return _.get(value, 'en', value);
  }
  render() {
    const { id, fields, meta, columns, onClick } = this.props;
    return (
      <StyledTableRow hover onClick={onClick}>
        {columns.map(column => <TableCell key={`${id}-${column.id}`}>{this.renderValue(fields[column.id])}</TableCell>)}
        <TableCell>{meta && meta.updatedAt && moment(_.get(meta.updatedAt, 'seconds'), 'X').fromNow()}</TableCell>
      </StyledTableRow>
    );
  }
}

import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Route, NavLink } from 'react-router-dom';
import { notify } from 'react-notify-toast';

import BackIcon from '@material-ui/icons/ArrowBack';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MatrixIcon from '@material-ui/icons/ViewComfy';
import UnitIcon from '@material-ui/icons/Room';
import UnitTypeIcon from '@material-ui/icons/Style';
import FloorplateIcon from '@material-ui/icons/Layers';
import MediaIcon from '@material-ui/icons/PhotoLibrary';
import CodeIcon from '@material-ui/icons/Code';
import UsersIcon from '@material-ui/icons/People';
import MapIcon from '@material-ui/icons/Map';
import SettingIcon from '@material-ui/icons/Settings';


import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';

import DashboardScreen from './DashboardScreen';
import BuildingMatrixScreen from './BuildingMatrixScreen';
import FloorplateScreen from './FloorplateScreen';
import FloorplateEditScreen from './FloorplateEditScreen';
import MediaLinkEditScreen from './MediaLinkEditScreen';

import UnitTypeScreen from './UnitTypeScreen';
import UnitTypeEditScreen from './UnitTypeEditScreen';
import GalleryScreen from './GalleryScreen';
import MediaLinkScreen from './MediaLinkScreen';
import UnitScreen from './UnitScreen';
import LocationMapScreen from './LocationMapScreen';
import LocationPlaceEditScreen from './LocationPlaceEditScreen';
import LocationPathEditScreen from './LocationPathEditScreen';
import LocationLayerEditScreen from './LocationLayerEditScreen';
import BuildingSettingScreen from './BuildingSettingScreen';
import BuildingSettingTheme from './BuildingSettingTheme';

import AppConfigScreen from './AppConfigScreen';
import UserManagementScreen from '../../../iam/screens/UserManagementScreen';

import FullscreenMessage from '../../components/FullscreenMessage';

import db from '../../engine/db';

import { updateProject } from '../../actions';

import UnAuthorization from '../../../../components/UnAuthorization';
import { utility } from '../../../../lib'

const { permission } = utility;
const {
  getProjectRole,
  verifyUserHasAccessToProject,
  isProjectSuperAdmin,
  isProjectAdmin,
  isProjectMediaManager,
} = permission;

const StyledListItem = styled(ListItem).attrs({
  activeClassName: 'active',
})`
  &.active {
    /* background: rgba(0, 0, 0, 0.2); */
    svg, span {
      color: #EB6323 !important;
    }
  }
`;

const Container = styled.div`
  background: #f7f7f7;
  height: calc(100vh - 60px);
`;

const Main = styled.div`
  background: white;
  height: calc(100vh - 60px);
  position: relative;
  flex-grow: 1;
  box-shadow: -1px 0 rgba(0, 0, 0, 0.15);
  overflow: auto;
`;

const mapStateToProps = (state, ownProps) => {
  const buildingId = _.get(ownProps, 'match.params.buildingId');
  const building = _.get(state, ['projects', buildingId]);
  return {
    building,
    medias: _.get(state, ['projects', buildingId, 'medias']),
  };
}

export default connect(mapStateToProps)(class extends React.Component {

  async componentDidMount() {

    const { projectId, dispatch } = this.props;
    const buildingId = _.get(this.props, 'match.params.buildingId');

    const buildingRef = db.collection('meerkat_projects').doc(buildingId);
    
    // Load Medias to building
    buildingRef.collection('medias').onSnapshot(querySnapshot => {
      const medias = _.reduce(querySnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: doc.data()}), {});
      dispatch(updateProject(buildingId, 'medias', medias));
    });

    // Load UnitTypes
    buildingRef.collection('unitTypes').onSnapshot(querySnapshot => {
      const unitTypes = _.reduce(querySnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: doc.data()}), {});
      dispatch(updateProject(buildingId, 'unitTypes', unitTypes));
    });

    // Load Floorplates
    buildingRef.collection('floorplates').onSnapshot(querySnapshot => {
      const floorplates = _.reduce(querySnapshot.docs, (acc, doc) => ({
        ...acc, [doc.id]: { id: doc.id, ...doc.data() }
      }), {});
      dispatch(updateProject(buildingId, 'floorplates', floorplates));
    });

    // Load Users to building
    const resourceName = `forviz:${projectId}:meerkat:${buildingId}`;
    fetch(`${process.env.REACT_APP_CLOUDFUNCTIONURL}/iam/resources/${resourceName}/users`)
      .then(response => response.json())
      .then(response => {
        dispatch(updateProject(buildingId, 'users', response.data));
      });
  }

  cancelEditFloorplate = () => {
    const { projectId, history } = this.props;
    const buildingId = _.get(this.props, 'match.params.buildingId');
    history.push(`/p/${projectId}/meerkat/b/${buildingId}/floorplates`);
  }

  cancelEditMediaLink = () => {
    const { projectId, history } = this.props;
    const buildingId = _.get(this.props, 'match.params.buildingId');
    history.push(`/p/${projectId}/meerkat/b/${buildingId}/media-links`);
  }

  cancelEdit = () => {
    const { projectId, history } = this.props;
    const buildingId = _.get(this.props, 'match.params.buildingId');
    history.push(`/p/${projectId}/meerkat/b/${buildingId}/unit-types`);
  }

  onSaveEdit = () => {}

  onDelete = (txt) => {
    const { projectId, history } = this.props;
    const buildingId = _.get(this.props, 'match.params.buildingId');
    history.push(`/p/${projectId}/meerkat/b/${buildingId}/unit-types`)
    notify.show(`${txt} Deleted`, 'danger');
  }

  render() {
    const { building } = this.props;
    
    // Loading
    if (!building) return (<LinearProgress />);

    // Check if requested building  is in iam_project
    const { projectId, accountId } = this.props;
    const buildingId = _.get(this.props, 'match.params.buildingId');
    
    if (_.get(building, `iam_projects.${projectId}`) !== true) return (<FullscreenMessage>You don't have permission to view this project</FullscreenMessage>);

    // System all green
    const { history, match, user, homeURL } = this.props;
    const showContent = true; // _.get(this.props, ['building', 'setting', 'module.content.enable'], false);

    const buildingName = _.get(building, 'name', 'Building');
    // console.warn('Meerkat > BuildingScreen > render!!');

    console.log('DEBUG', this)

    if (!verifyUserHasAccessToProject(user, accountId, 'meerkat', projectId)) {
      return (
        <UnAuthorization
          message={`Sorry, you don't have permission to access [${projectId}] project`}
        />
      );
    }

    const userProjectRole = getProjectRole(user, accountId, 'meerkat', projectId)
    const isSuperAdminOrAdmin = isProjectSuperAdmin(userProjectRole)

    console.log('DEBUG', { userProjectRole, isSuperAdminOrAdmin })

    return (
      <Container>
        <Helmet>
          <title>{buildingName} | Meerkat App</title>
        </Helmet>
        <div style={{ display: 'flex' }}>
          <Drawer
            variant="permanent"
            style={{ position: 'relative', width: 240, height: `calc(100vh - 60px)` }}
            PaperProps={{
              style: { top: 60, width: 240, height: `calc(100vh - 60px)` }
            }}
          >
            <List>
              <ListItem button component={NavLink} to={homeURL}>
                <ListItemIcon>
                  <BackIcon />
                </ListItemIcon>
                <ListItemText inset primary="All Projects" />
              </ListItem>
            </List>
            <div style={{ padding: 24 }}><Typography variant="h5">{buildingName}</Typography></div>
            <List component="nav" subheader={<ListSubheader component="div">Sale Status</ListSubheader>}>
              <StyledListItem button component={NavLink} exact to={`${match.url}`}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText inset primary="Dashboard" />
              </StyledListItem>
              {
                isSuperAdminOrAdmin && (
                  <StyledListItem button component={NavLink} to={`${match.url}/matrix`}>
                    <ListItemIcon>
                      <MatrixIcon />
                    </ListItemIcon>
                    <ListItemText inset primary="Matrix" />
                  </StyledListItem>
                )
              }
            </List>
            {showContent &&
              <React.Fragment>
                <Divider />
                <List component="nav" subheader={<ListSubheader component="div">Content</ListSubheader>}>
                  {isSuperAdminOrAdmin && (<StyledListItem button component={NavLink} to={`${match.url}/units`}>
                    <ListItemIcon><UnitIcon /></ListItemIcon>
                    <ListItemText inset primary="Units" />
                  </StyledListItem>)}
                  {isSuperAdminOrAdmin && (<StyledListItem button component={NavLink} to={`${match.url}/floorplates`}>
                    <ListItemIcon><FloorplateIcon /></ListItemIcon>
                    <ListItemText inset primary="Floorplates" />
                  </StyledListItem>)}
                  {isSuperAdminOrAdmin && (<StyledListItem button component={NavLink} to={`${match.url}/unit-types`}>
                    <ListItemIcon><UnitTypeIcon /></ListItemIcon>
                    <ListItemText inset primary="Unit Types" />
                  </StyledListItem>)}
                  {isSuperAdminOrAdmin && (<StyledListItem button component={NavLink} to={`${match.url}/map`}>
                    <ListItemIcon><MapIcon /></ListItemIcon>
                    <ListItemText inset primary="Location Maps" />
                  </StyledListItem>)}
                  <StyledListItem button component={NavLink} to={`${match.url}/medias`}>
                    <ListItemIcon><MediaIcon /></ListItemIcon>
                    <ListItemText inset primary="Medias" />
                  </StyledListItem>
                  {isSuperAdminOrAdmin && (<StyledListItem button component={NavLink} to={`${match.url}/media-links`}>
                    <ListItemIcon><MediaIcon /></ListItemIcon>
                    <ListItemText inset primary="Media Links" />
                  </StyledListItem>)}
                  {isSuperAdminOrAdmin && (<StyledListItem button component={NavLink} to={`${match.url}/config`}>
                    <ListItemIcon><CodeIcon /></ListItemIcon>
                    <ListItemText inset primary="App Config" />
                  </StyledListItem>)}
                </List>
              </React.Fragment>
            }
            {showContent && isSuperAdminOrAdmin && (
              <React.Fragment>
                <Divider />
                <List component="nav" subheader={<ListSubheader component="div">Admin</ListSubheader>}>
                  <StyledListItem button component={NavLink} to={`${match.url}/users`}>
                    <ListItemIcon><UsersIcon /></ListItemIcon>
                    <ListItemText inset primary="Users" />
                  </StyledListItem>
                  <StyledListItem button component={NavLink} to={`${match.url}/settings`}>
                    <ListItemIcon><SettingIcon /></ListItemIcon>
                    <ListItemText inset primary="Setting" />
                  </StyledListItem>
                  <StyledListItem button component={NavLink} to={`${match.url}/theme-settings`}>
                    <ListItemIcon><SettingIcon /></ListItemIcon>
                    <ListItemText inset primary="Theme Setting" />
                  </StyledListItem>
                </List>
              </React.Fragment>
            )}
          </Drawer>
          <Main>
            <Route
              exact
              path="/p/:projectId/meerkat/b/:buildingId"
              render={routerProps => <DashboardScreen {...routerProps} user={user} buildingId={buildingId} />}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/matrix"
              render={routerProps => <BuildingMatrixScreen {...routerProps} user={user} />}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/floorplates"
              render={routerProps => <FloorplateScreen {...routerProps} user={user} buildingId={buildingId} />}
            />
            <Route
              exact
              path="/p/:projectId/meerkat/b/:buildingId/floorplates/:floorplateId"
              children={({ match, ...rest }) => {
                const projectId = _.get(match, 'params.projectId');
                const buildingId = _.get(match, 'params.buildingId');
                const floorplateId = _.get(match, 'params.floorplateId');
                const docRef = (!_.isEmpty(floorplateId) && !_.isEmpty(buildingId)) ? db.collection('meerkat_projects').doc(buildingId).collection('floorplates').doc(floorplateId) : null;
                return (
                  <Drawer anchor="right" open={docRef !== null} onClose={() => history.goBack()}>
                    <FloorplateEditScreen
                      width="600px"                      
                      buildingId={buildingId}
                      {...rest}
                      docRef={docRef}
                      onCancel={this.cancelEditFloorplate}
                      onSave={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/floorplates`);
                        notify.show('Floorplate Saved', 'success');
                      }}
                      onDelete={this.onDelete}
                    />
                  </Drawer>
                );
              }}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/units"
              render={routerProps => <UnitScreen {...routerProps} user={user} building={building} />}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/unit-types"
              render={routerProps => <UnitTypeScreen {...routerProps} user={user} buildingId={buildingId} />}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/unit-types/:typeId"
              children={({ match, ...rest }) => {
                const projectId = _.get(match, 'params.projectId');
                const typeId = _.get(match, 'params.typeId');
                const buildingId = _.get(match, 'params.buildingId');
                const docRef = (!_.isEmpty(typeId) && !_.isEmpty(buildingId)) ? db.collection('meerkat_projects').doc(buildingId).collection('unitTypes').doc(typeId) : null;
                const open = docRef !== null;
                return (
                  <Drawer anchor="right" open={open} onClose={() => history.goBack()}>
                    <UnitTypeEditScreen
                      width="600px"
                      buildingId={buildingId}
                      {...rest}
                      docRef={docRef}
                      onCancel={this.cancelEdit}
                      onSave={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/unit-types`);
                        notify.show('UnitType Saved', 'success');
                      }}
                      onDelete={this.onDelete}
                    />
                  </Drawer>
                );
              }}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/map"
              render={routerProps => <LocationMapScreen {...routerProps} user={user} buildingId={buildingId} />}
            />
            <Route
              path={`/p/:projectId/meerkat/b/:buildingId/map/places/:placeId`}
              children={({ match, ...rest }) => {
                const projectId = _.get(match, 'params.projectId');
                const placeId = _.get(match, 'params.placeId');
                const buildingId = _.get(match, 'params.buildingId');
                const docRef = (!_.isEmpty(placeId) && !_.isEmpty(buildingId)) ? db.collection('meerkat_projects').doc(buildingId).collection('mapPlaces').doc(placeId) : null;
                const open = docRef !== null;
                return (
                  <Drawer anchor="right" open={open} onClose={() => history.goBack()}>
                    <LocationPlaceEditScreen
                      width="600px"
                      buildingId={buildingId}
                      {...rest}
                      docRef={docRef}
                      onCancel={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/map`);
                      }}
                      onSave={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/map`);
                        notify.show('Place Saved', 'success');
                      }}
                      onDelete={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/map`);
                        notify.show('Place Deleted', 'success');
                      }}
                    />
                  </Drawer>
                );
              }}
            />
            <Route
              path={`/p/:projectId/meerkat/b/:buildingId/map/paths/:pathId`}
              children={({ match, ...rest }) => {
                const projectId = _.get(match, 'params.projectId');
                const pathId = _.get(match, 'params.pathId');
                const buildingId = _.get(match, 'params.buildingId');
                const docRef = (!_.isEmpty(pathId) && !_.isEmpty(buildingId)) ? db.collection('meerkat_projects').doc(buildingId).collection('mapPaths').doc(pathId) : null;
                const open = docRef !== null;
                return (
                  <Drawer anchor="right" open={open} onClose={() => history.goBack()}>
                    <LocationPathEditScreen
                      width="600px"
                      buildingId={buildingId}
                      {...rest}
                      docRef={docRef}
                      onCancel={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/map`);
                      }}
                      onSave={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/map`);
                        notify.show('Path Saved', 'success');
                      }}
                      onDelete={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/map`);
                        notify.show('Path Deleted', 'success');
                      }}
                    />
                  </Drawer>
                );
              }}
            />
             <Route
              path={`/p/:projectId/meerkat/b/:buildingId/map/layers/:layerId`}
              children={({ match, ...rest }) => {
                const projectId = _.get(match, 'params.projectId');
                const layerId = _.get(match, 'params.layerId');
                const buildingId = _.get(match, 'params.buildingId');
                const docRef = (!_.isEmpty(layerId) && !_.isEmpty(buildingId)) ? db.collection('meerkat_projects').doc(buildingId).collection('mapLayers').doc(layerId) : null;
                const open = docRef !== null;
                return (
                  <Drawer anchor="right" open={open} onClose={() => history.goBack()}>
                    <LocationLayerEditScreen
                      width="600px"
                      buildingId={buildingId}
                      {...rest}
                      docRef={docRef}
                      onCancel={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/map`);
                      }}
                      onSave={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/map`);
                        notify.show('Layer Saved', 'success');
                      }}
                      onDelete={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/map`);
                        notify.show('Layer Deleted', 'success');
                      }}
                    />
                  </Drawer>
                );
              }}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/medias"
              render={routerProps => <GalleryScreen {...routerProps} user={user} buildingId={buildingId} />}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/media-links"
              render={routerProps => <MediaLinkScreen {...routerProps} user={user} buildingId={buildingId} />}
            />
            <Route
              exact
              path="/p/:projectId/meerkat/b/:buildingId/media-links/:mediaLinkId"
              children={({ match, ...rest }) => {
                const projectId = _.get(match, 'params.projectId');
                const buildingId = _.get(match, 'params.buildingId');
                const mediaLinkId = _.get(match, 'params.mediaLinkId');
                const docRef = (!_.isEmpty(mediaLinkId) && !_.isEmpty(buildingId)) ? db.collection('meerkat_projects').doc(buildingId).collection('mediaLinks').doc(mediaLinkId) : null;
                return (
                  <Drawer anchor="right" open={docRef !== null} onClose={() => history.goBack()}>
                    <MediaLinkEditScreen
                      width="600px"                      
                      buildingId={buildingId}
                      {...rest}
                      docRef={docRef}
                      onCancel={this.cancelEditMediaLink}
                      onSave={() => {
                        history.push(`/p/${projectId}/meerkat/b/${buildingId}/media-links`);
                        notify.show('MediaLink Saved', 'success');
                      }}
                      onDelete={this.onDelete}
                    />
                  </Drawer>
                );
              }}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/config"
              render={routerProps => <AppConfigScreen {...routerProps} user={user} buildingId={buildingId} />}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/users"
              render={routerProps => <UserManagementScreen {...routerProps} resourceName={`forviz:${projectId}:meerkat:${buildingId}`} />}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/settings"
              render={routerProps => <BuildingSettingScreen {...routerProps} buildingId={_.get(routerProps, 'match.params.buildingId')} />}
            />
            <Route
              path="/p/:projectId/meerkat/b/:buildingId/theme-settings"
              render={routerProps => <BuildingSettingTheme {...routerProps} buildingId={_.get(routerProps, 'match.params.buildingId')} />}
            />
          </Main>
        </div>
      </Container>
    );
  }
});

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import FirestoreDocEditor from '../../../../components/FirestoreDocEditor';

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, 'projectId');
  return {
    setting: _.get(state, ['projects', projectId, 'setting']),
  }
};

export default connect(mapStateToProps)((props) => {
  const { setting } = props;
  return (
    <FirestoreDocEditor
      {...props}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => {
        const languages = _.get(setting, 'languages', []);
        const nameFields = _.reduce(languages, (acc, lang) => ({
          ...acc, [_.camelCase(`name-${lang}`)]: { label: `Name (${lang})`, value: _.get(data, `fields.name.${lang}`, "") }
        }), {});

        return {
          ...nameFields,
          slug: { label: "Slug", value: _.get(data, "fields.slug") },
        }
      }}
      mapFieldValuesToDocData={fieldValues => {
        const languages = _.get(setting, 'languages', []);
        const nameValues = _.reduce(languages, (acc, lang) => ({
          ...acc, [lang]: fieldValues[_.camelCase(`name-${lang}`)] }), {});
          
        return {
          fields: {
            name: nameValues,
            slug: fieldValues.slug,
          }
        }
      }}
      sections={[
        {
          title: 'Name',
          fields: _.map( _.get(setting, 'languages', []), lang => _.camelCase(`name-${lang}`)),
        },
        {
          title: 'Slug',
          fields: ['slug'],
        },
      ]}
    />
  );
});
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { notify } from 'react-notify-toast';
import { Motion, spring } from 'react-motion';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import AddIcon from '@material-ui/icons/Add';

import PageHeader from '../../../../components/PageHeader';
import FieldEditor from '../../components/FieldEditor';

import { db } from '../../engine';

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

const reinsert = (arr, from, to) => {
  const _arr = arr.slice(0);
  const val = _arr[from];
  _arr.splice(from, 1);
  _arr.splice(to, 0, val);
  return _arr;
}

const clamp = (n, min, max) => {
  return Math.max(Math.min(n, max), min);
}

const insertArrayAt = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
];

const emptyField = {
  id: '',
  label: '',
  type: '',
  ui: '',
  options: [],
  validation: {},
}


const springConfig = {stiffness: 300, damping: 50};
// const itemsCount = 4;

const ROW_HEIGHT = 68;

const Section = styled.section`
  box-sizing: border-box;
  margin: 25px 0;
  padding: 0 20px; 
  position: relative;
`;

// const PreviewContainer = styled.div`
//   background: #f7f7f7;
//   border-left: 1px solid #ececec;
//   height: 100%;
//   padding: 20px;
// `;

class ModelDetail extends React.PureComponent {

  static defaultProps = {
    id: 'teemp',
  }

  constructor(props) {
    super(props);

    this.state = {
      model: null,
      
      topDeltaY: 0,
      mouseY: 0,
      isPressed: false,
      originalPosOfLastPressed: 0,
      draggingField: null,
      order: [],
      
      focusFieldId: null,
    }    
  }
  
  static getDerivedStateFromProps (props, state) {
    if (!_.isEqual(props.model, state.model)) {
      return { model: props.model, order: _.map(_.get(props, 'model.fields', []), (f, i) => i) };
    }
    return null;
  }

  save = (model) => {
    const { spaceId } = this.props;
    db.collection('cic_spaces').doc(spaceId).collection('models').doc(model.id).set(model, { merge: true });
    notify.show('Model Saved', 'success');

    // history.goBack();
  }

  addFieldAfterIndex = (index) => {
    const prevModel = _.get(this.props, 'model');
    const prevFields = _.get(prevModel, 'fields', []);

    const tempFieldLabel = prevFields.length === 0 ? 'Title' : `Field ${prevFields.length}`;
    const tempFieldId = _.kebabCase(tempFieldLabel);
    
    const updatedModel = {
      ...prevModel,
      fields: insertArrayAt(prevFields, index + 1, { ...emptyField, id: tempFieldId, label: tempFieldLabel, ui: 'TextField' }),
    }
    this.save(updatedModel);

    this.setState({
      focusFieldId: tempFieldId,
    });
  }

  deleteFieldAtIndex = (index) => {
    const prevModel = _.get(this.props, 'model');
    const prevFields = _.get(prevModel, 'fields', []);

    const updatedModel = {
      ...prevModel,
      fields: _.filter(prevFields, (field, fieldIndex) => fieldIndex !== index),
    }
    this.save(updatedModel);

    this.setState({
      focusFieldId: '',
    });
  }

  handleFieldChange = (fieldIndex, fieldData) => {
    // console.log('handleFieldChange', fieldIndex, fieldData);
    const prevModel = _.get(this.props, 'model');
    const updatedModel = {
      ...prevModel,
      fields: _.map(_.get(prevModel, 'fields'), (field, i) => {
        if (i === fieldIndex) {
          return { ...field, ...fieldData };
        }
        return field;
      }),
    }
    this.save(updatedModel);
  }

  setFocusFieldId = (id, e) => {
    this.setState({ focusFieldId: id });
  }

  updateFieldsOrder = () => {
    // Update Order
    const { order } = this.state;
    // console.log('order', order);
    const prevModel = _.get(this.props, 'model');
    const prevFields = prevModel.fields;
    const updatedModel = {
      ...prevModel,
      fields: _.map(_.range(_.size(order)), i => {
        const orderIndex = order[i];
        // console.log('o', i, orderIndex, prevFields);
        return _.get(prevFields, orderIndex);
      }),
    };
    if (!_.isEqual(prevModel, updatedModel)) {
      this.setState({
        focusFieldId: this.state.draggingField,
        draggingField: null,
        order: _.map(_.get(updatedModel, 'fields', []), (f, i) => i)
      });
      this.save(updatedModel);
    }
  }

  /* Rearrange Field Order */
  // handleTouchStart = (key, pressLocation, e) => {
  //   this.handleMouseDown(key, pressLocation, e.touches[0]);
  // }

  handleTouchMove = (e) => {
    e.preventDefault();
    this.handleMouseMove(e.touches[0]);
  }

  handleMouseDown = (pos, pressY, fieldId, {pageY}) => {
    // console.log('handleMouseDown', pressY, fieldId);
    
    window.addEventListener('touchmove', this.handleTouchMove);
    window.addEventListener('touchend', this.handleMouseUp);
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);

    this.setState({
      topDeltaY: pageY - pressY,
      mouseY: pressY,
      isPressed: true,
      originalPosOfLastPressed: pos,
      draggingField: fieldId,
      focusFieldId: null,
    });
  }

  handleMouseMove = ({pageY}) => {
    const {isPressed, topDeltaY, order, originalPosOfLastPressed} = this.state;
    const itemsCount = _.size(_.get(this.props, 'model.fields'));
    if (isPressed) {
      const mouseY = pageY - topDeltaY;
      const currentRow = clamp(Math.round(mouseY / ROW_HEIGHT), 0, itemsCount - 1);
      let newOrder = order;

      if (currentRow !== order.indexOf(originalPosOfLastPressed)){
        newOrder = reinsert(order, order.indexOf(originalPosOfLastPressed), currentRow);
      }

      this.setState({ mouseY: mouseY, order: newOrder });
    }
  }

  handleMouseUp = () => {
    window.removeEventListener('touchmove', this.handleTouchMove);
    window.removeEventListener('touchend', this.handleMouseUp);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);

    this.setState({isPressed: false, topDeltaY: 0}, () => {
      this.updateFieldsOrder();
    });

  }
  /* End of rearrange Field Order */

  getStyle = (i) => {
    const { isPressed, originalPosOfLastPressed, mouseY, order } = this.state;
    if (!isPressed) {
      return {
        y: mouseY,
        scale: spring(1, springConfig),
        shadow: spring(1, springConfig),
      };
    } else {
      // Pressed
      return originalPosOfLastPressed === i && isPressed
      ? {
          scale: spring(1.1, springConfig),
          shadow: spring(16, springConfig),
          y: mouseY,
        }
      : {
          scale: spring(1, springConfig),
          shadow: spring(1, springConfig),
          y: spring(order.indexOf(i) * ROW_HEIGHT, springConfig),
        };
    }
  }

  render() {
    const { model } = this.props;
    const { mouseY, isPressed, originalPosOfLastPressed, draggingField, order, focusFieldId } = this.state;
    const fields = _.get(model, 'fields', []);
    return (
      <div>
        <Paper elevation={0}>
          <PageHeader
            title={_.get(model, 'title', 'Model')}
            renderFloatingActionButtons={() => (
              <Button variant="extendedFab" color="primary" onClick={this.addFieldAfterIndex.bind(null, _.size(fields))}><AddIcon /> Add field</Button>
            )}
          />
          <Grid container justify="space-between">
            <Grid item sm={9}>
              <div>
                <Section style={{ height: ROW_HEIGHT * _.size(fields)}}>
                {_.map(fields, (field, i) => {

                  // const style = this.getStyle(i);
                  const style = originalPosOfLastPressed === i && isPressed
                  ? {
                      scale: spring(1.1, springConfig),
                      shadow: spring(16, springConfig),
                      y: mouseY,
                    }
                  : {
                      scale: spring(1, springConfig),
                      shadow: spring(1, springConfig),
                      y: spring(order.indexOf(i) * ROW_HEIGHT, springConfig),
                    };

                  return (
                    <Motion style={style} key={field.id}>
                      {({scale, shadow, y}) =>
                        <Paper
                          elevation={focusFieldId === field.id ? 6 : 1}
                          onClick={this.setFocusFieldId.bind(null, field.id)}
                          style={{
                            boxSizing: 'border-box',
                            left: 0,
                            right: 0,
                            // boxShadow: `rgba(0, 0, 0, 0.2) 0px ${shadow}px ${2 * shadow}px 0px`,
                            zIndex: field.id === draggingField ? 99 : i,
                            position: isPressed ? 'absolute' : 'relative',
                            transform: `translate3d(0, ${isPressed ? y : 0}px, 0) scale(${scale})`,
                            WebkitTransform: `translate3d(0, ${isPressed ? y : 0}px, 0) scale(${scale})`,
                          }}>
                          <FieldEditor
                            {...field}
                            fieldIndex={i}
                            focus={focusFieldId === field.id}
                            onDragStart={this.handleMouseDown.bind(null, i, y, field.id)}
                            onActionAdd={this.addFieldAfterIndex.bind(null, i)}
                            onActionDelete={this.deleteFieldAtIndex.bind(null, i)}
                            onFieldChange={this.handleFieldChange}     
                          />
                        </Paper>
                      }
                    </Motion>
                  );
                })}
                </Section>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(ModelDetail);

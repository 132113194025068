function inArray(array, el) {
  for (let i = 0 ; i < array.length; i++)
  if (array[i] === el) return true;
  return false;
}

function addRand(array, currentList) {
  const rand = array[Math.floor(Math.random() * array.length)];
  if(!inArray(currentList, rand)) {
    currentList.push(rand);
    return rand;
  }
  return addRand(array, currentList);
}

export default (randomList, wantedNumber = 3) => {
  const output = []
  for(let i = 0; i < wantedNumber; i++) {
    addRand(randomList, output);
  }
  return output
}
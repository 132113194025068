import React, { Component } from 'react';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { notify } from 'react-notify-toast';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import { db, storage } from '../../engine';

const accept = 'image/*';

const DropzoneActive = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  border: 1px dashed #ececec;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  box-sizing: border-box;
  color: #666;
  opacity: 0;
  transition: all 0.6s ease-out;

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      z-index: 9999;
    `};
`;

const StyledButton = styled(Button)`
  ${props =>
    props.active &&
    css`
      &&& {
        background-color: lightgray;
      }
    `};
`;

const mapStateToProps = (state, ownProps) => {
  const { spaceId } = ownProps;
  const { medias } = state.spaces[spaceId];
  return {
    allMedias: medias
  };
};

export default connect(mapStateToProps)(
  class extends Component {
    static getDerivedStateFromProps(nextProps, state) {
      const prevProps = state.prevProps;
      const selectedValue = !_.isEqual(prevProps.value, nextProps.value) ? nextProps.value : state.selectedValue;
      return {
        prevProps: nextProps,
        selectedValue
      };
    }
    static defaultProps = {
      multiple: false,
      value: [],
      searchText: ''
    };

    static propTypes = {
      multiple: PropTypes.bool,
      value: PropTypes.arrayOf(),
      searchText: PropTypes.string
    };

    state = {
      media: 0,
      dropzoneActive: false,
      selectedValue: [],
      prevProps: {}
    };

    handleSelect = media => {
      const { multiple } = this.props;
      let { selectedValue } = this.state;

      if (multiple) {
        const hasMedia = _.includes(selectedValue, media.id);
        const updatedValue = hasMedia ? _.filter(selectedValue, value => value !== media.id) : [...selectedValue, media.id];

        this.setState({ selectedValue: updatedValue });
      } else {
        this.setState({ selectedValue: media.id });
      }
    };

    saveSelectedMedia = () => {
      const { selectedValue } = this.state;
      this.props.onSelect(selectedValue);
    };

    /* Dropzone */
    onDragEnter = () => {
      this.setState({
        dropzoneActive: true
      });
    };

    onDragLeave = () => {
      this.setState({
        dropzoneActive: false
      });
    };

    onDrop = (acceptedFiles, rejectedFiles) => {
      this.setState({
        uploadedFiles: acceptedFiles,
        dropzoneActive: false
      });

      const { spaceId } = this.props;
      const storageRef = storage.ref(`cic/spaces/${spaceId}`);

      Promise.all(
        _.map(acceptedFiles, uploadedFile => {
          const docId = uploadedFile.name;
          const fileRef = storageRef.child(docId);
          notify.show(`Uploading ${docId}`, 'success', 4000);

          return fileRef
            .put(uploadedFile)
            .then(snapshot => {
              return Promise.all([
                Promise.resolve(uploadedFile),
                Promise.resolve(snapshot.ref),
                Promise.resolve(snapshot.metadata),
                snapshot.ref.getDownloadURL()
              ]);
            })
            .then(([file, ref, metadata, url]) => {
              return { ...file, ref, metadata, url };
            });
        })
      ).then(files => {
        db.collection('cic_spaces')
          .doc(spaceId)
          .collection('medias')
          .doc(files[0].ref.name)
          .set({
            ref: files[0].ref.toString(),
            title: files[0].ref.name,
            url: files[0].url,
            metadata: {
              updated: files[0].metadata.updated,
              size: files[0].metadata.size
            }
          });
        this.setState({ uploadedFiles: files });
      });
    };

    render() {
      const { allMedias, multiple, searchText } = this.props;
      const { dropzoneActive, selectedValue } = this.state;

      const visibleMedias = searchText !== '' ? _.filter(allMedias, media => _.includes(_.toLower(media.title), _.toLower(searchText))) : allMedias;

      return (
        <React.Fragment>
          <ul
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            <Dropzone
              ref={node => {
                this.dropzoneRef = node;
              }}
              disableClick
              accept={accept}
              style={{ position: 'relative' }}
              onDrop={this.onDrop}
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
            >
              <DropzoneActive visible={dropzoneActive}>Drop files...</DropzoneActive>
              <Button
                color="primary"
                variant="extendedFab"
                style={{ width: 150, height: 125, margin: '8px 16px' }}
                onClick={() => {
                  this.dropzoneRef.open();
                }}
              >
                <AddIcon />
                Upload
              </Button>
              {_.map(visibleMedias, media => {
                let hasMedia;
                if (multiple) {
                  hasMedia = _.some(selectedValue, value => value === media.id);
                } else {
                  hasMedia = _.isEqual(selectedValue, media.id);
                }
                return (
                  <StyledButton key={media.url} onClick={() => this.handleSelect(media)} active={hasMedia}>
                    <div style={{ width: 150, height: 150 }}>
                      <img
                        src={media.url}
                        style={{
                          width: '100%',
                          height: '85%',
                          objectFit: 'cover'
                        }}
                        alt=""
                      />
                      <div style={{ fontSize: '12px' }}>{media.title}</div>
                    </div>
                  </StyledButton>
                );
              })}
            </Dropzone>
            <div style={{ textAlign: 'right', width: '100%' }}>
              <Button variant="contained" color="primary" onClick={this.saveSelectedMedia}>
                Save
              </Button>
            </div>
          </ul>
        </React.Fragment>
      );
    }
  }
);

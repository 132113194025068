import qs from 'query-string';
// import _ from 'lodash';
import { fetchSpaceEntries, updateSpaceEntry } from "../../mink/api";

const BASEURL = process.env.REACT_APP_BASEURL;
const REACT_APP_BASEURL_PROPERTY = process.env.REACT_APP_BASEURL_PROPERTY || 'http://localhost:5000/forviz-2017/us-central1/meerkat';

const getAccessToken = user => user.stsTokenManager.accessToken;

export const createProject = user => (name, accountId, data = {}) => {
  return fetch(`${BASEURL}/meerkatCreateProject`, {
    headers: {
      Authorization: `Bearer ${getAccessToken(user)}`,
      'Forviz-iam-project-Id': accountId,
    },
    method: 'POST',
    body: JSON.stringify({
      name,
      ...data,
    })
  })
    .then(res => res.json())
    .then(res => {
      if (res.status === 'error') throw new Error(res.message);
      return res.data;
    });
}


export const fetchUnits = user => spaceId => {
  return fetchSpaceEntries(user)(spaceId, { model: "unit" });
};

export const fetchSales = user => spaceId => {
  return fetchSpaceEntries(user)(spaceId, { model: "sale" });
};

export const reserveUnit = user => ({ projectId, unitId, saleId }) => {
  return fetch(`${REACT_APP_BASEURL_PROPERTY}/${projectId}/${unitId}/reserve?${qs.stringify({ saleId })}`, {
    headers: { Authorization: `Bearer ${getAccessToken(user)}` }
  })
    .then(res => res.json())
    .then(res => {
      if (res.status !== 'success') throw new Error(res.message);
      console.log('reserveUnitResponse', res)
      return res.responseData
    });
}

export const cancelReserveUnit = user => ({ projectId, unitId, reserveId }) => {
  return fetch(`${REACT_APP_BASEURL_PROPERTY}/${projectId}/${unitId}/reserve/${reserveId}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${getAccessToken(user)}` }
  })
    .then(res => res.json())
    .then(res => {
      if (res.status !== 'success') throw new Error(res.message);
      console.log('reserveUnitResponse', res)
      return 'success'
    });
}

export const updateUnitStatus = user => ({ projectId, unitId, dataToUpdate }) => {
  return fetch(`${REACT_APP_BASEURL_PROPERTY}/${projectId}/${unitId}`, {
    method: 'PUT',
    headers: { Authorization: `Bearer ${getAccessToken(user)}` },
    body: JSON.stringify(dataToUpdate)
  })
    .then(res => res.json())
    .then(res => {
      if (res.status !== 'success') throw new Error(res.message);
      console.log('reserveUnitResponse', res)
      return 'success'
    });
}

export const updateUnit = user => ({ spaceId, unitId, dataToUpdate }) => {
  const entryId = unitId;
  const model = "unit";
  const data = dataToUpdate;
  return updateSpaceEntry(user)(spaceId, entryId, model, data);
};


import React from 'react';
import styled from 'styled-components';

const Body = styled.rect.attrs((props) => {
  switch (props.screenSize) {
    case '1920x1080':
      return {
        x: 200,
        y: 0,
        width: 2000,
        height: 1160 
      }
    case '1080x1920':
    default:
      return {
        x: 620,
        y: 0,
        width: 1160,
        height: 2400 
      }
  }
})`
  stroke: white;
  stroke-width: 8px;
  fill: none;

  transition: all 0.3s ease-out;
`;

const Screen = styled.rect.attrs((props) => {
  switch (props.screenSize) {
    case '1920x1080':
      return {
        x: 240,
        y: 40,
        width: 1920,
        height: 1080 
      }
    case '1080x1920':
    default:
      return {
        x: 660,
        y: 40,
        width: 1080,
        height: 1920 
      }
  }
})`
  stroke: white;
  stroke-width: 4px;
  fill: url(#diagonal-stripe-2);
  transition: all 0.3s ease-out;
`;

const Blocks = ({ screenSize, layout }) => {
  switch (screenSize) {
    case '1920x1080': {
      switch (layout) {
        case '30:70':
          return (
            <g>
              <rect x={240} y={40} width={576} height={1080} fill="red" fillOpacity={0.3} />
              <rect x={816} y={40} width={1344} height={1080} fill="blue" fillOpacity={0.3} />
            </g>
          );
        case '50:50':
          return (
            <g>
              <rect x={240} y={40} width={960} height={1080} fill="red" fillOpacity={0.3} />
              <rect x={1200} y={40} width={960} height={1080} fill="blue" fillOpacity={0.3} />
            </g>
          );
        case '70:30':
          return (
            <g>
              <rect x={240} y={40} width={1344} height={1080} fill="red" fillOpacity={0.3} />
              <rect x={1584} y={40} width={576} height={1080} fill="blue" fillOpacity={0.3} />
            </g>
          );
        case 'fullscreen':
        default:
          return <rect x={240} y={40} width={1920} height={1080} fill="red" fillOpacity={0.3} />
      }  
    }
    
    case '1080x1920':
    default: {
      switch (layout) {
        case '30:70':
          return (
            <g>
              <rect x={660} y={40} width={1080} height={576} fill="red" fillOpacity={0.3} />
              <rect x={660} y={616} width={1080} height={1344} fill="blue" fillOpacity={0.3} />
            </g>
          );
        case '50:50':
          return (
            <g>
              <rect x={660} y={40} width={1080} height={960} fill="red" fillOpacity={0.3} />
              <rect x={660} y={1000} width={1080} height={960} fill="blue" fillOpacity={0.3} />
            </g>
          );
        case '70:30':
          return (
            <g>
              <rect x={660} y={40} width={1080} height={1344} fill="red" fillOpacity={0.3} />
              <rect x={660} y={1384} width={1080} height={576} fill="blue" fillOpacity={0.3} />
            </g>
          );
        case 'fullscreen':
        default:
          return <rect x={660} y={40} width={1080} height={1920} fill="red" fillOpacity={0.3} />
      }
    }
  }
}


const Text = styled.text.attrs((props) => {
  switch (props.screenSize) {
    case '1920x1080':
      return {
        textAnchor: 'middle',
        x: 1200,
        y: 580,
        height: 40,
      }
    case '1080x1920':
    default:
      return {
        textAnchor: 'middle',
        x: 1200,
        y: 960,
        height: 40,
      }
  }
})`
  fill: white;
  font-size: 84px;
`;


export default ({ screenSize = '1920x1080', layout="fullscreen" }) => (
  <svg viewBox="0 0 2400 2400">
    <defs>
      <pattern id="diagonal-stripe-2" patternUnits="userSpaceOnUse" width="48" height="48">
        <image xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSd3aGl0ZScvPgogIDxwYXRoIGQ9J00tMSwxIGwyLC0yCiAgICAgICAgICAgTTAsMTAgbDEwLC0xMAogICAgICAgICAgIE05LDExIGwyLC0yJyBzdHJva2U9J2JsYWNrJyBzdHJva2Utd2lkdGg9JzInLz4KPC9zdmc+" x="0" y="0" width="10" height="10"> </image>
      </pattern>
    </defs>
    <Body screenSize={screenSize.text} />
    <Screen screenSize={screenSize.text} layout={layout} />
    <Blocks screenSize={screenSize.text} layout={layout} />
    <Text screenSize={screenSize.text}>{screenSize.text}</Text>
  </svg>
)
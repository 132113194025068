import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

const pendingColor = '#ff784e';
const activeColor = '#357a38';

const Container = styled.div`
  position: relative;
  svg {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
  }
`;


export default class extends React.Component {

  static defaultProps = {
    svgWidth: 1920,
    svgHeight: 1080,
    units: undefined,
    nodes: undefined,
  }

  state = {
    units: [],
  }
  componentDidMount() {
    const { docRef } = this.props;
    this.unsubscribe = docRef.collection('units').onSnapshot(querySnapshot => {
      const units = _.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() }));
      this.setState({
        units,
      })
    })
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  renderLine = (line) => {
    return (
      <line key={line.id} {...line} stroke="#357a38" strokeWidth={2} opacity="0.5" />
    );
  }

  renderNode = (node, status = 'active') => {
    return (
      <Tooltip key={node.id} TransitionComponent={Zoom} placement="top" title={node.id}>
        <circle key={node.id} cx={node.x} cy={node.y} r={4} fill={status === 'active' ? activeColor : pendingColor} opacity="0.5" />
      </Tooltip>
    );
  }

  renderUnit = (unit, status = 'active') => {
    return (
      <Tooltip key={unit.id} TransitionComponent={Zoom} placement="top" title={unit.id}>
        <unit.type key={unit.id} {..._.omit(unit, ['elements'])} fill={status === 'active' ? activeColor : pendingColor} opacity="0.5" />
      </Tooltip>
    );
  }

  render() {
    const { src, svgWidth, svgHeight } = this.props;

    const errorNodes = [];

    const units = this.props.units ? this.props.units : this.state.units;
    const nodes = this.props.nodes ? this.props.nodes : this.state.nodes;

    const nodeDatas = _.reduce(nodes, (acc, node) => ({ ...acc, [node.id]: node }), {});
    const nodeLines = _.reduce(nodes, (acc, node) => {
      return [
        ...acc,
        ..._.map(node.neighbor, nodeId => {
          const destNode = nodeDatas[nodeId];
          if (!destNode) errorNodes.push(nodeId);
          return {
            id: `${node.id}-${nodeId}`,
            x1: node.x,
            y1: node.y,
            x2: _.get(nodeDatas, `${nodeId}.x`, 0),
            y2: _.get(nodeDatas, `${nodeId}.y`, 0),
          };
        }),
      ];
    }, []);
    return (
      <Container style={{ backgroundColor: '#202A30' }}>
        <img src={src} width="100%" alt="" />
        <svg viewBox={`0 0 ${svgWidth} ${svgHeight}`} width="100%">
          <g>
            {_.map(nodeLines, line => this.renderLine(line))}
          </g>
          <g>
            {_.map(nodes, node => this.renderNode(node))}
          </g>
          <g>
            {_.map(units, unit => this.renderUnit(unit))}
          </g>
        </svg>
      </Container>
    );
  }
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "renderContent", {
  enumerable: true,
  get: function get() {
    return _renderContent.default;
  }
});
exports.createClient = void 0;

var _App = _interopRequireDefault(require("./App.js"));

var _renderContent = _interopRequireDefault(require("./utils/renderContent"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var createClient = function createClient(_ref) {
  var spaceId = _ref.spaceId;
  var app = new _App.default({
    spaceId: spaceId
  });
  return app;
}; // -----------

/*
import cicjs from 'forviz-cicjs';

const client = cicjs.createClient(spaceId);

componentDidMount() {
  client.getEntries();
}
*/


exports.createClient = createClient;
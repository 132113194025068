import _ from 'lodash';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVED/FORMS':
      return _.reduce(action.forms, (acc, form) => ({ ...acc, [form.id]: form }), {});

    case 'FORM/CREATE':
      return {
        ...state,
        [action.id]: action.form,
      }
    case 'FORM/UPDATE':
      return {
        ...state,
        [action.id]: { ...state[action.id], ...action.data }
      }
    
    case 'FORM/DELETE':
      return {
        ..._.omit(state, [action.id]),
      }
    default: return state;
  }
}
import React from 'react';
import _ from 'lodash';
import anime from 'animejs';
import styled from 'styled-components';
import { ReactSVGPanZoom } from 'react-svg-pan-zoom';
 
import _4F from './assets/4F_San Francisco.svg';
import _3F from './assets/3F_Istanbul.svg';
import _2F from './assets/2F_London.svg';
import _1F from './assets/1F_Tokyo.svg';
import _MF from './assets/MF_Paris.svg';
import _GF from './assets/GF_Rome.svg';

const floors = [
  _GF,
  _MF,
  _1F,
  _2F,
  _3F,
  _4F,
];

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.floorplans = {};

    this.state = {
      currentFloor: null,
    }
  }

  componentDidMount() {
    this.unfocusFloor();
  }

  componentDidUpdate (prevState) {
    if (this.state.currentFloor !== prevState.currentFloor) {
      if (this.state.currentFloor === null) {
        this.unfocusFloor();
      } else {
        this.focusFloor(this.state.currentFloor)
      }
    }
  }

  unfocusFloor = () => {
    anime({
      targets: ".floor",
      duration: 300,
      translateY: (el, index) => (floors.length - index - 1) * 200,
      translateX: -100,
      scale: 0.75,
      rotateX: '45deg', 
      rotateZ: '-30deg',
      opacity: 1,
      elasticity: 0,
      easing: 'easeOutQuart'
    });

    _.forEach(this.floorplans, floor => {
      floor.fitToViewer();
    });
  }

  focusFloor = (currentIndex) => {
    anime({
      targets: `.floor`,
      duration: (el, index) => currentIndex === index ? 900 : 600,
      delay: (el, index) => currentIndex === index ? 200 : index * 50,
      rotateX: 0,
      rotateZ: 0,
      scale: (el, index) => currentIndex === index ? 1 : 0.5,
      translateX: 0,
      translateY: (el, index) => (index - currentIndex) * -600,
      opacity: (el, index) => currentIndex === index ? 1 : 0,
      elasticity: 0,
      easing: 'easeOutQuart'
    });

    this.floorplans[currentIndex].setPointOnViewerCenter(960, 540, 1.5);
  }

  handleClickFloor = index => {
    this.setState({
      currentFloor: this.state.currentFloor === null ? index : null,
    });
  }

  clickOutside = () => {
    if (this.state.currentFloor !== null) this.setState({ currentFloor: null });
  }

  render() {
    const { currentFloor } = this.state;
    return (
      <div>
        <div style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'scroll' }} onClick={this.clickOutside}>
          {floors.map((floor, index) => (
            <div
              className="floor"
              key={`floor-${index}`}
              id={`floor-${index}`}
              style={{
                position: 'absolute',
                transformOrigin: '50% 50%',
                top: 0,
                left: 0,
                zIndex: (index + 1),
              }}
            >
              <ReactSVGPanZoom
                ref={Viewer => this.floorplans[index] = Viewer}
                width={1080}
                height={currentFloor === index ? 1080 : 607 }
                tool={currentFloor === index ? 'pan' : null}
                className="viewer"
                style={{
                  'svg > g': {
                    transition: 'all .5s',
                  }
                }}
                toolbarPosition="none"
                miniaturePosition="none"
                background="transparent"
              >
                <svg width={1920} height={1080} viewBox="0 0 1920 1080">
                  <image xlinkHref={floor} x={0} y={0} width={1920} height={1080} alt="" onClick={e => this.handleClickFloor(index)}/>
                </svg>
              </ReactSVGPanZoom>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
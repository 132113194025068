import React from 'react';
import { Route } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Fuse from 'fuse.js';

import _ from 'lodash';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
// import { Flipper, Flipped } from 'react-flip-toolkit';
import { notify } from 'react-notify-toast';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import BackIcon from '@material-ui/icons/ArrowBack';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import AddIcon from '@material-ui/icons/Add';

// import {
//   saveMediaToFirestore,
//   deleteMediaFromFirestore,
//   uploadToStorage,
//   deleteFromStorage
// } from 'forviz-mediajs';

import AddMediaDialog from './AddMediaDialog';

import Confirm from '../../../../components/ConfirmDialog';
import PageHeader from '../../../../components/PageHeader';
import MediaItem from '../../../../components/MediaItem';

const accept = 'image/*,video/*';

const styles = {
  fullscreen: {
    position: 'fixed',
    zIndex: 1300,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'black',
  },
  imgWrapper: {
    position: 'absolute',
    left: 0,
    right: 300,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
};

const DropzoneActive = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  opacity: 0;
  border: 1px dashed #ececec;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  box-sizing: border-box;
  color: #666;
  transition: all 0.6s ease-out;

  ${props => props.visible && css`
    opacity: 1;
    z-index: 9999;
  `};
`;

const InfoPanel = styled.div`
  width: 300px;
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0;
  bottom: 0;
`;

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;
  const medias = _.get(state, ['projects', projectId, 'medias']);
  return {
    medias,
    mediaEntities: _.map(medias, media => media),
  }
}

export default connect(
  mapStateToProps,
)(class extends React.Component {
  
  constructor(props) {
    super(props);

    this.inputID = React.createRef();
    this.inputTitle = React.createRef();
    this.inputTags = React.createRef();

    this.state = {
      dropzoneActive: false,
      uploadedFiles: [],
      focusedImageId: null,
      showMediaDialog: false,
      searchText: '',
      currentTag: 'all',
      orderBy: 'metadata.updated',
      orderDirection: 'desc',
    }

    this.notify = notify.createShowQueue();

  }

  componentDidUpdate(prevProps) {
    // console.log('update', this.props.mediaEntities);
    if (!_.isEqual(prevProps.mediaEntities, this.props.mediaEntities)) {
      const fuseOptions = {
        shouldSort: true,
        threshold: 0.1,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "title",
          "id",
          "tags"
        ]
      };
      this.fuse = new Fuse(this.props.mediaEntities, fuseOptions); 
    }
  }

  /* Dropzone */
  onDragEnter = () => {
    this.setState({
      dropzoneActive: true
    });
  }

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false
    });
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { client } = this.props;
    console.log('onDrop', { acceptedFiles, rejectedFiles });
    this.setState({
      uploadedFiles: acceptedFiles,
      dropzoneActive: false
    });

    this.notify('Uploading', 'success', 4000);
    Promise.all(
      _.map(acceptedFiles, uploadedFile => {
       return client.uploadToStorage(uploadedFile).catch(e => {
         console.log('client upload error', e.message, e.ref);
         return { status: 'error', code: e.message, ref: e.ref, uploadedFile };
       });
      }),
    ).then(files => {
      console.log('upload all complete', files);
      this.setState({ uploadedFiles: files, showMediaDialog: true });
      this.notify('Upload All Complete', 'success', 4000);
    });

  }

  handleCloseDialog = () => this.setState({ showMediaDialog: false })

  /* End of Dropzone */

  deleteMedia = (mediaId) => {
    const { client, history, match } = this.props;
    const { medias } = this.props;
    const media = _.get(medias, mediaId);

    // Delete Firestore
    // this.setState({ focusedImageId: null });
    this.notify(`Deleting Media`, 'success', 4000);
    client.deleteFromStorage(media.ref).then(() => {
      this.notify(`Medias deleted from storage`, 'success', 4000);
    });

    client.deleteMediaFromFirestore(mediaId).then(() => {
      this.notify(`Medias deleted from firestore`, 'success', 4000);
    });

    history.push(match.url);
  }

  handleChangeSearch = (e) => {
    this.setState({ searchText: e.target.value });
  }

  handleChangeTag = (e, value) => this.setState({ currentTag: value })

  updateMediaInfo = (mediaId, e) => {
    const { client, medias } = this.props;
    const id = this.inputID.current.value;
    const title = this.inputTitle.current.value;
    const tags = this.inputTags.current.value;
    const media = _.get(medias, id);
    // console.log('updateMediaInfo', media);
    // console.log('save', id, title, tags);
    client.saveMediaToFirestore(id, media.ref, {
      title,
      tags:  _.map(_.split(tags, ','), _.trim)
    })
    .then(() => {
      this.notify(`Medias update`, 'success', 4000);
    });
  }

  /* ReUpload */
  reUpload = (doc) => {
    this.reUploadDropzoneRef.open();
  }

  reUploadOnDrop = (acceptedFiles, rejectedFiles, mediaId) => {
    const { client, medias } = this.props;
    // const { focusedImageId } = this.state;
    const focusedImage = mediaId !== null ? _.get(medias, mediaId) : null;
    
    const uploadedFile = acceptedFiles[0];
    this.notify(`Re-Uploading ${mediaId}`, 'success', 4000);
        
    // console.log('uploadedFile', uploadedFile);
    client.uploadToStorage(uploadedFile, focusedImage.ref)
      .then(file => {
        this.notify(`Re-Uploaded ${mediaId} complete`, 'success', 4000);
        return client.saveMediaToFirestore(mediaId, file.ref.toString(), { url: file.url })
      })
      .then(() => {
        this.notify(`Update Media ${mediaId} complete`, 'success', 4000);
      });
  }

  renderMediaItem = (item) => {
    const ext = _.last(_.split(item, '.'));
    switch (ext) {
      case 'mp4':
        return (
          <div style={{ width: '100%', paddingTop: '62.5%' }}>
            <video style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
              <source src={item.url} type="video/mp4" />
            </video>
          </div>
        );
      default:
        return (
          <React.Fragment>
            <img src={item.url} alt="" style={{ maxWidth: '100%' }} />
            <span>{item.title}</span>
          </React.Fragment>
        );
    }
  }

  applyZIndex = el => {
    el.style.zIndex = 1300;
  }

  removeZIndex = el => {
    el.style.zIndex = "";
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (!_.isEqual(prevState.entities, this.state.entities)) {
  //     const fuseOptions = {
  //       shouldSort: true,
  //       threshold: 0.1,
  //       location: 0,
  //       distance: 100,
  //       maxPatternLength: 32,
  //       minMatchCharLength: 1,
  //       keys: [
  //         "fields.name.en",
  //         "fields.name.th",
  //       ]
  //     };
  //     this.fuse = new Fuse(this.state.entities, fuseOptions); 
  //   }
  // }


  filterItems = (entities, currentTag, searchText) => {

    const searchResultItems = (searchText !== '' && typeof this.fuse.search === 'function') ? this.fuse.search(searchText) : entities;
    // console.log('searchResultItems', searchResultItems);
    const result = currentTag === 'all' ? searchResultItems : _.filter(searchResultItems, item => _.includes(item.tags, currentTag));
    // const result = _.filter(searchResultItems, item => {
    //   // console.log('this.state.filters', item, this.state.filters);
    //   return _.every(this.state.filters, (filterValue, filterKey) => {
    //     // console.log(filterKey, filterValue);
    //     return filterValue === '' || _.get(item, filterKey) === filterValue;
    //   });
    // })
    // console.log('filterResult', result);
    return result;
  }
  
  render() {
    const { medias, mediaEntities, client, match, history } = this.props;

    // const allTags = this.state.tags;
    // console.log('medias', medias);    
    const allTags = _.compact(_.flatten(_.map(medias, item => item.tags)));
    // console.log('allTags', allTags);

    const { dropzoneActive, uploadedFiles, searchText, currentTag, showMediaDialog } = this.state;

    const filteredItems = this.filterItems(mediaEntities, currentTag, searchText);

    // const items = currentTag === 'all' ? medias : _.pickBy(medias, item => _.includes(item.tags, currentTag));
    const orderedItems = _.orderBy(filteredItems, [this.state.orderBy], [this.state.orderDirection]);
    // const focusedImage = focusedImageId !== null ? _.get(medias, focusedImageId) : null;
    // console.log('orderedItems', orderedItems);
    return (
      <div>
        <PageHeader
          title="Medias"
          renderFloatingActionButtons={() => (
            <Button color="primary" variant="extendedFab" onClick={() => { this.dropzoneRef.open() }}>
              <AddIcon />Upload
            </Button>
          )}
        />
        <Dropzone
          ref={(node) => { this.dropzoneRef = node; }}
          disableClick
          accept={accept}
          style={{position: 'relative' }}
          onDrop={this.onDrop}
          maxSize={50 * 1000 * 1000}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
        >
          <DropzoneActive visible={dropzoneActive}>Drop files...</DropzoneActive>
          <div>
            <div style={{ padding: 24 }}>
              <div>
                <Grid container spacing={24}>
                  <Grid item sm={12}>
                    {currentTag !== 'all' && (
                      <Chip
                        label={currentTag}
                        variant="outlined"
                        onDelete={e => this.handleChangeTag(e, 'all')}
                        color="secondary"
                      />
                    )}
                  </Grid>
                  <Grid item sm={9}>
                    <Grid container spacing={24} style={{ marginTop: 12 }}>
                      {_.map(orderedItems, (item) => (
                        <Grid item sm={6} md={3} lg={2} key={item.id}>
                          <MediaItem onClick={() => history.push(`${match.url}/${item.id}`)} {...item} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item sm={3}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={searchText}
                      onChange={this.handleChangeSearch}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                      }}
                    />
                    <List
                      subheader={<ListSubheader component="div">Tags</ListSubheader>}
                    >
                      {_.map(_.uniq(allTags), tag => (
                        <ListItem button key={tag} selected={currentTag === tag} onClick={e => this.handleChangeTag(e, tag)}>
                          <ListItemText primary={tag} />
                          <ListItemSecondaryAction>
                            <Chip label={_.sumBy(allTags, t => t === tag)} variant="outlined" />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Route
              path={`${match.url}/:mediaId`}
              render={(routerProps) => {
                const focusedImageId = _.get(routerProps, 'match.params.mediaId');
                const focusedImage = _.get(medias, focusedImageId);
                return (
                  <div style={styles.fullscreen}>
                    <div style={styles.imgWrapper}>
                      <img
                        onClick={() => this.setState({ focusedImageId: null })}
                        src={_.get(focusedImage, 'url')}
                        alt=""
                        style={{ maxWidth: 'calc(100% - 300px)', height: 'auto' }}
                      /> 
                    </div>
                    <AppBar position="fixed" style={{ background: 'rgba(0, 0, 0, 0.8)' }}>
                      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <IconButton onClick={() => history.push(`${match.url}`)} style={{ color: 'white' }}><BackIcon /></IconButton>
                        <Typography variant="h6" color="inherit">
                          {_.get(focusedImage, 'title')}
                        </Typography>
                        <IconButton></IconButton>
                      </Toolbar>
                    </AppBar>
                    <InfoPanel>
                      <Paper style={{ padding: 20, height: '100%' }}>
                        <Paper elevation={0} style={{ margin: '20px 0' }}>
                          <Dropzone
                            ref={(node) => { this.reUploadDropzoneRef = node; }}
                            multiple={false}
                            accept={accept}
                            disableClick
                            style={{ position: 'relative' }}
                            onDrop={(acceptedFiles, rejectedFiles) => this.reUploadOnDrop(acceptedFiles, rejectedFiles, focusedImageId)}
                          >
                            <Typography style={{ fontSize: '0.8em', opacity: 0.6 }}>If you wanna just reupload the file, but keep everything the same.</Typography>                   
                            <Button variant="outlined" color="default" fullWidth onClick={e => this.reUpload(focusedImage)}>Reupload</Button>
                          </Dropzone>
                        </Paper>
                        <Divider />
                        <Paper elevation={0} style={{ margin: '20px 0' }}>
                          <FormControl fullWidth style={{ marginBottom: 20 }}>
                            <TextField
                              variant="outlined"
                              key={`id-${focusedImageId}`}
                              inputRef={this.inputID}
                              fullWidth
                              label="ID"
                              defaultValue={focusedImageId}
                              disabled
                            />
                          </FormControl>
                          <FormControl fullWidth style={{ marginBottom: 20 }}>
                            <TextField
                              variant="outlined"
                              key={`title-${focusedImageId}`}
                              inputRef={this.inputTitle}
                              fullWidth
                              label="Title"
                              defaultValue={_.get(focusedImage, 'title')}
                            />
                          </FormControl>
                          <FormControl fullWidth style={{ marginBottom: 20 }}>
                            <TextField
                              variant="outlined"
                              key={`tags-${focusedImageId}`}
                              inputRef={this.inputTags}
                              fullWidth
                              label="Tags"
                              defaultValue={_.join(_.get(focusedImage, 'tags'), ',')}
                            />
                          </FormControl>
                          <Button  variant="contained" fullWidth color="primary" onClick={e => this.updateMediaInfo(focusedImageId, e)}>Save</Button>
                        </Paper>
                        <Divider />
                        <Paper elevation={0} style={{ margin: '20px 0' }}>
                          <Confirm
                            render={() => <Button color="primary" variant="outlined" fullWidth>Delete</Button>}
                            onOk={() => this.deleteMedia(focusedImageId)}
                            content="Confirm Delete this?"
                          />
                          
                        </Paper>
                      </Paper>
                    </InfoPanel>
                  </div>
                )
              }}
            />
          </div>
          <AddMediaDialog open={showMediaDialog} onClose={this.handleCloseDialog} files={uploadedFiles} client={client} />
        </Dropzone>
      </div>
    );
  }
});


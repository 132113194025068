import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

const List = styled.ol`
  list-style: none;
  padding: 4px 10px;

  li {
    display: flex;
    align-items: center;

    .list-number {
      flex: 0;
      margin-right: 5px;
      opacity: 0.78;
      font-size: 0.8em;
    }
  }
`;

export default class extends React.Component {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    render: PropTypes.func,
  }

  handleSingleChange = index => e => {
    const v = e.target ? _.get(e, 'target.value') : e;
    console.log('handleSingleChange', index, v);
    const { value } = this.props;
    const updatedValue = _.map(value, (singleValue, valueIndex) => {
      if (index === valueIndex) return v;
      return singleValue;
    });

    this.props.onChange(updatedValue);
  }

  addItem = () => {
    const value = _.get(this.props, 'value', []);
    this.props.onChange([...value, `Option ${_.size(value) + 1}`]);
  }

  deleteItem = index => e => {
    const value = _.get(this.props, 'value', []);
    const updatedValue = _.filter(value, (v, i) => i !== index);
    this.props.onChange(updatedValue);
  }

  render() {
    const { value, render } = this.props;
    return (
      <List>
        {_.map(value, (singleValue, index) => {
          return (
            <li key={`field-${index}`}>
              <div className="list-number">{index + 1}.</div>
              <div style={{ flexGrow: 1 }}>
                {render({
                  inputProps: {
                    value: singleValue,
                    onChange: this.handleSingleChange(index),
                  }
                })}
              </div>
              <div style={{ flex: 0 }}>
                <IconButton onClick={this.deleteItem(index)}><CloseIcon fontSize="small" /></IconButton>
              </div>
            </li>
          );
        })}
        <li key={`field-new`}>
          <div className="list-number">{_.size(value) + 1}.</div>
          <Button onClick={this.addItem} variant="outlined" size="small">Add Option</Button>
        </li>  
      </List>
    );
  }
}
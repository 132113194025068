import { createModule } from 'redux-modux';
import _ from 'lodash'
import { EVENT_NAMES } from '../../config';

const initialState = []

export const getLogsByEventName = (logs, eventName) => {
  const searchEventIndex = _.findIndex(EVENT_NAMES, EVENT_NAME => EVENT_NAME === eventName)
  const searchLogs = logs[searchEventIndex] || {}
  return searchLogs
}

const setLogs = (state, action) => {
  const nextState = {
    ...state,
    ...action.data,
  }
  return nextState
};


const handlers = {
  setLogs
};

export default createModule({
  moduleName: 'Logs', // Optional (Prefix when console.log  action's type)
  initialState,
  handlers
});
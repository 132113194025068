import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import FirestoreDocEditor from '../../../../components/FirestoreDocEditor';

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, 'projectId');
  const setting = _.get(state, ['projects', projectId, 'setting']);
  
  const medicalCenters = _.get(state, ['projects', projectId, 'medicalCenters']);
  const medicalCenterOptions = _.map(medicalCenters, (cat, id) => ({
    id,
    value: id,
    label: _.get(cat, 'fields.name.en'),
  }));

  return {
    medias: _.get(state, ['projects', projectId, 'medias']),
    medicalCenterOptions,
    setting,
  }
};
export default connect(mapStateToProps)((props) => {
  const { projectId, medias, medicalCenterOptions } = props;
  return (
    <FirestoreDocEditor
      {...props}
      mediaEngine="firebase"
      mediaEngineConfig={{
        storagePath: `aspen/projects/${projectId}`,
        firestorePath: `aspen_projects/${projectId}/medias`
      }}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => {
        return {
          name: {
            label: 'Name',
            type: 'Object',
            layout: 'horizontal',
            value: _.get(data, 'fields.name', {}),
            defaultValue: { en: '', th: '' },
          },
          medicalCenter: {
            label: 'Medical Center',
            type: 'Dropdown',
            value: _.get(data, 'fields.medicalCenter', ''),
            options: medicalCenterOptions,
          },
          avatar: {
            label: 'avatar',
            type: 'Image',
            value: _.get(data, 'fields.avatar'),
            options: _.map(medias, (media, key) => ({ value: key, label: key, url: media.url }))
          },
          tags: { label: 'Keywords/Tags', value: _.join(_.get(data, 'fields.tags', [])) },
          status: {
            label: 'Status',
            type: 'Dropdown',
            value: _.get(data, 'fields.status', 'Draft'),
            options: [
              { value: 'Draft', label: 'Draft' },
              { value: 'Published', label: 'Published' },
              { value: 'Archived', label: 'Archived' },
            ]
          },
        };
      }}
      mapFieldValuesToDocData={fieldValues => {
        // const fieldValues = _.mapValues(fields, field => field.value);
        return {
          fields: {
            name: fieldValues.name,
            medicalCenter: fieldValues.medicalCenter,
            avatar: fieldValues.avatar,
            tags: _.map(_.split(fieldValues.tags, ','), _.trim),
            status: fieldValues.status,
          }
        }
      }}
      sections={[
        {
          title: 'Profile',
          fields: ['name', 'medicalCenter'],
        },
        {
          title: 'Media',
          fields: ['avatar'],
        },
        {
          title: 'Taxonomy',
          fields: ['tags']
        },
        {
          title: 'Publish',
          fields: ['status']
        },
      ]}
    />
  );
});
import _ from 'lodash'

const ROLE_SUPER_ADMIN = 'superadmin'
const ROLE_ADMIN = 'admin'
const ROLE_EDITOR = 'editor'
const ROLE_VIEWER = 'viewer'
const ROLE_MEDIA_MANAGER = 'media-manager'

const getUserClaims = (user) => user.claims && user.claims['@']
const getFirstKey = (object) => _.keys(object)[0]
const verifyClaimHasAllAccess = (claim) => _.has(claim, '*')

const getAccountClaim = (claims, accountId) => {
  const hasAllAccountAccess = verifyClaimHasAllAccess(claims)
  if (hasAllAccountAccess) return _.get(claims, [ '*' ])
  return _.get(claims, [ accountId ])
}
const getAppClaim = (claims, accountId, appId) => {
  const accountClaim = getAccountClaim(claims, accountId)
  const hasAllAppAccess = verifyClaimHasAllAccess(accountClaim)
  if (hasAllAppAccess) return _.get(accountClaim, [ '*' ])
  return _.get(claims, [ accountId, appId ])
}
const getProjectClaim = (claims, accountId, appId, projectId) => {
  const appClaim = getAppClaim(claims, accountId, appId)
  const hasAllProjectAccess = verifyClaimHasAllAccess(appClaim)
  if (hasAllProjectAccess) return _.get(appClaim, [ '*' ])
  return _.get(claims, [ accountId, appId, projectId ])
}

export const verifyUserHasAccessToAccount = (user, accountId) => {
  const claims = getUserClaims(user);
  if (!claims) return false
  const accountClaim = getAccountClaim(claims, accountId)
  console.debug('[Permission] verifyUserHasAccessToAccount', { accountClaim })
  return verifyClaimHasAllAccess(claims) || accountClaim !== undefined
  // const regex = new RegExp(`^(\\w)+(:){1}(${accountId}){1}(:)*(\\w|\\*)*(:)*(\\w|\\*)*$`, 'gim')
  // return regex.test(claims);
}

export const verifyUserHasAccessToApp = (user, accountId, appId) => {
  const claims = getUserClaims(user);
  if (!claims) return false
  const accountClaim = getAccountClaim(claims, accountId)
  const appClaim = getAppClaim(claims, accountId, appId)
  console.debug('[Permission] verifyUserHasAccessToApp', { appClaim })
  return verifyClaimHasAllAccess(accountClaim) || appClaim !== undefined
  // const regex = new RegExp(`^(\\w)+(:){1}(\\w|\\*|[$&+,:;=?@#|'<>.^*()%!-])+(:){1}(${appId}){1}(:)*(\\w|\\*)*$`, 'gim')
  // return regex.test(claims);
}

export const verifyUserHasAccessToProject = (user, accountId, appId, projectId) => {
  const claims = getUserClaims(user);
  if (!claims) return false
  const appClaim = getAccountClaim(claims, accountId, appId)
  const projectClaim = getProjectClaim(claims, accountId, appId, projectId)
  console.debug('[Permission] verifyUserHasAccessToProject', { projectClaim })
  return verifyClaimHasAllAccess(appClaim) || projectClaim !== undefined
  // const regex = new RegExp(`^(\\w)+(:){1}(\\w|\\*|[$&+,:;=?@#|'<>.^*()%!-])+(:){1}(\\w|\\*|[$&+,:;=?@#|'<>.^*()%!-])+(:)*(${projectId}){1}$`, 'gim')
  // return regex.test(claims);
}

const isProjectRole = (roleKey, userRole) => {
  if (!userRole) return false
  return new RegExp(`^${roleKey}$`, 'gim').test(userRole)
}

export const isProjectSuperAdmin = (userRole) => isProjectRole(ROLE_SUPER_ADMIN, userRole)
export const isProjectAdmin = (userRole) => isProjectRole(ROLE_ADMIN, userRole)
export const isProjectEditor = (userRole) => isProjectRole(ROLE_EDITOR, userRole)
export const isProjectViewer = (userRole) => isProjectRole(ROLE_VIEWER, userRole)
export const isProjectMediaManager = (userRole) => isProjectRole(ROLE_MEDIA_MANAGER, userRole)

export const getProjectRole = (user, accountId, appId, projectId) => {
  let role = 'stranger';
  const claims = getUserClaims(user);
  if (claims) {
    role = getProjectClaim(claims, accountId, appId, projectId);
  }
  console.debug('User\'s role:', { accountId, appId, projectId, claims, role })
  return role
}

export default {
  projectRole: {
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_EDITOR,
    ROLE_VIEWER,
    ROLE_MEDIA_MANAGER
  },
  isProjectSuperAdmin,
  isProjectAdmin,
  isProjectEditor,
  isProjectViewer,
  isProjectMediaManager,
  verifyUserHasAccessToAccount,
  verifyUserHasAccessToApp,
  verifyUserHasAccessToProject,
  getProjectRole
}
import React from 'react';
import { Route } from 'react-router-dom';

import { MuiThemeProvider } from '@material-ui/core/styles';

import theme from './theme';

// Screens
import BuildingPlanScreen from './screens/BuildingPlanScreen';

export default (props) => {
  const { match } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <Route
        path={`${match.url}/`}
        component={BuildingPlanScreen}
      />
    </MuiThemeProvider>
  );
}
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

export default class extends React.Component {
  state = {
    view: 'preview',
  }

  switchToEditor = e => this.setState({ view: 'editor' })
  switchToPreview = e => this.setState({ view: 'preview' })

  render() {
    const { props } = this;
    const { view } = this.state;
    return (
      <div>
        <Button variant="outline" onClick={this.switchToEditor}>Editor</Button>
        <Button variant="outline" onClick={this.switchToPreview}>Preview</Button>
        {view === 'editor' &&
          <TextField
            fullWidth
            variant="outlined"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            {...props}
          />
        }
        {view === 'preview' &&
          <div style={{ border: '1px solid #ececec' }}>
            <span dangerouslySetInnerHTML={{ __html: props.value }} />
          </div>
        }
      </div>
    );
  }
}
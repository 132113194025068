import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import CreateDocButton from '../../components/CreateDocButton';

import PageHeader from '../../../../components/PageHeader';


const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(22),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(22),
    color: theme.palette.text.secondary,
  },

  galleryRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
});

const Container = styled.div`
  box-sizing: border-box;
`;

const Content = styled.div`
  padding: 16px;
`;

const mapStateToProps = (state, ownProps) => {
  const { buildingId } = ownProps;
  return {
    medias: _.get(state, ['projects', buildingId, 'medias']),
    unitTypes: _.get(state, ['projects', buildingId, 'unitTypes']),
  }
}

class UnitTypeScreen extends React.Component {
  render () {
    const { history, match, classes, medias, unitTypes } = this.props;
    const mainTypes = _.chain(unitTypes)
      .map((item, id) => ({ id, ...item }))
      .groupBy(item => _.get(item, 'functions.bed'))
      .value();
    return (
      <Container>
        <PageHeader
          title="Unit Types"
          renderFloatingActionButtons={() => (
            <CreateDocButton label="UnitType" onCreate={id => history.push(`${match.url}/${id}`)} />
          )}
        />
        <Content>
          {_.map(mainTypes, (items, bedroom) => (
            <div key={bedroom}>
              <Typography variant="h5" style={{ margin: '1em 0' }}>{bedroom} Bedroom</Typography>
              <Grid container spacing={24}>
              {_.map(items, item => {
                return (
                  <Grid key={item.code} item xs={12} sm={4}>
                    <Card>
                      <Link to={`${match.url}/${item.id}`}>
                        <CardMedia
                          className={classes.media}
                          image={_.get(medias, `${item.unitplan}.url`)}
                          title="Unitplan"
                          style={{ height: 300, backgroundColor: '#202A30' }}
                        />
                      </Link>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">{item.title}</Typography>
                        <Typography component="p">
                          {_.get(item, 'functions.area')} sq.m.
                        </Typography>
                      </CardContent>
                      <CardActions>{_.map(_.get(item, 'tags', []), tag => <Chip key={tag} label={tag} />)}</CardActions>
                    </Card>
                  </Grid>
                );
              })}
              </Grid>
            </div>
          ))}
        </Content>
      </Container>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps)(UnitTypeScreen));

import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

import { db } from '../../engine';
import { utility } from '../../../../lib'

const { permission } = utility;
const {
  verifyUserHasAccessToApp,
} = permission;


const Container = styled.div`
  
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);

  .header {
    height: 100px;
    background: #000;
    padding: 30px;
    box-sizing: border-box;
    flex-grow: 0;

    h2 {
      color: white;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-grow: 1;
  }
`;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px ${theme.spacing.unit + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
});

const ProjectCoverButton = withStyles(styles)(({ classes, src, title, to }) => (
  <ButtonBase
    focusRipple
    key={title}
    className={classes.image}
    focusVisibleClassName={classes.focusVisible}
    component={Link}
    to={to}
    style={{
      width: 400,
      height: 600,
    }}
  >
    <span
      className={classes.imageSrc}
      style={{
        backgroundImage: `url(${src})`,
      }}
    />
    <span className={classes.imageBackdrop} />
    <span className={classes.imageButton}>
      <Typography
        component="span"
        variant="subtitle1"
        color="inherit"
        className={classes.imageTitle}
      >
        {title}
        <span className={classes.imageMarked} />
      </Typography>
    </span>
  </ButtonBase>
));

export default class extends React.Component {
  state = {
    projects: [],
  }

  componentDidMount() {
    const { match, history, accountId } = this.props;

    db.collection('zentry_projects').where('iam_projects', '==', accountId).get()
      .then(querySnapshot => {
        const projects = _.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() }));
        this.setState({
          projects,
        });

        if (_.size(projects) === 1) {
          history.push(`${match.url}/${_.get(projects, '0.id')}`);
        }
      });
  }

  render() {
    const { match } = this.props;
    const { projects } = this.state;

    return (
      <Container>
        <div className="header">
          <Typography variant="h2">Zentry</Typography>
        </div>
        <div className="content">
          {_.map(projects, project => <ProjectCoverButton key={project.id} src={_.get(project, 'setting.coverImage.url')} title={project.title} to={`${match.url}/${project.id}`} />)}
        </div>
      </Container>
    );
  }
}
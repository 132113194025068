import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { notify } from 'react-notify-toast';

import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CreateDocButton from '../../components/CreateDocButton';

import { createProject } from '../../api';
// import { getProjectsRef } from '../../api/realtime';

const background = require('./sky.jpg');

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  background: url(${background}) center bottom no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
`;

const InstructionText = styled.div`
  flex-basis: 100px;
  flex-grow: 0;
  width: 970px;
  margin: 50px auto 0;
  h1, h2, h3, h4, h5, h6 {
    color: white;
  }
`;

const BuildingButton = styled(ButtonBase)`
  transition: all 500ms;
  transform-origin: 50% 100%;
  &:hover {
    transform: scale(1.1);
  }
`;

const AddBuilding = styled.div`
  transition: all 500ms;
  border: 2px dashed white;
  padding: 60px;
  text-align: center;
  color: white;

  &:hover {
    transform: scale(1.1);
    border-color: salmon;
    color: salmon;
  }
`;

const BuildingContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
`;

const Ground = styled.div`
  background: #4a5a38;
  height: 100px;
  flex-grow: 0;
`;

const Building = styled.div`
  height: ${props => props.numFloors * 10}px;
`;

export default class extends React.Component {
  static defaultProps = {
    projects: [],
  }

  handleCreateBuilding = async (buildingName) => {
    const { user, match, history, account } = this.props;
    const accountId = _.get(account, 'id');
    try {
      notify.show('Creating Building, please wait', 'info');
      const building = await createProject(user)(buildingName, accountId);
      notify.show('Building created', 'success');
      history.push(`${match.url}/b/${building.id}`);
    } catch (e) {
      notify.show(e.message, 'error');
    }
  }

  render() {
    const { match, projects } = this.props;
    return (
      <Container>
        <InstructionText>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h2">Welcome to Meerkat</Typography>
              <Typography variant="h6">Please select building</Typography>
            </Grid>
          </Grid>
        </InstructionText>
        <BuildingContainer>
          {_.map(projects, project =>
            <div key={project.id}>
              <BuildingButton component={Link} to={`${match.url}/b/${project.id}`}>
                <Building {...project} numFloors={_.get(project, 'setting.building.floors', 8)}>
                  <img src={_.get(project, 'setting.building.avatar.url')} style={{ height: '100%' }} alt="" />
                  <div style={{ position: 'absolute', width: '100%', left: 0, right: 0, bottom: -40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ background: 'black', display: 'inline-block', margin: '0 auto', padding: '12px 24px' }}>
                      <Typography variant="subtitle1" style={{ color: 'white' }}>{project.name}</Typography>
                    </div>
                  </div>
                </Building>
              </BuildingButton>
            </div>)}
            <div key="new">
              <CreateDocButton
                label="Building"
                onCreate={this.handleCreateBuilding}
                renderButton={({ onClick }) => (
                  <ButtonBase onClick={onClick}>
                    <AddBuilding>Add a building</AddBuilding>
                  </ButtonBase>  
                )}
              />
            </div>
        </BuildingContainer>
        <Ground />
      </Container>
    );
  }
}

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _mediumDraft = require("medium-draft");

var _exporter = _interopRequireDefault(require("./exporter"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = function _default(content) {
  return (0, _exporter.default)((0, _mediumDraft.createEditorState)(content).getCurrentContent());
};

exports.default = _default;
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

// import AddIcon from '@material-ui/icons/Add';
// import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import KioskWireframe from '../../components/KioskWireframe';

import InputField from '../../../../components/InputField';

const getBlocksFromLayout = (layout, screenSize) => {
  const { width, height } = screenSize;
  switch (layout) {
    case 'V30:70':
      return {
        secondary: { x: 0, y: 0, width, height: .3 * height, playlistId: '' },
        primary: { x: 0, y: .3 * height, width, height: .7 * height, playlistId: '' }
      }
    case 'V50:50':
      return {
        top: { x: 0, y: 0, width, height: .5 * height, playlistId: '' },
        bottom: { x: 0, y: .5 * height, width, height: .5 * height, playlistId: '' }
      }
    case 'V70:30':
      return {
        secondary: { x: 0, y: .7 * height, width, height: .3 * height, playlistId: '' },
        primary: { x: 0, y: 0, width, height: .7 * height, playlistId: '' }
      }
    case 'H30:70':
      return {
        secondary: { x: 0, y: 0, width: .3 * width, height, playlistId: '' },
        primary: { x: 0.3 * width, y: 0, width: .7 * width, height, playlistId: '' }
      }
    case 'H50:50':
      return {
        left: { x: 0, y: 0, width: .5 * width, height, playlistId: '' },
        right: { x: 0.5 * width, y: 0, width: .5 * width, height, playlistId: '' }
      }
    case 'H70:30':
      return {
        secondary: { x: 0.7 * width, y: 0, width: .3 * width, height, playlistId: '' },
        primary: { x: 0, y: 0, width: .7 * width, height, playlistId: '' }
      }
    case 'fullscreen':
    default:
      return  {
        fullscreen: { x: 0, y: 0, width, height, playlistId: '' }
      }
  }
}
const mapStateToProps = (state, ownProps) => {
  const { accountId } = ownProps;
  const medias = _.get(state, ['accounts', accountId, 'medias']);
  const playlists = _.get(state, ['accounts', accountId, 'playlists']);

  return {
    mediaEntities: _.map(medias, media => media),
    playlists,
  }
}
export default connect(mapStateToProps)(class extends React.Component {
  state = {
    activeStep: 3,
    completed: {},
    fields: {
      name: 'test',
      location: '',
      screenSize: {
        width: 1920,
        height: 1080,
        text: '1920x1080',
      },
      layout: 'fullscreen',
      blocks: {
        fullscreen: {
          playlistId: 'playlistId',
        },
      }
    }
  };

  handleStep = step => () => {
    this.setState({
      activeStep: step,
    });
  };

  handleChange = (key, value) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [key]: value,
      }
    });
  }

  handleChangeScreenSize = (text) => {
    const [width, height] = _.split(text, 'x');
    this.handleChange('screenSize', { width, height, text });
  }

  handleChangeLayout = (value) => {
    const { screenSize } = this.state.fields;
    const blocks = getBlocksFromLayout(value, screenSize);

    this.setState({
      fields: {
        ...this.state.fields,
        layout: value,
        blocks,
      }
    });
  }


  // handleChangeLayoutTemplate = (layoutIndex, layoutValue) => {
  //   this.setState({
  //     fields: {
  //       ...this.state.fields,
  //       layouts: _.map(this.state.fields.layouts, (layout, index) => {
  //         if (layoutIndex === index) return layoutValue;
  //         return layout;
  //       }),
  //     }
  //   })
  // }

  handleSelectBlockSource = (blockId, playlistId) => {
    console.log('handleSelectBlockSource', blockId, playlistId);
    this.setState({
      fields: {
        ...this.state.fields,
        blocks: _.mapValues(this.state.fields.blocks, (block, bId) => {
          if (bId === blockId) return { ...block, playlistId };
          return block;
        }),
      }
    });
  }

  handleSubmit = () => {
    const { onCreate } = this.props;
    onCreate(this.state.fields);
  }

  renderKioskSetup = () => {
    const { fields } = this.state;
    return (
      <Grid container>
        <Grid item sm={4}>
          <KioskWireframe style={{ width: '100%' }} screenSize={fields.screenSize} />
        </Grid>
        <Grid item sm={8}>
          <InputField type="Dropdown" label="Screen Size" value={fields.screenSize}
            options={[{ value: '1920x1080', label: '1920 x 1080' }, { value: '1080x1920', label: '1080 x 1920' }]}
            onChange={e => this.handleChangeScreenSize(e.target.value)}
          />
          <InputField type="TextField" label="Device Name" value={fields.name} onChange={e => this.handleChange('name', e.target.value)} />
          <InputField type="TextField" label="Location" value={fields.location} onChange={e => this.handleChange('location', e.target.value)} />
          <InputField type="TextField" label="Mac Address" value={fields.mcaddress} onChange={e => this.handleChange('macaddress', e.target.value)} />
          <InputField type="TextField" label="IP Address" value={fields.ipaddress} onChange={e => this.handleChange('ipaddress', e.target.value)} />
        </Grid>
        <Grid item sm={12} style={{ textAlign: 'right' }}>
          <Button color="primary" onClick={this.handleStep(1)}>Next</Button>
        </Grid>
      </Grid>
    );
  }

  renderLayout = () => {
    const { fields } = this.state;
    const layoutValue = fields.layout;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item sm={12}>
            <Typography variant="h4" style={{ marginBottom: '0.5em' }}>Layouts</Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 30 }}>
          <Grid item sm={3}>
            <KioskWireframe style={{ width: '100%' }} screenSize={fields.screenSize} layout={layoutValue} />
          </Grid>
          <Grid item sm={7}>
            <InputField
              type="Dropdown"
              label="Layout"
              value={layoutValue}
              onChange={e => this.handleChangeLayout(e.target.value)}
              options={[
                { value: 'fullscreen', label: 'Fullscreen' },
                { value: 'H30:70', label: '30:70' },
                { value: 'H50:50', label: '50:50' },
                { value: 'H70:30', label: '70:30' },
                { value: 'V30:70', label: '30:70' },
                { value: 'V50:50', label: '50:50' },
                { value: 'V70:30', label: '70:30' },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={12} style={{ textAlign: 'right' }}>
            <Button color="primary" onClick={this.handleStep(2)}>Next</Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  /*
  renderLayouts = () => {
    const { fields } = this.state;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item sm={12}>
            <Typography variant="h4">Layouts / Blocks</Typography>
            <br />
            <InputField type="Dropdown" label="No of Layouts"
              value={_.size(fields.layouts)}
              options={[{ value: 1, label: '1 Layout' }, { value: 2, label: '2 Layouts' }]}
              onChange={e => this.handleChange('layouts', _.map(_.range(e.target.value), (index) => 'fullscreen'))}
            />
          </Grid>
        </Grid>
        {_.map(fields.layouts, (layoutValue, layoutIndex) => (
          <Grid container style={{ marginBottom: 30 }}>
            <Grid item sm={3}>
              <KioskWireframe style={{ width: '100%' }} screenSize={fields.screenSize} layout={layoutValue} />
            </Grid>
            <Grid item sm={7}>
              <InputField
                type="Dropdown"
                label={`Layout ${layoutIndex + 1}`}
                value={layoutValue}
                onChange={e => this.handleChangeLayoutTemplate(layoutIndex, e.target.value)}
                options={[
                  { value: 'fullscreen', label: 'Fullscreen' },
                  { value: '30:70', label: '30:70' },
                  { value: '50:50', label: '50:50' },
                  { value: '70:30', label: '70:30' },
                ]}
              />
            </Grid>
          </Grid>
        ))}
        <Grid container>
          <Grid item sm={12} style={{ textAlign: 'right' }}>
            <Button color="primary" onClick={this.handleStep(2)}>Next</Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  */

  renderSelectSources = () => {
    const { playlists } = this.props;
    const { fields } = this.state;
    console.log('renderSelectSources', fields);

    return (
      <Grid container>
        <Grid item sm={8}>
          <Typography variant="h4" style={{ marginBottom: '0.5em' }}>Select Sources</Typography>
          {_.map(fields.blocks, (block, blockIndex) => (
            <Grid container key={`block-${blockIndex}`} style={{ marginBottom: 30 }}>
              <Grid item sm={3}>
                <KioskWireframe style={{ width: '100%' }} screenSize={fields.screenSize} />
              </Grid>
              <Grid item sm={9}>
                {/* <Button color="default" style={{ marginRight: 8 }}><AddIcon /> Add Media</Button> */}
                {/* <Button color="default"><PlaylistAddIcon /> Load Playlist</Button> */}
                <TextField
                  select
                  style={{ width: '100%' }}
                  label="Select Playlist"
                  value={block.source}
                  onChange={e => this.handleSelectBlockSource(block.id, e.target.value)}
                  variant="outlined"
                >
                  {_.map(playlists, option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container>
          <Grid item sm={12} style={{ textAlign: 'right' }}>
            <Button color="primary" onClick={this.handleStep(3)}>Next</Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderSummary = () => {
    const { fields } = this.state;
    return (
      <div>
        <div style={{ border: '1px solid rgba(255, 255, 255, 0.2)', padding: 10, marginBottom: 5 }}>
          <Grid container>
            <Grid item sm={3}><Typography>Layout</Typography></Grid>
            <Grid item sm={3}><Typography>{fields.layout}</Typography></Grid>
          </Grid>
        </div>
        <div style={{ border: '1px solid rgba(255, 255, 255, 0.2)', padding: 10 }}>
        {_.map(fields.blocks, (block) => {
          return (
            <Grid container key={`block-${block.id}`}>
              <Grid item sm={3}>
                <KioskWireframe style={{ width: '100%' }} screenSize={fields.screenSize} />
              </Grid>
              <Grid item sm={4}>
                <Typography variant="h6" style={{ marginBottom: 0 }}>Block ID:</Typography>
                <Typography>{block.id}</Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography variant="h6" style={{ marginBottom: 0 }}>Source:</Typography>
                <Typography>{block.source}</Typography>
              </Grid>
            </Grid>
          );
        })}
        </div>
        <Grid container>
          <Grid item sm={12} style={{ textAlign: 'right' }}>
            <Button color="primary" onClick={this.handleSubmit}>Create device</Button>
          </Grid>
        </Grid>
      </div>
    );
  }

  render() {

    const steps = [
      'Step 1: Kiosk Setup',
      'Step 2: Layouts / Blocks',
      'Step 3: Select Sources',
      'Review'
    ];

    const { activeStep } = this.state;
    return (
      <div>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton onClick={this.handleStep(index)} completed={this.state.completed[index]}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div style={{ margin: 30 }}>
          {activeStep === 0 && this.renderKioskSetup()}
          {activeStep === 1 && this.renderLayout()}
          {activeStep === 2 && this.renderSelectSources()}
          {activeStep === 3 && this.renderSummary()}

        </div>
      </div>
    );
  }
})

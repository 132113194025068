import React from 'react';
import { Helmet } from 'react-helmet';
import { Route } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import logger from 'redux-logger';

import { MuiThemeProvider } from '@material-ui/core/styles';

// Screens
import theme from './theme';

import rootReducer from './reducers';
const store = createStore(rootReducer, applyMiddleware(logger));

class Root extends React.Component {

  render() {
    const { match } = this.props;
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <title>@</title>
          </Helmet>
          <Route exact path={`${match.url}`} render={routerProps => <div>@</div>} />
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default Root


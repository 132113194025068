import _ from "lodash";
export default (building, disableAll) => {
  const buildingData = _.chain(building)
    .map((buildingData, buildingKey) => {
      return {
        value: buildingKey,
        label: _.get(buildingData, ["fields", "name", "en"], "")
      };
    })
    .sortBy(["label"])
    .value();
  if (disableAll) {
    return buildingData;
  }
  return [
    {
      value: "all",
      label: "All"
    },
    ...buildingData
  ];
};

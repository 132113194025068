"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createClient = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _firebase = _interopRequireDefault(require("./firebase"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var createClient = function createClient(config) {
  var projectId = config.projectId;

  var db = _firebase["default"].firestore();

  var projectRef = db.collection("ombres_projects").doc(projectId);
  var globalBrandCollectionRef = db.collection("ombres_brands");

  var getProjectRef = function getProjectRef() {
    return projectRef;
  };

  var getGlobalBrands = function getGlobalBrands() {
    return globalBrandCollectionRef.get().then(function (querysnapshot) {
      return _lodash["default"].map(querysnapshot.docs, function (doc) {
        return _objectSpread({
          id: doc.id
        }, doc.data());
      });
    });
  };

  var getGlobalBrandDocRef = function getGlobalBrandDocRef(brandId) {
    return globalBrandCollectionRef.doc(brandId);
  };

  var getBrands = function getBrands() {
    return projectRef.collection("brands").get().then(function (querysnapshot) {
      return _lodash["default"].map(querysnapshot.docs, function (doc) {
        return _objectSpread({
          id: doc.id
        }, doc.data());
      });
    });
  };

  var getBrandsCollectionRef = function getBrandsCollectionRef() {
    return projectRef.collection("brands");
  };

  var getBrandDocRef = function getBrandDocRef(brandId) {
    return projectRef.collection("brands").doc(brandId);
  };

  var getShops = function getShops() {
    return projectRef.collection("shops").get().then(function (querysnapshot) {
      return _lodash["default"].map(querysnapshot.docs, function (doc) {
        return _objectSpread({
          id: doc.id
        }, doc.data());
      });
    });
  };

  var getShopsCollectionRef = function getShopsCollectionRef() {
    return projectRef.collection("shops");
  };

  var getShopDocRef = function getShopDocRef(shopId) {
    return projectRef.collection("shops").doc(shopId);
  };

  var getCategoriesCollectionRef = function getCategoriesCollectionRef() {
    return projectRef.collection("categories");
  };

  var getCategoryDocRef = function getCategoryDocRef(id) {
    return projectRef.collection("categories").doc(id);
  };

  var getCategories = function getCategories() {
    return getCategoriesCollectionRef().get().then(function (querysnapshot) {
      return _lodash["default"].map(querysnapshot.docs, function (doc) {
        return _objectSpread({
          id: doc.id
        }, doc.data());
      });
    });
  };

  var getFloors = function getFloors() {
    return projectRef.collection("floors").get().then(function (querysnapshot) {
      return _lodash["default"].map(querysnapshot.docs, function (doc) {
        return _objectSpread({
          id: doc.id
        }, doc.data());
      });
    });
  };

  var getFloorsCollectionRef = function getFloorsCollectionRef() {
    return projectRef.collection("floors");
  };

  var getFloorDocRef = function getFloorDocRef(id) {
    return projectRef.collection("floors").doc(id);
  };

  var getNodesCollectionRef = function getNodesCollectionRef() {
    return projectRef.collection("nodes");
  };

  var getNodeDocRef = function getNodeDocRef(id) {
    return projectRef.collection("nodes").doc(id);
  };

  var getCustomFields = function getCustomFields() {
    return projectRef.collection("customFields").get().then(function (querysnapshot) {
      return _lodash["default"].map(querysnapshot.docs, function (doc) {
        return _objectSpread({
          id: doc.id
        }, doc.data());
      });
    });
  };

  var getCustomFieldsCollectionRef = function getCustomFieldsCollectionRef() {
    return projectRef.collection("customFields");
  };

  var getCustomFieldDocRef = function getCustomFieldDocRef(customFieldId) {
    return projectRef.collection("customFields").doc(customFieldId);
  };

  var getCollectionRef = function getCollectionRef(collectionName) {
    return projectRef.collection(collectionName);
  };

  var getDocRef = function getDocRef(collectionName, docId) {
    return projectRef.collection(collectionName).doc(docId);
  };

  return {
    getProjectRef: getProjectRef,
    getGlobalBrands: getGlobalBrands,
    getGlobalBrandDocRef: getGlobalBrandDocRef,
    getBrands: getBrands,
    getBrandsCollectionRef: getBrandsCollectionRef,
    getBrandDocRef: getBrandDocRef,
    getShops: getShops,
    getShopsCollectionRef: getShopsCollectionRef,
    getShopDocRef: getShopDocRef,
    getCategories: getCategories,
    getCategoriesCollectionRef: getCategoriesCollectionRef,
    getCategoryDocRef: getCategoryDocRef,
    getFloors: getFloors,
    getFloorsCollectionRef: getFloorsCollectionRef,
    getFloorDocRef: getFloorDocRef,
    getNodesCollectionRef: getNodesCollectionRef,
    getNodeDocRef: getNodeDocRef,
    getCollectionRef: getCollectionRef,
    getDocRef: getDocRef,
    getCustomFields: getCustomFields,
    getCustomFieldsCollectionRef: getCustomFieldsCollectionRef,
    getCustomFieldDocRef: getCustomFieldDocRef
  };
};

exports.createClient = createClient;
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import FirestoreDocEditor from '../../../../components/FirestoreDocEditor';

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, 'projectId');
  return {
    medias: _.get(state, ['projects', projectId, 'medias']),
  };
};

export default connect(mapStateToProps)((props) => {
  const { projectId, medias } = props;
  return (
    <div>
      <FirestoreDocEditor
        {...props}
        renderTitle={() => 'Setting'}
        allowEditId={false}
        renderSectionAs="tab"
        mediaEngine="firebase"
        mediaEngineConfig={{
          storagePath: `aspen/projects/${projectId}`,
          firestorePath: `aspen_projects/${projectId}/medias`
        }}
        docRef={props.docRef}
        mapDocDataToFields={(data, id) => {
          return {
            languages: {
              label: 'Languages',
              type: 'Checkboxes',
              value: _.get(data, 'setting.languages', []),
              options: [
                { value: 'en', label: 'English' },
                { value: 'th', label: 'ไทย' },
                { value: 'zh', label: 'Chinese' },
                { value: 'jp', label: 'Japan' },
                { value: 'ru', label: 'Russia' },
              ]
            },
            coverImage: {
              label: 'Cover Image',
              type: 'Image',
              value: _.get(data, 'setting.coverImage.id'),
              options: _.map(medias, (media, key) => ({ value: key, label: key, url: media.url }))
            },
          }
        }}
        mapFieldValuesToDocData={fieldValues => {
          // console.log('fieldValues', fieldValues);
          // const fieldValues = _.mapValues(fields, field => field.value);
          return {
            setting: {
              languages: fieldValues.languages,
              coverImage: {
                id: fieldValues.coverImage,
                ref: _.get(medias, `${fieldValues.coverImage}.ref`),
                url: _.get(medias, `${fieldValues.coverImage}.url`),
              }
            }
          }
        }}
        sections={[
          {
            title: 'General',
            fields: ['languages', 'coverImage'],
          },
        ]}
      />
    </div>
  );
});

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import FirestoreDocEditor from "../../../../components/FirestoreDocEditor";
import getBuildingOption from "../../utils/getBuildingOption";
import {
  getSections,
  getMapDocDataToFields,
  getMapFieldValuesToDocData
} from "../../utils/getCustomfield";

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, "projectId");
  const setting = _.get(state, ["projects", projectId, "setting"]);
  return {
    medias: _.get(state, ["projects", projectId, "medias"]),
    building: _.get(state, ["projects", projectId, "building"]),
    setting
  };
};
export default connect(mapStateToProps)(props => {
  const { projectId, medias, setting, building, docCustomFieldRef } = props;
  const buildingEnable = _.get(setting, "buildingEnable");
  const buildingSection = buildingEnable
    ? [
        buildingEnable && {
          title: "Location",
          fields: ["building"]
        }
      ]
    : [];
  const [customField, setCustomField] = useState({});
  const [updateVersionForRender, setUpdateVersionForRender] = useState(0);

  useEffect(() => {
    docCustomFieldRef.onSnapshot(doc => {
      const docData = doc.data();
      setUpdateVersionForRender(updateVersionForRender + 1);
      setCustomField(docData);
    });
  }, []);

  const customSections = getSections(customField, {
    languages: _.get(setting, "languages", [])
  });
  return (
    <FirestoreDocEditor
      key={`updateVersionForRender-${_.size(
        customField
      )}-${updateVersionForRender}`}
      {...props}
      mediaEngine="firebase"
      mediaEngineConfig={{
        storagePath: `ombres/projects/${projectId}`,
        firestorePath: `ombres_projects/${projectId}/medias`
      }}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => {
        const cuntomMapDocDataToFields = getMapDocDataToFields(customField, {
          data,
          medias,
          languages: _.get(setting, "languages", [])
        });
        const languages = _.get(setting, "languages", []);
        const nameFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`name-${lang}`)]: {
              label: `Name (${lang})`,
              value: _.get(data, `fields.name.${lang}`, "")
            }
          }),
          {}
        );
        const descriptionFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`description-${lang}`)]: {
              label: `Description (${lang})`,
              value: _.get(data, `fields.description.${lang}`, "")
            }
          }),
          {}
        );
        return {
          ...nameFields,
          ...descriptionFields,
          coverPhoto: {
            label: "Logo",
            type: "Image",
            value: _.get(data, "fields.coverPhoto"),
            options: _.map(medias, (media, key) => ({
              value: key,
              label: key,
              url: media.url
            }))
          },
          gallery: {
            label: "Gallery",
            type: "Gallery",
            value: _.get(data, "fields.gallery"),
            options: _.map(medias, (media, key) => ({
              value: key,
              label: key,
              url: media.url
            }))
          },
          order: { label: "Order", value: _.get(data, "fields.order", 0) },
          tags: {
            label: "Keywords/Tags",
            value: _.join(_.get(data, "fields.tags", []))
          },
          startDate: {
            label: "Start Date",
            type: "Date",
            value: _.get(data, "fields.startDate")
          },
          endDate: {
            label: "End Date",
            type: "Date",
            value: _.get(data, "fields.endDate")
          },
          status: {
            label: "Status",
            type: "Dropdown",
            value: _.get(data, "fields.status", "Draft"),
            options: [
              { value: "Draft", label: "Draft" },
              { value: "Published", label: "Published" },
              { value: "Archived", label: "Archived" }
            ]
          },
          building: {
            label: "Building",
            type: "Dropdown",
            value: _.get(data, "fields.building", "all"),
            options: getBuildingOption(building)
          },
          ...cuntomMapDocDataToFields
        };
      }}
      mapFieldValuesToDocData={fieldValues => {
        const languages = _.get(setting, "languages", []);
        const nameValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`name-${lang}`)]
          }),
          {}
        );
        const descriptionValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`description-${lang}`)]
          }),
          {}
        );

        const cuntomMapFieldValuesToDocData = getMapFieldValuesToDocData(
          customField,
          {
            fieldValues,
            languages: _.get(setting, "languages", [])
          }
        );
        // const fieldValues = _.mapValues(fields, field => field.value);
        return {
          fields: {
            name: nameValues,
            description: descriptionValues,
            coverPhoto: fieldValues.coverPhoto,
            gallery: fieldValues.gallery,
            startDate: fieldValues.startDate,
            endDate: fieldValues.endDate,
            tags: _.map(_.split(fieldValues.tags, ","), _.trim),
            status: fieldValues.status,
            building: fieldValues.building,
            ...cuntomMapFieldValuesToDocData
          }
        };
      }}
      sections={[
        {
          title: "Event Info",
          fields: [
            ..._.map(_.get(setting, "languages", []), lang =>
              _.camelCase(`name-${lang}`)
            ),
            ..._.map(_.get(setting, "languages", []), lang =>
              _.camelCase(`description-${lang}`)
            )
          ]
        },
        {
          title: "Media",
          fields: ["coverPhoto", "gallery"]
        },
        {
          title: "Period",
          fields: ["startDate", "endDate"]
        },
        {
          title: "Taxonomy",
          fields: ["tags"]
        },
        ...buildingSection,
        ...customSections,
        {
          title: "Publish",
          fields: ["status"]
        }
      ]}
    />
  );
});

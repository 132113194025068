import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import FirestoreDocEditor from '../../../../components/FirestoreDocEditor';

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, 'projectId');
  const floors = _.get(state, ['projects', projectId, 'floors']);
  const floorOptions = _.map(floors, (floor, floorId) => ({
    value: floorId,
    label: _.get(floor, 'fields.name.en'),
  }));

  const nodes = _.get(state, ['projects', projectId, 'nodes']);
  const nodeOptions = _.map(nodes, (node, nodeId) => ({
    value: nodeId,
    label: nodeId,
  }));
  return {
    nodeOptions,
    floorOptions,
  }
};
export default connect(mapStateToProps)((props) => {
  const { nodeOptions, floorOptions } = props;
  return (
    <FirestoreDocEditor
      {...props}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => ({
        name: { label: 'Name', value: _.get(data, 'fields.name', id) },
        node: {
          label: 'Node',
          value: _.get(data, 'fields.node'),
          type: 'Autocomplete',
          placeholder: 'Select Node',
          suggestions: nodeOptions,
        },
        floor: {
          label: 'Floor',
          type: 'Dropdown',
          value: _.get(data, 'fields.floor'),
          options: floorOptions,
        },
        direction: { label: 'Direction', value: _.get(data, 'fields.direction', 0) },
        orientation: {
          label: 'Orientation',
          type: 'Dropdown',
          value: _.get(data, 'fields.orientation', 'portrait'),
          options: [
            { value: 'portrait', label: 'Portrait' },
            { value: 'landscape', label: 'Landscape' },
          ]
        },
        status: {
          label: 'Status',
          type: 'Dropdown',
          value: _.get(data, 'fields.status', 'Draft'),
          options: [
            { value: 'Draft', label: 'Draft' },
            { value: 'Published', label: 'Published' },
            { value: 'Archived', label: 'Archived' },
          ]
        },
      })}
      mapFieldValuesToDocData={fieldValues => {
        // const fieldValues = _.mapValues(fields, field => field.value);
        return {
          fields: {
            name: fieldValues.name,
            node: fieldValues.node,
            floor: fieldValues.floor,
            direction: _.toNumber(fieldValues.direction),
            orientation: fieldValues.orientation,
            isPortrait: fieldValues.orientation === 'portrait',
            isLandscape: fieldValues.orientation === 'landscape',
            status: fieldValues.status,
          }
        }
      }}
      sections={[
        {
          title: 'Kisok Setting',
          fields: ['name', 'node', 'floor', 'direction', 'orientation', 'status'],
        },
      ]}
    />
  );
});
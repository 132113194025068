import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';

import AddIcon from '@material-ui/icons/Add';

import CreateDocButton from '../../../../components/CreateDocButton';
import FirestoreCollectionViewer from '../../../../components/FirestoreCollectionViewer';
import PageHeader from '../../../../components/PageHeader';

// import client from '../../engine';
import { createClient } from 'forviz-ombresjs';
import DetailScreen from './detail';
import DetailTransportationScreen from './detail-transportation';

import StatusTableCellValue from '../../components/StatusTableCellValue';

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;
  return {
    medias: _.get(state, ['projects', projectId, 'medias']),
  }
}

export default connect(mapStateToProps)(class extends React.Component {
  state = {
    items: [],
    page: 0,
    rowsPerPage: 10,
    firstVisible: null,
    lastVisible: null,
  }

  handleSelectRow = (record, selected, selectedRows, e) => {
    const { match, history } = this.props;
    history.push(`${match.url}/${record.fields.category}/${record.id}`)
  }

  handleCreate = (values) => {
    const { projectId } = this.props;
    
    const { category, title } = values;
    const id = `${_.kebabCase(category)}!${_.kebabCase(title)}`;
    const client = createClient({ projectId });
    const docRef = client.getProjectRef().collection('infos').doc(id);
    docRef.set({
      fields: {
        name: {
          en: title,
        },
        category,
      }
    }).then(() => {
      const { match, history } = this.props;
      history.push(`${match.url}/${category}/${id}`);  
    });
  }

  render() {
    const { match, history, projectId } = this.props;
    const listScreenUrl = match.url;
    const client = createClient({ projectId });
    return (
      <div>
        <PageHeader
          title="Informations"
          renderFloatingActionButtons={() =>
            <CreateDocButton
              label="Info"
              fields={[
                {
                  type: 'Dropdown', name: 'category', label: 'Category',
                  options: [
                    { value: 'service', label: 'Service' },
                    { value: 'transportation', label: 'Transportation' },
                    { value: 'toursit', label: 'Tourist' }
                  ]
                },
                { type: 'TextField', name: 'title', label: 'Title' }
              ]}
              renderButton={() => <Button variant="extendedFab" color="primary"><AddIcon /> Add an info</Button>}
              onCreate={this.handleCreate}
            />
          }
        />
        <FirestoreCollectionViewer
          columns={[
            { title: 'ID', dataIndex: 'id', render: id => <span style={{ color: '#787878' }}>{id}</span> },
            { title: 'Category', dataIndex: 'fields.category' },
            { title: 'Name (En)', dataIndex: 'fields.name.en' },
            { title: 'Name (Th)', dataIndex: 'fields.name.th' },
            { title: 'Name (Zh)', dataIndex: 'fields.name.zh' },
            { title: 'Status', dataIndex: 'fields.status', render: status => <StatusTableCellValue status={status} /> },
          ]}
          collectionRef={client.getProjectRef().collection('infos')}
          onSelect={this.handleSelectRow}
        />
        <Route
          path={`${match.url}/:category/:id`}
          children={({ match, ...rest }) => {
            const id = _.get(match, 'params.id');
            const category = _.get(match, 'params.category');
            const docRef = !_.isEmpty(id) ? client.getProjectRef().collection('infos').doc(id) : null;
            const docCustomFieldRef = client.getCustomFieldDocRef("informations");
            const open = docRef !== null;

            const DetailScreenComponent = category === 'transportation' ? DetailTransportationScreen : DetailScreen;

            return (
              <Drawer anchor="right" open={open} onClose={() => history.goBack()}>
                <DetailScreenComponent
                  width="400px"
                  {...rest}
                  idEditable={false}
                  projectId={projectId}
                  docRef={docRef}
                  docCustomFieldRef={docCustomFieldRef}
                  onClose={() => {
                    history.push(listScreenUrl);
                  }}
                />
              </Drawer>
            );
          }}
        />
      </div>
    );
  }
});

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { notify } from 'react-notify-toast';

import styled from 'styled-components';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dropzone from 'react-dropzone';

import FloorplanLoader from '../../../../components/FloorplanLoader';

function xml2json(xml) {
  try {
    var obj = {};
    if (xml.children.length > 0) {
      for (var i = 0; i < xml.children.length; i++) {
        var item = xml.children.item(i);
        var nodeName = item.nodeName;

        if (typeof (obj[nodeName]) === "undefined") {
          obj[nodeName] = xml2json(item);
        } else {
          if (typeof (obj[nodeName].push) === "undefined") {
            var old = obj[nodeName];

            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(xml2json(item));
        }
      }
    } else {
      obj = xml.textContent;
    }
    return obj;
  } catch (e) {
      console.log(e.message);
  }
}

const DropArea = styled.div`
  textarea { 
    width: 100%;
    height: 400px;
    border: 1px solid #999;
    border-radius: 6px;
  }

  p {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    pointer-events: none;
  }

  &.drop-ok {
    border-color: #4caf50;
    p {
      color: #4caf50;
    }
  }

  &.drop-not-ok {
    border-color: #ab003c;
    p {
      color: #ab003c;
    }
  }
  &.has-error p {
    color: #ff3d00;
  }
`;


const mapStateToProps = (state, ownProps) => {
  // const { projectId } = ownProps;
  return {
    // medias: _.get(state, ['projects', projectId, 'medias', mediaId, 'url']),
  };
}

export default connect(mapStateToProps)(class extends React.Component {
  static defaultProps = {
    floorId: '',
  }

  state = {
    floorplate: {},
    step: 0,
    acceptedNodes: {},
    error: null,
  }
  
  componentDidMount() {
    // Get Floor
    const { docRef } = this.props;
    docRef.get()
      .then(doc => {
        this.setState({
          floorplate: doc.data(),
        });
      });
  }

  onDropStart = () => {
    this.setState({ error: null });
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { docRef } = this.props;
    const floorId = docRef.id;

    console.log('drop', acceptedFiles);
    const reader = new FileReader();
    const _self = this;
    // Closure to capture the file information.
    if (acceptedFiles.length > 0) {
      reader.onloadend = function () {
        // console.log(reader.result);
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(reader.result, "text/xml");

        const errorLinks = [];
        const jsonDoc = xml2json(xmlDoc);
        const nodes = jsonDoc.node_list.node;
        const nodeDatas = _.reduce(nodes, (acc, node) => ({ ...acc, [node.id]: node }), {});

        if (!nodes) {
          _self.setState({ error: 'Cannot find any node' });
        } else {

          if (nodes.length === 0) {
            _self.setState({ error: 'No elements under element ID = unit' })
          } else {
            
            const nodesData = _.reduce(nodes, (acc, node) => {
              const id = `${floorId}-${node.id}`;
              return {
                ...acc,
                [id]: {
                  id: `${floorId}-${node.id}`,
                  x: _.toNumber(node.x),
                  y: _.toNumber(node.y),
                  floorId: floorId,
                  neighbor: _.map(_.split(node.neighbor, ','), id => {
                    if (_.get(nodeDatas, id) === undefined) {
                      errorLinks.push({ from: node.id, to: id });
                    }
                    return `${floorId}-${id}`;
                  }),
                }
              }
            }, {});
             
            console.log('nodeData', nodesData);

            if (errorLinks.length > 0) {
              _self.setState({
                error: `Cant create link from ${_.map(errorLinks, ({ from, to }) => `(${from} => ${to})`)}`,
              });
            } else {
              _self.setState({
                acceptedNodes: nodesData,
                step: 1,
              });
            }
          }
        }
      }

      // Read in the image file as a data URL.
      reader.readAsText(acceptedFiles[0]);
    }
  }

  handleReset = () => {
    this.setState({
      step: 0,
      acceptedNodes: [],
    });
  }


  handleSave = () => {
    const { projectDocRef, docRef } = this.props;
    const floorId = docRef.id;
    if (projectDocRef && this.state.acceptedNodes) {
      // Delete Old Units
      const nodeCollectionRef = projectDocRef.collection('nodes');
      nodeCollectionRef.where('floorId', '==', floorId).get().then(querySnapshot => {
        
        const oldIds = _.map(querySnapshot.docs, doc => doc.id);
        const newIds = _.map(this.state.acceptedNodes, node => node.id);
        const toRemoveIds = _.difference(oldIds, newIds);
        // Delete
        _.forEach(toRemoveIds, id => {
          nodeCollectionRef.doc(id).delete();
        });

        // Update
        // console.log('update', this.state.acceptedNodes);
        _.forEach(this.state.acceptedNodes, node => {
          nodeCollectionRef.doc(node.id).set(node, { merge: true });
        });
      });

      // Save update;
      projectDocRef.collection('floors').doc(floorId).update({
        'fields.nodeImported': moment().format(),
      });
      
    }
    notify.show('Saved', 'success');
    if (this.props.onSaved) this.props.onSaved();
    if (this.props.onClose) this.props.onClose();
  }

  render() {
    const { onClose, open, medias, docRef } = this.props;
    const { floorplate, step, acceptedNodes, error } = this.state;

    const floorMediaId = _.get(floorplate, 'fields.floorplan');
    const floorplanMedia = _.get(medias, floorMediaId);
    const floorId = docRef.id;
    return (
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Floor {floorId} : Node Importer</DialogTitle>
        <DialogContent>
          {step === 0 &&
            <Grid container>
              <Grid item sm={12}>
                <Dropzone
                  disableClick
                  style={{ width: 600, height: 400 }}
                  onDropStart={this.onDropStart}
                  onDrop={this.onDrop}
                >
                  {({ isDragActive, isDragReject }) => {
                    let text = 'Copy XML code or drop XML file.';
                    if (isDragActive) text = 'This is XML file, Looks goods to me';
                    if (isDragReject) text = 'This is not XML you mother bucker';

                    if (error) text = error;
                    return (
                      <DropArea className={`${isDragActive ? 'drop-ok' : ''} ${isDragReject ? 'drop-not-ok' : ''} ${error !== null ? 'has-error' : ''}`}>
                        <textarea></textarea>
                        <p>{text}</p>
                      </DropArea>
                    );
                  }}
                </Dropzone>
              </Grid>
            </Grid>
          }
          {step === 1 &&
            <Grid container style={{ width: 1180, maxWidth: '100%' }}>
              <Grid item sm={9}>
                <div>
                  <FloorplanLoader
                    {...floorplate.fields}
                    src={_.get(floorplanMedia, 'url')}
                    docRef={docRef}
                    nodes={acceptedNodes}
                  />
                </div>
              </Grid>
              <Grid item sm={3}>
                <List>
                  {_.map(acceptedNodes, node =>
                    <ListItem key={node.id}>
                      <ListItemText primary={node.id} secondary={`(x:${node.x}, y:${node.y}, neighbor:${node.neighbor}`} />
                    </ListItem>
                  )}
                </List>
              </Grid>
            </Grid>
          }
        </DialogContent>
        <DialogActions>
          {step === 0 && <Button onClick={this.props.onClose}>Cancel</Button>}
          {step === 1 && (
            <div>
              <Button onClick={this.handleReset}>Back</Button>
              <Button onClick={this.handleSave} color="primary" autoFocus>
                Continue
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    );
  }
});


// {_.map(roomtypes, option =>
// <MenuItem key={option} value={option}>{option}</MenuItem>)}
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

// import { updateForm, deleteForm } from '../../api';
import { updateFormAction, deleteFormAction } from '../../actions';
import FirestoreDocEditor from '../../../../components/FirestoreDocEditor';
import ListField from '../../../../components/InputField/ListField';
import ActionInputField from './ActionInputField';

// import { parseFromPlumV1, parseToPlumV1 } from './utils';

const parseFromPlumV2 = (data, id) => {
  const result = {
    id: _.get(data, 'id', id),
    name: { label: 'Name', value: _.get(data, 'name', id) },
    validations: { label: 'Validations', list: true, value: _.get(data, 'validations') },
    actions: {
      label: 'Actions', list: true, type: 'EmailActions',
      value: _.map(_.get(data, 'actions', []), action => {
        return {
          ...action,
          to: _.join(_.map(action.to, toObj => toObj.email), ', '),
          cc: _.join(_.map(action.cc, toObj => toObj.email), ', '),
          bcc: _.join(_.map(action.bcc, toObj => toObj.email), ', '),
          // cc: _.join(action.cc, ', '),
          // bcc: _.join(action.bcc, ', '),
          body: decodeURI(action.body),
        }
      }),
    },
    status: _.get(data, 'status', 'enable'),
  }
  // console.log('parseFromPlumV2', data, result);
  
  return result;
}
const parseToPlumV2 = (fieldValues, accountId) => {
  debugger;
  const result = {
    id: fieldValues.id,
    account_id: accountId,
    name: fieldValues.name,
    status: fieldValues.status,
    validations: _.reduce(fieldValues.validations, (acc, rule) => ({ ...acc }), {}),
    actions: _.map(fieldValues.actions, action => {
      return  {
        type: 'email',
        to: _.map(_.split(action.to, ','), toEmail => {
          return {
            name: '',
            email: _.trim(toEmail),
          };
        }),
        bcc: _.map(_.compact(_.split(action.bcc, ',')), email => ({ name: _.trim(email), email: _.trim(email) })),
        cc: _.map(_.compact(_.split(action.cc, ',')), email => ({ name: _.trim(email), email: _.trim(email) })),
        replyTo: {
          name: _.get(action, 'replyTo.name', ''),
          email: _.get(action, 'replyTo.email', ''),
        },
        from: {
          name: _.get(action, 'from.name', ''),
          email: _.get(action, 'from.email', ''),
        },
        subject: action.subject,
        body: encodeURI(action.body),
      }
    }),
  }
  // console.log('parseToPlumV2', fieldValues, result);
 
  return result;
}


const mapStateToProps = (state, ownProps) => {
  return {
    accountId: ownProps.accountId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSave: (formId, data) => {
      dispatch(updateFormAction(formId, data));
    },
    onDelete: (formId) => {
      dispatch(deleteFormAction(formId));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)((props) => {
  const { accountId, onSave, onDelete } = props;
  return (
    <FirestoreDocEditor
      {...props}
      titleField="name"
      renderSectionAs="tab"
      onSave={onSave}
      onDelete={onDelete}
      mapDocDataToFields={(data, id) => {
        // console.log('mapDocDataToFields:data', data);
        const parsed = parseFromPlumV2(data, id);
        return parsed;
      }}
      mapFieldValuesToDocData={fieldValues => {
        const parsed = parseToPlumV2(fieldValues, accountId);
        return parsed;
      }}
      renderInput={(inputProps, renderInputDefault) => {
        switch (inputProps.type) {
          case 'EmailActions': {
            return (
              <ListField
                {...inputProps} 
                render={singleProps => {
                  // console.log('EmailAction', singleProps);
                  return (<ActionInputField {...singleProps.inputProps} />);
                }}
              />
            );
            // return _.map(inputProps.value, value => <ActionInputField value={value} onChange={inputProps.onChange} />);
          }
          default:
            return renderInputDefault(inputProps);
        }
      }}
      sections={[
        {
          title: 'General',
          fields: ['name'],
        },
        {
          title: 'Actions',
          fields: ['actions'],
        },
        {
          title: 'Validations',
          fields: ['validations'],
        },
        {
          title: 'Restriction',
          fields: ['allowDomains'],
        },
        
      ]}
    />
  );
});



// const shop = {
//   units: [
//     { floorId: '2F', unit: '201' },
//     { floorId: '1F', unit: '105_1' },
//   ],
//   entranceNode: '1F-98'
// }

import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Icon from '@material-ui/core/Icon';

import MediaSelector from './MediaSelector';

const styles = theme => ({
  iconHover: {
    position: 'absolute',
    top: '-7px',
    right: '-7px',
    cursor: 'pointer',
    fontSize: 30,
    '&:hover': {
      color: red[800]
    }
  }
});

const SelectMedia = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const StyledFormControl = styled(FormControl)`
  && {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
  }
  &&:hover {
    border: 1px solid black;
  }
`;
const StyledInputLabel = styled(InputLabel)`
  && {
    background-color: white;
    padding: 0 5px;
  }
`;

const ContainerMedia = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .boder-box-media {
    width: 147px;
    height: 147px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .box-media {
    width: 140px;
    height: 140px;
  }
`;

const MediaPreview = ({ mediaId, src, classes, onDelete }) => {
  return (
    <div className="boder-box-media" key={mediaId}>
      <div className="box-media">
        <Icon className={classes.iconHover} color="error" onClick={() => onDelete(mediaId)}>
          remove_circle
        </Icon>
        <img src={src} alt="" />
      </div>
    </div>
  );
}

export default withStyles(styles)(
  class extends Component {
    static defaultProps = {
      multiple: false
    };

    static propTypes = {
      multiple: PropTypes.bool,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    };

    constructor(props) {
      super(props);
      this.labelRef = React.createRef();
    }

    state = {
      selectedValue: []
    };
    handleInputChange = media => {
      const { multiple } = this.props;
      this.props.onChange(multiple ? media : media[0]);
    };

    removeMedia = mediaId => {
      const { value, multiple } = this.props;
      const updatedValue = multiple ? _.filter(value, val => val !== mediaId) : null;
      this.props.onChange(updatedValue);
    };

    render() {
      const { onUploadFile, onCheckFile, label, multiple, classes, options } = this.props;
      const value = multiple ? this.props.value : _.compact([ this.props.value ]);
      // console.log('FileUploader value', value, options);
      return (
        <StyledFormControl variant="outlined" fullWidth>
          <StyledInputLabel shrink variant="outlined" htmlFor="name" ref={this.labelRef}>
            {label}
          </StyledInputLabel>
          <SelectMedia>
            <MediaSelector onUploadFile={onUploadFile} onCheckFile={onCheckFile} onChange={this.handleInputChange} multiple={multiple} value={value} options={options} />
            {_.size(value) > 0 && (
              <ContainerMedia>
                {_.map(value, mediaId => {
                  const media = _.find(options, option => option.value === mediaId);
                  return <MediaPreview key={mediaId} mediaId={mediaId} src={_.get(media, 'url')} classes={classes} onDelete={this.removeMedia} />;
                })}
              </ContainerMedia>
            )}
          </SelectMedia>
        </StyledFormControl>
      );
    }
  }
);

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles, ExpansionPanelDetails } from '@material-ui/core';

import FloorplateUnitEditor from './FloorplateUnitEditor';
import FloorplanLoader from '../../components/FloorplanLoader';
import CreateDocButton from '../../components/CreateDocButton';

import db from '../../engine/db';

import PageHeader from '../../../../components/PageHeader';


const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(22),
    flexBasis: '33.33%',
    flexShrink: 0,
    marginRight: 20,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(22),
    color: theme.palette.text.secondary,
  },

  galleryRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  chip: {
    marginRight: theme.spacing.unit,
  },
});

const Container = styled.div`
  box-sizing: border-box;
`;

const Content = styled.div`
  padding: 16px;
`;

const mapStateToProps = (state, ownProps) => {
  const { buildingId } = ownProps;
  return {
    floorplates: _.orderBy(_.get(state, ['projects', buildingId, 'floorplates']), ["floors.0"], ["desc"]),
    medias: _.get(state, ['projects', buildingId, 'medias']),
  }
}

class FloorplateScreen extends React.Component {
  state = {
    openUnitsImporterDialog: false,
  }

  handleClose = () => {
    this.setState({ openUnitsImporterDialog: false });
  }

  render () {
    const { match, history, buildingId, classes, floorplates: items } = this.props;
    return (
      <Container>
        <PageHeader
          title="Floorplates"
          renderFloatingActionButtons={() => (
            <CreateDocButton label="Floorplate" onCreate={id => history.push(`${match.url}/${id}`)} />
          )}
        />
        <Content>
          {_.map(items, (item) => (
            <ExpansionPanel key={item.id} expanded={true}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Link to={`${match.url}/${item.id}`} style={{ textDecoration: 'none' }}>
                  <Typography className={classes.heading}>Floor {item.code} </Typography>
                </Link>
                <Button component={Link} to={`${match.url}/${item.id}/editor`}>Unit Importer</Button>
                {/* <Typography className={classes.secondaryHeading}>UnitTypes: {_.map(item.tags, tag => <Chip className={classes.chip} label={tag} />)}</Typography> */}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Link to={`${match.url}/${item.id}`} style={{ overflow: 'hidden' }}>
                  <div style={{ marginTop: -100, marginBottom: -100 }}>
                    <FloorplanLoader
                      {...item}
                      buildingId={buildingId}
                      mediaId={item.floorplan}
                      docRef={db.collection('meerkat_projects').doc(buildingId).collection('floorplates').doc(item.id)}
                    />
                  </div>
                </Link>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </Content>
        <Route
          path={`${match.url}/:floorId/editor`}
          children={(routerProps) => {
            const floorId = _.get(routerProps, 'match.params.floorId');
            const floorDocRef = floorId ? db.collection('meerkat_projects').doc(buildingId).collection('floorplates').doc(floorId) : undefined;
            if (floorDocRef) {
              return (
                <FloorplateUnitEditor
                  open={floorId !== undefined}
                  buildingId={buildingId}
                  docRef={floorDocRef}
                  onClose={() => history.push(`${match.url}`)}
                  onSaved={() => history.push(`${match.url}`)}
                />
              );
            }
            return null;
          }}
        />
       
      </Container>
    );
  }
}

export default withStyles(styles)(connect(mapStateToProps)(FloorplateScreen));

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import FirestoreDocEditor from '../../../../components/FirestoreDocEditor';

const mapStateToProps = (state, ownProps) => {
  return {}
};

export default connect(mapStateToProps)((props) => {
  return (
    <FirestoreDocEditor
      {...props}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => ({
        name: { label: 'Name', value: _.get(data, 'name', id) },
        // expireAt: { label: 'Expire', type: 'Date', value: _.get(data, 'expireAt') },
        startAt: { label: 'Start At', value: _.get(data, 'startAt') },
        endAt: { label: 'End At', value: _.get(data, 'endAt') },
        duration: { label: 'Duration (seconds)', value: _.get(data, 'duration') },
      })}
      mapFieldValuesToDocData={fieldValues => {
        // const fieldValues = _.mapValues(fields, field => field.value);
        return {
          name: fieldValues.name,
          startAt: _.toNumber(fieldValues.startAt),
          endAt: _.toNumber(fieldValues.endAt),
          duration: _.toNumber(fieldValues.duration),
          // expireAt: fieldValues.expireAt,
        }
      }}
      sections={[
        {
          title: 'Media Setting',
          fields: ['name'],
        },
        {
          title: 'Image Setting',
          fields: ['duration']
        },
        {
          title: 'Video Setting',
          fields: ['startAt', 'endAt']
        }
      ]}
    />
  );
});
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
// import { Transition } from 'react-spring';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import EditableTableCell from '../../components/EditableTableCell';

import db from '../../engine/db';

import { updateUnit } from '../../api';

import PageHeader from '../../../../components/PageHeader';

export default class extends React.Component {
  state = {
    items: [],
    page: 0,
    rowsPerPage: 10,
    firstVisible: null,
    lastVisible: null,
  }
  componentDidMount() {
    this.fetchData();
  }

  fetchData = (param) => {
    const reverse = _.get(param, 'reverse', false);
    const { building, buildingId } = this.props;
    const spaceId = _.get(building, ['setting', 'unit.source.spaceId']);
    const { rowsPerPage } = this.state;
    let unitRefs = db.collection('meerkat_projects').doc(buildingId).collection('units')
      .limit(rowsPerPage);
    
    unitRefs = !reverse ? unitRefs.orderBy('id', 'asc').startAfter(this.state.lastVisible) : unitRefs.orderBy('id', 'desc').startAfter(this.state.firstVisible);
    
    unitRefs.get().then(querySnapshot => {
      const items = _.sortBy(_.map(querySnapshot.docs, (doc) => {
        return { id: doc.id, ...doc.data()};
      }), 'id');
      this.setState({
        items, 
        firstVisible: items[0].id, // querySnapshot.docs[0],
        lastVisible: items[items.length - 1].id, // querySnapshot.docs[querySnapshot.docs.length-1],
      })
    });
  }

  handleChangeField = (value, name, unitId) => {
    const { user, building } = this.props;
    const spaceId = _.get(building, ['setting', 'unit.source.spaceId']);

    const data = _.find(this.state.items, item => item.id === unitId);
    updateUnit(user)({
      spaceId,
      unitId,
      dataToUpdate: { ...data.fields, [name]: value },
    });
  }

  handleChangePage = (event, page) => {
    const currentPage = this.state.page;
    
    // Next
    if (page > currentPage) {
      this.fetchData()
    } else if (page < currentPage) {
    // Prev
      this.fetchData({ reverse: true });
    }
    
    this.setState({ page });
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value }, () => {
      this.fetchData();
    });
  }

  render() {
    const { page, rowsPerPage, items } = this.state;
    
    const fields = [
      { name: 'id', disabled: true },
      { name: 'building', disabled: true },
      { name: 'floor', disabled: true },
      { name: 'type' },
      { name: 'room-type' },
      { name: 'direction' },
      { name: 'mirror' },
      { name: 'area' },
      { name: 'status', type: 'select', options: ['A', 'L', 'S'] },
    ]
    return (
      <div>
        <PageHeader
          title="Units"
        />
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Building</TableCell>
                <TableCell>Floor</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Unit Type</TableCell>
                <TableCell>Direction</TableCell>
                <TableCell>Mirror</TableCell>
                <TableCell>Area</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Updated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* 
              <Transition
                native
                keys={items.map(item => item.id)}
                from={{ opacity: 0, transform: `translateY(0%)` }}
                enter={{ opacity: 1, transform: `translateY(-100%)` }}
                leave={{ opacity: 0,  transform: `translateY(0%)`, pointerEvents: 'none' }}
              >
                {items.map(unit => styles => (
                  <tr key={unit.id} style={styles}>
                    {_.map(fields, field => (
                      <EditableTableCell
                        key={`${unit.id}-${field.name}`}
                        onFieldChange={this.handleChangeField}
                        disabled={field.disabled}
                        type={field.type}
                        options={field.options}
                        rowId={unit.id}
                        name={field.name}
                        value={_.get(unit, `fields.${field.name}`)}
                      />
                    ))}
                    <TableCell style={{ fontSize: '0.7em', color: '#AAA' }}>{moment(_.get(unit, 'meta.updatedAt').toDate()).fromNow()}</TableCell>
                  </tr>
                ))}
              </Transition>
              */}
              {items.map(unit => {
                const updatedAt = _.get(unit, 'meta.updatedAt');
                const time = updatedAt ? moment(updatedAt.toDate()).fromNow() : '';
                return (
                  <TableRow key={unit.id}>
                    {_.map(fields, field => (
                      <EditableTableCell
                        key={`${unit.id}-${field.name}`}
                        onFieldChange={this.handleChangeField}
                        disabled={field.disabled}
                        type={field.type}
                        options={field.options}
                        rowId={unit.id}
                        name={field.name}
                        value={field.name === 'id' ? unit.id : _.get(unit, `fields.${field.name}`)}
                      />
                    ))}
                    <TableCell style={{ fontSize: '0.7em', color: '#AAA' }}>{time}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={9}
                  count={600}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  rowsPerPageOptions={[10, 25, 50]}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </div>
    );
  }
}
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import FirestoreDocForm from '../../components/FirestoreDocForm';

const mapStateToProps = (state, ownProps) => {
  const buildingId = _.get(ownProps, 'buildingId');
  return {
    medias: _.get(state, ['projects', buildingId, 'medias']),
  }
};
export default connect(mapStateToProps)(class extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: '',
    }
  }
  
  render() {
    return (
      <FirestoreDocForm
        {...this.props}
        docRef={this.props.docRef}
        mapDocDataToFields={(data, id) => {
          this.setState({id});
          return {
            title: { label: 'Title', value: _.get(data, 'title') },
            link: { label: 'Link', value: _.get(data, 'link') },
          }
        }}
        mapFieldsToDocData={fields => {
          const fieldValues = _.mapValues(fields, field => field.value);
  
          return {
            id: this.state.id,
            title: fieldValues.title,
            link: fieldValues.link,
          }
        }}
        sections={[
          {
            title: 'Info',
            fields: ['title', 'link']
          },
        ]}
      />
    );
  }
});

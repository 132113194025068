import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { notify } from "react-notify-toast";
import uuidv4 from "uuid/v4";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Drawer from "@material-ui/core/Drawer";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";

import CreateDocButton from "../../../../components/CreateDocButton";
import FirestoreCollectionViewer from "../../../../components/FirestoreCollectionViewer";
import PageHeader from "../../../../components/PageHeader";

import { createClient } from "forviz-ombresjs";

import DetailScreen from "./detail";
import getBuildingOption from "../../utils/getBuildingOption";

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;
  const customFields = _.get(state, ["projects", projectId, "customFields"]);
  console.log({ customFields });
  return {
    entities: _.map(customFields, (shop, id) => ({
      id,
      ...shop
    }))
  };
};

export default connect(mapStateToProps)(
  class extends React.Component {
    handleSelectRow = (record, selected, selectedRows, e) => {
      const { match, history } = this.props;
      history.push(`${match.url}/${record.id}`);
    };

    render() {
      const { match, history, entities, projectId } = this.props;
      const listScreenUrl = match.url;
      const client = createClient({ projectId });
      console.log("match.url", match.url);
      return (
        <div>
          <PageHeader title="Directory" style={{ marginBottom: 16 }} />
          <FirestoreCollectionViewer
            paging
            columns={[{ title: "Name", dataIndex: "label" }]}
            defaultLimit={-1}
            defaultOrderBy="fields.floor"
            entities={entities}
            onSelect={this.handleSelectRow}
          />
          <Route
            path={`${match.url}/:customFieldId`}
            children={({ match, ...rest }) => {
              const customFieldId = _.get(match, "params.customFieldId");
              const docRef = !_.isEmpty(customFieldId)
                ? client.getCustomFieldDocRef(customFieldId)
                : null;
              const open = docRef !== null;
              return (
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={() => history.goBack()}
                >
                  <DetailScreen
                    idEditable={false}
                    width="700px"
                    projectId={projectId}
                    {...rest}
                    docRef={docRef}
                    onClose={() => {
                      history.push(listScreenUrl);
                    }}
                  />
                </Drawer>
              );
            }}
          />
        </div>
      );
    }
  }
);

import React from 'react';
import _ from 'lodash';
import FirestoreDocForm from '../../components/FirestoreDocForm';
import { connect } from 'react-redux';
import db from '../../engine/db';

const mapStateToProps = (state, ownProps) => {
  const buildingId = _.get(ownProps, 'buildingId');
  // console.log('SettingScreen mapStateToProps', buildingId, ownProps);
  const docRef = db.collection('meerkat_projects').doc(buildingId);
  return {
    medias: _.get(state, ['projects', buildingId, 'medias']),
    docRef,
  }
};

export default connect(mapStateToProps)((props) => {
  const { medias } = props;
  return (
    <FirestoreDocForm
      {...props}
      docRef={props.docRef}
      resources={{
        medias,
      }}

      mapDocDataToFields={(data, id) => ({
        primary_color: { label: 'Primary Color', value: _.get(data, 'theme_setting.palette.primary') },
        secondary_color: { label: 'Secondary Color', value: _.get(data, 'theme_setting.palette.secondary') },
        title: { label: 'Title Color', value: _.get(data, 'theme_setting.palette.title') },
        sub_title: { label: 'Text Primary', value: _.get(data, 'theme_setting.palette.sub_title') },
        body: { label: 'Text Secondary', value: _.get(data, 'theme_setting.palette.body') },

      })}
      
      mapFieldsToDocData={fields => {
        const fieldValues = _.mapValues(fields, field => field.value);
        return {
          theme_setting: {
            palette: {
              "primary":fieldValues.primary_color,
              "secondary": fieldValues.secondary_color,
              "title": fieldValues.title,
              "sub_title": fieldValues.sub_title,
              "body": fieldValues.body,
            }
          }
        }
      }}
      sections={[
        {
          title: 'Main Theme Color',
          fields: ['primary_color', 'secondary_color']
        },
        {
          title: 'Text Theme Color',
          fields: ['title', 'sub_title','body']
        }
      ]}
    />
  );
});
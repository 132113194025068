import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { notify } from "react-notify-toast";
import uuidv4 from "uuid/v4";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Drawer from "@material-ui/core/Drawer";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";

import CreateDocButton from "../../../../components/CreateDocButton";
import FirestoreCollectionViewer from "../../../../components/FirestoreCollectionViewer";
import PageHeader from "../../../../components/PageHeader";

import { createClient } from "forviz-ombresjs";

import DetailScreen from "./detail";
import getBuildingOption from "../../utils/getBuildingOption";

import { utility } from '../../../../lib'

const { permission } = utility;
const {
  getProjectRole,
  isProjectSuperAdmin,
  isProjectAdmin
} = permission;

const StyledShopProgressBar = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 0;
  flex-direction: row;
  border: 1px solid #357a38;
  border-radius: 2px;

  .status-block {
    background: #f7f7f7;
    flex: 1 1 18px;
    height: 18px;
    border-left: 1px solid #ccc;
    color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;

    &.done {
      color: #357a38;
      background: #4caf50;
      border-left-color: #357a38;
    }

    &.warning {
      background: #f44336;
      color: #aa2e25;
      border-left-color: #aa2e25;
    }
  }

  .status-block:first-child {
    border-left: none;
  }
`;

const ShopProgressBar = shop => {
  const { logoHasProblem } = shop;
  const units = _.get(shop, "fields.units");
  const unitDone =
    !_.isEmpty(units) &&
    _.some(
      units,
      unit => unit.floorId !== undefined && unit.unitNo !== undefined
    );
  const zoneDone = !_.isEmpty(_.get(shop, "fields.zone"));
  const nodeDone = !_.isEmpty(_.get(shop, "fields.node"));
  const logoDone = !_.isEmpty(_.get(shop, "fields.logo"));

  const categoryDone =
    !_.isEmpty(_.get(shop, "fields.category")) ||
    !_.isEmpty(_.get(shop, "fields.subcategory"));

  return (
    <StyledShopProgressBar>
      <Tooltip title={`${logoDone ? "Has logo" : "No logo"}`}>
        <div
          className={`status-block ${logoDone ? "done" : ""} ${
            logoHasProblem ? "warning" : ""
          }`}
        >
          L
        </div>
      </Tooltip>
      <Tooltip title={`${unitDone ? "Has unit" : "No unit"}`}>
        <div className={`status-block ${unitDone ? "done" : ""}`}>U</div>
      </Tooltip>
      <Tooltip title={`${zoneDone ? "Has zone" : "No zone"}`}>
        <div className={`status-block ${zoneDone ? "done" : ""}`}>Z</div>
      </Tooltip>
      <Tooltip title={`${nodeDone ? "Has node" : "No node"}`}>
        <div className={`status-block ${nodeDone ? "done" : ""}`}>N</div>
      </Tooltip>
      <Tooltip title={`${categoryDone ? "Has category" : "No category"}`}>
        <div className={`status-block ${categoryDone ? "done" : ""}`}>C</div>
      </Tooltip>
    </StyledShopProgressBar>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;
  const shops = _.get(state, ["projects", projectId, "shops"]);
  const medias = _.get(state, ["projects", projectId, "medias"]);
  return {
    shopEntities: _.map(shops, (shop, id) => ({
      id,
      ...shop,
      logoMedia: _.get(medias, `${shop.fields.logo}`)
    })),
    categories: _.get(state, ["projects", projectId, "categories"]),
    zones: _.get(state, ["projects", projectId, "zones"]),
    floors: _.get(state, ["projects", projectId, "floors"]),
    medias: _.get(state, ["projects", projectId, "medias"]),
    building: _.get(state, ["projects", projectId, "building"]),
    setting: _.get(state, ["projects", projectId, "setting"])
  };
};

export default connect(mapStateToProps)(
  class extends React.Component {
    handleSelectRow = (record, selected, selectedRows, e) => {
      const { match, history } = this.props;
      history.push(`${match.url}/${record.id}`);
    };

    handleCreate = values => {
      const { projectId } = this.props;
      const { unit, floor, brandName } = values;
      const id = uuidv4();

      const client = createClient({ projectId });

      const docRef = client.getShopDocRef(id);
      docRef.get().then(docSnapShot => {
        if (!docSnapShot.exists) {
          docRef
            .set({
              fields: {
                name: {
                  en: brandName
                },
                floor,
                unit
              }
            })
            .then(() => {
              const { match, history } = this.props;
              history.push(`${match.url}/${id}`);
            });
        } else notify.show("shopId exists, Please Change Unit", "error");
      });
    };

    render() {
      const {
        match,
        user,
        accountId,
        history,
        shopEntities,
        floors,
        zones,
        categories,
        projectId,
        building,
        setting
      } = this.props;
      
      const userProjectRole = getProjectRole(user, accountId, 'ombres', projectId)
      const isSuperAdmin = isProjectSuperAdmin(userProjectRole)
      const isAdmin = isProjectAdmin(userProjectRole)

      const listScreenUrl = match.url;
      const client = createClient({ projectId });
      const mainCategories = _.pickBy(categories, category =>
        _.isEmpty(_.get(category, ["fields", "parent"], ""))
      );
      const allSubCategories = _.pickBy(
        categories,
        category => !_.isEmpty(_.get(category, ["fields", "parent"], ""))
      );
      const buildingEnable = _.get(setting, "buildingEnable");
      const buildingOption = getBuildingOption(building);
      const filters = [
        {
          label: "Floor",
          dataIndex: "fields.floor",
          options: _.map(floors, (floor, id) => ({
            value: id,
            label: _.get(floor, "fields.name.en")
          }))
        },
        {
          label: "Category",
          dataIndex: "fields.category",
          options: _.map(mainCategories, (category, id) => ({
            value: id,
            label: _.get(category, "fields.name.en")
          }))
        },
        {
          label: "SubCategory",
          dataIndex: "fields.subcategory",
          options: _.map(allSubCategories, (category, id) => ({
            value: id,
            label: _.get(category, "fields.name.en"),
            parent: _.get(category, "fields.parent")
          })),
          parent: "fields.category"
        }
      ]
      if (buildingEnable) {
        filters.push({
          label: "Building",
          dataIndex: "fields.building",
          options: getBuildingOption(building, true)
        });
      }
      filters.push({
        label: "Status",
        dataIndex: "fields.status",
        options: [
          {
            value: 'Published',
            label: 'Published',
          },
          {
            value: 'Draft',
            label: 'Draft',
          },
          {
            value: 'Archived',
            label: 'Archived',
          }
        ],
      });
      return (
        <div>
          <PageHeader
            title="Directory"
            style={{ marginBottom: 16 }}
            renderFloatingActionButtons={() => (
              <CreateDocButton
                label="Shop"
                fields={[
                  {
                    type: "TextField",
                    name: "unit",
                    label: "Unit",
                    required: true
                  },
                  {
                    type: "TextField",
                    name: "floor",
                    label: "Floor",
                    required: true
                  },
                  {
                    type: "TextField",
                    name: "brandName",
                    label: "Brand",
                    required: true
                  }
                ]}
                renderButton={() => (
                  <Button variant="contained" color="primary">
                    <AddIcon /> Add Shop
                  </Button>
                )}
                onCreate={this.handleCreate}
              />
            )}
          />
          <FirestoreCollectionViewer
            paging
            filters={filters}
            columns={[
              {
                title: "Status",
                dataIndex: "id",
                width: 200,
                render: (text, row) => {
                  const fileName = _.get(row, "logoMedia.metadata.name", "");
                  const logoHasProblem = fileName.match(/(\s&'[ก-ฮ])/);

                  return (
                    <ShopProgressBar {...row} logoHasProblem={logoHasProblem} />
                  );
                }
              },
              {
                title: "Logo",
                dataIndex: "logoMedia",
                width: 80,
                render: logo => <Avatar src={_.get(logo, `url`)} />
              },
              { title: "Name", dataIndex: "fields.name.en" },
              { title: "Name (ไทย)", dataIndex: "fields.name.th" },
              { title: "Floor", dataIndex: "fields.floor" },
              {
                title: "Units",
                dataIndex: "fields.units",
                render: (units, row) =>
                  _.map(units, unit => (
                    <Chip
                      key={`${unit.floorId}-${unit.unitNo}`}
                      variant="outlined"
                      avatar={<Avatar>{unit.floorId}</Avatar>}
                      label={unit.unitNo}
                    />
                  ))
              },
              {
                title: "Zone",
                dataIndex: "fields.zone",
                render: zoneId => _.get(zones, `${zoneId}.fields.name.en`)
              },
              { title: "Node", dataIndex: "fields.node" },
              {
                title: "Category",
                dataIndex: "fields.subcategory",
                render: (subcategory, row) => {
                  const categoryId = _.get(row, `fields.category`, "");
                  return `${_.get(
                    categories,
                    `${categoryId}.fields.name.en`,
                    ""
                  )} ${_.get(categories, `${subcategory}.fields.name.en`, "")}`;
                }
              }
            ]}
            defaultLimit={-1}
            defaultOrderBy="fields.floor"
            entities={shopEntities}
            // collectionRef={client.getShopsCollectionRef()}
            onSelect={this.handleSelectRow}
          />
          <Route
            path={`${match.url}/:shopId`}
            children={({ match, ...rest }) => {
              const shopId = _.get(match, "params.shopId");
              const docRef = !_.isEmpty(shopId)
                ? client.getShopDocRef(shopId)
                : null;

              const docCustomFieldRef = client.getCustomFieldDocRef("shops");
              const open = docRef !== null;
              return (
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={() => history.goBack()}
                >
                  <DetailScreen
                    idEditable={false}
                    width="700px"
                    projectId={projectId}
                    {...rest}
                    canDelete={isSuperAdmin || isAdmin}
                    docRef={docRef}
                    docCustomFieldRef={docCustomFieldRef}
                    onClose={() => {
                      history.push(listScreenUrl);
                    }}
                  />
                </Drawer>
              );
            }}
          />
        </div>
      );
    }
  }
);

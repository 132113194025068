import React from 'react'
import styled from 'styled-components'

import parseDuration from '../../utils/parseDuration'

import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import orange from '@material-ui/core/colors/orange'
import green from '@material-ui/core/colors/green'

import IconSchedule from '@material-ui/icons/Schedule';

const IconTime = <IconSchedule style={{ color: '#FFF', marginLeft: 5 }} />

const Container = styled.div`
  margin-bottom: 20px;
`

const PaperStyled = styled(Paper)`
  padding: 20px;
  margin: 0 auto;
  width: 50%;
`

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`

const Column = styled.div`
  flex: 1;

  &:last-of-type {
    text-align: right;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: ${props => props.left ? 'flex-start' : props.right ? 'flex-end' : 'center'};
  align-items: center;
`

const LinearProgressStyled = withStyles({
  root: {
    height: '10px',
  },
  barColorPrimary: {
    backgroundColor: green[500],
  },
  barColorSecondary: {
    backgroundColor: orange[500],
  },
})(LinearProgress)

/**
 * Duration Bar component
 *
 * @param  {Number} duration total duration time of the playlist
 * @param  {Number} maxDuration max duration time
 */
const DurationBar = ({ duration, maxDuration }) => {

  return (
    <Container>
      <PaperStyled>
        <Columns>
          <Column>
            <Typography variant="subtitle1">Playlist duration</Typography>
            <Row left><Typography variant="h5">{parseDuration(duration)}</Typography>{IconTime}</Row>
          </Column>
          <Column>
            <Typography color={maxDuration - duration < 0 ? 'error' : 'default' } variant="subtitle1">Remaining time</Typography>
            <Row right><Typography color={maxDuration - duration < 0 ? 'error' : 'default' } variant="h5">{parseDuration(maxDuration - duration)}</Typography>{IconTime}</Row>
          </Column>
        </Columns>
        <LinearProgressStyled variant="determinate" color={maxDuration - duration < 0 ? 'secondary' : 'primary'} value={(duration * 100) / maxDuration} />
      </PaperStyled>
    </Container>
  )
}

export default DurationBar


import moment from 'moment';

// 0 = Jan & 11 = Dec
function weekOfMonth(monthNumber = 0) {
  const monthMoment = moment().month(monthNumber)
  const firstDay = monthMoment.startOf('month')
  const endDay = monthMoment.endOf('month')
  const monthRange = moment.range(firstDay, endDay);
  const weeks = []
  for (let mday of monthRange.by('days')) {
    if (weeks.indexOf(mday.week()) === -1) {
        weeks.push(mday.week());
    }
  }
  const calendar = []
  for (let index = 0; index < weeks.length; index++) {
    var weeknumber = weeks[index];
    let firstWeekDay = moment(firstDay).week(weeknumber).day(0);
    if (firstWeekDay.isBefore(firstDay)) {
      firstWeekDay = firstDay;
    }
    let lastWeekDay = moment(endDay).week(weeknumber).day(6);
    if (lastWeekDay.isAfter(endDay)) {
        lastWeekDay = endDay;
    }
    const weekRange = moment.range(firstWeekDay, lastWeekDay)
    calendar.push(weekRange)
  }
  console.log('calendar', calendar)
  return calendar;
}

export default weekOfMonth
const BASEURL = process.env.REACT_APP_BASEURL;

export const createAccount = (uid, name) => {
  return fetch(`${BASEURL}/accounts`, {
    method: 'POST',
    body: JSON.stringify({
      uid,
    	name,
    }),
  });
}

export const fetchAccountApps = (accountId) => {
  return fetch(`${BASEURL}/iam/account/:accountId/apps`)
    .then(res => res.json())
    .then(res => {
      return res.data;
    });
}

export const enableApp = (accountId, appId) => {
  return fetch(`${BASEURL}/iam/account/${accountId}/apps/${appId}/activate`)
    .then(res => res.json())
    .then(res => {
      return res.data;
    });
}

export const disableApp = (accountId, appId) => {
  return fetch(`${BASEURL}/iam/account/${accountId}/apps/${appId}/deactivate`)
    .then(res => res.json())
    .then(res => {
      return res.data;
    });
}
import React from 'react';
import moment from 'moment';
import filesize from 'filesize';
import { Route } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Waypoint } from 'react-waypoint';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
// import { Flipper, Flipped } from 'react-flip-toolkit';
import { notify } from 'react-notify-toast';
import { toast } from 'react-toastify';

import { connect } from 'react-redux';
import { updateAccount } from '../../actions'

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import BackIcon from '@material-ui/icons/ArrowBack';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import AddIcon from '@material-ui/icons/Add';

// import {
//   saveMediaToFirestore,
//   deleteMediaFromFirestore,
//   uploadToStorage,
//   deleteFromStorage
// } from 'forviz-mediajs';

import AddMediaDialog from './AddMediaDialog';

import MediaItemAction from '../../components/MediaItemAction';

import InputField from '../../../../components/InputField';
import Confirm from '../../../../components/ConfirmDialog';
import PageHeader from '../../../../components/PageHeader';
import Table from '../../../../components/Table';

import isImageFile from '../../utils/isImageFile';

const accept = 'image/*,video/*';
const maxUploadFileSize = 50 * 1000 * 1000;
const byteToMB = (byte) => byte / 1000 / 1000;

const styles = {
  fullscreen: {
    position: 'fixed',
    zIndex: 1300,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'black',
  },
  imgWrapper: {
    position: 'absolute',
    left: 0,
    right: 300,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
};

const DropzoneActive = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  opacity: 0;
  border: 1px dashed #ececec;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  box-sizing: border-box;
  color: #666;
  transition: all 0.6s ease-out;

  ${props => props.visible && css`
    opacity: 1;
    z-index: 9999;
  `};
`;

const InfoPanel = styled.div`
  width: 300px;
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0;
  bottom: 0;
`;

const mapStateToProps = (state, ownProps) => {
  const { accountId } = ownProps;
  const medias = _.get(state, ['accounts', accountId, 'medias']);
  const playlists = _.get(state, ['accounts', accountId, 'playlists']);

  return {
    accountId,
    medias,
    mediaEntities: _.map(medias, media => media),
    playlists,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { accountId } = ownProps;

  return {
    updateMedia: (medias) => dispatch(updateAccount(accountId, 'medias', medias)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(class extends React.Component {

  constructor(props) {
    super(props);

    this.inputID = React.createRef();
    this.inputTitle = React.createRef();
    this.inputExpireAt = React.createRef();
    this.inputTags = React.createRef();

    this.state = {
      dropzoneActive: false,
      uploadedFiles: [],
      focusedImageId: null,
      showMediaDialog: false,
      searchText: '',
      currentTag: 'all',
      orderBy: 'metadata.updated',
      orderDirection: 'desc',
      foundMedia: {},
    }

    this.notify = notify.createShowQueue();
  }

  componentDidMount = () => {
    const { accountId, mediaClient, updateMedia } = this.props
    /**
     * Get first 8 medias from firestore and dispatch to redux store
     */
    mediaClient.getMedia('metadata.timeCreated', 8, 'desc').onSnapshot(snapshot => {
      const medias = _.reduce(snapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: { id: doc.id, ...doc.data() }}), {});
      updateMedia(medias)
    });
  }

  /* Dropzone */
  onDragEnter = () => {
    this.setState({
      dropzoneActive: true
    });
  }

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false
    });
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { mediaClient } = this.props;
    // console.log('onDrop', { mediaClient, acceptedFiles, rejectedFiles });
    if (_.size(rejectedFiles) > 0) {
      alert(`Reject files ${_.chain(rejectedFiles).map(file => file.name).join(', ')} because of file size exceed ${byteToMB(maxUploadFileSize)} MB`)
    }
    if (_.size(acceptedFiles) <= 0) {
      alert(`None of accepted files`)
      return
    }
    this.setState({
      uploadedFiles: acceptedFiles,
      dropzoneActive: false
    });

    const toastId = toast.info(`Uploading ${_.size(acceptedFiles)} files ...`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false
    });
    Promise.all(
      _.map(acceptedFiles, uploadedFile => {
       return mediaClient.uploadToStorage(uploadedFile).catch(e => {
         console.log('onDrop client upload error', e.message, e.ref);
         return { status: 'error', code: e.message, ref: e.ref, uploadedFile };
       });
      }),
    ).then(files => {
      // toast.dismiss();
      // console.log('onDrop Uploaded complete', files);
      this.setState({ uploadedFiles: files, showMediaDialog: true });
      toast.update(toastId, {
        type: toast.TYPE.SUCCESS,
        render: `Uploaded ${_.size(files)} files completely`,
        autoClose: 5000
      })
    });
  }

  handleCloseDialog = () => this.setState({ showMediaDialog: false })

  /* End of Dropzone */

  deleteMedia = (mediaId) => {
    const { mediaClient, history, match } = this.props;
    const { medias } = this.props;
    const media = _.get(medias, mediaId);

    // Delete Firestore
    // this.setState({ focusedImageId: null });
    this.notify(`Deleting Media`, 'success', 4000);
    mediaClient.deleteFromStorage(media.ref).then(() => {
      this.notify(`Medias deleted from storage`, 'success', 4000);
    });

    mediaClient.deleteMediaFromFirestore(mediaId).then(() => {
      this.notify(`Medias deleted from firestore`, 'success', 4000);
    });

    history.push(match.url);
  }

  handleChangeSearch = (e, mediaClient) => {
    const { value } = e.target
    this.setState({ searchText: value });
    /**
     * Handle search media on firestore
     * this function is call after searchText state has changed.
     * it is assigned to setTimeout with .5 seconds delay time.
     *
     * @param  {MediaClient} mediaClient
     */
    const handleSearchMedia = async (mediaClient) => {
      try {
        const { searchText } = this.state;

        const mediaSnapshot = await mediaClient.searchMedia('string', 'title', searchText).get();
        const medias = _.reduce(mediaSnapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: { id: doc.id, ...doc.data() }}), {});
        this.setState({ foundMedia: medias });
      } catch (error) {
        console.error(error)
      }
    }

    /* clear previous timeout first for prevent handleSearchMedia function will be called several time */
    clearTimeout(this.searchTimeout);
    if (value) {
      this.searchTimeout = setTimeout(() => {
        handleSearchMedia(mediaClient);
      }, 500);
    } else {
      this.setState({ foundMedia: {} })
    }
  }

  handleChangeTag = (e, value) => this.setState({ currentTag: value })

  updateMediaInfo = (mediaId, e) => {
    const { mediaClient, medias } = this.props;
    const id = this.inputID.current.value;
    const title = this.inputTitle.current.value;
    const expireAt = this.inputExpireAt.current.value;
    const tags = this.inputTags.current.value;
    const media = _.get(medias, id);
    // console.log('updateMediaInfo', media);
    // console.log('save', id, title, tags);
    mediaClient.saveMediaToFirestore(id, media.ref, {
      title,
      expireAt,
      tags:  _.map(_.split(tags, ','), _.trim)
    })
    .then(() => {
      this.notify(`Medias update`, 'success', 4000);
    });
  }

  /* ReUpload */
  reUpload = (doc) => {
    this.reUploadDropzoneRef.open();
  }

  reUploadOnDrop = (acceptedFiles, rejectedFiles, mediaId) => {
    const { mediaClient, medias } = this.props;
    // const { focusedImageId } = this.state;
    const focusedImage = mediaId !== null ? _.get(medias, mediaId) : null;

    const uploadedFile = acceptedFiles[0];
    this.notify(`Re-Uploading ${mediaId}`, 'success', 4000);

    // console.log('uploadedFile', uploadedFile);
    mediaClient.uploadToStorage(uploadedFile, focusedImage.ref)
      .then(file => {
        this.notify(`Re-Uploaded ${mediaId} complete`, 'success', 4000);
        return mediaClient.saveMediaToFirestore(mediaId, file.ref.toString(), { url: file.url })
      })
      .then(() => {
        this.notify(`Update Media ${mediaId} complete`, 'success', 4000);
      });
  }

  applyZIndex = el => {
    el.style.zIndex = 1300;
  }

  removeZIndex = el => {
    el.style.zIndex = "";
  }

  // filterItems = (entities, currentTag, searchText) => {

  //   const searchResultItems = (searchText !== '' && typeof this.fuse.search === 'function') ? this.fuse.search(searchText) : entities;
  //   const result = currentTag === 'all' ? searchResultItems : _.filter(searchResultItems, item => _.includes(item.tags, currentTag));
  //   return result;
  // }

  handleDeleteMedia = async (mediaId) => {
    const { client } = this.props;
    const effectPlaylists = await client.getPlaylistsThatContainMedia(mediaId)
    let alertMessage = 'Delete this media won\'t effect any playlist.'
    if (_.size(effectPlaylists) > 0) {
      const namePlaylist = _.join(_.map(effectPlaylists, 'name'), ', ')
      alertMessage = `Delete this media will effect to these playlists = "${namePlaylist}"`
    }
    const isConfirm = window.confirm(alertMessage);
    if (isConfirm) {
      client.deleteMediaFromLibrary(mediaId)
    }
  }

  /**
   * Handle edit media
   * push routes to edit screen
   *
   * @param  {String} mediaId
   */
  handleEditMedia = (mediaId) => {
    const { match, history } = this.props;
    history.push(`${match.url}/${mediaId}`);
  }

  /**
   * @param  {} updateMedia
   * @param  {} mediaClient
   * @param  {} lastVisibleMedia
   * @param  {} medias
   */
  handleLoadMoreMedia = async (updateMedia, mediaClient, lastVisibleMedia, medias) => {
    if (lastVisibleMedia) {
      /* get the last doc by id from firestore for the query cursor startAfter() */
      const lastDoc = await mediaClient.getMediaDocRef(lastVisibleMedia.id).get()

      /* get next 8 medias after lastDoc object then constructuring */
      const snapshot = await mediaClient.getMediaPagination('metadata.timeCreated', lastDoc, 8, 'desc').get();
      const newMedias = _.reduce(snapshot.docs, (acc, doc) => ({ ...acc, [doc.id]: { id: doc.id, ...doc.data() }}), {});

      /* dispatch medias to redux store */
      updateMedia({ ...medias, ...newMedias })
    }
  }

  render() {
    const { medias, mediaEntities, playlists, client, mediaClient, match, history, updateMedia } = this.props;
    const { dropzoneActive, uploadedFiles, searchText, currentTag, showMediaDialog, foundMedia } = this.state;

    // const filteredItems = this.filterItems(mediaEntities, currentTag, searchText);
    const orderedItems = _.orderBy(_.isEmpty(foundMedia) ? mediaEntities : foundMedia, [this.state.orderBy], [this.state.orderDirection]);
    return (
      <div ref={ref => this.scrollRef = ref}>
        <PageHeader
          title="Medias"
          renderFloatingActionButtons={() => (
            <Button color="primary" variant="extendedFab" onClick={() => { this.dropzoneRef.open() }}>
              <AddIcon />Upload
            </Button>
          )}
        />
        <Dropzone
          ref={(node) => { this.dropzoneRef = node; }}
          disableClick
          accept={accept}
          style={{position: 'relative' }}
          onDrop={this.onDrop}
          maxSize={50 * 1000 * 1000}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
        >
          <DropzoneActive visible={dropzoneActive}>Drop files...</DropzoneActive>
          <div>
            <div style={{ padding: 24 }}>
              <div>
                <Grid container spacing={24}>
                  <Grid item sm={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Search"
                      value={searchText}
                      onChange={e => this.handleChangeSearch(e, mediaClient)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                      }}
                    />
                  </Grid>
                  {/* <Grid item sm={6}>
                    <List
                      subheader={<ListSubheader component="div">Tags</ListSubheader>}
                    >
                      {_.map(_.uniq(allTags), tag => (
                        <ListItem button key={tag} selected={currentTag === tag} onClick={e => this.handleChangeTag(e, tag)}>
                          <ListItemText primary={tag} />
                          <ListItemSecondaryAction>
                            <Chip label={_.sumBy(allTags, t => t === tag)} variant="outlined" />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                  </Grid> */}
                </Grid>
                <Grid container spacing={24}>
                  <Grid item sm={12}>
                    {currentTag !== 'all' && (
                      <Chip
                        label={currentTag}
                        variant="outlined"
                        onDelete={e => this.handleChangeTag(e, 'all')}
                        color="secondary"
                      />
                    )}
                  </Grid>
                  <Grid item sm={12}>
                    <Grid container spacing={24} style={{ marginTop: 12 }}>
                      <Grid item sm={12}>
                        <Table
                          columns={[
                            {
                              key: 'preview',
                              dataIndex: 'url',
                              title: 'Preview',
                              render: (url, media) => {
                                let mediaDOM = <span />
                                switch (_.get(media, 'metadata.contentType')) {
                                  case 'image/png':
                                  case 'image/jpg':
                                  case 'image/jpeg':
                                    mediaDOM = (
                                      <img src={url} alt="" style={{ width: 150 }} />
                                    );
                                    break;
                                  default:
                                    mediaDOM = (
                                      <video style={{ width: 150 }} muted>
                                        <source src={url} type={media.type} />
                                      </video>
                                    );
                                    break;
                                }
                                return (
                                  <div key={media.id} onClick={() => this.handleEditMedia(media.id)} style={{ cursor: 'pointer' }}>
                                    {mediaDOM}
                                  </div>
                                )
                              }
                            },
                            { key: 'title', dataIndex: 'title', title: 'Title' },
                            { key: 'size', title: 'Size', dataIndex: 'metadata.size', render: filesize },
                            { key: 'added', dataIndex: 'metadata.timeCreated', title: 'Added', render: time => moment(time).format('DD/MM/YYYY') },
                            { key: 'expireAt', dataIndex: 'expireAt', title: 'Expire', render: time => moment(time).format('DD/MM/YYYY') },
                            { key: 'action',
                              dataIndex: 'action',
                              title: 'Action',
                              render: (action, media) => (
                                <MediaItemAction
                                  client={client}
                                  mediaId={media.id}
                                  playlists={playlists}
                                  menus={[
                                    { key: 'addto-playlist' },
                                    { key: 'edit', onClick: (mediaId) => this.handleEditMedia(mediaId) },
                                    { key: 'delete', onClick: () => this.handleDeleteMedia(media.id) },
                                  ]}
                                />
                              )
                            },
                          ]}
                          dataSource={orderedItems}
                          sortable={false}
                          paging={false}
                        />
                        <Waypoint
                          onEnter={() => this.handleLoadMoreMedia(updateMedia, mediaClient, orderedItems[orderedItems.length - 1], medias)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Route
              path={`${match.url}/:mediaId`}
              render={(routerProps) => {
                const focusedImageId = _.get(routerProps, 'match.params.mediaId');
                const focusedImage = _.get(medias, focusedImageId);
                console.log('focusedImage', focusedImage)
                return (
                  <div style={styles.fullscreen}>
                    <div style={styles.imgWrapper}>
                      {
                        isImageFile(_.get(focusedImage, 'metadata.contentType', '')) ?
                        (
                          <img
                            onClick={() => this.setState({ focusedImageId: null })}
                            src={_.get(focusedImage, 'url')}
                            alt=""
                            style={{ maxWidth: 'calc(100% - 300px)', height: 'auto' }}
                          />
                        ) : (
                          <video style={{ maxWidth: 'calc(100% - 300px)', height: 'auto' }} muted controls playsInline>
                            <source src={_.get(focusedImage, 'url')} type={_.get(focusedImage, 'metadata.contentType', 'video/mp4')} />
                          </video>
                        )
                      }
                    </div>
                    <AppBar position="fixed" style={{ background: 'rgba(0, 0, 0, 0.8)' }}>
                      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <IconButton onClick={() => history.push(`${match.url}`)} style={{ color: 'white' }}><BackIcon /></IconButton>
                        <Typography variant="h6" color="inherit">
                          {_.get(focusedImage, 'title')}
                        </Typography>
                        <IconButton></IconButton>
                      </Toolbar>
                    </AppBar>
                    <InfoPanel>
                      <Paper style={{ padding: 20, height: '100%' }}>
                        <Paper elevation={0} style={{ margin: '20px 0' }}>
                          <Dropzone
                            ref={(node) => { this.reUploadDropzoneRef = node; }}
                            multiple={false}
                            accept={accept}
                            disableClick
                            style={{ position: 'relative' }}
                            onDrop={(acceptedFiles, rejectedFiles) => this.reUploadOnDrop(acceptedFiles, rejectedFiles, focusedImageId)}
                          >
                            <Typography style={{ fontSize: '0.8em', opacity: 0.6 }}>If you wanna just reupload the file, but keep everything the same.</Typography>
                            <Button variant="outlined" color="default" fullWidth onClick={e => this.reUpload(focusedImage)}>Reupload</Button>
                          </Dropzone>
                        </Paper>
                        <Divider />
                        <Paper elevation={0} style={{ margin: '20px 0' }}>
                          <FormControl fullWidth style={{ marginBottom: 20 }}>
                            <TextField
                              variant="outlined"
                              key={`id-${focusedImageId}`}
                              inputRef={this.inputID}
                              fullWidth
                              label="ID"
                              defaultValue={focusedImageId}
                              disabled
                            />
                          </FormControl>
                          <FormControl fullWidth style={{ marginBottom: 20 }}>
                            <TextField
                              variant="outlined"
                              key={`title-${focusedImageId}`}
                              inputRef={this.inputTitle}
                              fullWidth
                              label="Title"
                              defaultValue={_.get(focusedImage, 'title')}
                            />
                          </FormControl>
                          <FormControl fullWidth style={{ marginBottom: 20 }}>
                            <InputField
                              type="Date"
                              variant="outlined"
                              key={`expireAt-${focusedImageId}`}
                              inputRef={this.inputExpireAt}
                              fullWidth
                              label="Expire"
                              defaultValue={_.get(focusedImage, 'expireAt')}
                            />
                          </FormControl>
                          <FormControl fullWidth style={{ marginBottom: 20 }}>
                            <TextField
                              variant="outlined"
                              key={`tags-${focusedImageId}`}
                              inputRef={this.inputTags}
                              fullWidth
                              label="Tags"
                              defaultValue={_.join(_.get(focusedImage, 'tags'), ',')}
                            />
                          </FormControl>
                          <Button  variant="contained" fullWidth color="primary" onClick={e => this.updateMediaInfo(focusedImageId, e)}>Save</Button>
                        </Paper>
                        <Divider />
                        <Paper elevation={0} style={{ margin: '20px 0' }}>
                          <Confirm
                            render={() => <Button color="primary" variant="outlined" fullWidth>Delete</Button>}
                            onOk={() => this.deleteMedia(focusedImageId)}
                            content="Confirm Delete this?"
                          />

                        </Paper>
                      </Paper>
                    </InfoPanel>
                  </div>
                )
              }}
            />
          </div>
          <AddMediaDialog open={showMediaDialog} onClose={this.handleCloseDialog} files={uploadedFiles} client={mediaClient} />
        </Dropzone>
      </div>
    );
  }
});


import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { notify } from 'react-notify-toast';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from '@material-ui/core/FormControl';
import BackIcon from '@material-ui/icons/ArrowBack';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import AddIcon from '@material-ui/icons/Add';

import {
  saveMediaToFirestore,
  deleteMediaFromFirestore,
  uploadToStorage,
  deleteFromStorage
} from 'forviz-mediajs';

import Confirm from '../../components/Confirm';
import AddMediaDialog from '../../components/AddMediaDialog';

import PageHeader from '../../../../components/PageHeader';

const accept = 'image/*,video/*';

const Image = styled.div`
  position: relative;
  background: #f7f7f7;
  border: 1px solid #ececec;

  span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.6em 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    background: linear-gradient(to bottom, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.4) 100%);
    color: white;
    text-shadow: 0 1px rgba(0, 0, 0, 0.5);
  }
`;

const styles = {
  fullscreen: {
    position: 'fixed',
    zIndex: 1300,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'black',
  },
  imgWrapper: {
    position: 'absolute',
    left: 0,
    right: 300,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
};

const DropzoneActive = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  border: 1px dashed #ececec;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  box-sizing: border-box;
  color: #666;
  transition: all 0.6s ease-out;

  ${props => props.visible && css`
    opacity: 1;
    z-index: 9999;
  `};
`;

const InfoPanel = styled.div`
  width: 300px;
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0;
  bottom: 0;
`;

const mapStateToProps = (state, ownProps) => {
  const { buildingId } = ownProps;
  return {
    medias: _.get(state, ['projects', buildingId, 'medias']),
  }
}

export default connect(
  mapStateToProps,
)(class extends React.Component {
  
  constructor(props) {
    super(props);

    this.inputID = React.createRef();
    this.inputTitle = React.createRef();
    this.inputTags = React.createRef();
    this.inputOrder = React.createRef();

    this.state = {
      dropzoneActive: false,
      uploadedFiles: [],
      focusedImageId: null,
      showMediaDialog: false,
      currentTag: 'all',
    }

    this.notify = notify.createShowQueue();

  }

  /* Dropzone */
  onDragEnter = () => {
    this.setState({
      dropzoneActive: true
    });
  }

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false
    });
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({
      uploadedFiles: acceptedFiles,
      dropzoneActive: false
    });

    const { buildingId } = this.props;
    this.notify('Uploading', 'success', 4000);
    Promise.all(
      _.map(acceptedFiles, uploadedFile => {
       return uploadToStorage(`meerkat/projects/${buildingId}`, uploadedFile);
      }),
    ).then(files => {
      console.log('upload all complete', files);
      this.setState({ uploadedFiles: files, showMediaDialog: true });
      this.notify('Upload All Complete', 'success', 4000);
    });

  }

  handleCloseDialog = () => this.setState({ showMediaDialog: false })

  /* End of Dropzone */

  deleteMedia = (mediaId) => {
    const { buildingId } = this.props;
    const { medias } = this.props;
    const media = _.get(medias, mediaId);

    // Delete Firestore
    this.setState({ focusedImageId: null });
    this.notify(`Deleting Media`, 'success', 4000)
    deleteFromStorage(media.ref)
      .then(() => deleteMediaFromFirestore(`meerkat_projects/${buildingId}/medias`, mediaId))
      .then(() => this.notify(`Medias deleted`, 'success', 4000));
  }

  handleChangeTag = (e, value) => this.setState({ currentTag: value })

  updateMediaInfo = (e) => {
    const { buildingId, medias } = this.props;
    const id = this.inputID.current.value;
    const title = this.inputTitle.current.value;
    const tags = this.inputTags.current.value;
    const order = this.inputOrder.current.value;
    const media = _.get(medias, id);
    // console.log('updateMediaInfo', media);
    // console.log('save', id, title, tags);
    saveMediaToFirestore(`meerkat_projects/${buildingId}/medias`, id, media.ref, {
      title,
      tags:  _.map(_.split(tags, ','), _.trim),
      order
    })
    .then(() => {
      this.notify(`Medias update`, 'success', 4000);
    });
  }

  /* ReUpload */
  reUpload = (doc) => {
    this.reUploadDropzoneRef.open();
  }

  reUploadOnDrop = (acceptedFiles, rejectedFiles) => {
    const { medias } = this.props;
    const { focusedImageId } = this.state;
    const focusedImage = focusedImageId !== null ? _.get(medias, focusedImageId) : null;
    
    const { buildingId } = this.props;
    const uploadedFile = acceptedFiles[0];
    this.notify(`Re-Uploading ${focusedImageId}`, 'success', 4000);
        
    // console.log('uploadedFile', uploadedFile);
    uploadToStorage(`meerkat/projects/${buildingId}`, uploadedFile, focusedImage.ref)
      .then(file => {
        this.notify(`Re-Uploaded ${focusedImageId} complete`, 'success', 4000);
        return saveMediaToFirestore(`meerkat_projects/${buildingId}/medias`, focusedImageId, file.ref.toString(), { url: file.url })
      })
      .then(() => {
        this.notify(`Update Media ${focusedImageId} complete`, 'success', 4000);
      });
  }

  renderMediaItem = (item) => {
    const ext = _.last(_.split(item, '.'));
    switch (ext) {
      case 'mp4':
        return (
          <div style={{ width: '100%', paddingTop: '62.5%' }}>
            <video style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
              <source src={item.url} type="video/mp4" />
            </video>
          </div>
        );
      default:
        return (
          <React.Fragment>
            <img src={item.url} alt="" style={{ maxWidth: '100%' }} />
            <span>{item.title}</span>
          </React.Fragment>
        );
    }
  }

  applyZIndex = el => {
    el.style.zIndex = 1300;
  }

  removeZIndex = el => {
    el.style.zIndex = "";
  }

  
  render() {
    const { medias, buildingId } = this.props;
    // const allTags = this.state.tags;
    
    const allTags = _.compact(_.flatten(_.map(medias, item => item.tags)));
    // console.log('allTags', allTags);

    const { focusedImageId, dropzoneActive, uploadedFiles, currentTag, showMediaDialog } = this.state;
    const items = currentTag === 'all' ? medias : _.pickBy(medias, item => _.includes(item.tags, currentTag));

    const focusedImage = focusedImageId !== null ? _.get(medias, focusedImageId) : null;
    // console.log('focusedImage', focusedImageId, focusedImage);
    return (
      <div>
        <PageHeader
          title="Medias"
          renderFloatingActionButtons={() => (
            <Button color="primary" variant="extendedFab" onClick={() => { this.dropzoneRef.open() }}>
              <AddIcon />Upload
            </Button>
          )}
        />
        <Dropzone
          ref={(node) => { this.dropzoneRef = node; }}
          disableClick
          accept={accept}
          style={{position: 'relative' }}
          onDrop={this.onDrop}
          maxSize={2 * 1000 * 1000}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
        >
          <DropzoneActive visible={dropzoneActive}>Drop files...</DropzoneActive>
          <Flipper flipKey={focusedImageId}>
            {focusedImageId === null && (
              <div style={{ padding: 24 }}>
                <div>
                  <Grid container spacing={24}>
                    <Grid item sm={12}>
                      {currentTag !== 'all' && (
                        <Chip
                          label={currentTag}
                          variant="outlined"
                          onDelete={e => this.handleChangeTag(e, 'all')}
                          color="secondary"
                        />
                      )}
                    </Grid>
                    <Grid item sm={9}>
                      <Grid container spacing={24} style={{ marginTop: 12 }}>
                        {_.map(items, (item, mediaId) => (
                          <Grid item sm={4} key={mediaId}>
                            <Flipped flipId={`media-${mediaId}`} onStart={this.applyZIndex} onComplete={this.removeZIndex}>
                              <Image onClick={() => this.setState({ focusedImageId: mediaId })}>
                                {this.renderMediaItem(item)}
                              </Image>
                            </Flipped>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        <List
                          subheader={<ListSubheader component="div">Tags</ListSubheader>}
                        >
                          {_.map(_.uniq(allTags), tag => (
                            <ListItem button key={tag} selected={currentTag === tag} onClick={e => this.handleChangeTag(e, tag)}>
                              <ListItemText primary={tag} />
                              <ListItemSecondaryAction>
                                <Chip label={_.sumBy(allTags, t => t === tag)} variant="outlined" />
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {focusedImageId !== null && (
              <Flipped flipId={`media-${focusedImageId}`} onStart={this.applyZIndex}>
                <div style={styles.fullscreen}>
                  <div style={styles.imgWrapper}>
                    <img
                      onClick={() => this.setState({ focusedImageId: null })}
                      src={_.get(focusedImage, 'url')}
                      alt=""
                      style={{ maxWidth: 'calc(100% - 300px)', height: 'auto' }}
                    /> 
                  </div>
                  <AppBar position="fixed" style={{ background: 'rgba(0, 0, 0, 0.8)' }}>
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <IconButton onClick={() => this.setState({ focusedImageId: null })} style={{ color: 'white' }}><BackIcon /></IconButton>
                      <Typography variant="h6" color="inherit">
                        {_.get(focusedImage, 'title')}
                      </Typography>
                      <IconButton></IconButton>
                    </Toolbar>
                  </AppBar>
                  <InfoPanel>
                    <Paper style={{ padding: 20, height: '100%' }}>
                      <Paper elevation={0} style={{ margin: '20px 0' }}>
                        <Dropzone
                          ref={(node) => { this.reUploadDropzoneRef = node; }}
                          multiple={false}
                          accept={accept}
                          disableClick
                          style={{ position: 'relative' }}
                          onDrop={this.reUploadOnDrop}
                        >
                          <Typography style={{ fontSize: '0.8em', opacity: 0.6 }}>If you wanna just reupload the file, but keep everything the same.</Typography>                   
                          <Button variant="outlined" color="default" fullWidth onClick={e => this.reUpload(focusedImage)}>Reupload</Button>
                        </Dropzone>
                      </Paper>
                      <Divider />
                      <Paper elevation={0} style={{ margin: '20px 0' }}>
                        <FormControl fullWidth style={{ marginBottom: 20 }}>
                          <TextField
                            variant="outlined"
                            key={`id-${focusedImageId}`}
                            inputRef={this.inputID}
                            fullWidth
                            label="ID"
                            defaultValue={focusedImageId}
                            disabled
                          />
                        </FormControl>
                        <FormControl fullWidth style={{ marginBottom: 20 }}>
                          <TextField
                            variant="outlined"
                            key={`title-${focusedImageId}`}
                            inputRef={this.inputTitle}
                            fullWidth
                            label="Title"
                            defaultValue={_.get(focusedImage, 'title')}
                          />
                        </FormControl>
                        <FormControl fullWidth style={{ marginBottom: 20 }}>
                          <TextField
                            variant="outlined"
                            key={`tags-${focusedImageId}`}
                            inputRef={this.inputTags}
                            fullWidth
                            label="Tags"
                            defaultValue={_.join(focusedImage.tags, ',')}
                          />
                        </FormControl>
                        <FormControl fullWidth style={{ marginBottom: 20 }}>
                          <TextField
                            variant="outlined"
                            key={`order-${focusedImageId}`}
                            inputRef={this.inputOrder}
                            fullWidth
                            label="Order"
                            defaultValue={_.get(focusedImage, 'order')}
                          />
                        </FormControl>
                        <Button variant="contained" fullWidth color="primary" onClick={this.updateMediaInfo}>Save</Button>
                      </Paper>
                      <Divider />
                      <Paper elevation={0} style={{ margin: '20px 0' }}>
                        <Confirm
                          render={() => <Button color="primary" variant="outlined" fullWidth>Delete</Button>}
                          onOk={() => this.deleteMedia(focusedImageId)}
                          content="Confirm Delete this?"
                        />
                        
                      </Paper>
                    </Paper>
                  </InfoPanel>
                </div>
              </Flipped>
            )}
          </Flipper>
          <AddMediaDialog open={showMediaDialog} onClose={this.handleCloseDialog} files={uploadedFiles} buildingId={buildingId} />
        </Dropzone>
      </div>
    );
  }
});


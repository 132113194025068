import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';
import { Route } from 'react-router-dom';
import uuidv4 from 'uuid/v4';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';

import AddIcon from '@material-ui/icons/Add';

import CreateDocButton from '../../../../components/CreateDocButton';
import FirestoreCollectionViewer from '../../../../components/FirestoreCollectionViewer';
import PageHeader from '../../../../components/PageHeader';

// import client from '../../engine';
import { createClient } from 'forviz-ombresjs';

import DetailScreen from './detail';

import { utility } from '../../../../lib'

const { permission } = utility;
const {
  getProjectRole,
  isProjectSuperAdmin 
} = permission;

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;
  return {
    medias: _.get(state, ['projects', projectId, 'medias']),
  }
}

export default connect(mapStateToProps)(class extends React.Component {
  state = {
    items: [],
    page: 0,
    rowsPerPage: 10,
    firstVisible: null,
    lastVisible: null,
  }

  handleSelectRow = (record, selected, selectedRows, e) => {
    const { match, history } = this.props;
    history.push(`${match.url}/${record.id}`)
  }

  handleCreate = (values) => {
    const { projectId } = this.props;

    const { title } = values;
    const id = uuidv4();

    const client = createClient({ projectId });
    const docRef = client.getCategoryDocRef(id);
    docRef.set({
      fields: {
        name: {
          en: title,
        }
      }
    }).then(() => {
      const { match, history } = this.props;
      history.push(`${match.url}/${id}`);  
    });

    // const { match, history } = this.props;
    // history.push(`${match.url}/${id}`);
  }

  render() {
    const { match, user, accountId, projectId, history } = this.props;
    const listScreenUrl = match.url;
    const client = createClient({ projectId });
    const userProjectRole = getProjectRole(user, accountId, 'ombres', projectId)
    const isSuperAdmin = isProjectSuperAdmin(userProjectRole)
    return (
      <div>
        <PageHeader
          title="Category"
          renderFloatingActionButtons={() =>
            {
              isSuperAdmin && (
                <CreateDocButton
                  label="Category"
                  renderButton={() => <Button variant="extendedFab" color="primary"><AddIcon /> Add a category</Button>}
                  onCreate={this.handleCreate}
                />
              )
            }
          }
        />
        <FirestoreCollectionViewer
          columns={[
            { title: 'Name (En)', dataIndex: 'fields.name.en' },
            { title: 'Name (Th)', dataIndex: 'fields.name.th' },
            { title: 'Name (Zh)', dataIndex: 'fields.name.zh' },
            { title: 'Parent Category', dataIndex: 'parent.fields.name.en' },
            { title: 'Status', dataIndex: 'fields.status' },
          ]}
          collectionRef={client.getCategoriesCollectionRef()}
          onSelect={this.handleSelectRow}
        />
        <Route
          path={`${match.url}/:id`}
          children={({ match, ...rest }) => {
            const id = _.get(match, 'params.id');
            const docRef = !_.isEmpty(id) ? client.getCategoryDocRef(id) : null;
            const open = docRef !== null;
            const docCustomFieldRef = client.getCustomFieldDocRef("category");
            return (
              <Drawer anchor="right" open={open} onClose={() => history.goBack()}>
                <DetailScreen
                  width="400px"
                  {...rest}
                  idEditable={false}
                  canDelete={isSuperAdmin}
                  projectId={projectId}
                  docRef={docRef}
                  docCustomFieldRef={docCustomFieldRef}
                  onClose={() => {
                    history.push(listScreenUrl);
                  }}
                />
              </Drawer>
            );
          }}
        />
      </div>
    );
  }
});

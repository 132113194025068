import React from 'react';
import _ from 'lodash';
import filesize from 'filesize';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

class MediaItem extends React.PureComponent {
  render() {
    const { onClick, url, title, metadata } = this.props;
    const fileName = _.get(metadata, 'name', '');
    const status = fileName.match(/(\s&'[ก-ฮ])/) ? 'warning' : 'ok';

    const ext = _.last(_.split(fileName, '.'));
    const updatedAt = metadata.updated ? moment(_.get(metadata, 'updated', null)).format('YYYY-MM-DD HH:mm:ss') : ''

    switch (ext) {
      case 'mp4':
        return (
          <Card onClick={onClick} style={{ background: status === 'warning' ? 'red' : '#fff' }}>
            <CardMedia component="video" src={url} style={{ height: 140 }} />
            <CardContent style={{ padding: '12px 24px' }}>
              <Typography
                variant="body1"
                style={{ 
                  "white-space": "nowrap",
                  "overflow": "hidden",
                  "text-overflow": "ellipsis"
                 }}
                >
                  {title}
              </Typography>
              <Typography variant="caption" color="textSecondary">{filesize(_.get(this.props, 'metadata.size', 0))}</Typography>
              <br />
              <Typography variant="caption" color="textSecondary">{updatedAt}</Typography>
            </CardContent>
          </Card>
        );
      default:
        return (
          <Card onClick={onClick} style={{ background: status === 'warning' ? 'red' : '#fff' }}>
            <CardMedia image={url} style={{ height: 140 }} />
            <CardContent style={{ padding: '12px 24px' }}>
              <Typography
                variant="body1"
                style={{ 
                  "white-space": "nowrap",
                  "overflow": "hidden",
                  "text-overflow": "ellipsis"
                 }}
              >
                {title}
              </Typography>
              <Typography variant="caption" color="textSecondary">{filesize(_.get(this.props, 'metadata.size', 0))}</Typography>
              <br />
              <Typography variant="caption" color="textSecondary">{updatedAt}</Typography>
            </CardContent>
          </Card>
        );
    }
  }
}

export default MediaItem
import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';

import InputField from '../../../../components/InputField';

const getChangeValue = eventOrValue => {
  if (typeof eventOrValue.target !== 'undefined') return eventOrValue.target.value;
  return eventOrValue;
}
export default class extends React.Component {
  static defaultProps = {
    value: {}
  }

  state = {
    advance: false,
  }

  renderEmailObjectField = (key, additionalProps = {}) => {
    const { value, name, onChange } = this.props;
    const fieldValue = _.get(value, [key]);

    return (
      <InputField
        type="TextField"
        value={fieldValue}
        label={_.capitalize(key)}
        variant="outlined"
        margin="dense"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        onChange={e => onChange({ ...value, [key]: getChangeValue(e) }, name)}
        {...additionalProps}
      />
    );
  }

  renderTextField = (key, additionalProps = {}) => {
    const { value, name, onChange } = this.props;
    const fieldValue = _.get(value, [key]);
    return(
      <InputField
        type="TextField"
        value={fieldValue}
        label={_.capitalize(key)}
        variant="outlined"
        margin="dense"
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        onChange={e => onChange({ ...value, [key]: getChangeValue(e) }, name)}
        {...additionalProps}
      />
    );
  }

  render() {
    const { advance } = this.state;
    // console.log('ActionInputField', this.props.value);
    return (
      <div style={{ padding: '0 4px'}}>
        <Grid container spacing={8}>
          <Grid item xs={2}>
            <TextField
              fullWidth
              select
              variant="outlined"
              margin="dense"
              value={'email'}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
            >
              <MenuItem key={'email'} value={'email'}>Email</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={10}>
            <div>
              <Grid container alignItems="center">
                <Grid item xs={9}>
                  {this.renderTextField('to')}
                </Grid>
                <Grid item xs={3}>
                  {!advance && <Button style={{ marginTop: 5 }} onClick={e => this.setState({ advance: true })}>CC/BCC <DownIcon /></Button>}
                  {advance && <Button style={{ marginTop: 5 }} onClick={e => this.setState({ advance: false })}>Less <UpIcon /></Button>}
                </Grid>
              </Grid>
              {advance && this.renderTextField('cc')}
              {advance && this.renderTextField('bcc')}
              {this.renderTextField('from', { type: 'Object', label: 'From', layout: 'horizontal', defaultValue: { name: '', email: '' } })}
              {this.renderTextField('replyTo', { type: 'Object', label: 'Reply To', layout: 'horizontal', defaultValue: { name: '', email: '' } })}
              {/* <Grid container>
                <Grid item xs={6}>{this.renderTextField('from.email', { label: 'From (Email)' })}</Grid>
                <Grid item xs={6}>{this.renderTextField('from.name', { label: 'From (Name)' })}</Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6}>{this.renderTextField('replyTo.email', { label: 'Reply To (Email)' })}</Grid>
                <Grid item xs={6}>{this.renderTextField('replyTo.name', { label: 'Reply To (Name)' })}</Grid>
              </Grid> */}
              {this.renderTextField('subject')}
              {this.renderTextField('body', { type: 'Html', inputProps: { multiline: true, rows: 10 }})}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
import React, { useEffect, useState } from "react";
import _ from "lodash";
import FirestoreDocEditor from "../../../../components/FirestoreDocEditor";
import { connect } from "react-redux";
import getBuildingOption from "../../utils/getBuildingOption";
import {
  getMapDocDataToFields,
  getMapFieldValuesToDocData,
  getSections,
} from "../../utils/getCustomfield";

// const cleanObject = obj => _.pickBy(obj, v => v !== undefined);

// const cleanObject = (obj) => {
//   Object.keys(obj).forEach(key => {
//     if (obj[key] && typeof obj[key] === 'object') cleanObject(obj[key]);
//     else if (obj[key] === undefined) delete obj[key];
//   });
//   return obj;
// };

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, "projectId");
  const setting = _.get(state, ["projects", projectId, "setting"]);

  const units = _.get(state, ["projects", projectId, "units"]);
  const unitOptions = _.map(units, (unit, unitId) => ({
    value: { floorId: unit.floorId, unitId: unit.id },
    label: unitId,
  }));

  const categories = _.get(state, ["projects", projectId, "categories"]);
  const categoriesOptions = _.map(categories, (cat, id) => ({
    id,
    value: id,
    label: _.get(cat, "fields.name.en"),
    parent: _.get(cat, "fields.parent"),
  }));

  const zones = _.get(state, ["projects", projectId, "zones"]);
  const zoneOptions = _.map(zones, (zone, zoneId) => ({
    value: zoneId,
    label: _.get(zone, "fields.name.en"),
  }));

  const floors = _.get(state, ["projects", projectId, "floors"]);
  const floorOptions = _.map(floors, (floor, floorId) => ({
    value: floorId,
    label: _.get(floor, "fields.name.en"),
  }));

  const nodes = _.get(state, ["projects", projectId, "nodes"]);
  const nodeOptions = _.map(nodes, (node, nodeId) => ({
    value: nodeId,
    label: nodeId,
  }));

  return {
    medias: _.get(state, ["projects", projectId, "medias"]),
    unitOptions,
    categoriesOptions,
    zoneOptions,
    floorOptions,
    nodeOptions,
    setting,
    building: _.get(state, ["projects", projectId, "building"]),
  };
};
export default connect(mapStateToProps)((props) => {
  const {
    projectId,
    setting,
    medias,
    categoriesOptions,
    zoneOptions,
    floorOptions,
    unitOptions,
    nodeOptions,
    building,
    docCustomFieldRef,
  } = props;
  const buildingEnable = _.get(setting, "buildingEnable");
  const [customField, setCustomField] = useState({});
  const [updateVersionForRender, setUpdateVersionForRender] = useState(0);

  useEffect(() => {
    docCustomFieldRef.onSnapshot((doc) => {
      const docData = doc.data();
      setUpdateVersionForRender(updateVersionForRender + 1);
      setCustomField(docData);
    });
  }, []);

  const privilegeIsShow = _.get(customField, ["fields", "privilege", "show"])
    ? [
        {
          title: "Privilege",
          fields: ["enablePrivilege", "privilegeEn", "privilegeTh"],
        },
      ]
    : [];

  const customSections = getSections(customField, {
    languages: _.get(setting, "languages", []),
  });
  return (
    <FirestoreDocEditor
      key={`updateVersionForRender-${_.size(
        customField
      )}-${updateVersionForRender}`}
      {...props}
      mediaEngine="firebase"
      mediaEngineConfig={{
        storagePath: `ombres/projects/${projectId}`,
        firestorePath: `ombres_projects/${projectId}/medias`,
      }}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => {
        const cuntomMapDocDataToFields = getMapDocDataToFields(customField, {
          data,
          medias,
          languages: _.get(setting, "languages", []),
        });
        const languages = _.get(setting, "languages", []);
        const nameFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`name-${lang}`)]: {
              label: `Name (${lang})`,
              value: _.get(data, `fields.name.${lang}`, ""),
              rules: {
                maxLength: {
                  maxLength: 30,
                  message: "Name is too long. (maximum is 30 characters)",
                },
              },
            },
          }),
          {}
        );
        const descriptionFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`description-${lang}`)]: {
              label: `Description (${lang})`,
              value: _.get(data, `fields.description.${lang}`, ""),
            },
          }),
          {}
        );

        return {
          ...nameFields,
          ...descriptionFields,
          category: {
            type: "Dropdown",
            label: "Category",
            value: _.get(data, "fields.category"),
            options: _.filter(categoriesOptions, (cat) =>
              _.isEmpty(_.get(cat, "parent"))
            ),
          },
          subcategory: {
            type: "Dropdown",
            label: "Sub Category",
            value: _.get(data, "fields.subcategory"),
            options: _.filter(
              categoriesOptions,
              (catOption) =>
                _.get(catOption, "parent") === _.get(data, "fields.category")
            ),
          },
          tags: {
            label: "Keywords/Tags",
            value: _.join(_.get(data, "fields.tags", [])),
          },
          logo: {
            label: "Logo",
            type: "Image",
            value: _.get(data, "fields.logo"),
            options: _.map(medias, (media, key) => ({
              value: key,
              label: key,
              url: media.url,
              tags: media.tags,
            })),
          },
          gallery: {
            label: "Gallery",
            type: "Gallery",
            value: _.get(data, "fields.gallery"),
            options: _.map(medias, (media, key) => ({
              value: key,
              label: key,
              url: media.url,
              tags: media.tags,
            })),
          },
          website: { label: "Website", value: _.get(data, "fields.website") },
          facebook: {
            label: "Facebook",
            value: _.get(data, "fields.facebook"),
          },
          instagram: {
            label: "Instagram",
            value: _.get(data, "fields.instagram"),
          },
          twitter: { label: "Twitter", value: _.get(data, "fields.twitter") },
          phone: { label: "Phone", value: _.get(data, "fields.phone") },
          building: {
            label: "Building",
            type: "Dropdown",
            value: _.get(data, "fields.building", "all"),
            options: getBuildingOption(building),
          },
          floor: {
            label: "Floor",
            type: "Dropdown",
            value: _.get(data, "fields.floor"),
            options: floorOptions,
          },
          unit: { label: "Unit", value: _.get(data, "fields.unit") },
          units: {
            label: "Units",
            type: "AutocompleteMultiple",
            value: _.map(
              _.filter(
                _.get(data, "fields.units"),
                ({ floorId, unitNo }) =>
                  floorId !== undefined || unitNo !== undefined
              ),
              (unitObj) =>
                `${_.get(unitObj, "floorId")}!${_.get(unitObj, "unitNo")}`
            ),
            suggestions: unitOptions,
          },
          unit3d: { label: "Unit 3d", value: _.get(data, "fields.unit3d") },
          node: {
            label: "Node",
            value: _.get(data, "fields.node"),
            type: "Autocomplete",
            placeholder: "Select Node",
            suggestions: nodeOptions,
          },
          node3d: { label: "Node 3d", value: _.get(data, "fields.node3d") },
          zone: {
            label: "Zone",
            type: "Dropdown",
            value: _.get(data, "fields.zone"),
            options: zoneOptions,
          },
          enablePrivilege: {
            label: "Show Privilege",
            type: "Switch",
            value: _.get(data, "fields.enablePrivilege"),
          },
          privilegeEn: {
            label: "Privilege (En)",
            value: _.get(data, "fields.privilege.en"),
            disabled: _.get(data, "fields.enablePrivilege") === false,
          },
          privilegeTh: {
            label: "Privilege (Th)",
            value: _.get(data, "fields.privilege.th"),
            disabled: _.get(data, "fields.enablePrivilege") === false,
          },
          startTime: {
            label: "Start Time",
            type: "Time",
            defaultValue: "10:00",
            value: _.get(data, "fields.startTime"),
          },
          endTime: {
            label: "End Time",
            type: "Time",
            defaultValue: "22:00",
            value: _.get(data, "fields.endTime"),
          },
          status: {
            label: "Status",
            type: "Dropdown",
            value: _.get(data, "fields.status", "draft"),
            options: [
              { value: "Draft", label: "Draft" },
              { value: "Published", label: "Published" },
              { value: "Archived", label: "Archived" },
            ],
          },
          ...cuntomMapDocDataToFields,
        };
      }}
      mapFieldValuesToDocData={(fieldValues) => {
        const languages = _.get(setting, "languages", []);
        const nameValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`name-${lang}`)],
          }),
          {}
        );
        const descriptionValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`description-${lang}`)],
          }),
          {}
        );

        const cuntomMapFieldValuesToDocData = getMapFieldValuesToDocData(
          customField,
          {
            fieldValues,
            languages: _.get(setting, "languages", []),
          }
        );

        return {
          fields: {
            name: nameValues,
            description: descriptionValues,
            category: fieldValues.category,
            subcategory: fieldValues.subcategory,
            tags: _.map(_.split(fieldValues.tags, ","), _.trim),
            logo: fieldValues.logo,
            gallery: fieldValues.gallery,
            startTime: fieldValues.startTime,
            endTime: fieldValues.endTime,
            building: fieldValues.building,
            floor: fieldValues.floor,
            unit: fieldValues.unit,
            units: _.map(fieldValues.units, (unit) => {
              const [floorId, unitNo] = _.split(unit, "!");
              return { floorId, unitNo };
            }),
            unit3d: fieldValues.unit3d,
            node: fieldValues.node,
            node3d: fieldValues.node3d,
            zone: fieldValues.zone,
            website: fieldValues.website,
            facebook: fieldValues.facebook,
            instagram: fieldValues.instagram,
            twitter: fieldValues.twitter,
            phone: fieldValues.phone,
            enablePrivilege: fieldValues.enablePrivilege,
            privilege: {
              en: fieldValues.privilegeEn,
              th: fieldValues.privilegeTh,
            },
            status: fieldValues.status,
            ...cuntomMapFieldValuesToDocData,
          },
        };
      }}
      sections={[
        {
          title: "Location",
          fields: _.compact([
            buildingEnable ? "building" : "",
            "floor",
            /*'unit',*/ "units",
            "node",
            "unit3d",
            "node3d",
            _.get(customField, ["fields", "zone", "show"]) ? "zone" : "",
          ]),
        },
        {
          title: "Shop",
          fields: [
            ..._.map(_.get(setting, "languages", []), (lang) =>
              _.camelCase(`name-${lang}`)
            ),
            ..._.map(_.get(setting, "languages", []), (lang) =>
              _.camelCase(`description-${lang}`)
            ),
            "website",
            "facebook",
            "instagram",
            "twitter",
            "phone",
          ],
        },
        {
          title: "Category",
          fields: ["category", "subcategory", "tags"],
        },
        {
          title: "Media",
          fields: ["logo", "gallery"],
        },
        {
          title: "Period",
          fields: ["startTime", "endTime"],
        },
        ...privilegeIsShow,
        ...customSections,
        {
          title: "Publish",
          fields: ["status"],
        },
      ]}
    />
  );
});

// const shop = {
//   units: [
//     { floorId: '2F', unit: '201' },
//     { floorId: '1F', unit: '105_1' },
//   ],
//   entranceNode: '1F-98'
// }

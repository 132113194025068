export const TEXTFIELD = 'TextField';
export const TEXTAREA = 'TextArea';
export const DRAFTJS = 'Draft';
export const RADIO = 'Radio';
export const CHECKBOX = 'Checkbox';
export const CHECKBOXES = 'Checkboxes';
export const SWITCH = 'Switch';
export const DROPDOWN = 'Dropdown';
export const AUTOCOMPLETE = 'Autocomplete';
export const AUTOCOMPLETE_MULTIPLE = 'AutocompleteMultiple';
export const IMAGE = 'Image';
export const GALLERY = 'Gallery';
export const DATE = 'Date';
export const TIME = 'Time';
export const OBJECT = 'Object';
export const HTML = 'Html';
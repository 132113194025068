import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Fuse from 'fuse.js';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import Table from '../Table';

const fuseOptions = {
  shouldSort: true,
  threshold: 0.1,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    "fields.name.en",
    "fields.name.th",
  ]
};

class FirestoreCollectionViewer extends React.Component {

  static propTypes = {
    dataSource: PropTypes.array,
    paging: PropTypes.bool
  }
  static defaultProps = {
    defaultOrderBy: 'title',
    defaultLimit: 50,
    paging: false
  }

  static getDerivedStateFromProps (props, prevState) {
    if (props.collectionRef === undefined && !_.isEqual(props.entities, prevState.entities)) {
      return {
        entities: props.entities,
      }
    }

    if (props.defaultLimit !== prevState.limit) {
      return {
        limit: props.defaultLimit,
      }
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      entities: props.entities || [],
      searchText: '',
      limit: 50,
      orderBy: props.defaultOrderBy,
      direction: 'asc',
      filters: {},
    }
  }

  componentDidMount() {
    if (this.props.collectionRef) this.fetchData();
    if(_.isEmpty(this.fuse)) {
      this.fuse = new Fuse(this.state.entities, fuseOptions);
    }
    this.parentToChild = _.reduce(_.get(this.props, ['filters'], []), (acc, filterData) => {
      if (filterData.parent) {
        return {
          ...acc,
          [filterData.parent]: {
            ..._.get(acc, [filterData.parent], {}),
            [filterData.dataIndex]: ''
          }
        }
      }
      return acc;
    },{});
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.entities, this.state.entities)) {
      this.fuse = new Fuse(this.state.entities, fuseOptions);
    }
  }

  componentWillUnmount() {
    if (typeof this.unsubscribe === 'function') this.unsubscribe();
  }

  fetchData = () => {
    const { collectionRef } = this.props;
    this.unsubscribe = collectionRef.onSnapshot(querySnapshot => {
      const entities = _.map(querySnapshot.docs, doc => {
        const docData = doc.data();
        const parentId = _.get(docData, ["fields", "parent"]);
        const parent = _.find(querySnapshot.docs, o => {
          return o.id === parentId;
        });
        return { id: doc.id, ...doc.data(), parent: parent && parent.data() };
      });
      this.setState({
        entities
      });
    });
  };
  

  handleChangeSearch = (e) => {
    this.setState({ searchText: e.target.value });
  }

  handleChangeSort = (orderBy, direction) => {
    this.setState({ orderBy, direction });
  }

  handleSelectFilter = (filterDataIndex, value) => {
    const resetData = _.get(this.parentToChild, filterDataIndex);
      this.setState({
        filters: {
          ...this.state.filters,
          [filterDataIndex]: value,
          ...resetData
        }
      })
  }

  filterItems = (entities, filters, searchText) => {
    const searchResultItems = (searchText !== '' && this.fuse && typeof this.fuse.search === 'function') ? this.fuse.search(searchText) : entities;
    const result = _.filter(searchResultItems, item => {
      return _.every(this.state.filters, (filterValue, filterKey) => {
        return filterValue === '' || _.get(item, filterKey) === filterValue;
      });
    })
    return result;
  }

  getFilterOptions = (filter) => {
    const { parent } = filter;
    const selectedParent = _.get(this.state, ['filters', parent], '');
    if (filter.parent) {
      return _.filter(filter.options, option => option.parent === selectedParent)
    }
    return filter.options;
  }

  render () {
    const { filters, columns, paging } = this.props;
    const { entities, searchText, limit, orderBy, direction } = this.state;
    // Filter
    // console.log('entities', entities);
    const filteredItems = this.filterItems(entities, this.state.filters, searchText);
    const visibleItems = limit > 0 ?_.take(filteredItems, this.state.limit) : filteredItems;
    // console.log('visibleItems', visibleItems);
    return (
      <Paper>
        <Toolbar>
          <Grid container>
            <Grid item sm={3}>
              <TextField
                style={{ width: '90%' }}
                variant="outlined"
                placeholder="Search"
                value={searchText}
                onChange={this.handleChangeSearch}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                }}
              />
            </Grid>
            <Grid item sm={7}>
              <Grid container>
                {_.map(filters, filter => (
                  <Grid item xs key={filter.dataIndex}>
                    <TextField
                      select
                      fullWidth
                      label={filter.label}
                      variant="outlined"
                      value={this.state.filters[filter.dataIndex] || ''}
                      onChange={e => this.handleSelectFilter(filter.dataIndex, e.target.value)}
                    >
                      <MenuItem value="">All</MenuItem>
                      {
                        _.map(
                          this.getFilterOptions(filter),
                          option => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          )
                        )
                      }
                    </TextField>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        <Table
          paging={paging}
          columns={columns}
          dataSource={visibleItems}
          orderBy={orderBy}
          direction={direction}
          onSelect={this.props.onSelect}
          renderTableFooterContent={
            () => `Showing ${_.size(visibleItems)} of result`
          }
          onChangeSort={this.handleChangeSort}
        />
      </Paper>
    );
  }
}

export default FirestoreCollectionViewer

import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import MenuList from '@material-ui/core/MenuList';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DashboardIcon from '@material-ui/icons/Dashboard';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ShopIcon from '@material-ui/icons/StoreMallDirectory';
import ArticleIcon from '@material-ui/icons/Notes';
import DoctorIcon from '@material-ui/icons/PermIdentity';
import FloorplateIcon from '@material-ui/icons/Layers';
import FacilityIcon from '@material-ui/icons/Place';
import MediaIcon from '@material-ui/icons/Photo';
import PromotionIcon from '@material-ui/icons/Loyalty';
import CodeIcon from '@material-ui/icons/Code';
import KioskUsageIcon from '@material-ui/icons/BarChart';
import KioskIcon from '@material-ui/icons/Dock';
import UserManagementIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';

import { primaryColor } from '../../theme';

const drawerWidth = 280;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#37474F',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
});

const BrandHeader = styled.div`
  background: transparent;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.2;
`;


// Override default MaterialUI
const StyledListSubheader = styled(ListSubheader)`
  &&& {
    color: white;
    opacity: 0.5;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &&& {
    color: white;
    span { color: white; }
    svg {
      fill: white;
    }
    &:hover {
      color: ${primaryColor};
      span {
        color: ${primaryColor};
      }
      svg {
        fill: ${primaryColor};
      }
    }

    &.active {
      background: ${primaryColor};
      color: black;
      span { color: black; }
      svg {
        fill: black;
      }
    }

    ${props => props.selected && css`
      background: ${primaryColor};
      color: black;
      span { color: black; }
      svg {
        fill: black;
      }
    `}
  }
`;

export default withRouter(withStyles(styles)(class extends React.Component {
  state = {
    mobileOpen: false
  }
  render() {
    const { match, classes } = this.props;
    
    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <BrandHeader>
          <Typography variant="h3">Aspen</Typography>
        </BrandHeader>
        <Divider />
        <MenuList>
          <StyledMenuItem button component={NavLink} exact to={`${match.url}`} key="dashboard">
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText inset primary="Dashboard" />
          </StyledMenuItem>
          <StyledMenuItem button component={NavLink} to={`${match.url}/feddbacks`} key="feedback">
            <ListItemIcon><FeedbackIcon /></ListItemIcon>
            <ListItemText inset primary="Customer Feedbacks" />
          </StyledMenuItem>
        </MenuList>
        <Divider />
        <MenuList subheader={<StyledListSubheader component="div">Informations</StyledListSubheader>}>
          <StyledMenuItem button component={NavLink} to={`${match.url}/medical-centers`} key="directory">
            <ListItemIcon><ShopIcon /></ListItemIcon>
            <ListItemText inset primary="Medical Centers" />
          </StyledMenuItem>
          <StyledMenuItem button component={NavLink} key={`content/doctors`} to={`${match.url}/doctors`}>
            <ListItemIcon><DoctorIcon /></ListItemIcon>
            <ListItemText disableTypography inset>Doctors</ListItemText>
          </StyledMenuItem>
          <StyledMenuItem button component={NavLink} key={`content/promotions`} to={`${match.url}/promotions`}>
            <ListItemIcon><PromotionIcon /></ListItemIcon>
            <ListItemText disableTypography inset>Promotion / Packages</ListItemText>
          </StyledMenuItem>
          <StyledMenuItem button component={NavLink} key={`content/articles`} to={`${match.url}/articles`}>
            <ListItemIcon><ArticleIcon /></ListItemIcon>
            <ListItemText disableTypography inset>Knowledges</ListItemText>
          </StyledMenuItem>
        </MenuList>
        <Divider />
        <MenuList subheader={<StyledListSubheader component="div">Building Management</StyledListSubheader>}>
          <StyledMenuItem button component={NavLink} to={`${match.url}/floors`} key="floors">
            <ListItemIcon><FloorplateIcon /></ListItemIcon>
            <ListItemText inset primary="Floorplan" />
          </StyledMenuItem>
          <StyledMenuItem button component={NavLink} key={`content/facilities`} to={`${match.url}/facilities`}>
            <ListItemIcon><FacilityIcon /></ListItemIcon>
            <ListItemText disableTypography inset>Facilities</ListItemText>
          </StyledMenuItem>
        </MenuList>
        <Divider />
        <MenuList subheader={<StyledListSubheader component="div">Kiosk Management</StyledListSubheader>}>
          <StyledMenuItem button component={NavLink} key={'kiosk/usage'} to={`${match.url}/kiosks`}>
            <ListItemIcon><KioskUsageIcon /></ListItemIcon>
            <ListItemText disableTypography inset>Usage Report</ListItemText>
          </StyledMenuItem>
          <StyledMenuItem button component={NavLink} key={'kiosk/management'} to={`${match.url}/kiosks`}>
            <ListItemIcon><KioskIcon /></ListItemIcon>
            <ListItemText disableTypography inset>Kiosk Setting</ListItemText>
          </StyledMenuItem>
          <StyledMenuItem button component={NavLink} key={'kiosk/config'} to={`${match.url}/config`}>
            <ListItemIcon><CodeIcon /></ListItemIcon>
            <ListItemText disableTypography inset>App Config</ListItemText>
          </StyledMenuItem>
        </MenuList>
        <Divider />
        <MenuList subheader={<StyledListSubheader component="div">Admin</StyledListSubheader>}>
          <StyledMenuItem button component={NavLink} key={'admin/medias'} to={`${match.url}/medias`}>
            <ListItemIcon><MediaIcon /></ListItemIcon>
            <ListItemText primary="Medias" />
          </StyledMenuItem>
          <StyledMenuItem button component={NavLink} key={'admin/users'} to={`${match.url}/users`}>
            <ListItemIcon><UserManagementIcon /></ListItemIcon>
            <ListItemText disableTypography inset>User Management</ListItemText>
          </StyledMenuItem>
          <StyledMenuItem button component={NavLink} key={'admin/settings'} to={`${match.url}/settings`}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText disableTypography inset>Settings</ListItemText>
          </StyledMenuItem>
        </MenuList>
      </div>
    );

    return (
      <nav className={classes.drawer}>
        {/* The implementation can be swap with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={this.props.container}
            variant="temporary"
            anchor="left"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}));

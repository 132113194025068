import React from 'react';
import { withRouter } from 'react-router-dom';

import { notify } from 'react-notify-toast';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import {
  BackgroundContainer,
  FormContainer,
  Form,
  FormHeader,
  VersionLabel,
  LoginGraphicImg,
  ArtworkContainer
} from './styles';
import Jelly from './Graphic';

const loginGraphic = require('./vector-smart-object.png');

const INITIAL_STATE = {
  email: '',
  password: '',
  error: '',
  submitting: false,
  errorCode: null,
};

class LoginScreen extends React.Component {

  state = { ...INITIAL_STATE };

  afterSignInSuccess = (user) => {
    const { history } = this.props;
    setTimeout(() => {
      this.setState({ submitting: false });
      history.push(user.defaultPath || '/');
    }, 400);
  }

  handleError = (error) => {
    const errorCode = error.code;
    const errorMessage = error.message || 'Cannot login';
    notify.show(`(${errorCode}) ${errorMessage}`, 'error', 10000);
  }

  handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      this.signInUser(e);
    }
  }

  handleChangeText = (name, value) => {
    this.setState({
      [name]: value,
      error: '',
    });
  }

  signInUser = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const { engine } = this.props;
    const { email, password } = this.state;
    try {
      const user = await engine.auth.signInWithEmailAndPassword(email, password);
      this.afterSignInSuccess(user);

    } catch (error) {
      console.log('signInError', error);
      this.handleError(error);
      this.setState({ submitting: false });

    }
  }

  signInWithGoogle = async (e) => {
    e.preventDefault();
    const { engine } = this.props;
    try {
      const user = await engine.auth.signInWithGoogle();
      this.afterSignInSuccess(user);

    } catch (error) {
      this.handleError(error);
      this.setState({ submitting: false });

    }
  }

  signInWithFacebook = async (e) => {
    e.preventDefault();
    const { engine } = this.props;
    try {
      const user = await engine.auth.signInWithFacebook();
      this.afterSignInSuccess(user);
    } catch (error) {
      this.handleError(error);
      this.setState({ submitting: false });

    }
  }

  render() {
    const { engine } = this.props;
    const { email, password, submitting } = this.state;
    return (
      <div>
        <BackgroundContainer>
          <Jelly style={{ position: 'absolute', width: '60%', bottom: 0, right: 0 }} />
          <VersionLabel>Version 1.1</VersionLabel>
        </BackgroundContainer>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ flexGrow: 1, minHeight: 'calc(100vh)' }}
        >
          <Grid item xs={12} sm={10} md={8} lg={6}>
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={10}
              wrap="wrap-reverse"
              style={{ height: 450 }}
            >
              <Grid item sm={6}>
                <FormContainer>
                  <Form>
                    <FormHeader>Login</FormHeader>
                    <TextField
                      id="email"
                      label="Email"
                      margin="normal"
                      value={email}
                      onChange={e => this.handleChangeText('email', e.target.value)}
                      fullWidth
                    />
                    <TextField
                      type="password"
                      id="password"
                      label="Password"
                      margin="normal"
                      value={password}
                      onKeyUp={this.handleKeyUp}
                      onChange={e => this.handleChangeText('password', e.target.value)}
                      fullWidth
                    />
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      style={{ margin: '10px 0' }}
                      onClick={this.signInUser}
                      disabled={submitting}
                    >
                      Sign in
                    </Button>
                    {engine.type === 'firebase' &&
                      <React.Fragment>
                        <hr />
                        <div>
                          <Button type="button" fullWidth style={{ background: '#ff3b3b', border: 'none', color: 'white', marginBottom: 20 }} onClick={this.signInWithGoogle}>Sign in with Google</Button>
                          {/* <Button type="button" fullWidth style={{ background: '#1b6cda', border: 'none', color: 'white' }} onClick={this.signInWithFacebook}>Sign in with Facebook</Button> */}
                        </div>
                      </React.Fragment>
                    }
                  </Form>
                </FormContainer>
              </Grid>
              <Grid item sm={6}>
                <ArtworkContainer>
                  <LoginGraphicImg src={loginGraphic} />
                </ArtworkContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withRouter(LoginScreen);

import React from 'react';
import _ from 'lodash';
// import Button from '@material-ui/core/Button';

import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import AreaEditor from './AreaEditor';
import FileUploader from './FileUploader';
import FieldTextEditor from './FieldTextEditor';

import {
  TEXTFIELD,
  TEXTAREA,
  DRAFTJS,
  RADIO,
  CHECKBOX,
  DROPDOWN,
  IMAGE,
  DATE,
  TIME,
  GALLERY,
} from '../../constants/fieldTypes';

const omitProps = ['spaceId'];

const getOptionValue = option => _.get(option, 'value', option);
const getOptionLabel = option => _.get(option, 'label', option);


export default class extends React.PureComponent {
  static defaultProps = {
    language: 'default',
  }

  getValueByLanguage = () => {
    const { value, language } = this.props;
    if (typeof value === 'string') return value;
    return _.get(value, language, '');
  }

  handleChangeByLanguage = e => {
    const { value, language, defaultLanguage } = this.props;
    let newValue = {};
    if (typeof value === 'string') {
      newValue[language] = e.target.value;
    } else {
      newValue = { ...value, [language]: e.target.value };
    }

    this.props.onChange(newValue);
  }
  render () {
    const { props } = this;
    switch (props.type) {
      case TEXTFIELD:
        // Multi Language
        return (
          <TextField
            fullWidth
            variant="outlined"
            {..._.omit(props, omitProps)}
            value={this.getValueByLanguage(props.value, props.language)}
            onChange={this.handleChangeByLanguage}
          />
        );
      case TEXTAREA:
        return (<TextField fullWidth multiline variant="outlined" {..._.omit(props, omitProps)} />);
      case DROPDOWN:
        return (
          <TextField
            fullWidth
            select
            variant="outlined"
            {..._.omit(props, omitProps)}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {_.map(props.options, option =>
              <MenuItem key={getOptionValue(option)} value={getOptionValue(option)}>{getOptionLabel(option)}</MenuItem>
            )}
          </TextField>
        );
      case RADIO:
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">{props.label}</FormLabel>
            <RadioGroup
              aria-label="Gender"
              name="gender1"
              value={props.value}
              onChange={props.onChange}
            >
              {_.map(props.options, option =>
                <FormControlLabel
                  key={getOptionValue(option)}
                  value={getOptionValue(option)}
                  control={<Radio />}
                  label={getOptionLabel(option)}
                />
              )}
            </RadioGroup>
          </FormControl>
        );
      case CHECKBOX:
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">{props.label}</FormLabel>
            <RadioGroup
              aria-label="Gender"
              name="gender1"
              value={props.value}
              onChange={props.onChange}
            >
              {_.map(props.options, option =>
                <FormControlLabel
                  key={getOptionValue(option)}
                  value={getOptionValue(option)}
                  control={<Checkbox />}
                  label={getOptionLabel(option)}
                />
              )}
            </RadioGroup>
          </FormControl>
        );
      case IMAGE:
        return <FileUploader {...props} path={`cic/spaces/${props.spaceId || 'temp'}`} />;
      case GALLERY:
        return <FileUploader {...props} path={`cic/spaces/${props.spaceId || 'temp'}`} multiple />;
      case DRAFTJS:
        return (<FieldTextEditor {...props} />);
      case DATE:
        return (
        <TextField
          fullWidth
          variant="outlined"
          {..._.omit(props, omitProps)}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />);
      
      case TIME:
        return (
        <TextField
          fullWidth
          variant="outlined"
          {..._.omit(props, omitProps)}
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
        />);
      case 'AreaEditor':
        return (<AreaEditor {...props} />);
      default:
        return (<TextField fullWidth variant="outlined" {..._.omit(props, omitProps)} />);
    }
  }
}
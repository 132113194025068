import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import MenuList from '@material-ui/core/MenuList';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DashboardIcon from '@material-ui/icons/Dashboard';
import ShopIcon from '@material-ui/icons/StoreMallDirectory';
import CategoryIcom from '@material-ui/icons/Category';
import FloorplateIcon from '@material-ui/icons/Layers';
import ZoneIcon from '@material-ui/icons/Map';
import MediaIcon from '@material-ui/icons/Photo';
import PromotionIcon from '@material-ui/icons/LocalOffer';
import EventIcon from '@material-ui/icons/Event';
import CodeIcon from '@material-ui/icons/Code';
import BusinessIcon from '@material-ui/icons/Business';
import KioskIcon from '@material-ui/icons/Dock';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import UserManagementIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';

import { primaryColor } from '../../theme';

import { utility } from '../../../../lib';

const { permission } = utility
const {
  isProjectSuperAdmin,
  isProjectAdmin
} = permission
const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#37474F',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

const BrandHeader = styled.div`
  background: transparent;
  padding: 32px 16px;
  color: white;
  opacity: 0.5;
`;


// Override default MaterialUI
const StyledListSubheader = styled(ListSubheader)`
  &&& {
    color: white;
    opacity: 0.5;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &&& {
    color: white;
    opacity: 0.78;
    span { color: white; }
    svg {
      fill: white;
    }
    &:hover {
      color: ${primaryColor};
      span {
        color: ${primaryColor};
      }
      svg {
        fill: ${primaryColor};
      }
    }

    &.active {
      background: ${primaryColor};
      color: black;
      span { color: black; }
      svg {
        fill: black;
      }
    }

    ${props => props.selected && css`
      background: ${primaryColor};
      color: black;
      span { color: black; }
      svg {
        fill: black;
      }
    `}
  }
`;

export default withRouter(withStyles(styles)(class extends React.Component {
  state = {
    mobileOpen: false
  }
  render() {
    const { role, match, classes } = this.props;
    
    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <BrandHeader>
          <Typography variant="h5">Ombrés</Typography>
        </BrandHeader>
        <Divider />
        <MenuList>
          <StyledMenuItem
            button
            component={NavLink}
            exact
            to={`${match.url}`}
            key="dashboard"
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </StyledMenuItem>
          <StyledMenuItem button component={NavLink} to={`${match.url}/medias`}>
            <ListItemIcon>
              <MediaIcon />
            </ListItemIcon>
            <ListItemText primary="Medias" />
          </StyledMenuItem>
        </MenuList>
        <Divider />
        <MenuList
          subheader={
            <StyledListSubheader component="div">Directory</StyledListSubheader>
          }
        >
          <StyledMenuItem
            button
            component={NavLink}
            to={`${match.url}/directory`}
            key="directory"
          >
            <ListItemIcon>
              <ShopIcon />
            </ListItemIcon>
            <ListItemText primary="Shops" />
          </StyledMenuItem>
          <StyledMenuItem
            button
            component={NavLink}
            to={`${match.url}/categories`}
            key="categories"
          >
            <ListItemIcon>
              <CategoryIcom />
            </ListItemIcon>
            <ListItemText primary="Category" />
          </StyledMenuItem>
          <StyledMenuItem
            button
            component={NavLink}
            to={`${match.url}/zones`}
            key="zones"
          >
            <ListItemIcon>
              <ZoneIcon />
            </ListItemIcon>
            <ListItemText primary="Zone" />
          </StyledMenuItem>
        </MenuList>
        <MenuList
          subheader={
            <StyledListSubheader component="div">
              Buildingplan
            </StyledListSubheader>
          }
        >
          <StyledMenuItem
            button
            component={NavLink}
            to={`${match.url}/building`}
            key="building"
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Building" />
          </StyledMenuItem>
          <StyledMenuItem
            button
            component={NavLink}
            to={`${match.url}/floors`}
            key="floors"
          >
            <ListItemIcon>
              <FloorplateIcon />
            </ListItemIcon>
            <ListItemText primary="Floor" />
          </StyledMenuItem>
          {/* <StyledMenuItem button component={NavLink} to={`${match.url}/buildingplan`}>
            <ListItemIcon><BuildingPlanIcon /></ListItemIcon>
            <ListItemText primary="Building Plan" />
          </StyledMenuItem> */}
        </MenuList>
        <Divider />
        <MenuList
          subheader={
            <StyledListSubheader component="div">Content</StyledListSubheader>
          }
        >
          <StyledMenuItem
            button
            component={NavLink}
            key={`content/promotions`}
            to={`${match.url}/promotions`}
          >
            <ListItemIcon>
              <PromotionIcon />
            </ListItemIcon>
            <ListItemText primary="Promotion" />
          </StyledMenuItem>
          <StyledMenuItem
            button
            component={NavLink}
            key={`content/events`}
            to={`${match.url}/events`}
          >
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="Events" />
          </StyledMenuItem>
          <StyledMenuItem
            button
            component={NavLink}
            key={`content/informations`}
            to={`${match.url}/informations`}
          >
            <ListItemIcon>
              <AnnouncementIcon />
            </ListItemIcon>
            <ListItemText primary="Informations" />
          </StyledMenuItem>
          <StyledMenuItem
            button
            component={NavLink}
            key={`content/banners`}
            to={`${match.url}/banners`}
          >
            <ListItemIcon>
              <ViewCarouselIcon />
            </ListItemIcon>
            <ListItemText primary="Banners" />
          </StyledMenuItem>
        </MenuList>
        <Divider />
        {(isProjectSuperAdmin(role) || isProjectAdmin(role)) && (
          <MenuList
            subheader={
              <StyledListSubheader component="div">Admin</StyledListSubheader>
            }
          >
            <StyledMenuItem
              button
              component={NavLink}
              key={"admin/kiosks"}
              to={`${match.url}/kiosks`}
            >
              <ListItemIcon>
                <KioskIcon />
              </ListItemIcon>
              <ListItemText primary="Kiosk Setting" />
            </StyledMenuItem>
            <StyledMenuItem
              button
              component={NavLink}
              key={"admin/config"}
              to={`${match.url}/config`}
            >
              <ListItemIcon>
                <CodeIcon />
              </ListItemIcon>
              <ListItemText primary="App Config" />
            </StyledMenuItem>
            <StyledMenuItem
              button
              component={NavLink}
              key={"admin/users"}
              to={`${match.url}/users`}
            >
              <ListItemIcon>
                <UserManagementIcon />
              </ListItemIcon>
              <ListItemText primary="User Management" />
            </StyledMenuItem>
            <StyledMenuItem
              button
              component={NavLink}
              key={"admin/customfields"}
              to={`${match.url}/custom-fields`}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Custom Fields" />
            </StyledMenuItem>
            <StyledMenuItem
              button
              component={NavLink}
              key={"admin/settings"}
              to={`${match.url}/settings`}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </StyledMenuItem>
          </MenuList>
        )}
      </div>
    );

    return (
      <nav className={classes.drawer}>
        {/* The implementation can be swap with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={this.props.container}
            variant="temporary"
            anchor="left"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}));

import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import FormLabel from '@material-ui/core/FormLabel';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter4Icon from '@material-ui/icons/Filter4';
import Filter5Icon from '@material-ui/icons/Filter1';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
  DatePicker,
} from '@material-ui/pickers';

import Table from '../../../../components/Table'

import { utility } from '../../../../lib'

import {
  DRAWER_WIDTH,
  SEARCH_EVENT,
  SEARCH_ORIGIN_EVENT,
  SEARCH_DESTINATION_EVENT,
  SEARCH_KEYWORDS_EVENT,
  SEARCH_CATEGORIES_EVENT,
} from '../../config'

import LogsModule, { getLogsByEventName } from '../../reducers/logs'

const { getDateTimeDisplay, jsonToCsvConvertor } = utility

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
  },
  appBar: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    'overflow-y': 'scroll',
  },
  nested: {
    paddingLeft: theme.spacing.unit * 6,
  },
}));

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps
  const { params } = match
  const eventName = params.eventName.replace('-', '.')
  const queryLogs = getLogsByEventName(state.logs, eventName)
  return {
    eventName,
    logs: queryLogs
  }
}

const Transaction = ({ dispatch, logs, location, history, eventName }) => {

  const classes = useStyles();
  const [ spacing, setSpacing ] = useState(2);
  const [ isLoading, setLoading ] = useState(false)

  const [ selectedStartDate, setSelectedStartDate ] = useState(moment().subtract(7, 'days'));
  const [ selectedEndDate, setSelectedEndDate ] = useState(moment());

  const getDataSource = () => {
    const startX = Number(selectedStartDate.startOf('day').format('x'))
    const endX = Number(selectedEndDate.endOf('day').format('x'))
    return _.sortBy(_.reduce(logs, (acc, log, logKey) => {
      const submitSeconds = log.timestamp.seconds;
      const jsDate = new Date(submitSeconds * 1000);
      const date = getDateTimeDisplay(jsDate, 'x')
      const currentX = Number(moment(jsDate).startOf('day').format('x'))
      if (startX <= currentX && currentX <= endX) {
        return [
          ...acc,
          {
            ...log,
            jsDate,
            logKey,
            date,
          }
        ]
      }
      return acc
    }, []), log => log.jsDate * -1)
  }

  const handleStartDateChange = (moment) => {
    const params = new URLSearchParams(location.search);
    params.set('start-date', moment.format('YYYY-MM-DD'))
    setSelectedStartDate(moment)
    history.push({ search: params.toString() });
  }

  const handleEndDateChange = (moment) => {
    const params = new URLSearchParams(location.search);
    params.set('end-date', moment.format('YYYY-MM-DD'))
    setSelectedEndDate(moment)
    history.push({ search: params.toString() });
  }
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const startDateYYYYMMDD = params.get('start-date')
    const endDateYYYYMMDD = params.get('end-date')
    if (startDateYYYYMMDD) setSelectedStartDate(moment(startDateYYYYMMDD))
    if (endDateYYYYMMDD) setSelectedEndDate(moment(endDateYYYYMMDD))
  }, [])

  const renderLoading = () => {
    return (
      <Grid item xs={12}>
        <Grid container justify="center" spacing={spacing}>
          <Grid item xs={6}>
            <Skeleton className={classes.skeleton} animation="wave" variant="rect" width='100%' height={400} />
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="center" spacing={spacing}>
              <Grid item xs={12}>
                <Skeleton className={classes.skeleton} animation="wave" variant="rect" width='100%' height={200} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton className={classes.skeleton} animation="wave" variant="rect" width='100%' height={100} />
              </Grid>
              <Grid item xs={12}>
                <Skeleton className={classes.skeleton} animation="wave" variant="rect" width='100%' height={100} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const renderContent = () => {
    const data = getDataSource()
    // console.log('renderContent', data, eventName);
    let useDataFilter = data;
    if (_.includes([SEARCH_DESTINATION_EVENT], eventName)) {
      useDataFilter = _.filter(data, each => _.includes(_.keys(each), 'destination') && _.get(each, 'destination') !== "")
    }
    // console.log('renderContent', useDataFilter);
    let column = [
      { title: 'date', dataIndex: 'date' },
    ]
    if (_.includes([SEARCH_EVENT,SEARCH_ORIGIN_EVENT], eventName)) {
      column = [
        ...column,
        { title: 'Origin', dataIndex: 'origin' },
      ]
    }
    if (_.includes([SEARCH_CATEGORIES_EVENT], eventName)) {
      column = [
        ...column,
        { title: 'Categories', dataIndex: 'destination' },
      ]
    }
    if (_.includes([SEARCH_EVENT,SEARCH_DESTINATION_EVENT], eventName)) {
      column = [
        ...column,
        { title: 'Destination', dataIndex: 'destination' },
      ]
    }
    if (_.includes([SEARCH_KEYWORDS_EVENT], eventName)) {
      column = [
        ...column,
        { title: 'Keywords', dataIndex: 'keywords' },
      ]
    }
    column = [
      ...column,
      { title: 'source', dataIndex: 'source' },
    ]
    return (
      <div style={{ width: '100%' }}>
        <Grid container spacing={spacing}>
          <Grid item xs={2}>
            <DatePicker
              autoOk
              label="Start Date"
              disableFuture
              format="DD/MM/YYYY"
              value={selectedStartDate}
              onChange={handleStartDateChange}
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              autoOk
              label="End Date"
              disableFuture
              format="DD/MM/YYYY"
              value={selectedEndDate}
              onChange={handleEndDateChange}
            />
          </Grid>
        </Grid>
        <Table
          paging
          columns={column}
          dataSource={useDataFilter}
          // orderBy='date'
          renderTableFooterContent={
            () => `Showing ${_.size(useDataFilter)} of result`
          }
          reportName={`${eventName}_${selectedStartDate.format('YYYY_MM_DD')}_${selectedEndDate.format('YYYY_MM_DD')}`}
          excelFunction={jsonToCsvConvertor}
        />
      </div>
    )
  }

  const render = () => {
    return (
      <Wrapper>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.text} variant="h4" gutterBottom>
              Transaction
            </Typography>
            <Typography className={classes.text} variant="h5" gutterBottom>
              {`Event: ${eventName} / (${Object.keys(logs).length} logs)`}
            </Typography>
          </Grid>
          {isLoading ? renderLoading() : renderContent()}
        </Grid>
      </Wrapper>
    )
  }

  return render()
}

const Wrapper = styled.div`
`

export default connect(mapStateToProps)(Transaction)

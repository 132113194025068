import React, { useEffect, useState } from "react";
import _ from "lodash";
import FirestoreDocEditor from "../../../../components/FirestoreDocEditor";
import { connect } from "react-redux";
import getBuildingOption from "../../utils/getBuildingOption";
import {
  getMapFieldValuesToDocData,
  getMapDocDataToFields,
  getSections
} from "../../utils/getCustomfield";

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, "projectId");
  const setting = _.get(state, ["projects", projectId, "setting"]);
  return {
    medias: _.get(state, ["projects", projectId, "medias"]),
    building: _.get(state, ["projects", projectId, "building"]),
    setting
  };
};

export default connect(mapStateToProps)(props => {
  const { projectId, medias, setting, building, docCustomFieldRef } = props;
  const [customField, setCustomField] = useState({});
  const [updateVersionForRender, setUpdateVersionForRender] = useState(0);

  useEffect(() => {
    docCustomFieldRef.onSnapshot(doc => {
      const docData = doc.data();
      setUpdateVersionForRender(updateVersionForRender + 1);
      setCustomField(docData);
    });
  }, []);
  const floorplanOptions = _.chain(medias)
    .map((media, key) => ({ value: key, label: key, url: media.url }))
    // .filter(media => _.includes(media.tags, 'floorplan'))
    .value();
  const buildingEnable = _.get(setting, "buildingEnable");
  const buildingSection = buildingEnable
    ? [
        buildingEnable && {
          title: "Building",
          fields: ["building"]
        }
      ]
    : [];

  const customSections = getSections(customField, {
    languages: _.get(setting, "languages", [])
  });
  return (
    <FirestoreDocEditor
      key={`updateVersionForRender-${_.size(
        customField
      )}-${updateVersionForRender}`}
      {...props}
      mediaEngine="firebase"
      mediaEngineConfig={{
        storagePath: `ombres/projects/${projectId}`,
        firestorePath: `ombres_projects/${projectId}/medias`
      }}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => {
        const languages = _.get(setting, "languages", []);
        const nameFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`name-${lang}`)]: {
              label: `Name (${lang})`,
              value: _.get(data, `fields.name.${lang}`, "")
            }
          }),
          {}
        );
        const detailFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`detail-${lang}`)]: {
              label: `Detail (${lang})`,
              value: _.get(data, `fields.detail.${lang}`, "")
            }
          }),
          {}
        );
        const cuntomMapDocDataToFields = getMapDocDataToFields(customField, {
          data,
          medias,
          languages: _.get(setting, "languages", [])
        });

        return {
          ...nameFields,
          ...detailFields,
          building: {
            label: "Building",
            type: "Dropdown",
            value: _.get(data, "fields.building", "all"),
            options: getBuildingOption(building)
          },
          floorplan: {
            label: "Floorplan",
            type: "Image",
            value: _.get(data, "fields.floorplan"),
            options: floorplanOptions
          },
          order: { label: "Order", value: _.get(data, "order", 0) },
          svgWidth: {
            label: "SVG Width",
            value: _.get(data, "fields.svgWidth", 1920)
          },
          svgHeight: {
            label: "SVG Height",
            value: _.get(data, "fields.svgHeight", 1080)
          },
          status: {
            label: "Status",
            type: "Dropdown",
            value: _.get(data, "fields.status", "draft"),
            options: [
              { value: "Draft", label: "Draft" },
              { value: "Published", label: "Published" },
              { value: "Archived", label: "Archived" }
            ]
          },
          ...cuntomMapDocDataToFields
        };
      }}
      mapFieldValuesToDocData={fieldValues => {
        const languages = _.get(setting, "languages", []);
        const nameValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`name-${lang}`)]
          }),
          {}
        );
        const detailValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`detail-${lang}`)]
          }),
          {}
        );

        const cuntomMapFieldValuesToDocData = getMapFieldValuesToDocData(
          customField,
          {
            fieldValues,
            languages: _.get(setting, "languages", [])
          }
        );
        return {
          fields: {
            name: nameValues,
            detail: detailValues,
            building: fieldValues.building,
            floorplan: fieldValues.floorplan,
            svgWidth: _.toNumber(fieldValues.svgWidth),
            svgHeight: _.toNumber(fieldValues.svgHeight),
            status: fieldValues.status,
            ...cuntomMapFieldValuesToDocData
          },
          order: _.toNumber(fieldValues.order)
        };
      }}
      sections={[
        {
          title: "Info",
          fields: [
            ..._.map(_.get(setting, "languages", []), lang =>
              _.camelCase(`name-${lang}`)
            )
          ]
        },
        {
          title: "Detail",
          fields: [
            ..._.map(_.get(setting, "languages", []), lang =>
              _.camelCase(`detail-${lang}`)
            )
          ]
        },
        ...buildingSection,
        {
          title: "Order",
          fields: ["order"]
        },
        {
          title: "Media",
          fields: ["floorplan", "svgWidth", "svgHeight"]
        },
        ...customSections,
        {
          title: "Publish",
          fields: ["status"]
        }
      ]}
    />
  );
});

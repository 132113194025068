import PropTypes from 'prop-types';
import React from 'react';

import { addNewBlock, Block } from 'medium-draft';
import ImageIcon from '@material-ui/icons/Image';
import IconButton from '@material-ui/core/IconButton';

import { storage } from '../../engine';

export default class ImageButton extends React.Component {

  static propTypes = {
    setEditorState: PropTypes.func,
    getEditorState: PropTypes.func,
    close: PropTypes.func,
  };

  static defaultProps = {
    path: `temp/`,
  }

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onClick() {
    this.input.value = null;
    this.input.click();
  }

  onChange(e) {
    const { path } = this.props;
    console.log('onChange', e.target.files);
    const file = e.target.files[0];
    if (file.type.indexOf('image/') === 0) {
      const docId = file.name;
      
      const storageRef = storage.ref(path);
      const fileRef = storageRef.child(docId);

      fileRef.put(file)
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then(url => {
          console.log('url', url);
          if (url) {
            this.props.setEditorState(addNewBlock(
              this.props.getEditorState(),
              Block.IMAGE, {
                src: url,
              }
            ));
          }
        });
      
    }
    this.props.close();
  }


  render() {
    return (
      <IconButton
        type="button"
        size="small"
        variant="outlined"
        style={{ background: 'white', border: '1px solid #ccc' }}
        onClick={this.onClick}
        title="Add an Image"
      >
        <ImageIcon style={{ fontSize: 20 }} />
        <input
          type="file"
          accept="image/*"
          ref={(c) => { this.input = c; }}
          onChange={this.onChange}
          style={{ display: 'none' }}
        />
      </IconButton>
    );
  }
}
import React from 'react';
import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import SearchIcon from '@material-ui/icons/Search';

export default class extends React.Component {
  state = {
    open: false,
    searchText: '',
  }

  handleClose = () => this.setState({ open: false })

  handleSearchInputChange = (e) => {
    this.setState({
      searchText: e.target.value,
    })
  }

  handleSelect = (value) => {
    const { name } = this.props;
    this.props.onSelect(name, value);

    this.setState({ open: false });
  }

  render() {
    const { value, options } = this.props;
    const { searchText } = this.state;
    const selectedMedia = _.find(options, opt => value === opt.value);

    const visibleOptions = searchText !== '' ? _.filter(options, opt => _.includes(_.toLower(opt.value), _.toLower(searchText))) : options;
    return (
      <div style={{ border: '1px solid #ececec', background: '#f7f7f7' }}>
        <Button onClick={() => this.setState({ open: true })}>
          <div>
            {value && <img src={_.get(selectedMedia, 'url')} alt="" style={{ maxWidth: '100%' }} />}
            <div>{value || 'Select media'}</div>
          </div>
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5">Select Media</Typography>
              <div>
                <Input
                  onChange={this.handleSearchInputChange}
                  autoFocus
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <Grid container>
            {_.map(visibleOptions, option => (
              <Grid item sm={4} key={option.url}>
                <Button key={option.url} onClick={() => this.handleSelect(option.value)}>
                  <div>
                    <img src={option.url} style={{ maxWidth: '100%' }} alt="" />
                    <div>{option.value}</div>
                  </div>
                </Button>
              </Grid>
            ))}
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import ConfirmDialog from '../../components/ConfirmDialog';
import CreateDocButton from '../../components/CreateDocButton';
import Table from '../../components/Table';

const createAPIKey = () => {};
const getAPIKeys = () => {};
const activateAPIKey = () => {};
const deactivateAPIKey = () => {};
const deleteAPIKey = () => {};

export default class extends React.Component {
  state = {
    items: [],
  }

  // TODO: Review this later
  componentDidMount() {
    // const { accountId } = this.props;
    // getAPIKeys(accountId)
    //   .then(keys => {
    //     this.setState({ items: keys });
    //   })
  }

  createKey = ({ name, scopes }) => {
    // console.log({ name, scopes });
    // const { accountId } = this.props;
    // createAPIKey(accountId, name, _.split(scopes, ','))
    //   .then(res => {
    //     console.log('created key', res);
    //   });
  } 

  deactivateKey = (keyId) => {
    const { accountId } = this.props;
    deactivateAPIKey(accountId, keyId);
  }

  activateKey = (keyId) => {
    const { accountId } = this.props;
    activateAPIKey(accountId, keyId);
  }

  deleteKey = (keyId) => {
    const { accountId } = this.props;
    deleteAPIKey(accountId, keyId); 
  }

  render() {
    const { items } = this.state;
    return (
      <Grid container style={{ minWidth: 980, margin: '0 auto', position: 'relative' }}>
        <Grid item xs={12}>
          <Box style={{ position: 'absolute', right: 32, top: -24 }}>
            <CreateDocButton
              label="API Key"
              fields={[
                { type: 'TextField', name: 'name', label: 'Key Name', required: true },
                {
                  type: 'TextArea', name: 'scopes', label: 'Scopes', multiline: true, rows: 3, helperText: 'Seperate with commas',
                },
                
              ]}
              renderButton={() => <Button variant="contained" color="primary">Create new API Key</Button>}
              onCreate={this.createKey}
            />
          </Box>
          <Table
            dataSource={items}
            rowClassName={(record, index) => record.activated ? '' : 'disabled'}
            cellProps={(column, row) => {
              if (row.activated === false) {
                return {
                  style: { background: 'rgba(0, 0, 0, 0.12)', color: 'rgba(0, 0, 0, 0.26)' }
                };
              }
              return {};
            }}
            columns={[
              { title: 'Name', dataIndex: 'name' },
              { title: 'Key', dataIndex: 'id' },
              { title: 'Issued', dataIndex: 'createdAt', render: time => `${moment(time._seconds, 'X').fromNow()}` },
              {
                title: 'Scope',
                dataIndex: 'scopes',
                render: (scopes, key) => {
                  return _.map(scopes, (scope) => (
                    <div>
                      <Typography
                        variant="overline"
                        gutterBottom
                        style={{
                          letterSpacing: 0,
                          padding: '3px 12px',
                          display: 'inline-block',
                          border: '1px solid #ececec',
                          borderRadius: '40px'
                        }}
                      >
                        {scope}
                      </Typography>
                    </div>
                ))}
              },
              {
                title: 'Actions',
                dataIndex: 'id',
                render: (id, row) => row.activated
                  ? (
                    <ConfirmDialog
                      title="Confirm?"
                      content="Do you really want to deactivate this key?"
                      onOk={e => this.deactivateKey(id)}
                      render={() => <Button color="secondary" vairant="contained">Deactivate</Button>}
                    />
                  ) : (
                    <div style={{ display: 'flex' }}>
                      <ConfirmDialog
                        title="Confirm?"
                        content="Do you really want to activate this key?"
                        onOk={e => this.activateKey(id)}
                        render={() => <Button color="primary" variant="contained">Activate</Button>}
                      />
                      <ConfirmDialog
                      title="Confirm delete?"
                      content="Do you really want to delete this key?"
                      onOk={e => this.deleteKey(id)}
                      render={() => <Button color="secondary" variant="contained">Delete</Button>}
                    />
                  </div>
                  )
              }
            ]}
          />
        </Grid>
      </Grid>
    );
  }
}
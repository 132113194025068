import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import _ from 'lodash';

import { MuiThemeProvider } from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// Screens
import AccountRoot from './screens/AccountRoot';
import AppRoot from './screens/AppRoot';

import theme from './theme';

import rootReducer from './reducers';

import { utility } from '../../lib';

const { permission } = utility;
const {
  verifyUserHasAccessToProject,
} = permission;

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}
const store = createStore(rootReducer, applyMiddleware(...middlewares));

const ZentryApp = ({ match, user, project: account }) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <Helmet>
              <title>Zentry</title>
            </Helmet>
            <Switch>
              <Route exact path={`${match.url}`} render={routerProps => <AppRoot {...routerProps} accountId={account.id} user={user} />} />
              <Route path={`${match.url}/:projectId`} render={routerProps => <AccountRoot {...routerProps} accountId={account.id} user={user} />} />
            </Switch>
          </MuiThemeProvider>
      </Provider>
    </MuiPickersUtilsProvider>
  );
}

export default ZentryApp;

import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';

export default class extends React.Component {
  static propTypes = {
    onCreate: PropTypes.func,
  }

  static defaultProps = {
    label: 'Document',
  }
  state = {
    open: false,
  }

  openDialog = () => this.setState({ open: true })
  closeDialog = () => {
    this.setState({ open: false });
  }

  handleOk = () => {
    const value = this.input.value;
    this.props.onCreate(value);
    this.closeDialog();
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleOk();
    }
  }

  renderDefaultButton = ({ onClick, label }) => {
    return (
      <Button
        variant="extendedFab"
        color="primary"
        aria-label="Add"
        __style={{ position: 'absolute', right: 100, bottom: -25 }}
        onClick={onClick}
      >
        <AddIcon /> {label !== '' && label}
      </Button>
    );
  }

  render() {
    const { label } = this.props;
    const renderButton = typeof this.props.renderButton === 'function' ? this.props.renderButton : this.renderDefaultButton;
    return (
      <div>
        {renderButton({ label, onClick: this.openDialog })}
        <Dialog
          open={this.state.open}
          onClose={this.closeDialog}
          maxWidth="sm"
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add a {label}</DialogTitle>
          <DialogContent>
            <TextField inputRef={c => this.input = c} label={`${label} ID`} fullWidth autoFocus onKeyDown={this.handleKeyDown} />
          </DialogContent>
          <DialogActions>
            <Button color="default" onClick={this.closeDialog}>Cancel</Button>
            <Button color="primary" onClick={this.handleOk}>Create {label}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
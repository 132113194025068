import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import logger from 'redux-logger';

import rootReducer from './reducers';

import theme from './theme';

// Screens
import AppDashboard from './screens/AppDashboard';
import SpaceScreen from './screens/SpaceScreen';
// import TextEditor from './components/TextEditor';

// storestore
const store = createStore(rootReducer, applyMiddleware(logger));

class index extends Component {
  render() {
    const { match, project } = this.props;
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <title>Firemink | Forviz console</title>
          </Helmet>
          <Route exact path={match.url} render={routerProps => <AppDashboard {...routerProps} accountId={project.id} account={project} />} />
          <Route path={`${match.url}/spaces/:spaceId`} render={routerProps => <SpaceScreen {...routerProps} account={project} />} />
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default index;

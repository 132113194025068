import React from "react";
import _ from 'lodash';
import PropTypes from "prop-types";
import MuiMenu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SubMenuItem from "./SubMenuItem";

export default class Menu extends React.Component {
  renderMenuItems = () => {
    const { menuItems } = this.props;
    return menuItems.map(menuItem => {
      if (menuItem.hasOwnProperty("items")) {
        return (
          <SubMenuItem
            key={menuItem.key}
            title={menuItem.title}
            menuItems={menuItem.items}
          />
        );
      }

      return (
        <MenuItem key={menuItem.key} onClick={menuItem.onClick}>
          {menuItem.title}
        </MenuItem>
      );
    });
  };

  render() {
    const { anchorElement, open, onClose, ...others } = this.props;
    return (
      <MuiMenu
        anchorEl={anchorElement}
        open={open}
        onClose={onClose}
        {..._.omit(others, ['menuItems'])}
      >
        {this.renderMenuItems()}
      </MuiMenu>
    );
  }
}

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  menuItems: PropTypes.array.isRequired,
  anchorElement: PropTypes.any,
  onClose: PropTypes.func.isRequired
};

/* Example of menuItems:
[
    {
        'key': 'item1',
        'title': 'Item 1',
        'onClick': (event) => function () {
        }
    },
    {
        'key': 'item2',
        'title': 'Item 2',
        'onClick': (event) => function () {
        }
    },
    {
        'key': 'item3',
        'title': 'Item 3',
        'subMenuItems': [
            {
                'key': 'item1',
                'title': 'Item 1',
                'onClick': (event) => function () {
                }
            },
            {
                'key': 'item2',
                'title': 'Item 2',
                'onClick': (event) => function () {
                }
            }
        ]
    }
];
 */

import React from 'react';
import _ from 'lodash';
import FirestoreDocForm from '../../components/FirestoreDocForm';
import { connect } from 'react-redux';
import db from '../../engine/db';

const mapStateToProps = (state, ownProps) => {
  const buildingId = _.get(ownProps, 'buildingId');
  // console.log('SettingScreen mapStateToProps', buildingId, ownProps);
  const docRef = db.collection('meerkat_projects').doc(buildingId);
  return {
    medias: _.get(state, ['projects', buildingId, 'medias']),
    docRef,
  }
};

export default connect(mapStateToProps)((props) => {
  const { medias } = props;
  return (
    <FirestoreDocForm
      {...props}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => ({
        buildingName: { label: 'Building Name', value: _.get(data, 'name', id) },
        buildingFloors: { label: 'Floors', value: _.get(data, 'setting.building.floors', 1) },
        buildingAvatar: {
          label: 'Avatar', type: 'media', value: _.get(data, 'setting.building.avatar.id'),
          options: _.map(medias, (media, key) => ({ value: key, label: key, url: media.url }))
        },
        contentEnable: { label: 'Enable Content Module', type: 'switch', value: _.get(data, ['setting', 'module.content.enable'], false) },
        unitSource: {
          label: 'Unit Data Source',
          type: 'Dropdown',
          options: [
            { value: 'none', label: 'None' },
            { value: 'meerkat', label: 'Meerkat' },
            { value: 'cic', label: 'Forviz CIC' },
          ],
          value: _.get(data, ['setting', 'unit.source'], 'cic')
        },
        unitSourceSpaceId: { label: 'Unit Data Source SpaceId', value: _.get(data, ['setting', 'unit.source.spaceId'], '') },
      })}
      mapFieldsToDocData={fields => {
        const fieldValues = _.mapValues(fields, field => field.value);
        return {
          name: fieldValues.buildingName,
          setting: {
            building: {
              floors: fieldValues.buildingFloors,
              avatar: {
                id: fieldValues.buildingAvatar,
                ref: _.get(medias, `${fieldValues.buildingAvatar}.ref`),
                url: _.get(medias, `${fieldValues.buildingAvatar}.url`),
              },
            },
            'module.content.enable': fieldValues.contentEnable,
            'unit.source': fieldValues.unitSource,
            'unit.source.spaceId': fieldValues.unitSourceSpaceId
          }
        }
      }}
      sections={[
        {
          title: 'Info',
          fields: ['buildingName', 'buildingFloors', 'buildingAvatar']
        },
        {
          title: 'System',
          fields: ['contentEnable', 'unitSource', 'unitSourceSpaceId'],
        },
      ]}
    />
  );
});
import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { notify } from 'react-notify-toast';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import AddIcon from '@material-ui/icons/Add';

import { db } from '../../engine';
import CreateDocButton from '../../components/CreateDocButton';

import PageHeader from '../../../../components/PageHeader';
import ConfirmDialog from '../../../../components/ConfirmDialog';

export default class extends React.Component {
  createModel = (values) => {
    const { title, ...rest } = values;
    
    const { spaceId, match, history } = this.props;
    const modelId = _.kebabCase(title);
    db.collection('cic_spaces').doc(spaceId).collection('models').doc(modelId).set({
      id: modelId,
      title,
      ...rest,
      titleField: 'title',
      type: 'collection',
      fields: [
        { id: 'title', label: 'Title', localized: false, showInList: true, type: 'Text', ui: 'TextField' }
      ],
    });
    notify.show(`Model ${title} Created`, 'success');
    history.push(`${match.url}/${modelId}`);
  }

  deleteModel = (modelId) => {
    const { spaceId } = this.props;
    db.collection('cic_spaces').doc(spaceId).collection('models').doc(modelId).delete();
  }

  render() {
    const { match, models } = this.props;
    return (
      <div>
        <PageHeader
          title="Content Models"
          renderFloatingActionButtons={() => (
            <CreateDocButton
              label="Model"
              onCreate={this.createModel}
              additionalFields={[
                { name: 'description', label: 'Description', type: 'TextArea' },
                { name: 'icon', label: 'Icon', type: 'TextField' },
              ]}
              renderButton={() => (<Button variant="extendedFab" color="primary"><AddIcon /> Add Model</Button>)}
            />
          )}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(models, model => (
              <TableRow key={model.id}>
                <TableCell>{model.title}</TableCell>
                <TableCell>
                  <div style={{ display: 'flex' }}>
                    <Button variant="outlined" size="small" component={Link} to={`${match.url}/${model.id}`}>Edit Model</Button>
                    <ConfirmDialog
                      title="Are you sure?"
                      content={`Do you really want to delete ${model.title} model?`}
                      onOk={() => this.deleteModel(model.id)}
                      render={() => <Button variant="outlined" size="small">Delete</Button>}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
       
      </div>
    );
  }
}
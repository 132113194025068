/* eslint-disable import/no-anonymous-default-export */
import React, { Component } from "react";
import _ from "lodash";
import Dropzone from "react-dropzone";
import { notify } from "react-notify-toast";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import AddMediaDialog from "./AddMediaDialog";

const accept = ["image/*", "video/*"];

const DropzoneActive = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  border: 1px dashed #ececec;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  box-sizing: border-box;
  color: #666;
  opacity: 0;
  transition: all 0.6s ease-out;

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      z-index: 9999;
    `};
`;

const UploadButton = styled.button`
  box-sizing: border-box;
  width: 158px;
  height: 158px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  background: #fafafa;
  display: block;
  line-height: 148px;
  text-align: center;
  outline: none;
  margin: 2px;
`;

const StyledButton = styled(Button)`
  &&& {
    box-sizing: border-box;
    margin: 2px;
    padding: 3px;
    border: 1px solid #d9d9d9;
  }
  ${props =>
    props.active &&
    css`
      &&& {
        border: 1px solid #1e88e5;
        box-shadow: 0px 0px 2px #1e88e5;
      }
    `};
`;

const MoreButton = styled(Button)`
  &&& {
    align-self: center;
    width: 100%;
    margin: 16px 0;
  }
`;

const numberImage = 50;

export default class extends Component {
  static getDerivedStateFromProps(nextProps, state) {
    const prevProps = state.prevProps;
    const selectedValue = !_.isEqual(prevProps.value, nextProps.value)
      ? nextProps.value
      : state.selectedValue;
    return {
      prevProps: nextProps,
      selectedValue
    };
  }
  static defaultProps = {
    multiple: false,
    value: [],
    searchText: "",
    onCheckFile: () => new Promise((resolve, reject) => resolve(true))
  };

  static propTypes = {
    multiple: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.string),
    searchText: PropTypes.string,
    onCheckFile: PropTypes.func
  };

  state = {
    media: 0,
    dropzoneActive: false,
    showMediaDialog: false,
    prevProps: {},
    takeMediaIndex: 1
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { searchText } = this.props;
    const { takeMediaIndex } = this.state;
    if (searchText !== prevProps.searchText && takeMediaIndex !== 1) {
      this.setState({ takeMediaIndex: 1 });
    }
  }

  handleSelect = (mediaId, checked) => {
    this.props.onSelect(mediaId, checked);
  };

  handleCloseDialog = () => this.setState({ showMediaDialog: false });

  handleMoreImage = () => {
    const { takeMediaIndex } = this.state;
    this.setState({ takeMediaIndex: takeMediaIndex + 1 });
  };

  /* Dropzone */
  onDragEnter = () => {
    this.setState({
      dropzoneActive: true
    });
  };

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false
    });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    // console.log('onDrop', acceptedFiles, rejectedFiles)
    const { onCheckFile } = this.props;

    const checkFilePromises = _.map(acceptedFiles, onCheckFile);
    Promise.all(checkFilePromises).then(checkedFiles => {
      const checkedDroppedFiles = _.map(acceptedFiles, (acceptedFile, i) => {
        acceptedFile.existingFile = checkedFiles[i];
        return acceptedFile;
      });
      this.setState({
        droppedFiles: checkedDroppedFiles,
        dropzoneActive: false,
        showMediaDialog: true
      });
    });
  };

  handleSubmitDialog = medias => {
    console.log("handleSaveMedias", medias);

    _.forEach(medias, media => {
      this.props.onUploadFile(media);
      this.handleSelect(media.id);
    });

    this.setState({ showMediaDialog: false });
  };

  getMediaTags = (medias) => {
    console.log('getMediaTags', medias)
    return _.chain(medias).reduce((acc, media) => {
      const tags = _.compact(media.tags)
      return [
        ...acc,
        ...tags
      ]
    }, []).compact().uniq().value()
  }

  render() {
    const { options, searchText } = this.props;
    const {
      dropzoneActive,
      showMediaDialog,
      droppedFiles,
      takeMediaIndex
    } = this.state;
    const visibleOptions =
      searchText !== ""
        ? _.filter(options, option =>
            _.includes(_.toLower(option.label), _.toLower(searchText))
          )
        : options;
    // console.log('visibleOptions', visibleOptions);
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
          }}
        >
          <Dropzone
            ref={node => {
              this.dropzoneRef = node;
            }}
            disableClick
            accept={accept}
            style={{ position: "relative" }}
            onDrop={this.onDrop}
            onDragEnter={this.onDragEnter}
            onDragLeave={this.onDragLeave}
          >
            <div
              style={{
                width: 800,
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-start"
              }}
            >
              <DropzoneActive visible={dropzoneActive}>
                Drop files...
              </DropzoneActive>
              <UploadButton
                onClick={() => {
                  this.dropzoneRef.open();
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    'flex-direction': 'column',
                    'justify-content': 'center',
                    'line-height': 'initial',
                    'align-items': 'center',
                    cursor: 'pointer'
                  }}
                >
                  <AddIcon />
                  <h2>Upload</h2>
                </div>
              </UploadButton>
              {_.map(
                _.take(visibleOptions, takeMediaIndex * numberImage),
                option => {
                  const active = _.includes(this.props.value, option.value);
                  return (
                    <StyledButton
                      key={option.url}
                      onClick={() => this.handleSelect(option.value, !active)}
                      active={active}
                    >
                      <div style={{ width: 150, height: 150 }}>
                        <img
                          src={option.url}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                          }}
                          alt=""
                        />
                        <div style={{ fontSize: "12px" }}>{option.title}</div>
                      </div>
                    </StyledButton>
                  );
                }
              )}
              {takeMediaIndex * numberImage < _.size(visibleOptions) && (
                <MoreButton
                  onClick={this.handleMoreImage}
                  variant="outlined"
                  // color="primary"
                >
                  More
                </MoreButton>
              )}
            </div>
          </Dropzone>
        </div>
        <AddMediaDialog
          open={showMediaDialog}
          onClose={this.handleCloseDialog}
          onSave={this.handleSubmitDialog}
          files={droppedFiles}
          mediaTags={this.getMediaTags(options)}
        />
      </React.Fragment>
    );
  }
}

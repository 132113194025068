"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createClient = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _firebase = _interopRequireDefault(require("./firebase"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var createClient = function createClient(config) {
  console.log('createClient', config);
  var accountId = config.accountId;

  var db = _firebase["default"].firestore();

  var accountRef = db.collection('zentry_projects').doc(accountId); // account

  var getAccountRef = function getAccountRef() {
    return accountRef;
  };

  var getEventRef = function getEventRef(eventName) {
    return accountRef.collection(eventName);
  }; // event


  var queryEvents = function queryEvents(eventName) {
    var queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    return getEventRef(eventName).get().then(function (querySnapshot) {
      var data = {};
      querySnapshot.forEach(function (doc) {
        data[doc.id] = doc.data();
      });
      return data;
    })["catch"](function (error) {
      console.log("Error getting event: ", error);
    });
  }; // settings


  var saveSetting = function saveSetting(setting) {
    accountRef.set({
      setting: setting
    }, {
      merge: true
    });
  };

  return {
    getAccountRef: getAccountRef,
    getEventRef: getEventRef,
    saveSetting: saveSetting,
    queryEvents: queryEvents
  };
};

exports.createClient = createClient;
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import FirestoreDocEditor from "../../../../components/FirestoreDocEditor";
import {
  getSections,
  getMapDocDataToFields,
  getMapFieldValuesToDocData
} from "../../utils/getCustomfield";

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, "projectId");
  const setting = _.get(state, ["projects", projectId, "setting"]);
  return {
    setting,
    medias: _.get(state, ["projects", projectId, "medias"])
  };
};

export default connect(mapStateToProps)(props => {
  const { projectId, medias, docCustomFieldRef, setting } = props;
  const [customField, setCustomField] = useState({});
  const [updateVersionForRender, setUpdateVersionForRender] = useState(0);

  useEffect(() => {
    docCustomFieldRef.onSnapshot(doc => {
      const docData = doc.data();
      setUpdateVersionForRender(updateVersionForRender + 1);
      setCustomField(docData);
    });
  }, []);

  const customSections = getSections(customField, {
    languages: _.get(setting, "languages", [])
  });
  return (
    <FirestoreDocEditor
      key={`updateVersionForRender-${_.size(
        customField
      )}-${updateVersionForRender}`}
      {...props}
      ediaEngine="firebase"
      mediaEngineConfig={{
        storagePath: `ombres/projects/${projectId}`,
        firestorePath: `ombres_projects/${projectId}/medias`
      }}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => {
        const cuntomMapDocDataToFields = getMapDocDataToFields(customField, {
          data,
          medias,
          languages: _.get(setting, "languages", [])
        });
        const languages = _.get(setting, "languages", []);
        const nameFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`name-${lang}`)]: {
              label: `Name (${lang})`,
              value: _.get(data, `fields.name.${lang}`, "")
            }
          }),
          {}
        );
        const fromFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`from-${lang}`)]: {
              label: `From (${lang})`,
              value: _.get(data, `fields.from.${lang}`, "")
            }
          }),
          {}
        );
        const toFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`to-${lang}`)]: {
              label: `To (${lang})`,
              value: _.get(data, `fields.to.${lang}`, "")
            }
          }),
          {}
        );

        return {
          ...nameFields,
          category: {
            label: "Category",
            value: _.get(data, "fields.category"),
            disabled: true
          },
          ...fromFields,
          ...toFields,
          timetable: {
            label: "Timetable",
            value: _.get(data, "fields.timetable", ""),
            helperText: "(ex. 9:00 - 10:00) One Per line"
          },
          status: {
            label: "Status",
            type: "Dropdown",
            value: _.get(data, "fields.status", "draft"),
            options: [
              { value: "Draft", label: "Draft" },
              { value: "Published", label: "Published" },
              { value: "Archived", label: "Archived" }
            ]
          },
          ...cuntomMapDocDataToFields
        };
      }}
      mapFieldValuesToDocData={fieldValues => {
        const languages = _.get(setting, "languages", []);
        const nameValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`name-${lang}`)]
          }),
          {}
        );

        const fromValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`from-${lang}`)]
          }),
          {}
        );

        const toValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`to-${lang}`)]
          }),
          {}
        );

        const cuntomMapFieldValuesToDocData = getMapFieldValuesToDocData(
          customField,
          {
            fieldValues,
            languages: _.get(setting, "languages", [])
          }
        );
        return {
          fields: {
            name: nameValues,
            from: fromValues,
            to: toValues,
            category: fieldValues.category,
            timetable: fieldValues.timetable,
            status: fieldValues.status,
            ...cuntomMapFieldValuesToDocData
          }
        };
      }}
      sections={[
        {
          title: "Title",
          fields: [
            "category",
            ..._.map(_.get(setting, "languages", []), lang =>
              _.camelCase(`name-${lang}`)
            )
          ]
        },
        {
          title: "From",
          fields: [
            ..._.map(_.get(setting, "languages", []), lang =>
              _.camelCase(`from-${lang}`)
            )
          ]
        },
        {
          title: "To",
          fields: [
            ..._.map(_.get(setting, "languages", []), lang =>
              _.camelCase(`to-${lang}`)
            )
          ]
        },
        {
          title: "Timetable",
          fields: ["timetable"]
        },
        ...customSections,
        {
          title: "Publish",
          fields: ["status"]
        }
      ]}
    />
  );
});

export default [
  {
    id: 'meerkat',
    name: 'Meerkat',
    description: 'Property Sale Content Management',
    img: 'https://source.unsplash.com/featured/?meerkat',
  },
  {
    id: 'firemink',
    name: 'Firemink',
    description: 'Headless Content Management',
    img: require('../../apps/firemink/firemink-logo.png'),
  },
  {
    id: 'ombres',
    name: 'Ombrés',
    description: 'Directory Content Management',
    imgBackground: '#ffc107',
    img: require('../../apps/ombres/ombres-logo.png'),
  },
  {
    id: 'splitr',
    name: 'Splitr',
    description: 'Digital Signage MAnagement',
    imgBackground: '#263238',
    img: require('../../apps/splitr/splitr-logo.png'),
  },
  {
    id: 'plum',
    name: 'Plum',
    description: 'Form Service',
    imgBackground: '#ffc107',
    img: require('../../apps/plum/plum-logo.png'),
  },
  {
    id: 'climbr',
    name: 'Climbr',
    description: 'Career Application Service',
    imgBackground: '#ffc107',
    img: require('../../apps/climbr/logo.jpg'),
  },
  {
    id: 'aspen',
    name: 'Aspen',
    description: 'Hospital Content Management',
    imgBackground: '#ffc107',
    img: require('../../apps/aspen/aspen-logo.jpg'),
  },
  {
    id: 'zentry',
    name: 'Zentry',
    description: 'Statistic Report',
    imgBackground: '#ffc107',
    img: require('../../apps/zentry/logo.png'),
  }
];
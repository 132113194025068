// @flow

import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import SortIcon from "@material-ui/icons/Reorder";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
  arrayMove
} from "react-sortable-hoc";

const DragHandle = sortableHandle(() => (
  <span>
    <SortIcon />
  </span>
));

const SortableTableRow = sortableElement(({ children, ...props }) => (
  <TableRow {...props}>
    <TableCell>
      <DragHandle />
    </TableCell>
    {children}
  </TableRow>
));

const SortableTableBody = sortableContainer(({ children }) => {
  return <TableBody>{children}</TableBody>;
});

class TableComponent extends React.Component {
  static propTypes = {
    reportName: PropTypes.string,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        dataIndex: PropTypes.string.isRequired,
        render: PropTypes.function
      })
    ),
    dataSource: PropTypes.arrayOf(PropTypes.object),
    sortable: PropTypes.bool,
    excelFunction: PropTypes.func,
    onChangeSort: PropTypes.func
  };

  static defaultProps = {
    reportName: 'report',
    columns: [
      {
        title: "ID",
        dataIndex: "id"
      },
      {
        title: "Title",
        dataIndex: "title"
      }
    ],
    dataSource: [],
    sortable: false,
    excelFunction: undefined,
    onChangeSort: undefined
  };

  state = {
    selected: [],
    rowsPerPage: 20,
    page: 0
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const dataSource = arrayMove(this.props.dataSource, oldIndex, newIndex);
    console.log("onSortEnd", {
      oldDatasource: this.props.dataSource,
      dataSource
    });
    if (typeof this.props.onSortEnd === "function")
      this.props.onSortEnd(dataSource);
  };

  expandLimit = () => {
    this.setState({ limit: -1 });
  };

  handleSelectRow = (e, row) => {
    if (typeof this.props.onSelect === "function") this.props.onSelect(row);
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: event.target.value
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });
  };

  sortByField = (array, field, direction) => {
    return _.orderBy(array, obj => String(_.get(obj, field)).toLowerCase(), direction);
  }

  /**
   * get data source
   * get paging data or get all data source
   *
   * @param  {Array} dataSource array of data
   * @param  {Boolean} paging is paging indicator or list all data source
   * @param  {Number} page current page
   * @param  {Number} rowsPerPage limit of data in current page
   * @param  {Function} callback for render
   *
   * @return n of array
   */
  getTableDataSource = (dataSource, paging, page, rowsPerPage, orderBy, direction) => {
    const rows = paging
    ? dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : dataSource
    return this.sortByField(rows, orderBy, direction)
  }

  renderDataTable = dataSource => {
    const { columns, sortable, rowProps, cellProps } = this.props;
    const TableRowComponent = sortable ? SortableTableRow : TableRow;
    return _.map(dataSource, (row, rowIndex) => {
      const _rowProps =
        typeof rowProps === "function" ? rowProps(row, rowIndex) : {};
      return (
        <TableRowComponent
          key={`${row.id}-${rowIndex}`}
          index={rowIndex}
          hover
          {..._rowProps}
          onClick={event => this.handleSelectRow(event, row)}
        >
          {_.map(columns, (column, colIndex) => {
            const _cellProps =
              typeof cellProps === "function"
                ? cellProps(column, row, colIndex, rowIndex)
                : {};
            return (
              <TableCell
                key={`${row.id}-${column.key || column.dataIndex}-${colIndex}`}
                {..._cellProps}
              >
                {typeof column.render === "function"
                  ? column.render(
                      _.get(row, _.split(column.dataIndex, ".")),
                      row
                    )
                  : _.get(row, _.split(column.dataIndex, "."))}
              </TableCell>
            );
          })}
        </TableRowComponent>
      );
    });
  };

  handleDownloadExcel = () => {
    const { dataSource, excelFunction, reportName } = this.props;
    const thisPageFor = _.toString(_.split(reportName, '_')[0]);
    // console.log('handleDownloadExcel', dataSource, thisPageFor)
    const clearNullData = _.filter(dataSource, each => _.get(each, 'origin') !== ""); // origin
    // console.log('clearNullData', clearNullData)
    const useDataExcel = (thisPageFor === 'search.origin') ? clearNullData : dataSource;
    excelFunction(useDataExcel, `${reportName}_${Date.now()}`)
  }

  render() {
    const { rowsPerPage, page } = this.state;
    const {
      columns,
      dataSource,
      orderBy,
      direction,
      sortable,
      paging,
      excelFunction,
      onChangeSort,
    } = this.props;

    const TableBodyComponent = sortable ? SortableTableBody : TableBody;

    const bodyProps = sortable
      ? { useDragHandle: true, onSortEnd: this.onSortEnd }
      : {};

    const tableDataSource = this.getTableDataSource(
      dataSource,
      paging,
      page,
      rowsPerPage,
      orderBy,
      direction
    );

    return (
      <div style={{ paddingLeft: 16, paddingRight: 16 }}>
        {excelFunction && typeof excelFunction === 'function' && (
          <Button variant="contained" color="primary" onClick={this.handleDownloadExcel}>
            <CloudDownloadIcon style={{ marginRight: 5 }} />{` Excel`}
          </Button>
        )}
        <Table>
          <TableHead>
            <TableRow>
              {sortable && <TableCell />}
              {columns.map((column, index) => (
                <TableCell key={`${column.dataIndex}-${index}`}>
                  <TableSortLabel
                    active={orderBy === column.dataIndex}
                    direction={direction}
                    onClick={() => onChangeSort(column.dataIndex, direction === 'asc' ? 'desc' : 'asc')}
                  >
                    {column.title}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBodyComponent {...bodyProps} distance={1}>
            {this.renderDataTable(tableDataSource)}
          </TableBodyComponent>
          {typeof this.props.renderTableFooterContent === "function" && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={_.size(columns)}>
                  {this.props.renderTableFooterContent()}
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>

        {paging && (
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={_.size(dataSource)}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        )}
      </div>
    );
  }
}

export default TableComponent

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import FirestoreDocEditor from "../../../../components/FirestoreDocEditor";
import getBuildingOption from "../../utils/getBuildingOption";
import {
  getSections,
  getMapDocDataToFields,
  getMapFieldValuesToDocData
} from "../../utils/getCustomfield";

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, "projectId");
  return {
    medias: _.get(state, ["projects", projectId, "medias"]),
    setting: _.get(state, ["projects", projectId, "setting"]),
    building: _.get(state, ["projects", projectId, "building"])
  };
};

export default connect(mapStateToProps)(props => {
  const { setting, building, medias, docCustomFieldRef } = props;
  const buildingEnable = _.get(setting, "buildingEnable");
  const buildingSection = buildingEnable
    ? [
        buildingEnable && {
          title: "Location",
          fields: ["building"]
        }
      ]
    : [];
  const [customField, setCustomField] = useState({});
  const [updateVersionForRender, setUpdateVersionForRender] = useState(0);

  useEffect(() => {
    docCustomFieldRef.onSnapshot(doc => {
      const docData = doc.data();
      setUpdateVersionForRender(updateVersionForRender + 1);
      setCustomField(docData);
    });
  }, []);

  const customSections = getSections(customField, {
    languages: _.get(setting, "languages", [])
  });
  return (
    <FirestoreDocEditor
      key={`updateVersionForRender-${_.size(
        customField
      )}-${updateVersionForRender}`}
      {...props}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => {
        const languages = _.get(setting, "languages", []);
        const nameFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`name-${lang}`)]: {
              label: `Name (${lang})`,
              value: _.get(data, `fields.name.${lang}`, "")
            }
          }),
          {}
        );
        const cuntomMapDocDataToFields = getMapDocDataToFields(customField, {
          data,
          medias,
          languages: _.get(setting, "languages", [])
        });

        return {
          ...nameFields,
          building: {
            label: "Building",
            type: "Dropdown",
            value: _.get(data, "fields.building", "all"),
            options: getBuildingOption(building)
          },
          ...cuntomMapDocDataToFields
        };
      }}
      mapFieldValuesToDocData={fieldValues => {
        const languages = _.get(setting, "languages", []);
        const nameValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`name-${lang}`)]
          }),
          {}
        );

        const cuntomMapFieldValuesToDocData = getMapFieldValuesToDocData(
          customField,
          {
            fieldValues,
            languages: _.get(setting, "languages", [])
          }
        );

        return {
          fields: {
            name: nameValues,
            building: fieldValues.building,
            ...cuntomMapFieldValuesToDocData
          }
        };
      }}
      sections={[
        ...buildingSection,
        {
          title: "Name",
          fields: _.map(_.get(setting, "languages", []), lang =>
            _.camelCase(`name-${lang}`)
          )
        },
        ...customSections
      ]}
    />
  );
});

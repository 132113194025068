"use strict";

var _speakingurl = _interopRequireDefault(require("speakingurl"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var slugify = function slugify(text) {
  return (0, _speakingurl["default"])(text, {
    mark: true,
    custom: {
      '@': '@',
      '#': 'hash'
    }
  });
};

module.exports = slugify;
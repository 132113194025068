import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

import MediaSelector from './MediaSelector';
import Confirm from '../Confirm';

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: ${props => props.width};
  position: relative;
  overflow: scroll;
  padding-bottom: 50px;
`;


const PaperContent = styled.div`
  padding: 0 40px;
  box-sizing: border-box;
`;

const Section = styled.section`
  margin: 25px 0;
`;

const ActionBar = styled.div`
  box-sizing: border-box;
  padding: 20px;
  position: fixed;
  right: 0;
  bottom: 0;
  width: ${props => props.width};
  background: #fafafa;
  border-top: 1px solid #ececec;
  display: flex;
  justify-content: flex-end;
`;

export default class extends React.PureComponent {

  static propTypes = {
    mapDocDataToFields: PropTypes.func,
    mapFieldsToDocData: PropTypes.func,
    sections: PropTypes.array,
    getSections: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    width: PropTypes.string,
  }
  static defaultProps = {
    width: '100%',
    mapDocDataToFields: (data) => _.mapValues(data, (value, key) => ({ label: _.toUpper(key), value })),
    mapFieldsToDocData: fields => _.mapValues(fields, field => field.value),
    sections: undefined,
    getSections: fields => [{ title: 'Fields', fields: _.keys(fields) }],
  }

  constructor(props) {
    super(props);
    const { mapDocDataToFields } = props;
    this.state = {
      fields: mapDocDataToFields(),
    }
  }

  loadDoc = (docRef) => {
    if (docRef) {
      const { mapDocDataToFields } = this.props;
      this.unsubscribeDoc = docRef
        .onSnapshot(doc => {
          this.setState({
            fields: mapDocDataToFields(doc.data(), doc.id),
          });
        });
    }
  }

  componentDidMount() {
    this.loadDoc(this.props.docRef);
  }

  componentDidUpdate(prevProps, prevState) {
    if (_.get(this.props, 'docRef.id') !== _.get(prevProps, 'docRef.id')) {
      this.loadDoc(this.props.docRef);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeDoc) this.unsubscribeDoc();
  }

  save = async  (e) => {
    const { docRef, mapFieldsToDocData } = this.props;
    e.preventDefault();

    const doc = await docRef.get();
    const data = mapFieldsToDocData(this.state.fields, doc.id);
    const cleanedData = _.pickBy(data, v => v !== undefined)
    console.log('save to docRef', cleanedData);
    if (doc.exists) {
      docRef.update(cleanedData);      
    } else {
      docRef.set(data);
    }

    if (typeof this.props.onSave === 'function') this.props.onSave();
  }

  deleteDoc = () => {
    const { docRef, onDelete } = this.props;
    docRef.delete().then(() => {
      onDelete(docRef.id);
    });
  }

  handleInputChange = (name, value) => {
    // console.log('handleInputChange', name, value);
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: {
          ...this.state.fields[name],
          value,
        }
      }
    })
  }


  renderField = (fieldName) => {
    const field = _.get(this.state, `fields.${fieldName}`);
    if (!field) return (<div />);

    const { label } = field;
    return (
      <div key={`field-${fieldName}`} style={{ display: 'flex', padding: '6px 0', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ flexBasis: 120 }}><Typography variant="caption">{label}</Typography></div>
        <div style={{ flex: 1}}>
          {this.renderInput({ ...field, name: fieldName })}
        </div>
      </div>
    );
  }

  renderInput = (field) => {
    const { type, name, value, options } = field;
    switch (type) {
      case 'select':
      case 'Dropdown':
        return (
          <TextField value={value} variant="outlined" select onChange={e => this.handleInputChange(name, e.target.value)}>
            {_.map(options, option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
          </TextField>
        );
      case 'code':
        return (
          <TextField
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            value={value}
            style={{ boxShadow: 'none' }}
            onChange={e => this.handleInputChange(name, e.target.value)}
          />
        );
      case 'media': {
        return (
          <MediaSelector {...field} onSelect={this.handleInputChange} />
        );
      }
      case 'switch': {
        return (
          <Switch
            color="primary"
            checked={this.state.value}
            onChange={e => this.handleInputChange(name, e.target.checked)}
          />
        );
      }
      default:
        return (
          <Input
            variant="outlined"
            fullWidth
            value={value || ''}
            style={{ boxShadow: 'none' }}
            onChange={e => this.handleInputChange(name, e.target.value)}
          />
        );
    }
  }

  render() {
    const { docRef, width, sections, getSections, onCancel } = this.props;
    const formSections = sections || getSections();
    return (
      <Container width={width}>
        <Paper elevation={0} style={{ padding: '40px 0', boxSizing: 'border-box' }}>
          <PaperContent>
            <Typography variant="h4">{_.get(docRef, 'id', '')}</Typography>
            {_.map(formSections, section => (
              <Section key={section.title}>
                <Typography variant="h5">{section.title}</Typography>
                <div>{_.map(section.fields, this.renderField)}</div>
                <Divider />
              </Section>
            ))}
          </PaperContent>
        </Paper>
        <ActionBar width={width}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Confirm
              title="Delete Document"
              content="This will permanently delete this document, are you sure?"
              onOk={this.deleteDoc}
              render={() => <Button style={{ color: '#ff1744' }}>Delete</Button>}
            />
            <div style={{ display: 'flex' }}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button color="primary" variant="contained" onClick={this.save}>Save</Button>
            </div>
          </div>
        </ActionBar>
      </Container>
    );
  }
}

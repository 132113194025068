import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { convertToRaw } from 'draft-js';
import {
  Editor,
  createEditorState,
} from 'medium-draft';

import 'medium-draft/lib/index.css';

// Exporter basic css
import 'medium-draft/lib/basic.css';

// Custom css
import './style.css';

import ImageSideButton from './ImageSideButton';


const withProps = (props) => WrappedComponent => {
  return (componentProps) => <WrappedComponent {...componentProps} {...props} />;
}

const toEditorData = editorState => {
  if (!editorState) return [];
  return convertToRaw(editorState.getCurrentContent());
}
export default class extends React.Component {

  static getDerivedStateFromProps (props, prevState) {
    if (props.value && !_.isEqual(props.value, prevState.value)) {
      return {
        editorState: createEditorState(props.value),
        prestine: true,
        value: props.value,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { uploadPath, onUploadFile } = props;

    this.sideButtons = [{
      title: 'Image',
      component: withProps({ onUploadFile })(ImageSideButton),
    }];

    this.state = {
      value: props.value,
      editorState: createEditorState(props.value), // for empty content
    };

    this.refsEditor = React.createRef();
  }

  componentDidMount() {
    // this.focus();
  }

  focus = () => {
    this.refsEditor.current.focus();
  }

  onChange = (editorState, callback = null) => {
    
    this.setState({ editorState, prestine: false }, () => {      
      if (callback) {
        callback();
      }
    });

    // Delete Image
    const newData = toEditorData(editorState);
    const prevData = toEditorData(this.state.editorState);

    const deletedImageSection = _.find(newData.blocks, (block, index) => {
      return block.type === 'unstyled' && _.get(prevData, `blocks.${index}.type`) === 'atomic:image';
    });

    // console.log('deleteSection', deletedImageSection);
    if (deletedImageSection) {
      // const imageRef = storage.refFromURL(deletedImageSection.data.src);
      // if (imageRef) imageRef.delete();

      const { onDeleteFile } = this.props;
      if (onDeleteFile) onDeleteFile(deletedImageSection.data.src);
    }
  }

  handleClickAway = () => {
    console.log('clickAway');
    this.save();
  };

  save = () => {
    if (this.refsEditor && this.refsEditor.current) {
      const _editorState = this.refsEditor.current.getEditorState();
      const editorData = toEditorData(_editorState);
      console.log('save', editorData);
      if (!_.isEqual(this.props.value, editorData)) {
        console.log('notEqual');
        window.localStorage.setItem('medium-draft', JSON.stringify(editorData));
        this.props.onChange(editorData);
      } else {
        console.log('equal, do not save');
      }
    }
  }

  load = () => {
    const editorData = JSON.parse(window.localStorage.getItem('medium-draft'));
    console.log('load', editorData);
    if (editorData) {
      this.onChange(createEditorState(editorData), this.focus);
    }
  }
  
  render() {
    const { editorState } = this.state;
    return (
      <Grid container spacing={24}>
        <Grid item sm={12}>
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Editor
              ref={this.refsEditor}
              editorState={editorState}
              sideButtons={this.sideButtons}
              onChange={this.onChange}
            />
          </ClickAwayListener>
        </Grid>
        {/* <Grid item sm={6}>
          <div style={{ border: '1px solid #aaa', boxSizing: 'border-box', padding: 12, borderRadius: 6 }}>
            <span dangerouslySetInnerHTML={{ __html: exporter(editorState.getCurrentContent()) }} />
          </div>
        </Grid> */}
      </Grid>
    );
  }
}
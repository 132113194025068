import { createMuiTheme } from '@material-ui/core/styles';

export const primaryColor = '#1e88e5';

export default createMuiTheme({
  typography: {
    useNextVariants: true,
    h1: {
      color: 'white',
    },
    h2: {
      color: 'white',
    },
    h3: {
      color: 'white',
    },
    h4: {
      color: 'white',
    },
    h5: {
      color: 'white',
    },
    h6: {
      color: 'white',
    },
    subtitle1: {
      fontSize: 12,
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: '#333333',
    }
  },
  overrides: {
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: 20,
      }
    },
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h2',
        subtitle2: 'h4',
        body1: 'span',
        body2: 'span',
      },
      text: {
        color: 'white',
      }
    },
  }
});
import React, { useEffect, useState } from "react";
import _ from "lodash";
import FirestoreDocEditor from "../../../../components/FirestoreDocEditor";
import { connect } from "react-redux";
import getBuildingOption from "../../utils/getBuildingOption";
import {
  getSections,
  getMapDocDataToFields,
  getMapFieldValuesToDocData
} from "../../utils/getCustomfield";

const mapStateToProps = (state, ownProps) => {
  const projectId = _.get(ownProps, "projectId");
  const setting = _.get(state, ["projects", projectId, "setting"]);

  const categories = _.get(state, ["projects", projectId, "categories"]);
  const categoriesOptions = _.map(categories, (cat, id) => ({
    id,
    value: id,
    label: _.get(cat, "fields.name.en"),
    parent: _.get(cat, "fields.parent")
  }));
  return {
    medias: _.get(state, ["projects", projectId, "medias"]),
    building: _.get(state, ["projects", projectId, "building"]),
    categoriesOptions,
    setting
  };
};
export default connect(mapStateToProps)(props => {
  const {
    categoriesOptions,
    setting,
    medias,
    building,
    docCustomFieldRef
  } = props;

  const [customField, setCustomField] = useState({});
  const [updateVersionForRender, setUpdateVersionForRender] = useState(0);
  useEffect(() => {
    docCustomFieldRef.onSnapshot(doc => {
      const docData = doc.data();
      setUpdateVersionForRender(updateVersionForRender + 1);
      setCustomField(docData);
    });
  }, []);
  const buildingEnable = _.get(setting, "buildingEnable");
  const buildingSection = buildingEnable
    ? [
        buildingEnable && {
          title: "Location",
          fields: ["building"]
        }
      ]
    : [];

  const customSections = getSections(customField, {
    languages: _.get(setting, "languages", [])
  });
  return (
    <FirestoreDocEditor
      key={`updateVersionForRender-${_.size(
        customField
      )}-${updateVersionForRender}`}
      {...props}
      docRef={props.docRef}
      mapDocDataToFields={(data, id) => {
        const languages = _.get(setting, "languages", []);
        const nameFields = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [_.camelCase(`name-${lang}`)]: {
              label: `Name (${lang})`,
              value: _.get(data, `fields.name.${lang}`, id)
            }
          }),
          {}
        );
        const cuntomMapDocDataToFields = getMapDocDataToFields(customField, {
          data,
          medias,
          languages: _.get(setting, "languages", [])
        });

        return {
          ...nameFields,
          parent: {
            label: "Parent",
            type: "Dropdown",
            value: _.get(data, "fields.parent"),
            options: _.filter(categoriesOptions, cat =>
              _.isEmpty(_.get(cat, "parent"))
            )
          },
          logo: {
            label: "Logo",
            type: "Image",
            value: _.get(data, "fields.logo"),
            options: _.map(medias, (media, key) => ({
              value: key,
              label: key,
              url: media.url
            }))
          },
          order: { label: "Order", value: _.get(data, "fields.order", 0) },
          tags: {
            label: "Keywords/Tags",
            value: _.join(_.get(data, "fields.tags", []))
          },
          building: {
            label: "Building",
            type: "Dropdown",
            value: _.get(data, "fields.building", "all"),
            options: getBuildingOption(building)
          },
          ...cuntomMapDocDataToFields
        };
      }}
      mapFieldValuesToDocData={fieldValues => {
        // const fieldValues = _.mapValues(fields, field => field.value);
        const languages = _.get(setting, "languages", []);
        const nameValues = _.reduce(
          languages,
          (acc, lang) => ({
            ...acc,
            [lang]: fieldValues[_.camelCase(`name-${lang}`)]
          }),
          {}
        );

        const cuntomMapFieldValuesToDocData = getMapFieldValuesToDocData(
          customField,
          {
            fieldValues,
            languages: _.get(setting, "languages", [])
          }
        );

        return {
          fields: {
            name: nameValues,
            parent: fieldValues.parent,
            logo: fieldValues.logo,
            tags: _.map(_.split(fieldValues.tags, ","), _.trim),
            building: fieldValues.building,
            order: _.toNumber(fieldValues.order),
            ...cuntomMapFieldValuesToDocData
          }
        };
      }}
      sections={[
        ...buildingSection,
        {
          title: "Brand",
          fields: _.map(_.get(setting, "languages", []), lang =>
            _.camelCase(`name-${lang}`)
          )
        },
        {
          title: "Media",
          fields: ["logo", "gallery"]
        },
        {
          title: "Order",
          fields: ["order"]
        },
        {
          title: "Parent",
          fields: ["parent"]
        },
        {
          title: "Tags",
          fields: ["tags"]
        },
        ...customSections
      ]}
    />
  );
});

import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

class ConfirmDialog extends React.PureComponent {
  static propTypes = {
    okText: PropTypes.string,
    onCreate: PropTypes.func,
  }

  static defaultProps = {
    okText: 'Ok',
    cancelText: 'Cancel',
    title: 'Confirm',
    content: '',
    items: null,
  }

  state = {
    open: false,
  }

  openDialog = () => this.setState({ open: true })
  closeDialog = () => this.setState({ open: false })

  handleOk = () => {
    this.props.onOk();
    this.closeDialog();
  }
  handleCancel = () => {
    if (typeof(this.props.onCancel) === 'function') {
      this.props.onCancel();
    }
    this.closeDialog();
  }

  render() {
    const { okText, cancelText, title, content, open, items } = this.props;
    return (
      <div>
        {(typeof(this.props.render) === 'function') && React.cloneElement(this.props.render(), { onClick: this.openDialog })}
        <Dialog open={open ?? this.state.open} onClose={this.closeDialog}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent style={{ minWidth: 300 }}>
            <DialogContentText>{content}</DialogContentText>
            {items && <DialogContentText style={{ whiteSpace: 'pre-line' }}>{items.join('\n')}</DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button color="default" variant="outlined" onClick={this.handleCancel}>{cancelText}</Button>
            <Button color="primary" variant="outlined" style={{ color: '#ff1744' }} onClick={this.handleOk}>{okText}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ConfirmDialog

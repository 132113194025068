import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const Container = styled.div`
  margin: 0 0 40px;
  width: auto;
  padding: 40px 40px 0;
  position: relative;
  border: 1px solid ${props => props.$dividerColor || '#fbfbfb'};
`;

const PageHeader = ({ title, renderHeaderRight, renderFloatingActionButtons, children, style }) => {
  const theme = useTheme();
  return (
    <Container $dividerColor={theme.palette.divider} style={style}>
      <Grid container alignItems="center">
        <Grid item sm={10}><Typography variant="h4" style={{ marginBottom: 30 }}>{title}</Typography></Grid>
        <Grid item sm={2}>
          {typeof renderHeaderRight === 'function' && renderHeaderRight()}
        </Grid>
      </Grid>
      {children}
      {typeof renderFloatingActionButtons === 'function' &&
        <div style={{ position: 'absolute', right: 50, bottom: -25 }}>
          {renderFloatingActionButtons()}
        </div>
      }
    </Container>
  );
}

PageHeader.propTypes = {
  renderHeaderRight: PropTypes.func,
  renderFloatingActionButtons: PropTypes.func,
  title: PropTypes.string,
}

export default PageHeader;
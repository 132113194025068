import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, LabelList } from 'recharts'

const renderCustomizedLabel = props => {
  const { x, y, width, height, value } = props
  const radius = height / 2
  return (
    <g>
      {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill='#8884d8' /> */}
      {
        value > 0 &&
        <text
          x={x + width / 2}
          y={y + radius}
          fill='#ffffff'
          textAnchor='middle'
          dominantBaseline='middle'
        >
          {value}
        </text>
      }
    </g>
  )
}

export default class extends PureComponent {
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    source: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }
  static defaultProps = {
    width: 800,
    height: 600,
    source: [
      {
        name: '1',
        value: 10,
        fill: '#FFF00F'
      },
      {
        name: '2',
        value: 20,
        fill: '#FF000F'
      }
    ],
    name: 'จำนวน',
    unit: ' ชิ้น',
    legendType: 'circle',
    fill: '#8884d8',
  }
  state = {}
  // ======================================================
  // Commit Phase
  // Can work with DOM, run side effects, schedule updates.
  // ======================================================
  componentDidMount = () => {}
  componentDidUpdate = (prevProps, prevState, snapshot) => {}
  componentWillUnmount = () => {}
  // ======================================================
  // Pre-commit Phase
  // Can read the DOM
  // ======================================================
  getSnapshotBeforeUpdate = (prevProps, prevState) => {
    return {}
  }
  // ======================================================
  // Render Phase
  // Pure and no side effects. May be paused, aborted or restarted by React.
  // ======================================================
  static getDerivedStateFromProps(props, state) {
    return {}
  }
  // ======================================================
  // Private Methods
  // ======================================================
  render() {
    const { width, height, source, name, unit, legendType, fill } = this.props
    return (
      <BarChart
        width={width}
        height={height}
        data={source}
        margin={{ top: 5, right: 15, left: -15, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />
        <Legend />
        {
          this.props.children ||
          <Bar name={name} unit={unit} legendType={legendType} dataKey='value' fill={fill} minPointSize={0}>
            <LabelList dataKey='value' content={renderCustomizedLabel} />
          </Bar>
        }
      </BarChart>
    )
  }
}

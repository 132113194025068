import React from 'react';
import _ from 'lodash';
import { notify } from 'react-notify-toast';

import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import AddIcon from '@material-ui/icons/Add';

import { db } from '../../engine';

import PageHeader from '../../../../components/PageHeader';
import CreateDocButton from '../../components/CreateDocButton';

export default class extends React.Component {
  state = {
    spaces: [],
  }

  createSpace = ({ title }) => {
    const { match, history, account } = this.props;
    const spaceId = `${account.id}:${_.kebabCase(title)}`;
    console.log('spaceId', spaceId);
    db.collection('cic_spaces').doc(spaceId).get()
      .then(doc => {
        if (doc.exists) {
          console.log('duplicate id, please use another id');
          notify.show('Duplicate ID, please change ID', 'error');
        } else {
          db.collection('cic_spaces').doc(spaceId).set({
            id: spaceId,
            name: title,
            iam_projects: {
              [account.id]: true
            }
          });
          notify.show(`Space ${title} Created`, 'success');
          history.push(`${match.url}/spaces/${spaceId}`);
        }
      });
  }

  componentDidMount() {
    const { accountId } = this.props; 
    db.collection('cic_spaces').where(`iam_projects.${accountId}`, '==', true).get()
      .then(querySnapshot => {
        this.setState({
          spaces: _.map(querySnapshot.docs, doc => ({ id: doc.id, ...doc.data() }))
        })
      })
  }

  showCreateDialog = () => {

  }

  render() {
    const { match } = this.props;
    const { spaces } = this.state;
    return (
      <div>
        <PageHeader
          title="Welcome to Firemink"
          renderFloatingActionButtons={() => (
            <CreateDocButton
              label="Space"
              onCreate={this.createSpace}
              renderButton={() => (<Button variant="extendedFab" color="primary"><AddIcon /> Add Space</Button>)}
            />
          )}
        >
          <Typography variant="body2">A workspace for editing content</Typography>
        </PageHeader>
        <div style={{ maxWidth: 980, margin: '0 auto' }}>
          <Grid container alignContent="center">
            <Grid container spacing={16}>
              {spaces.map(space => (
                <Grid item sm={3} key={space.id}>
                  <ButtonBase component={Link} to={`${match.url}/spaces/${space.id}`} style={{ display: 'block' }}>
                    <Card>
                      <CardContent>
                        <Typography variant="caption">{space.id}</Typography>
                        <Typography variant="h5">{space.name}</Typography>
                      </CardContent>
                    </Card>
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
import React, { useRef, useReducer, useEffect } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { updateAccount } from '../../actions'

import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import { notify } from 'react-notify-toast';

import PageHeader from '../../../../components/PageHeader'

/**
 * Local reducer
 * store value of settings form
 * 
 * @param {Object}  state { maxPlaylistDuration: Number }
 * @param {Object}  action { type: String, payload: [ String, Object, Array, Number ]}
 */
const localReducer = (state, action) => {
  switch (action.type) {
  case 'CHANGE_MAX_PLAYLIST_DURATION':
    return { ...state, maxPlaylistDuration: parseInt(action.payload || 0, 10) }

  case 'RECEIVE_DATA_FROM_STORE':
    return { ...action.payload }

  default:
    return state
  }
}

const SettingScreen = ({ setting, accountId, updateSettingToStore, client }) => {
  const maxPlaylistDurationInputRef = useRef(null)
  const [ settingState, stateDispatch ] = useReducer(localReducer, setting)

  useEffect(() => {
    stateDispatch({ type: 'RECEIVE_DATA_FROM_STORE', payload: setting })
  }, [setting])

  const handleSaveSetting = async () => {
    try { 
      await client.saveSetting(settingState)

      /* dispatch to redux store */
      updateSettingToStore(accountId, settingState)
      notify.show('Setting saved successfully!', 'success', 4000);
    } catch (error) {
      console.error(error)
      notify.show(`Error ${error.message}`, 'danger', 4000);
    }
  }

  return (
    <div>
      <PageHeader 
        title="Settings"
        renderFloatingActionButtons={() => (
          <Button color="primary" variant="extendedFab" onClick={handleSaveSetting}>
            <SaveIcon />
            <span style={{ marginLeft: 10 }}>save</span>
          </Button>
        )}
      />
      
      <FormControl fullWidth>
        <TextField
          fullWidth
          variant="outlined"
          key="max-playlist-duration"
          inputRef={maxPlaylistDurationInputRef}
          label="Max playlist duration"
          onChange={(e) => stateDispatch({ type: 'CHANGE_MAX_PLAYLIST_DURATION', payload: e.target.value })}
          value={_.get(settingState, 'maxPlaylistDuration', 0)}
          helperText="Set max duration time of each playlist in seconds"
        />
      </FormControl>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { accountId } = ownProps
  const setting = _.get(state, [ 'accounts', accountId, 'setting' ])

  return {
    accountId,
    setting,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateSettingToStore: (accountId, data) => dispatch(updateAccount(accountId, 'setting', data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingScreen)


"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createClient = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _firebase = _interopRequireDefault(require("./firebase"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var createClient = function createClient(config) {
  var projectId = config.projectId;

  var db = _firebase.default.firestore();

  var projectRef = db.collection('aspen_projects').doc(projectId);

  var getProjectRef = function getProjectRef() {
    return projectRef;
  };

  var getBrands = function getBrands() {
    return projectRef.collection('brands').get().then(function (querysnapshot) {
      return _lodash.default.map(querysnapshot.docs, function (doc) {
        return _objectSpread({
          id: doc.id
        }, doc.data());
      });
    });
  };

  var getBrandsCollectionRef = function getBrandsCollectionRef() {
    return projectRef.collection('brands');
  };

  var getBrandDocRef = function getBrandDocRef(brandId) {
    return projectRef.collection('brands').doc(brandId);
  };

  var getShops = function getShops() {
    return projectRef.collection('shops').get().then(function (querysnapshot) {
      return _lodash.default.map(querysnapshot.docs, function (doc) {
        return _objectSpread({
          id: doc.id
        }, doc.data());
      });
    });
  };

  var getMedicalCentersCollectionRef = function getMedicalCentersCollectionRef() {
    return projectRef.collection('medical-centers');
  };

  var getMedicalCenterDocRef = function getMedicalCenterDocRef(shopId) {
    return projectRef.collection('medical-centers').doc(shopId);
  };

  var getCategoriesCollectionRef = function getCategoriesCollectionRef() {
    return projectRef.collection('categories');
  };

  var getCategoryDocRef = function getCategoryDocRef(id) {
    return projectRef.collection('categories').doc(id);
  };

  var getFloors = function getFloors() {
    return projectRef.collection('floors').get().then(function (querysnapshot) {
      return _lodash.default.map(querysnapshot.docs, function (doc) {
        return _objectSpread({
          id: doc.id
        }, doc.data());
      });
    });
  };

  var getFloorsCollectionRef = function getFloorsCollectionRef() {
    return projectRef.collection('floors');
  };

  var getFloorDocRef = function getFloorDocRef(id) {
    return projectRef.collection('floors').doc(id);
  };

  var getNodesCollectionRef = function getNodesCollectionRef() {
    return projectRef.collection('nodes');
  };

  var getNodeDocRef = function getNodeDocRef(id) {
    return projectRef.collection('nodes').doc(id);
  };

  var getCollectionRef = function getCollectionRef(collectionName) {
    return projectRef.collection(collectionName);
  };

  var getDocRef = function getDocRef(collectionName, docId) {
    return projectRef.collection(collectionName).doc(docId);
  };

  return {
    getProjectRef: getProjectRef,
    getBrands: getBrands,
    getBrandsCollectionRef: getBrandsCollectionRef,
    getBrandDocRef: getBrandDocRef,
    getShops: getShops,
    getMedicalCentersCollectionRef: getMedicalCentersCollectionRef,
    getMedicalCenterDocRef: getMedicalCenterDocRef,
    getCategoriesCollectionRef: getCategoriesCollectionRef,
    getCategoryDocRef: getCategoryDocRef,
    getFloors: getFloors,
    getFloorsCollectionRef: getFloorsCollectionRef,
    getFloorDocRef: getFloorDocRef,
    getNodesCollectionRef: getNodesCollectionRef,
    getNodeDocRef: getNodeDocRef,
    getCollectionRef: getCollectionRef,
    getDocRef: getDocRef
  };
};

exports.createClient = createClient;
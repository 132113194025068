import React from 'react';
import ReactDOM from 'react-dom';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';

import TextEditor from '../TextEditor';

export default class extends React.Component {
    
  labelNode = null;
  labelRef = null;

  constructor(props){
    super(props);
    this.labelRef = React.createRef();
  }

  componentDidMount() {
    this.labelNode = ReactDOM.findDOMNode(this.labelRef.current);
    this.forceUpdate();
  }

  render() {
    const { id, label, value, spaceId, onChange } = this.props;
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel shrink variant="outlined" htmlFor={id} ref={this.labelRef}>{label}</InputLabel>
        <OutlinedInput
          notched={true}
          labelWidth={this.labelNode ? this.labelNode.offsetWidth * 0.75 + 8 : 0}
          inputComponent={() => (
            <TextEditor value={value} onChange={onChange} uploadPath={`/cic/spaces/${spaceId}`} />
          )}
        />
      </FormControl>
    );
  }
}
import { updateForm, deleteForm } from '../api';

export const receivedForms = (forms) => ({
  type: 'RECEIVED/FORMS',
  forms,
});

export const updateFormAction = (formId, formData) => {
  return (dispatch) => {

    // Fire API
    updateForm(formId, formData)
      .then(response => {
        console.log('updateForm', response);
      })
      .catch(e => {
        console.log(e.message);
      });
    
    // Update Reducer
    dispatch({
      type: 'FORM/UPDATE',
      id: formId,
      data: formData,
    });
  }
}

export const deleteFormAction = formId => {
  return (dispatch) => {

    // Fire API
    deleteForm(formId)
      .then(response => {
        console.log('deleteForm', response);
      })
      .catch(e => {
        console.log(e.message);
      });
    
    // Update Reducer
    dispatch({
      type: 'FORM/DELETE',
      id: formId,
    });
  }
}
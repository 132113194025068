import React from 'react';
import _ from 'lodash';
import FirestoreDocForm from '../../components/FirestoreDocForm';
import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {};

export default connect(mapStateToProps)((props) => {
  const { medias } = props;
  return (
    <FirestoreDocForm
      {...props}
      docRef={props.docRef}
      resources={{
        medias,
      }}
      mapDocDataToFields={(data, id) => ({
        title: { label: 'Title', value: _.get(data, 'title', id) },
        description: { label: 'Description', value: _.get(data, 'description', '') },
        walkTime: { label: 'Walk Time', value: _.get(data, 'walkTime', '') },
        driveTime: { label: 'Drive Time', value: _.get(data, 'driveTime', '') },
        distance: { label: 'Distance', value: _.get(data, 'distance', '') },
        d: { label: 'd', value: _.get(data, 'd') },
        tags: { label: 'Tags', value: _.join(_.get(data, 'tags', [])) },
      })}
      mapFieldsToDocData={fields => {
        const fieldValues = _.mapValues(fields, field => field.value);
        return {
          title: fieldValues.title,
          description: fieldValues.description,
          d: fieldValues.d,
          tags: _.split(fieldValues.tags, ','),
        }
      }}
      sections={[
        {
          title: 'Info',
          fields: ['title', 'description']
        },
        {
          title: 'Path',
          fields: ['d'],
        },
        {
          title: 'Travel',
          fields: ['distance', 'walkTime', 'driveTime'],
        },
        {
          title: 'Taxonomy',
          fields: ['tags']
        },
      ]}
    />
  );
});
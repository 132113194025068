import React from 'react';
import _ from 'lodash';
import { getFormSubmissions } from '../../api';

import PageHeader from '../../../../components/PageHeader';
import Table from '../../../../components/Table';

export default class extends React.Component {
  state = {
    error: null,
    submissions: [],
  }
  componentDidMount() {
    this.fetchSubmissions(this.props.id);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.id !== this.props.id) {
      this.fetchSubmissions(this.props.id);
    }
  }

  fetchSubmissions = async (formId) => {
    try {
      const submissions = await getFormSubmissions(formId);
      this.setState({
        submissions,
      });
    } catch (e) {
      console.log(e.message);
      this.setState({
        error: e.message,
      })
    }
  }
  render() {
    const { width } = this.props;
    const { error, submissions } = this.state;
    const fields = _.get(submissions, '0.data.fields');
    return (
      <div style={{ width }}>
        <PageHeader title="Submissions" />
        {error !== null && <span>{error}</span>}
        <Table
          dataSource={submissions}
          columns={_.map(fields, (value, name) => ({
            title: name,
            name,
            dataIndex: `data.fields.${name}`,
          }))}
        />
      </div>
    );
  }
}

import styled from 'styled-components';

export const BackgroundContainer = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cfcfcf;
  border-radius: 9px;
`;

export const Form = styled.form`
  background: white;
  padding: 24px 26px;
  border-radius: 9px;
`;

export const FormHeader = styled.h1`
  margin-top: 0;
`;

export const VersionLabel = styled.div`
  margin: 15px 0;
  font-size: 14px;
  color: #3e3e3e;
  position: absolute;
  left: 50px;
  bottom: 50px;
`;

export const ArtworkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginGraphicImg = styled.img`
  width: 100%;
`;

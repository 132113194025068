import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import AddIcon from '@material-ui/icons/Add';

import CreateDocButton from '../../../../components/CreateDocButton';
import FirestoreCollectionViewer from '../../../../components/FirestoreCollectionViewer';
import PageHeader from '../../../../components/PageHeader';

import { createClient } from 'forviz-aspenjs';

import DetailScreen from './detail';

const StyledShopProgressBar = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 0;
  flex-direction: row;
  border: 1px solid #357a38;
  border-radius: 2px;

  .status-block {
    background: #f7f7f7;
    flex: 1 1 18px;
    height: 18px;
    border-left: 1px solid #ccc;
    color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;

    &.done {
      color: #357a38;
      background: #4caf50;
      border-left-color: #357a38;
    }

    &.warning {
      background: #f44336;
      color: #aa2e25;
      border-left-color: #aa2e25;
    }
  }
  

  .status-block:first-child {
    border-left: none;
  }
`;

const ShopProgressBar = (shop) => {
  const { logoHasProblem } = shop;
  const units = _.get(shop, 'fields.units');
  const unitDone = !_.isEmpty(units) && _.some(units, unit => unit.floorId !== undefined && unit.unitNo !== undefined);
  const zoneDone = !_.isEmpty(_.get(shop, 'fields.zone'));
  const nodeDone = !_.isEmpty(_.get(shop, 'fields.node'));
  const logoDone = !_.isEmpty(_.get(shop, 'fields.logo'));

  const categoryDone = !_.isEmpty(_.get(shop, 'fields.category')) || !_.isEmpty(_.get(shop, 'fields.subcategory'));
  
  return (
    <StyledShopProgressBar>
      <Tooltip title={`${logoDone ? 'Has logo' : 'No logo'}`}>
        <div className={`status-block ${logoDone ? 'done' : ''} ${logoHasProblem ? 'warning' : ''}`}>L</div>
      </Tooltip>
      <Tooltip title={`${unitDone ? 'Has unit' : 'No unit'}`}>
        <div className={`status-block ${unitDone ? 'done' : ''}`}>U</div>
      </Tooltip>
      <Tooltip title={`${zoneDone ? 'Has zone' : 'No zone'}`}>
        <div className={`status-block ${zoneDone ? 'done' : ''}`}>Z</div>
      </Tooltip>
      <Tooltip title={`${nodeDone ? 'Has node' : 'No node'}`}>
        <div className={`status-block ${nodeDone ? 'done' : ''}`}>N</div>
      </Tooltip>
      <Tooltip title={`${categoryDone ? 'Has category' : 'No category'}`}>
        <div className={`status-block ${categoryDone ? 'done' : ''}`}>C</div>
      </Tooltip>
    </StyledShopProgressBar>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;
  const medicalCenters = _.get(state, ['projects', projectId, 'medicalCenters']);
  const medias = _.get(state, ['projects', projectId, 'medias']);
  return {
    medicalCenterEntities: _.map(medicalCenters, (medicalCenter, id) => ({
      id,
      ...medicalCenter,
      iconMedia: _.get(medias, `${medicalCenter.fields.icon}`) 
    })),
    categories: _.get(state, ['projects', projectId, 'categories']),
    zones: _.get(state, ['projects', projectId, 'zones']),
    floors: _.get(state, ['projects', projectId, 'floors']),
    medias: _.get(state, ['projects', projectId, 'medias']),
  }
}

export default connect(mapStateToProps)(class extends React.Component {

  handleSelectRow = (record, selected, selectedRows, e) => {
    const { match, history } = this.props;
    history.push(`${match.url}/${record.id}`)
  }

  handleCreate = (values) => {
    const { projectId } = this.props;
    const { name } = values;
    const id = _.kebabCase(name);

    const client = createClient({ projectId });

    const docRef = client.getMedicalCenterDocRef(id);
    docRef.set({
      fields: {
        name: {
          en: name,
        },
      }
    }).then(() => {
      const { match, history } = this.props;
      history.push(`${match.url}/${id}`);  
    });

  }

  render() {
    const { match, history, medicalCenterEntities, floors, categories, projectId } = this.props;
    const listScreenUrl = match.url;
    const client = createClient({ projectId });

    return (
      <div>
        <PageHeader
          title="Medical Centers"
          style={{ marginBottom: 16 }}
          renderFloatingActionButtons={() =>
            <CreateDocButton
              label="Medical Center"
              fields={[
                { type: 'TextField', name: 'name', label: 'Center Name', required: true },
              ]}
              renderButton={() => <Button variant="extendedFab" color="primary"><AddIcon /> Add Medical Center</Button>}
              onCreate={this.handleCreate}
            />
          }
        />
        <FirestoreCollectionViewer
          columns={[
            { title: 'Icon', dataIndex: 'iconMedia', width: 80, render: icon => <Avatar src={_.get(icon, `url`)} /> },
            { title: 'Name', dataIndex: 'fields.name.en' },
            { title: 'Name (ไทย)', dataIndex: 'fields.name.th' },
            { title: 'Floor', dataIndex: 'fields.floor' },
            {
              title: 'Units',
              dataIndex: 'fields.units',
              render: (units, row) => _.map(units, unit => <Chip key={`${unit.floorId}-${unit.unitNo}`} variant="outlined" avatar={<Avatar>{unit.floorId}</Avatar>} label={unit.unitNo} />) },
            { title: 'Node', dataIndex: 'fields.node' },
          ]}
          defaultLimit={-1}
          defaultOrderBy="fields.floor"
          entities={medicalCenterEntities}
          onSelect={this.handleSelectRow}
        />
        <Route
          path={`${match.url}/:shopId`}
          children={({ match, ...rest }) => {
            const shopId = _.get(match, 'params.shopId');
            const docRef = !_.isEmpty(shopId) ? client.getMedicalCenterDocRef(shopId) : null;
            const open = docRef !== null;
            return (
              <Drawer anchor="right" open={open} onClose={() => history.goBack()}>
                <DetailScreen
                  width="700px"
                  projectId={projectId}
                  {...rest}
                  docRef={docRef}
                  onClose={() => {
                    history.push(listScreenUrl);
                  }}
                />
              </Drawer>
            );
          }}
        />
      </div>
    );
  }
});

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
// import Downshift from 'downshift';

import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import AreaEditor from './AreaEditor';
import FileUploader from './FileUploader';
import FieldTextEditor from './FieldTextEditor';
import HTMLEditor from './HTMLEditor';
import ListField from './ListField';
// import TextFieldWithSuggestion from './TextFieldWithSuggestion';

import DownshiftSingle from './Downshift/Single';
import DownShiftMultiple from './Downshift/Multiple';

import {
  TEXTFIELD,
  TEXTAREA,
  HTML,
  DRAFTJS,
  RADIO,
  CHECKBOX,
  CHECKBOXES,
  SWITCH,
  DROPDOWN,
  AUTOCOMPLETE,
  AUTOCOMPLETE_MULTIPLE,
  IMAGE,
  DATE,
  TIME,
  GALLERY,
  OBJECT,
} from './constants';

import validateRules from './validation'

const omitProps = ['onUploadFile', 'onDeleteFile', 'list'];

const getOptionValue = option => _.get(option, 'value', option);
const getOptionLabel = option => _.get(option, 'label', option);

export default class extends React.PureComponent {

  static propTypes = {
    list: PropTypes.bool,
    multiple: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onUploadFile: PropTypes.func,
    onDeleteFile: PropTypes.func,
    renderMedia: PropTypes.func,
  }
  static defaultProps = {
    language: 'default',
    list: false,
    multiple: false,
  }

  handleCheck = (e) => {
    const value = e.target.checked;
    this.props.onChange(value);
  }

  handleCheckCheckboxes = (e) => {
    const selectedValue = e.target.value;
    const value = this.props.value || [];
    const newValue = _.includes(value, selectedValue) ? _.filter(value, v => v !== selectedValue) : [...value, selectedValue];
    this.props.onChange(newValue);
  }

  validateField = (props) => {
    const { value, rules } = props
    if (!rules) return ''
    const errorMessage = validateRules(value, rules)
    // console.log('validateField', props, { value, rules, errorMessage })
    return errorMessage
  }

  render() {
    if (this.props.list) {
      return (
        <ListField
          {..._.omit(this.props, omitProps)}
          render={({ inputProps }) => this.renderInput(inputProps)}
        />
      );
    }
    return this.renderInput(this.props);
  }

  renderInput (props) {
    // const { props } = this;
    const errorMessage = this.validateField(props)
    switch (props.type) {
      case DROPDOWN:
        return (
          <TextField
            fullWidth
            select
            variant="outlined"
            {..._.omit(props, omitProps)}
            value={props.value || ''}
            InputLabelProps={{
              shrink: true,
            }}
          >
            {_.map(props.options, option =>
              <MenuItem key={getOptionValue(option)} value={getOptionValue(option)}>{getOptionLabel(option)}</MenuItem>
            )}
          </TextField>
        );
      case RADIO:
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">{props.label}</FormLabel>
            <RadioGroup
              key={props.value}
              aria-label="Gender"
              name="gender1"
              value={props.value}
              onChange={props.onChange}
            >
              {_.map(props.options, option =>
                <FormControlLabel
                  key={getOptionValue(option)}
                  value={getOptionValue(option)}
                  control={<Radio />}
                  label={getOptionLabel(option)}
                />
              )}
            </RadioGroup>
          </FormControl>
        );
      case CHECKBOX:
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">{props.label}</FormLabel>
            <RadioGroup
              key={props.value}
              aria-label={props.label}
              name={props.name}
              value={props.value}
              onChange={props.onChange}
            >
              {_.map(props.options, option =>
                <FormControlLabel
                  key={getOptionValue(option)}
                  value={getOptionValue(option)}
                  control={<Checkbox />}
                  label={getOptionLabel(option)}
                />
              )}
            </RadioGroup>
          </FormControl>
        );

      case CHECKBOXES:
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">{props.label}</FormLabel>
            <FormGroup>
              {_.map(props.options, option => {
                const label = getOptionLabel(option);
                const value = getOptionValue(option);
                return (
                  <FormControlLabel
                    key={value}
                    control={
                      <Checkbox
                        checked={_.includes(props.value, value)}
                        onChange={this.handleCheckCheckboxes}
                        value={value}
                      />
                    }
                    value={value}
                    label={label}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        );
      case SWITCH: {
        // console.log('Switch', props);
        return (
          <FormControlLabel
            control={
              <Switch
                key={`switch-${props.value}`}
                checked={props.value}
                onChange={this.handleCheck}
              />
            }
            label={props.label}
          />
        );
      }
      case IMAGE: {
        // console.log('Media', props);
        return <FileUploader {...props} onUploadFile={this.props.onUploadFile} onDeleteFile={this.props.onDeleteFile} onCheckFile={this.props.onCheckFile} />;
      }
      case GALLERY: {
        return <FileUploader {...props} onUploadFile={this.props.onUploadFile} onDeleteFile={this.props.onDeleteFile} multiple />;
      }
      case DRAFTJS: {
        return (<FieldTextEditor {...props} />);
      }
      case DATE:
        return (
        <TextField
          fullWidth
          variant="outlined"
          {..._.omit(props, omitProps)}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />);

      case TIME:
        return (
        <TextField
          fullWidth
          variant="outlined"
          {..._.omit(props, omitProps)}
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
        />);
      case 'AreaEditor':
        return (<AreaEditor {...props} />);

      case AUTOCOMPLETE: {
        return (
          <DownshiftSingle {...props} />
        );
      }
      case AUTOCOMPLETE_MULTIPLE: {
        return (
          <DownShiftMultiple {...props} />
        );
      }

      case OBJECT: {

        if (props.layout === 'horizontal') {
          const values = { ...props.defaultValue, ..._.get(props, 'value', {}) };
          return (
            <div style={{ width: '100%', marginBottom: 20 }}>
              <Typography variant="caption">{props.label}</Typography>
              <div style={{ border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: 4, padding: 10 }}>
                {_.map(values, (value, key) => {
                  return (
                    <TextField
                      fullWidth
                      value={value}
                      multiline
                      style={{ marginBottom: 6 }}
                      InputProps={{
                        // style: { alignItems: 'flex-start' },
                        startAdornment: <InputAdornment position="start" style={{ marginRight: 12 }}>{_.upperCase(key)}</InputAdornment>,
                        disableUnderline: true,
                      }}
                      onChange={e => props.onChange({ ...values, [key]: e.target.value }, props.name)}
                    />
                  );
                })}
              </div>
            </div>
          );
        }

        return (
          <div style={{ width: '100%' }}>
            <Typography variant="caption">{props.label}</Typography>
            <Grid container spacing={16}>
              {_.map(_.get(props, 'value', {}), (value, key) => {
                return (
                  <Grid item xs>
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={value}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{_.upperCase(key)}</InputAdornment>,
                      }}
                      onChange={e => props.onChange({ ..._.get(props, 'value', {}), [key]: e.target.value }, props.name)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        );
      }

      case HTML:
        return (
          <HTMLEditor {..._.omit(props, omitProps)} />
        );

      case TEXTAREA: {
        return (<TextField fullWidth multiline variant="outlined" {..._.omit(props, omitProps)} />);
      }

      case TEXTFIELD:
      default: {

        // <TextField
        //   error
        //   id="outlined-error-helper-text"
        //   label="Error"
        //   defaultValue="Hello World"
        //   helperText="Incorrect entry."
        //   className={classes.textField}
        //   margin="normal"
        //   variant="outlined"
        // />

        return (
          <TextField
            error={!_.isEmpty(errorMessage)}
            helperText={errorMessage}
            fullWidth
            variant="outlined"
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            {..._.omit(props, omitProps)}
          />
        );
      }
    }
  }
}